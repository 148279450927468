<template>
<div class="overflow-y-auto h-screen w-full ">
    <h4 class="font-semibold mt-2 text-white">Bienvenid@</h4>

    <div class="h-full    flex flex-wrap content-start justify-center w-full bg-red-100 outline-none focus:outline-none overflow-y-scroll">
        <!--header-->

        <div class="sticky top-0 flex flex-wrap justify-between p-5 border-b border-solid border-blueGray-200  z-30 bg-white rounded-t w-full">
            <h3 class="text-3xl font-semibold">
                Cotizaciones
            </h3>
            <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="showM=false">
                <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                </span>
            </button>
        </div>
        <!--body-->
        <div class="w-full   overflow-y-scroll flex  flex-wrap justify-start  content-start z-20 h-full">

            <div class="h-full bg-gray-100 py-2 flex flex-wrap w-full content-start px-2 ">

                <div class="flex flex-wrap justify-center h-full bg-gray-300   mt-2 overflow-y-scroll w-full content-start" v-if="info.hasOwnProperty('cotizaciones')">

                    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 w-full">

                        <!-- This is an example component -->
                        <template v-for="(row,index_abono) in info.cotizaciones" v-bind:key="index_abono">
                            <div class="relative mx-auto w-full" :class="CalcularAbonos(row)>=row.total?'bg-green-100':'bg-white'">
                                <a href="#" class="relative inline-block duration-300 ease-in-out transition-transform transform hover:-translate-y-2 w-full">
                                    <div class="shadow p-4 rounded-lg bg-white">
                                        <div class="flex justify-center relative rounded-lg overflow-hidden h-52">

                                            <div class="transition-transform duration-500 transform ease-in-out hover:scale-110 w-full">
                                                <div class="absolute inset-0 bg-black opacity-80">
                                                    <img class="object-cover static h-full w-full" :src="row.productos_cotizaciones.length>0?row.productos_cotizaciones[0].productos.url:''">
                                                </div>
                                            </div>

                                            <div class="absolute flex justify-center bottom-0 mb-3">
                                                <div class="flex bg-white px-4 py-1 space-x-5 rounded-lg overflow-hidden shadow">

                                                    <p class="flex items-center font-medium text-gray-800">
                                                        <i class="text-2xl mr-1 text-blue-500 las la-calendar"></i>
                                                        {{formatFullDate(row.created_at)}}
                                                    </p>

                                                    <p class="flex items-center font-medium text-red-600">
                                                        <i class=" text-2xl text-red-500 mr-1 las la-user-tie"></i>
                                                        {{row.usuario.name}}
                                                    </p>

                                                </div>
                                            </div>

                                            <span :class="row.venta==null||row.venta==''?'bg-blue-500':'bg-green-500'" class="absolute top-0 left-0 inline-flex mt-3 ml-3 px-3 py-2 rounded-lg z-10  text-sm font-medium text-white select-none">
                                                {{row.venta==null||row.venta==''?'COTIZACIÓN':'COMPRA'}}
                                            </span>
                                        </div>

                                        <div class="mt-4 w-full text-left">
                                            <h2 class="font-medium text-base md:text-lg text-gray-800 line-clamp-1" title="New York">
                                                #{{row.id}} - Fecha <span class=" text-gray-400 text-base">/ {{formatFullDate(row.created_at)}}</span>
                                            </h2>
                                            <p class="mt-2 text-sm text-gray-800 line-clamp-1" title="New York, NY 10004, United States">

                                            </p>
                                        </div>

                                        <div class="grid grid-cols-2 grid-rows-2 gap-4 mt-8">
                                            <p class="inline-flex flex-col xl:flex-row xl:items-center text-gray-800">
                                                <i class="las la-money-bill-wave text-xl text-green-700 mr-2"></i>
                                                <span class=" xl:mt-0 text-green-600 font-extrabold">
                                                    Total :${{formatPrice(Number(row.total).toFixed(0))}}
                                                </span>
                                            </p>
                                            <p v-if="row.venta!=null&&row.venta!=''" class="inline-flex flex-col xl:flex-row xl:items-center text-gray-800">
                                                <i class="las la-hand-holding-usd text-xl text-blue-700 mr-2"></i>
                                                <span class=" xl:mt-0 text-blue-600 font-extrabold">
                                                    Abonado: ${{formatPrice(CalcularAbonos(row))}}
                                                </span>
                                            </p>

                                        </div>

                                        <div class="grid grid-cols-2 mt-8">
                                            <div v-if="row.venta!=null&&row.venta!=''" class="flex items-center">

                                                <span @click="el_cotizacion=row;addAbono=true" class=" inline-flex mt-3  px-3 py-2 rounded-lg z-10 bg-blue-700 text-sm font-medium text-white select-none">
                                                    Agregar abono
                                                </span>
                                            </div>

                                            <div class="flex justify-start">
                                                <p class="inline-block font-semibold text-primary whitespace-nowrap leading-tight rounded-xl">
                                                    <span @click="el_cotizacion=row;viewCotizacion=true;" class=" inline-flex mt-3 ml-3 px-3 py-2 rounded-lg z-10 bg-green-600 text-sm font-medium text-white select-none">
                                                        Ver cotización
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                        </template>
                    </div>
                </div>
            </div>
        </div>

        <el-drawer v-model="addAbono" title="" :with-header="false" size="50%">
            <!--content-->
            <div class="h-full    flex flex-wrap content-start justify-center w-full bg-gray-100 outline-none focus:outline-none overflow-y-scroll">
                <!--header-->

                <div class="sticky top-0 flex flex-wrap justify-between p-5 border-b border-solid border-blueGray-200  z-30 bg-white rounded-t w-full">
                    <h3 class="text-3xl font-semibold">
                        Realizar pago
                    </h3>
                    <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="showM=false">
                        <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                        </span>
                    </button>
                </div>
                <!--body-->
                <div class="w-full   overflow-y-scroll flex  flex-wrap justify-start  content-start z-20 h-full py-10">

                    <div class=" flex flex-wrap w-full">

                        <div class="px-3 w-1/2 flex flex-wrap content-center text-base font-extrabold text-red-600 text-center justify-center">
                            Saldo pendiente: ${{formatPrice(Number(el_cotizacion.total)-Number(CalcularAbonos(el_cotizacion)))}}
                        </div>
                        <div class=" px-3  w-1/2">
                            <div class="mb-5">
                                <label for="lName" class="mb-3 block text-base font-medium text-[#07074D]">
                                    Total pago
                                </label>
                                <input type="number" placeholder="Total Abono" v-model="obj_abono.total" @input="verificarTotalAbono($event)" class="w-full  rounded-md border border-[#e0e0e0] text-center py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                            </div>
                        </div>
                    </div>
                    <div class=" flex flex-wrap w-full">
                        <span class="text-xs text-red-600">{{msg}}</span>
                    </div>
                </div>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="addAbono=false">Cancelar</el-button>
                    <el-button @click="guardarAbono()" type="primary">Registrar abono</el-button>

                </div>
            </template>
        </el-drawer>

        <el-drawer v-model="viewCotizacion" title="" :with-header="false" size="90%">
            <!--content-->
            <div class="h-full    flex flex-wrap content-start justify-center w-full bg-red-100 outline-none focus:outline-none overflow-y-scroll">
                <!--header-->

                <div class="sticky top-0 flex flex-wrap justify-between p-5 border-b border-solid border-blueGray-200  z-30 bg-white rounded-t w-full">
                    <h3 class="text-3xl font-semibold">
                        Cotización
                    </h3>

                </div>
                <!--body-->
                <div class="w-full   overflow-y-scroll flex  flex-wrap justify-start  content-start z-20 h-full">

                    <div class="h-full bg-white px-2 flex flex-wrap w-full">
                        <TemplateCotizacion :cotizacion="el_cotizacion" :dn="true" :dc="true" :dtotal="true" :dqr="true" :ddt="true"></TemplateCotizacion>
                    </div>

                </div>

            </div>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="viewCotizacion=false">Cerrar</el-button>

                </div>
            </template>
        </el-drawer>
    </div>
</div>
</template>

<script>
import 'vue-cal/dist/vuecal.css'

import TemplateCotizacion from "@/components/TemplateCotizacion.vue";
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import user from "@/Services/user";
import metodos from "@/Services/metodos";
import abonos from "@/Services/abonos";

import generales from "@/functions/generales";
import {
    dateEquals
} from 'element-plus';

export default {
    components: {
        TemplateCotizacion
    },
    mixins: [user, generales, metodos],
    data() {
        const user = useUserStore()

        return {

            user: user.getAll(),

            fecha: new Date(),
            el_cotizacion: null,
            addAbono: false,
            viewCotizacion: false,
            obj_abono: {
                total: 0
            },
            info: {},
            msg: '',

        }

    },
    mounted() {
        this.getData();
    },
    created() {

    },
    methods: {
        async guardarAbono() {

            let totmsg = 0;
            if (this.obj_abono.total <= 0) {
                this.msg = 'Es necesario agregar un monto mayor a 0';
                this.totmsg++;
            }

            if (this.totmsg > 0) {
                return
            } else {

            }
            emitter.emit("loading", true)
            let dat = {
                id: this.el_cotizacion.id,
                total: this.obj_abono.total
            }
            await abonos.BOTTON(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    //this.limpiar();
                    //console.log(response)
                    //this.getData();
                    //window.location.assign(response)
                    window.location.href = response
                    //this.$router.push(response); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;

                emitter.emit("loading", false)
            });
        },
        verificarTotalAbono($event) {

            let calculoCapital = (Number($event.target.value)).toFixed(0)
            if (Number(calculoCapital) > (this.CalcularDeuda()).toFixed(0)) {
                this.obj_abono.total = Number(this.CalcularDeuda()).toFixed(0);
            } else {
                this.obj_abono.total = Number($event.target.value).toFixed(0)
            }
        },
        CalcularDeuda() {
            if (this.el_cotizacion.id != 0) {
                let total = 0;
                this.el_cotizacion.abonos.forEach(element => {
                    total = Number(total) + Number(element.capital)
                });
                return Number(this.el_cotizacion.total) - Number(total)
            } else {
                return 0;
            }

        },
        CalcularAbonos(row) {

            let total = 0;
            row.abonos.forEach(element => {
                total = Number(total) + Number(element.capital)
            });
            return Number(total)

        },
        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await user.GET_PERFIL().then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.info = response
                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.msg);

                }
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
