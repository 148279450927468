<template>
  <div class="h-screen w-full flex  justify-center  ">
    <div class="w-full bg-white flex h-full " />
  </div>
</template>

    
<script>


import {
    reactive,
    ref
} from "vue";
import userService from "@/Services/user";

import {
    emitter
} from "@/mitt.js";
import {
    useUserStore
} from '@/stores/user'
import {
    useConfigStore
} from '@/stores/config'
import {
    useRouter,
    useRoute
} from 'vue-router'
export default {
    name: "Home",
    components: {

    },
    props: {
        title: {
            type: Number,
            default: 0
        },

    },
    setup(props) {
        const router = useRouter()
        const route = useRoute()
        const user = useUserStore()
        const config = useConfigStore()
        const step = ref(1)
        const tipoperfil = ref(1)
        // expose to template and other options API hooks
        return {
            user: user.getAll(),
            config,
            router,
            route,
            step,
            tipoperfil

        }

    },
    data() {
        return {
            email: '',
            username: '',
            genero: 'Hombre',
            year_u: 0,
            idioma_u: 'es',
            pais: 'Mexico',
            instagram: '',
            facebook: '',
            tiktok: '',
            twiter: '',
            msg: [],
            paises: new Array("Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antarctica", "Antigua and Barbuda",
                "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
                "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana",
                "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burma", "Burundi", "Cambodia", "Cameroon", "Canada", "Cape Verde",
                "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo, Democratic Republic",
                "Congo, Republic of the", "Costa Rica", "Cote d'Ivoire", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark",
                "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea",
                "Eritrea", "Estonia", "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana",
                "Greece", "Greenland", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hong Kong",
                "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy", "Jamaica", "Japan",
                "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South", "Kuwait", "Kyrgyzstan", "Laos", "Latvia",
                "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macedonia", "Madagascar",
                "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia",
                "Moldova", "Mongolia", "Morocco", "Monaco", "Mozambique", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand",
                "Nicaragua", "Niger", "Nigeria", "Norway", "Oman", "Pakistan", "Panama", "Papua New Guinea", "Paraguay", "Peru",
                "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Samoa", "San Marino", " Sao Tome",
                "Saudi Arabia", "Senegal", "Serbia and Montenegro", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia",
                "Solomon Islands", "Somalia", "South Africa", "Spain", "Sri Lanka", "Sudan", "Suriname", "Swaziland", "Sweden",
                "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago",
                "Tunisia", "Turkey", "Turkmenistan", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States",
                "Uruguay", "Uzbekistan", "Vanuatu", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"),

            idiomas: [{
                    id: 'es',
                    nombre: 'Espanol'
                },
                {
                    id: 'en',
                    nombre: 'Ingles'
                },
                {
                    id: 'fr',
                    nombre: 'Frences'
                },
                {
                    id: 'italiano',
                    nombre: 'Italiano'
                },

            ]
        }
    },
    watch: {
        email(value) {
            // binding this to the data value in the email input
            this.email = value;
            this.validateEmail(value);
        },
        edad(value) {
            // binding this to the data value in the email input
            this.edad = value;
            //this.validateEdad(value);
        }
    },
    mounted() {
        this.verificarPerfil()
    },
    methods: {
         showMenu1 (flag)  {
             let menu1 = document.getElementById("menu1");

    if (flag) {
        icon1.classList.toggle("rotate-180");
        menu1.classList.toggle("hidden");
    }
},

 showMenu2  (flag)  {
     let menu2 = document.getElementById("menu2");

    if (flag) {
        icon2.classList.toggle("rotate-180");
        menu2.classList.toggle("hidden");
    }
},


 showMenu3 (flag)  {
     let menu3 = document.getElementById("menu3");
    if (flag) {
        icon3.classList.toggle("rotate-180");
        menu3.classList.toggle("hidden");
    }
},
        years() {
            const year = new Date().getFullYear()
            return Array.from({
                length: year - 1900
            }, (value, index) => 1901 + index)
        },
        validateEmail(value) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                this.msg['email'] = '';
            } else {
                this.msg['email'] = 'Invalid Email Address';
            }
        },
        async verificarPerfil() {

            //console.log(this.user.primer_acceso)
            if (this.user.primer_acceso) {
                switch (this.user.role) {
                    case 'general':
                        router.push('/app/stream');
                        break;
                    case 'creator':
                        router.push('/app/creator');
                        break;

                    default:
                        break;
                }
            }

        }
    }
};
</script>

<style scoped>

</style>
