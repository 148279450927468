/* eslint-disable */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

//import "tailwindcss/tailwind.css";
import VueFeather from 'vue-feather';
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VideoBg from 'vue-videobg'
import VuePictureSwipe from 'vue-picture-swipe';
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
import VueApexCharts from "vue3-apexcharts";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import LazyTube from "vue-lazytube";
import VueMobileDetection from "vue-mobile-detection";

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import  btn_component from '@/components/buttons/btn_component'
import  alert_component from '@/components/alerts/alert_component'
import  number_controls from '@/components/inputs/number_controls'

import  "tabulator-tables"
import  VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'

import "vue-search-select/dist/VueSearchSelect.css"
window.PAR=function (numero){
  return Math.ceil(numero/2) * 2
}

window.RCERCANO=function(value) {
  return (value % 1 <= 0.5) ? Math.floor(value) : Math.ceil(value);
}
window.RARRIBA= function(numero) {
  // Separar la parte entera y decimal del número
  const [entero, decimal] = numero.toString().split(".");
  
  // Convertir el primer dígito del decimal a entero
  const primerDigito = parseInt(decimal[0]);
  
  // Determinar el número entero más cercano en decimal, siempre redondeando hacia arriba
  let enteroDecimal;
  if (decimal.length === 1 && primerDigito === 0) {
    enteroDecimal = parseInt(entero);
  } else {
    enteroDecimal = parseInt(entero) + 1;
  }
  
  // Devolver el número entero más cercano en decimal
  return enteroDecimal;
}

window.PLIENZO= function(precio, alto, ancho) {
  ancho = Math.max(1, Math.ceil(ancho));
  alto = Math.max(1, alto);
  return (precio * alto) * ancho;
}

import vSelect from 'vue-select'

import 'vue-select/dist/vue-select.css';
import VueSignaturePad from 'vue-signature-pad';


const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const app=createApp(App)
  .use(MonthPicker)
  .use(MonthPickerInput)
  .use(VueApexCharts)
  .use(VueSignaturePad)
  .use(router)
  .use(VueMobileDetection)
  .use(ElementPlus)
  .use(LazyTube)
  .use(VueCal)
  .use(Buefy.Default)
  .use(pinia);

  //.use(VueFeather.name, VueFeather)


  app.component('v-select', vSelect)
  app.component('btn_component', btn_component);
  app.component('number_controls', number_controls);
  
  app.component('alert_component', alert_component);
  app.component('VuePictureSwipe', VuePictureSwipe);
  app.component(VueFeather.name, VueFeather);
  app.component('VideoBg', VideoBg);
  app.mount("#app");
