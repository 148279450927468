<template>
    <section class="w-full mx-auto h-full px-4 sm:px-6 lg:px-4 py-4 bg-gray-100 content-start flex flex-wrap">
      <!-- Selector de fecha inicio y fecha fin -->
      <div class="flex items-center space-x-4 py-2">
        <el-date-picker
          v-model="fechaInicio"
          type="date"
          placeholder="Fecha de inicio"
          :default-value="getDefaultStartDate"
        ></el-date-picker>
        <el-date-picker
          v-model="fechaFin"
          type="date"
          placeholder="Fecha de fin"
          :default-value="getDefaultEndDate"
        ></el-date-picker>
        <el-button type="primary" @click="applyDateRange">Aplicar</el-button>
      </div>
      
      <!-- Resto del contenido del componente -->
     <!-- <div v-if="data_reporte != null" :class="{'flex-row': !IsMov(), 'flex-row-reverse': IsMov()}" class="w-full h-12 bg-gray-100 flex flex-wrap content-center justify-between px-2">
        <div class="w-10/12" :class="{'justify-end': IsMov(), 'justify-center': !IsMov()}">
          <el-pagination v-model:currentPage="pagina" small background layout="prev, pager, next" :page-size="data_reporte['per_page']" :total="Number(data_reporte['total'])" @current-change="pageChangue" />
        </div>
      </div>-->
      <Tabla v-if="data_reporte != null" :columns="headings" :rows="data_reporte" :filtros="['producto']" :buscarp="buscar" :filtroApi="false"  />
    </section>
  </template>
  
  <script>
  import { ref, onMounted, onBeforeUnmount } from 'vue';
  import { emitter } from '@/mitt.js';
  import { useUserStore } from '@/stores/user';
  import generales from '@/functions/generales';
  import reportes from '@/Services/reportes';
  import DynamicChart from '@/components/charts/DynamicChart.vue';
  import { filter } from 'lodash';
  import Tabla from "@/components/Tabla.vue";
 
  export default {
    components: {
      Tabla
    },
    mixins: [generales],
    setup() {
      const user = useUserStore();
      const key = ref(0);
      const buscar = ref('all');
      const pagina = ref(1);
      const fechaInicio = ref(getDefaultStartDate());
      const fechaFin = ref(getDefaultEndDate());
      const data_reporte = ref(null);
      
      const headings= ref([
        {
    key: 'cliente',
    name: 'Cliente ',
    show: true,
    class: 'text-green-500 font-extrabold w-[300px]'
},
{
    key: 'producto',
    name: 'Producto ',
    show: true,
    class: 'text-green-500 font-extrabold w-[200px]'
},

{
    key: 'vendedor',
    name: 'Vendedora',
    show: true,
    class: ' w-[200px]'
},

{
    key: 'fecha_venta',
    name: 'Fecha venta',
    show: true,
    class: ' w-[200px]'
},
{
    key: 'fecha_produccion',
    name: 'Fecha producción',
    show: true,
    class: ' w-[200px]'
},
{
    key: 'fecha_terminado',
    name: 'Fecha terminado',
    class: ' w-[200px]',
    show: true
},
{
    key: 'dias_produccion_habiles',
    name: 'Dias producción',
    show: true,
    class: ' w-[200px]'
},

{
    key: 'instalacion',
    name: 'Fecha instalación',
    show: true,
    class: ' w-[200px]'
},

{
    key: 'dias_instalacion_habiles',
    name: 'Dias instalacion',
    show: true,
    class: ' w-[200px]'
},


])
  
      const pageChangue = (val) => {
        pagina.value = val;
        getData();
      }
  
      const applyDateRange = () => {
        getData();
      }
    
      const getData = async () => {
        emitter.emit('loading', true);
        let dat = {
        //  page: pagina.value,
          fechaInicio: formatFecha(fechaInicio.value), // Pasa la fecha de inicio
          fechaFin: formatFecha(fechaFin.value),     // Pasa la fecha de fin
        }
        await reportes.GET_PRODUCCION_CONTADOR(dat).then((response) => {
          if (response != null && !response.hasOwnProperty('error')) {
            data_reporte.value = response;
          } else {
            // emitter.emit("alerta_show",response.msg);
          }
          emitter.emit('loading', false);
        });
      }
      const formatFecha=(fecha) =>{
        const dateObj = new Date(fecha);
        return dateObj.toISOString();
      const formattedDate = `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
      return formattedDate;
    }
      // Función para obtener el primer día del mes actual
      function getDefaultStartDate() {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), 1);
      }
  
      // Función para obtener el último día del mes actual
      function getDefaultEndDate() {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth() + 1, 0);
      }
  
      onMounted(() => {
        getData();
      })
  
      onBeforeUnmount(() => {
        // Add any cleanup logic here.
      })
  
      return {
        getData,
        pageChangue,
        buscar,
        headings,
        key,
        data_reporte,
        fechaInicio,
        fechaFin,
        applyDateRange
      };
    },
  };
  </script>
  
  <style scoped>
    /* Your component-specific styles here */
  </style>
  


