<template lang="es">
<section class=" w-full mx-auto h-full px-4 sm:px-6 lg:px-4 py-4 bg-gray-100  content-start flex flex-wrap ">

    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-3 ">

        <div class="col-span-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-3">
            <div class=" col-span-2 w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">

                <h1 class="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900">
                    Estadísticas de vendedores
    
                </h1>
                <h1 class="font-bold mt-2  font-heading text-gray-900 w-full flex flex-wrap justify-center content-center">
                  
                    <el-date-picker class="w-full" v-model="fecha" type="daterange" unlink-panels range-separator="-" start-placeholder="De" end-placeholder="Hasta" @change="changuekey()" />

                </h1>
            </div>
            <div class=" col-span-2 w-full bg-white rounded-lg px-2 py-2 flex  justify-start items-center">
    
               
            </div>
            </div>

        <div class="col-span-2 h-42 bg-white rounded-lg px-2 py-2 flex  justify-start items-center">

            <DynamicChart v-if="key" :endpoint="'?grafica=cotizaciones_perido&fecha_inicio='+formatDateEN(fecha[0])+'&fecha_fin='+formatDateEN(fecha[1])" chartType="bar" chartLabel="Ventas"></DynamicChart>
        </div>
      
        

    </div>

</section>
</template>

    
    
<script>
import {
    emitter
} from "@/mitt.js";
import {
    useUserStore
} from '@/stores/user'

import generales from "@/functions/generales";
import tablero from "@/Services/tablero";
import DynamicChart from '@/components/charts/DynamicChart.vue'

import {
    filter
} from "lodash";


export default {
    components: {
      
        DynamicChart
    },
    mixins: [generales],
    data() {
        const user = useUserStore()

        return {
            key:0,
           /*
            inicio: this.buscar == 'all' ? this.formatDateEN(this.fecha[0]) : '2021-01-28',
                fin: this.formatDateEN(this.fecha[1]),
            
                */
           
            fecha: [this.getFirstDayOfMonth(), this.getLastDayOfMonth()],

        }

    },
    mounted() {

    },
    created() {
      
    },
    methods: {
        changuekey(){
           
                this.key=0;
                this.key=1;
           
        }
       

    }
}
</script>
    
    
<style scoped>

</style>
