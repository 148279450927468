<template>
  <section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-center content-center py-4  px-5 overflow-y-hidden">
    <div class="w-4/12 h-full px-5">
      <div class=" mt-2 justify-start flex flex-wrap w-full px-2">
        <span
          class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold "
          htmlFor="nombre"
        >
          Proveedor
        </span>
        <searchSelect
          :rows="provedores_data"
          :valor="provedor"
          :label="'provedor'"
          @seleccionado="action"
        />
      </div>
      <div class="mb-2  justify-start flex flex-wrap w-full px-2">
        <span
          class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold "
          htmlFor="nombre"
        >
          Costo total 
        </span>
        <input
          id="cantidad"
          v-model="costo_orden"
          class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-white leading-tight focus:outline-none focus:shadow-outline h-10"
          type="number"
          placeholder="Total orden"
        >
      </div>
      <div class="mb-2  justify-start flex flex-wrap w-full px-2">
        <span
          class="px-4 py-2 block text-blue-700 text-start text-sm font-semibold "
          htmlFor="nombre"
        >
          Total productos : {{CalculoTotalProductos()}}
        </span>
      
      </div>
       <div class="mb-2  justify-start flex flex-wrap w-full px-2">
        <span
          class="px-4 py-2 block text-green-700 text-start text-sm font-semibold "
          htmlFor="nombre"
        >
          Utilidad :{{CalculoUtilidadProductos()}}
        </span>
       
      </div>




      
      <div class="mb-2  justify-start flex flex-wrap w-full px-2">
        <span class="text-red-700 text-xs font-extrabold">{{ msg }}</span>
        <button
          :class="Object.keys(productos_entrada).length>0?'text-blue-800 bg-blue-500 hover:bg-blue-700 hover:text-white  border-blue-800 cursor-pointer ':' text-gray-800 bg-gray-500  border-gray-800 cursor-no-drop'"
          class="  w-full  bg-opacity-40 border   border-solid  font-bold uppercase px-6 py-2 text-sm outline-none  rounded focus:outline-none  ease-linear transition-all duration-150"
          type="button"
          @click="Object.keys(productos_entrada).length>0?guardar():msg='No ha agregado productos a esta entrada'"
        >
          Ingresar Entrada
        </button>
      </div>
    </div>
    <div class="   flex  flex-wrap justify-start content-center  w-8/12 h-full">
      <div
        style="height:120px"
        class="flex items-center justify-between p-6 border-t border-solid    bg-gray-200 w-full"
      >
        <div class=" mt-2 justify-start flex flex-wrap w-6/12 px-2">
          <span
            class="px-4 py-2 block text-gray-700 text-start text-xs font-semibold "
            htmlFor="nombre"
          >
            Producto
          </span>
          <searchSelect
            :rows="productos_data"
            :valor="producto"
            :label="'producto'"
            @seleccionado="action_producto"
          />
        </div>
        <div class="mb-2  justify-start flex flex-wrap w-3/12 px-2">
          <span
            class="px-4 py-2 block text-gray-700 text-start text-xs font-semibold "
            htmlFor="nombre"
          >
            Cantidad
          </span>
          <input
            id="cantidad"
            v-model="cantidad"
            class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-white leading-tight focus:outline-none focus:shadow-outline h-10"
            type="number"
            placeholder="Cantidad"
          >
        </div>

        <div class="mb-2 mt-8  justify-start flex flex-wrap w-3/12 px-2">
          <button
            class="text-green-800 w-full h-10 bg-green-500 bg-opacity-40 border hover:text-white  hover:bg-green-700 border-solid border-green-800 font-bold uppercase px-6 py-1 text-xs outline-none  rounded focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="agregar()"
          >
            Agregar
          </button> 
        </div>
      </div>
      <div
        style="height:calc(100% - 120px)"
        class="  px-2 py-2 bg-blue-200 overflow-y-auto w-full "
      >
        <div
          v-for="(row_prod,index_prod) in productos_entrada"
          :key="index_prod"
          class=" my-1 bg-gray-100 px-2 py-1 w-full rounded  justify-start flex"
        >
          <span
            class="h-6 w-6 bg-red-700  text-xs text-white mx-2 my-3 font-extrabold px-2 py-1 rounded cursor-pointer"
            @click="delete productos_entrada[index_prod]"
          >
            x
          </span>
          <span class="h-6 bg-black text-white text-xs  mx-2 my-3 font-extrabold px-2 py-1 rounded-xl cursor-pointer">
            {{ row_prod.cantidad }}
          </span>
          <div class=" bg-yellow-600 text-white text-xs mx-2  font-extrabold px-2 py-1 rounded-xl cursor-pointer flex flex-wrap justify-center">
            <span class=" bg-yellow-600 text-white text-xs mx-2  font-extrabold px-2  rounded-xl cursor-pointer">
            ${{ formatPrice((row_prod.cantidad * row_prod.precio).toFixed(2)) }}
           
          </span>
           <span class="  text-black text-xs mx-2  font-extrabold px-2 py-1 rounded-xl cursor-pointer">
           
            Total Venta
          </span>
          </div>
          <!--v-for="(row,index) in row_prod" v-bind:key="index"-->
          <div class="h-10 flex flex-wrap my-1 bg-white px-2 py-1 w-full rounded   justify-start content-center">
            {{ row_prod.nombre }}
            <span
              v-for="(prod_mod,index_pm) in row_prod.productos_modificadores"
              :key="index_pm"
              class="bg-blue-500 px-1 mx-1 rounded h-6 py-1 font-extrabold text-white text-xs flex w-auto flex-wrap"
            >
              <!--{{/*prod_mod.modificadores.modificador.nombre*/}}-->  {{ prod_mod.modificadores.nombre }}
            </span>
          </div>
        </div>
      </div>
    </div>
    
    <!--footer-->
  </section>
</template>

<script>
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import provedores from "@/Services/provedores";
import ordenes_entrada from "@/Services/ordenes_entrada";
import productos from "@/Services/productos";
import generales from "@/functions/generales";

import searchSelect from "@/components/SearchSelect.vue";
export default {
    components: {

        searchSelect
    },
    mixins: [provedores, productos,generales,ordenes_entrada],
    data() {
        const user = useUserStore()

        return {
            headings: [

                {
                    key: 'id',
                    name: '#',
                    show: true
                }, {
                    key: 'nombre',
                    name: 'Nombre',
                    show: true
                },
                {
                    key: 'telefono',
                    name: 'Telefono',
                    show: true
                },
                {
                    key: 'correo',
                    name: 'Correo',
                    show: true
                },
                {
                    key: 'rfc',
                    name: 'Rfc',
                    show: true
                },

                {
                    key: 'edit',
                    name: 'Editar',
                    show: true
                },
                {
                    key: 'eliminar',
                    name: 'Eliminar',
                    show: true
                }
            ],
            buscar: '',
            open: false,
            user: user.getAll(),
            data: [],
            actionModal: null,
            showM: false,
            id: 0,
           costo_orden:0,
            provedor: null,
            producto: null,
            cantidad:1,
            provedores_data: [],
            productos_data: [],
            productos_entrada:{},

        }

    },
    mounted() {
        this.getData();
        this.getProductos();
        this.getProveedores();
    },
    created() {

    },
    methods: {
      CalculoUtilidadProductos(){
let total=0;
total=this.CalculoTotalProductos()-Number(this.costo_orden);
return total;
      },
      CalculoTotalProductos(){

let total=0;
Object.entries(this.productos_entrada).forEach(([key, element]) => {

  total=Number(total)+(Number(element.precio)*Number(element.cantidad))
});
return total;
      },
        action(ev) {
            console.log(ev.value)
            this.provedor = ev.value
        },
        action_producto(ev){
            this.producto=ev.value;

        },
        
        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },
        agregar(){
            let prod=this.producto;
            prod.ventas_id=0;
console.log(this.productos_entrada,prod)

    if(this.productos_entrada.hasOwnProperty(prod.id)){
this.productos_entrada[prod.id].cantidad=this.productos_entrada[prod.id].cantidad+this.cantidad;
    }else{
       
        //this.productos_entrada[prod.id]=;
        this.productos_entrada[prod.id]=prod;
        this.productos_entrada[prod.id].cantidad=this.cantidad;
         console.log(this.productos_entrada)
    }
 



        },

        limpiar() {
            this.id = 0;
            this.provedor=null;
            this.productos_entrada={};
            this.costo_orden=0;
            
        },
       
        async getProveedores() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await provedores.GET(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.provedores_data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async getProductos() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await productos.GET_NOT_OPCION(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.productos_data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await provedores.GET(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async guardar() {
           
            console.log('entree----')
            if (Object.keys(this.productos_entrada).length==0) {
 emitter.emit("alerta_show",'Seleccionar almenos un producto');
 return;
            }
            if(Number(this.costo_orden)<=0){
 emitter.emit("alerta_show",'El costo total no puede ser igual o menor a 0');
 return;
         
            }
             if(this.provedor==null){
 emitter.emit("alerta_show",'Es necesario seleccionar un provedor');
 return;
         
            }
             let dat = {
                provedor:this.provedor.id,
                precio:this.costo_orden,
                productos_entrada: this.productos_entrada,
              

            }
                this.crear(dat)
           // } else {
                //this.editar(dat)
            //}
        },
        async editar(dat) {
            emitter.emit("loading", true)

            await provedores.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async crear(dat) {
            emitter.emit("loading", true)

            await ordenes_entrada.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    this.productos_entrada={};
                    //this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        

    }
}
</script>
