import moment from "moment-timezone";
import dayjs from "dayjs";

import rt from "dayjs/plugin/relativeTime";
import { es } from "dayjs/locale/es";

dayjs.extend(rt);

export default {
  data() {
    return {};
  },

  created() {},

  methods: {
    getMesAno(fecha){
      const meses = [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
      ];
      
      
      
      const mes = meses[fecha.getMonth()];
      const año = fecha.getFullYear();
      
     return `${mes} de ${año}`;
    },
    verfiNull(valor) {

      if (valor == null || valor == "" || valor == "null"|| valor == 0 ) {
        return "";
      } else {
        return valor;
      }

    },
    IsMov() {
      return true;
      return this.$isMobile();
    },
    timeAgo(date) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      options.timeZone = "UTC";
      options.timeZoneName = "short";
      let fecha = this.formatFullDateTime(date);
      let f = new Date(date);
      let ff = f.toLocaleString("es-MX", options);
      return ff;
      console.log(fecha, "-------fecha------", date);
      return dayjs().to(dayjs(date)); // 31 years
    },

    exportCSV(id) {
      let csv = [];
      var container = document.querySelector("#" + id);
      const rows = container.querySelectorAll("tr");
      for (const row of rows.values()) {
        const cells = row.querySelectorAll("td, th");
        const rowText = Array.from(cells).map((cell) => cell.innerText);
        csv.push(rowText.join(","));
      }
      const csvFile = new Blob([csv.join("\n")], {
        type: "text/csv;charset=utf-8;",
      });
      // saveAs(csvFile, "data.csv");

      var temp_link = document.createElement("a");

      // Download csv file
      temp_link.download = id + ".csv";
      var url = window.URL.createObjectURL(csvFile);
      temp_link.href = url;

      // This link should not be displayed
      temp_link.style.display = "none";
      document.body.appendChild(temp_link);

      // Automatically click the link to trigger download
      temp_link.click();
      document.body.removeChild(temp_link);
    },

    //archivo imagen

    //end archivo imagen

    formatNumberAnd0(event) {
      var inputChar = String.fromCharCode(event.keyCode);
      var code = event.keyCode;
      var allowedKeys = [8];

      console.log();
      if (allowedKeys.indexOf(code) !== -1) {
        return;
      }
      event.target.value = event.target.value.replace(
        /[^\d\/]|^[\/]*$/g,
        "" // To allow only digits and `/`
      );
    },
    formatNumber(event) {
      var inputChar = String.fromCharCode(event.keyCode);
      var code = event.keyCode;
      var allowedKeys = [8];

      console.log();
      if (allowedKeys.indexOf(code) !== -1) {
        return;
      }
      event.target.value = event.target.value.replace(
        /[^\d\/]|^[\/]*$/g,
        "" // To allow only digits and `/`
      );
    },
    CapitalizeTo(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    validarNumero(valor) {
      // Comprobar si el valor es null, undefined o una cadena vacía
      if (valor === null || valor === undefined || valor === '') {
        return 0;
      }
      // Comprobar si el valor es numérico
      if (isNaN(valor)) {
        return 0;
      }
      // Convertir el valor a número y devolverlo
      return Number(valor);
    },
    validation(data) {
      let text = "";
      for (var [key, value] of Object.entries(data.errors)) {
        text +=
          '<div class="w-full flex flex-wrap text-center justify-center content-center"><span>' +
          value +
          "</span> </div><br>"; // "a 5", "b 7", "c 9"
      }
      return text;
      //emitter.emit("alerta_show",text);
    },
    /**calculos de datos */

    CalcularAbonos(abonos) {
      let total = 0;
      abonos.forEach((element) => {
        total = Number(total) + Number(element.cantidad);
      });
      return total;
    },

    /**--------- */

    getFirstDayOfMonth() {
      var date = new Date();
      var d = new Date(date.getFullYear(), date.getMonth(), 1);
      let month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    formatDateEN(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getMes(date) {
      return moment(date).format("MM");
    },
    formatTimeName(date) {
      return moment(date)
        .tz("HH:mm:ss", "America/Monterrey")
        .format("LT");
    },
     formatFecha(fecha) {
      return format(new Date(fecha), 'dd/MM/yyyy'); // Formatea la fecha como desees
    },
    formadivateTimeLT(date) {
      return moment(date)
        .tz("HH:mm:ss", "America/Monterrey")
        .format("L");
    },
    formatDateLT(date) {
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      options.timeZone = "UTC";
      options.timeZoneName = "short";
      let fecha = this.formatFullDateTime(date);
      let f = new Date(date);
      let ff = f.toLocaleString("es-MX", options);

      return String(ff).replace(", UTC", "");
    },
    getAno(date) {
      return moment(date).format("YYYY");
    },
    formatDateTime(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    formatDateTimeLT(date) {
      console.log(date, "---date---");
      return moment(date).format("YYYY-MM-DD HH:mm");
    },
    getLastDayOfMonth() {
      var date = new Date();

      var d = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      let month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
      // return lastDay;
    },

    getfisrtDay() {
      var myCurrentDate = new Date();
      var myPastDate = new Date(myCurrentDate);
      myPastDate.setDate(myPastDate.getDate() - 7);
      return myPastDate;
    },

    formatPrice(value) {
      value = value.toString().replace(".00", "");
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    setMasOneDay(date_d) {
      var myPastDate = new Date(date_d);
      myPastDate.setDate(myPastDate.getDate() + 1);
      return myPastDate;
    },
    formatFullDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    formatFullDateTime(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return (
        [year, month, day].join("-") +
        " " +
        d.getUTCHours() +
        ":" +
        d.getUTCMinutes() +
        ":" +
        d.getUTCSeconds()
      );
    },

    //functions table

    colorProduccion(status) {
      switch (status) {
        case "EN PRODUCCION":
          return "bg-yellow-600 text-black font-extrabold";
          break;
        case "TERMINADO":
          return "bg-green-500 text-black font-extrabold";
          break;

        default:
          return "bg-red-500 text-black font-extrabold";
          break;
      }
    },
    getStatusInstalacion(row) {
      let count = 0;
      row.citas.forEach((element) => {
        if (element.tipo == "instalacion") {
          count++;
        }
      });
      if (count > 0) {
        return "EN PROGRESO";
      } else {
        return "SIN PROGRAMAR";
      }
    },
    colorInstalacion(row) {
      row = row._cell.row.data;
      let col = "";
      switch (row.status_instalacion) {
        case "INCOMPLETO":
          col = "bg-orange-500 text-black font-extrabold";
          break;
        case "INSTALADO":
          col = "bg-green-500 text-black font-extrabold";
          break;
        case "SIN PROGRAMAR":
          col = "bg-red-500 text-black font-extrabold";
          break;
        case "EN PROGRESO":
          col = "bg-yellow-600 text-black font-extrabold";
          break;

        default:
          col = "bg-red-500 text-black font-extrabold";
          break;
      }
      return `<span class="${col} text-black text-xs font-extrabold px-2 py-1 rounded-full"> ${
        row.status_instalacion == null || row.status_instalacion == ""
          ? this.getStatusInstalacion(row)
          : row.status_instalacion
      }  </span>`;
    },
    colorVerificar(row) {
      row = row._cell.row.data;
      let col = "";
      switch (row.status_verificar) {
        case "PENDIENTE DE VERIFICAR":
          col = "bg-yellow-500 text-black font-extrabold";
          break;
        case "RECHAZADA":
          col = "bg-red-500 text-black font-extrabold";
          break;
        case "APROBADA":
          col = "bg-green-500 text-black font-extrabold";
          break;

        default:
          col = "bg-blue-500 text-black font-extrabold";
          break;
      }

      return `<span class="${col} text-black text-xs font-extrabold px-2 py-1 rounded-full"> ${
        row.status_verificar == null || row.status_verificar == ""
          ? "SIN VERIFICAR"
          : row.status_verificar
      }  </span>`;
    },
    impar(numero) {
      //?'':'white'
      if (numero % 2 == 0) {
        return "gray-400 bg-opacity-10";
      } else {
        return "white";
      }
    },
    formatDat(row) {
      row = row._cell.row.data;
      var options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      let dat = new Date(row.created_at);
      return dat.toLocaleDateString("es-ES", options);
    },
    getCliente(row) {
      row = row._cell.row.data;
      return row.clientes.name;
    },
    getUsuario(row) {
      row = row._cell.row.data;

      console.log(row, "--");
      return row.usuario.name;
    },
    getCompras(row) {
      row = row._cell.row.data;

      return `<span class="bg-blue-300 text-black text-xs font-extrabold px-2 py-1 rounded-full"> ${row.citas.length} -  Citas </span>`;
    },
    CalcExistencias(row) {
      row = row._cell.row.data;
      let total = 0;
      let total_vendidos = 0;
      row.existencias.forEach((element) => {
        total = Number(total) + element.cantidad;
      });
      row.detalle_ventas.forEach((element) => {
        total_vendidos = Number(total_vendidos) + element.cantidad;
      });
      return total - total_vendidos;
    },
    
    verificarTipo(tipo) {
      switch (tipo) {
        case "distancia_permitir":
          return "Permite estas Cordenadas";
          break;
        case "distancia_bloquear":
          return "Bloquea estas Cordenadas";
          break;
        case "bloqueo_hora":
          return "Bloquea por horas";
          break;

        default:
          break;
      }
    },
  },
};
