<template>
    <section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-start  ">
        <div class="flex items-wrap justify-start content-center space-x-4 py-2 w-full px-5">
            <el-date-picker
              v-model="fecha[0]"
              type="date"
              placeholder="Fecha de inicio"
              :default-value="getDefaultStartDate"
            ></el-date-picker>
            <el-date-picker
              v-model="fecha[1]"
              type="date"
              placeholder="Fecha de fin"
              :default-value="getDefaultEndDate"
            ></el-date-picker>
            <el-button type="primary" @click="applyDateRange">Aplicar</el-button>
          </div>
          
        <!--<div v-if="!IsMov()" class="w-full h-24 bg-gray-100 flex flex-wrap content-center justify-start px-2">
           
            <div class="">
                <el-pagination v-model:currentPage="pagina" small background layout="prev, pager, next" :page-size="data['per_page']" :total="Number(data['total'])" @current-change="pageChangue" class="" />
    
            </div>
    
        </div>
        <div v-if="IsMov()" class="w-full h-24 bg-gray-100 flex flex-wrap content-center justify-end px-2 ">
    
            <div class="w-10/12 h-12 justify-end content-center flex flex-wrap ">
                <el-pagination v-model:currentPage="pagina" small background layout="prev, pager, next" :page-size="data['per_page']" :total="Number(data['total'])" @current-change="pageChangue" class="" />
    
            </div>
           
    
        </div>-->
  
        
    <VistaDetallePedido v-if="data" :cotizaciones="data"></VistaDetallePedido>
        <!--<Tabla v-bind:key="reftable" v-if="data.hasOwnProperty('data')" :add="false" :columns="headings" :rows="data.data" :filtros="['email','phone','id']" :buscarp="buscar" :filtroApi="true" :paginate="true" :page="{
                page:data.current_page,
                total:data.last_page
              }" />-->
    
       
    
    </section>
    </template>
    
        
        
    <script>
    import {
        emitter
    } from "../../../mitt.js";
    import {
        useUserStore
    } from '../../../stores/user'
    import VistaDetallePedido from "@/components/VistaDetallePedidoInstalados.vue";
    import generales from "@/functions/generales";
    import metodos_pagos from "@/Services/metodos_pagos";
    import abonos from "@/Services/abonos";
    import cotizaciones from "@/Services/cotizaciones";
    
    import {
        filter
    } from "lodash";
    export default {
        components: {
          
         
            VistaDetallePedido
        },
        mixins: [generales, cotizaciones, metodos_pagos, abonos],
        data() {
            const user = useUserStore()
    
            return {
                headings: [
    
                    {
                        key: 'id',
                        name: '# Cot',
                        show: true
                    },
                    {
                        key: 'idventa',
                        name: '# venta',
                        show: true
                    },
                    {
                        key: 'fecha_ago',
                        name: 'Fecha Cotización',
                        show: true
                    },
    
                    {
                        key: 'clientes',
                        name: 'Cliente',
                        show: true
                    },
    
                    {
                        key: 'usuario',
                        name: 'Usuario',
                        show: true
                    },
                    {
    
                        key: 'status_produccion',
                        name: 'Estatus producción',
                        show: true
                    },
    
                    /* {
                         key: 'edit',
                         name: 'Editar',
                         show: true
                     },*/
    
                ],
                dates: [],
                upbuton: 0,
                el_cotizacion: {
                    id: 0
                },
                open: false,
                user: user.getAll(),
                metodos_data: [],
                data: {},
                actionModal: null,
                viewCotizacion: false,
                showM: false,
                info: '',
                id: 0,
                obj_abono: {
                    cotizacion: 0,
                    tipo_pago: '',
                    impuesto: 0,
                    comision: 0,
                    total: 0,
                },
                addAbono: false,
                tipo_metodo: null,
                buscar: 'all',
                pagina: 1,
                limit: 0,
                reftable: 0,
                tipo: 'instaladores',
                fecha: [this.getDefaultStartDate(), this.getDefaultEndDate()],
    
            }
    
        },
        mounted() {
            emitter.on('closeProduccion', () => {
    
                this.closeProduccion();
    
            });
        },
        async created() {
            if (this.$route.query.hasOwnProperty('cid')) {
                this.buscar = this.$route.query.cid;
            }
    
            await this.getData()
            await this.getDataMetodos();
        },
        methods: {
            applyDateRange  () {
        this.getData();
      },
             getDefaultStartDate() {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), 1);
      },
  
      // Función para obtener el último día del mes actual
       getDefaultEndDate() {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth() + 1, 0);
      },
            IsMov() {
    
                //return true;
                return this.$isMobile()
            },
            accionProduccion(row) {
    
                this.el_cotizacion = row;
                this.viewCotizacion = true;
    
            },
            async pageChangue(val) {
    
                this.page = val;
                await this.getData();
    
            },
    
            infoModal(row) {
                this.info = row;
                console.log(row, '---info---')
            },
            paginaSearch(pagina) {
                console.log('---- dat---' + pagina)
                this.pagina = pagina;
    
                this.getData();
            },
    
            addRow() {
                this.showM = !this.showM;
                this.limpiar()
            },
            limpiar() {
                this.viewCotizacion = false;
            },
            closeProduccion() {
                this.viewCotizacion = false;
            },
            buscador(row) {
                if (String(row.telefono).toUpperCase().search(String(this.buscar).toUpperCase()) != -1 || (String(row.nombre)).toUpperCase().search(String(this.buscar).toUpperCase()) != -1) {
                    return true;
                } else {
                    return false;
                }
            },
            getColumns() {
                let col = 0;
                this.headings.forEach(element => {
                    if (element.show) {
                        col = col + 1
                    }
                });
                return col;
            },
    
            buscarGet(text) {
                this.buscar = text;
                if (text == '') {
                    this.buscar = 'all';
                }
                this.getData();
            },
    
            async getData() {
                this.showM = false;
                this.actionModal = null;
                emitter.emit("loading", true)
                let dat = {
                    search: this.buscar,
                    page: this.pagina,
                    tipo: this.tipo,
                    inicio: this.formatDateEN(this.fecha[0]),
                    fin: this.formatDateEN(this.fecha[1]),
                   // data: {}
                }
    
                await cotizaciones.GET_PRODUCCION_INSTALADOS_06(dat).then(response => {
    
                    if (response != null && !response.hasOwnProperty('error')) {
    
                        
    
                        this.data = response;
    
                       
                        this.reftable = this.reftable + 1;
    
                        //this.$router.push('/app/'); 
                    } else {
    
                        emitter.emit("alerta_show", response.error);
    
                    }
                    emitter.emit("loading", false)
                });
            },
    
            async Verificar(status) {
    
                let dat = {
                    id: this.el_cotizacion.id,
                    status_verificar: status,
                    observaciones_verificar: this.el_cotizacion.observaciones_verificar
    
                }
                if (status == 'APROBADA') {
                    dat.status_produccion = 'EN PRODUCCION'
                }
    
                if (this.el_cotizacion.id == 0) {
                    //this.crear(dat)
                } else {
                    this.editar(dat)
                }
            },
    
            async editar(dat) {
                emitter.emit("loading", true)
    
                await cotizaciones.EDITAR_PARAMS(dat).then(response => {
    
                    if (response != null && !response.hasOwnProperty('error')) {
    
                        this.el_cotizacion = response;
                        //this.$router.push('/app/'); 
                    } else {
    
                        // emitter.emit("alerta_show",response.msg);
    
                    }
                    this.showM = false;
                    this.limpiar();
                    emitter.emit("loading", false)
                });
            },
    
        }
    }
    </script>
    