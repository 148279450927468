<template>
  <section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-center content-start ">
    <div
      x-show="selectedRows.length"
      class="bg-teal-200 fixed top-0 left-0 right-0 z-40 w-full shadow"
    />

    <Tabla
      :columns="headings"
      :rows="data"
      :filtros="['nombre','telefono','correo','rfc']"
    />

      
    <el-drawer @closed="getData()" v-model="showM" title="" :with-header="false" :size="$isMobile()?'100%':'60%'">
      <!--content-->
      <div class="h-full    flex flex-wrap content-start justify-center w-full  outline-none focus:outline-none overflow-y-scroll">
          <!--header-->

          <div class="sticky top-0 flex flex-wrap justify-between p-5 border-b border-solid border-blueGray-200 text-xl font-extrabold z-30 bg-white rounded-t w-full">
            {{ id==0?'Crear Unidad':'Editar Unidad' }}
          </div>
          <!--body-->
          <div class="w-full   overflow-y-scroll flex  flex-wrap justify-start  content-start z-20 h-full">

              <div class="h-full bg-white px-2 flex flex-wrap w-full justify-start">

                  <div class="h-full overflow-y-scroll flex flex-wrap content-start">
                    <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                      <span
                        class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold "
                        htmlFor="nombre"
                      >
                        Nombre
                      </span>
                      <input
                        id="cantidad"
                        v-model="nombre"
                        class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
                        type="text"
                        placeholder="Nombre"
                      >
                    </div>
                    <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2">
                      <span
                        class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold "
                        htmlFor="nombre"
                      >
                        Descripcion
                      </span>
                      <input
                        id="cantidad"
                        v-model="descripcion"
                        class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
                        type="text"
                        placeholder="Descripción"
                      >
                    </div>
                    
        
                           
        
                   

                  </div>
              </div>

          </div>

      </div>
      <template #footer>
          <div class="dialog-footer">

            <button class="my-1 bg-green-500 mx-1 px-2 py-2 text-base font-medium rounded border-2  border-black" @click="guardar()">Guardar</button>
            <button @click="showM=false" class="my-1 bg-blue-200 mx-1 px-2 py-2 text-base font-medium rounded border-2  border-black">Cerrar</button>
          
           

          </div>
      </template>
  </el-drawer>

  

  </section>
</template>

<script>
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import unidades from "@/Services/unidades";
import Tabla from "@/components/Tabla.vue";
export default {
    components: {
Tabla
    },
    mixins: [unidades],
    data() {
        const user = useUserStore()

        return {
            headings: [
               
                {
                    key: 'id',
                    name: '#',
                    show: true
                },{
                    key: 'nombre',
                    name: 'Nombre',
                    show: true
                },
                {
                    key: 'descripcion',
                    name: 'Descripcion',
                    show: true
                },
             
                 {
                    key: 'edit',
                    name: 'Editar',
                    show: true
                },
                 {
                    key: 'eliminar',
                    name: 'Eliminar',
                    show: true
                }
            ],
            buscar:'',
            open: false,
            user: user.getAll(),
            data: [],
            actionModal: null,
            showM: false,
            id: 0,
            nombre: '',
            descripcion: '',
         

        }

    },
    mounted() {

    },
    created() {
        this.getData()
    },
    methods: {
        addRow(){
            this.showM=!this.showM;
            this.limpiar()
        },
        
        limpiar() {
            this.id = 0;
            this.nombre = '';
            this.descripcion = '';
         
        },
        edit(row) {
            this.id = row.id;
            this.nombre = row.nombre;
            this.descripcion = row.descripcion;
            
            this.showM = true;
            this.actionModal = null;
        },
        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await unidades.GET(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async guardar() {
            if(this.nombre==''){
            emitter.emit("alerta_show",'El campo nombre es requerido');
            return false;
          }
            let dat = {
                id:this.id,
                nombre: this.nombre,
                descripcion: this.descripcion,
              
                
            }
            if (this.id == 0) {
                this.crear(dat)
            } else {
                this.editar(dat)
            }
        },
        async editar(dat) {
            emitter.emit("loading", true)
            
            await unidades.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async crear(dat) {
            emitter.emit("loading", true)
          
            await unidades.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async eliminar(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await unidades.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
