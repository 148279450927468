<template>
<section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-center content-start ">
    <div v-if="data.hasOwnProperty('last_page')&&Number(data['last_page'])>1" class="w-full h-24 bg-gray-200 flex flex-wrap content-center justify-start px-2">
        <div class="flex flex-wrap content-center justify-end w-full sm:w-full md:w-6/12 lg:w-auto xl:auto">
            <el-pagination v-model:currentPage="pagina" small background layout="prev, pager, next" :page-size="data['per_page']" :total="Number(data['total'])" @current-change="pageChangue" class="" />

        </div>

    </div>

    <Tabla :idt="'tablll'" v-bind:key="reftable" v-if="data.hasOwnProperty('data')" :add="false" :columns="headings" :rows="data.data" :filtros="['email','phone','id']" :buscarp="buscar" :filtroApi="true" :paginate="true" :page="{
          page:data.current_page,
          total:data.last_page
         
        }" />

    <el-drawer v-model="Show_FechaLlegada" title="" :with-header="false" :size="$isMobile()?'100%':'60%'">
        <!--content-->
        <div class="h-full    flex flex-wrap content-start justify-center w-full  outline-none focus:outline-none overflow-y-scroll">
            <!--header-->

            <div class="sticky top-0 flex flex-wrap justify-between p-5 border-b border-solid border-blueGray-200 text-xl font-extrabold z-30 bg-white rounded-t w-full">
                Asignar fecha de llegada
            </div>
            <!--body-->
            <div class="w-full   overflow-y-scroll flex  flex-wrap justify-start  content-start z-20 h-full">

                <div class="h-full bg-white px-2 flex flex-wrap w-full justify-start">

                    <div class="h-full overflow-y-scroll flex flex-wrap content-start w-full">
                        <div class="mb-2 mt-2  justify-start flex flex-wrap w-full px-2">
                            <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                                Fecha
                            </span>
                            <input id="cantidad" v-model="fecha" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="date" placeholder="Fecha">
                        </div>

                    </div>
                </div>

            </div>

        </div>
        <template #footer>
            <div class="dialog-footer">

                <button class="my-1 bg-green-500 mx-1 px-2 py-2 text-base font-medium rounded border-2  border-black" @click="UpdateFechaLLegada()">Asignar fecha</button>
                <button @click="Show_FechaLlegada=false" class="my-1 bg-blue-200 mx-1 px-2 py-2 text-base font-medium rounded border-2  border-black">Cerrar</button>

            </div>
        </template>
    </el-drawer>
    <el-drawer v-model="Show_FechaPago" title="" :with-header="false" :size="$isMobile()?'100%':'60%'">
      <!--content-->
      <div class="h-full    flex flex-wrap content-start justify-center w-full  outline-none focus:outline-none overflow-y-scroll">
          <!--header-->

          <div class="sticky top-0 flex flex-wrap justify-between p-5 border-b border-solid border-blueGray-200 text-xl font-extrabold z-30 bg-white rounded-t w-full">
              Asignar fecha de pago
          </div>
          <!--body-->
          <div class="w-full   overflow-y-scroll flex  flex-wrap justify-start  content-start z-20 h-full">

              <div class="h-full bg-white px-2 flex flex-wrap w-full justify-start">

                  <div class="h-full overflow-y-scroll flex flex-wrap content-start w-full">
                      <div class="mb-2 mt-2  justify-start flex flex-wrap w-full px-2">
                          <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                              Fecha
                          </span>
                          <input id="cantidad" v-model="fecha" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="date" placeholder="Fecha">
                      </div>

                  </div>
              </div>

          </div>

      </div>
      <template #footer>
          <div class="dialog-footer">

              <button class="my-1 bg-green-500 mx-1 px-2 py-2 text-base font-medium rounded border-2  border-black" @click="UpdateFechaPago()">Asignar fecha</button>
              <button @click="Show_FechaPago=false" class="my-1 bg-blue-200 mx-1 px-2 py-2 text-base font-medium rounded border-2  border-black">Cerrar</button>

          </div>
      </template>
  </el-drawer>
  <el-drawer v-model="Show_FechaPedido" title="" :with-header="false" :size="$isMobile()?'100%':'60%'">
    <!--content-->
    <div class="h-full    flex flex-wrap content-start justify-center w-full  outline-none focus:outline-none overflow-y-scroll">
        <!--header-->

        <div class="sticky top-0 flex flex-wrap justify-between p-5 border-b border-solid border-blueGray-200 text-xl font-extrabold z-30 bg-white rounded-t w-full">
            Asignar fecha de pedido
        </div>
        <!--body-->
        <div class="w-full   overflow-y-scroll flex  flex-wrap justify-start  content-start z-20 h-full">

            <div class="h-full bg-white px-2 flex flex-wrap w-full justify-start">

                <div class="h-full overflow-y-scroll flex flex-wrap content-start w-full">
                    <div class="mb-2 mt-2  justify-start flex flex-wrap w-full px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Fecha
                        </span>
                        <input id="cantidad" v-model="fecha" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="date" placeholder="Fecha">
                    </div>

                </div>
            </div>

        </div>

    </div>
    <template #footer>
        <div class="dialog-footer">

            <button class="my-1 bg-green-500 mx-1 px-2 py-2 text-base font-medium rounded border-2  border-black" @click="UpdateFechaPedido()">Asignar fecha</button>
            <button @click="Show_FechaPedido=false" class="my-1 bg-blue-200 mx-1 px-2 py-2 text-base font-medium rounded border-2  border-black">Cerrar</button>

        </div>
    </template>
</el-drawer>

</section>
</template>

<script>
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import ordenes from "@/Services/ordenes";

import generales from "@/functions/generales";
import productos from "@/Services/productos";
import Tabla from "@/components/Tabla.vue";
export default {
    components: {
        Tabla
    },
    mixins: [generales],
    data() {
        const user = useUserStore()

        return {
            headings: [
                {
                    key: 'id',
                    name: '#',
                    class: 'min-w-[120px]',
                    show: true
                },
                {
                    key: 'pedido_id',
                    name: '# pedido',
                    class: 'min-w-[120px]',
                    show: true
                },
                {
                    key: 'clientes_cotizaciones',
                    name: 'Cliente',
                    show: true
                }
                , {
                    key: 'status_ordenes',
                    class: 'min-w-[200px]',
                    name: 'Estatus Orden',
                    show: true
                },
                {
                    key: 'fecha_creacion',
                    name: 'Fecha de creación',
                    class: 'min-w-[160px]',
                    show: true
                },
                {
                    key: 'fecha_pedido',
                    name: 'Fecha de pedido',
                    class: 'min-w-[160px]',
                    show: true
                },
                {
                    key: 'fecha_llegada',
                    class: 'min-w-[160px]',
                    name: 'Fecha llegada',
                    show: true
                },
                {
                    key: 'fecha_pago',
                    name: 'Fecha de pago',
                    class: 'min-w-[160px]',
                    show: true
                }
               
                , {
                    key: 'cotizaciones_id',
                    class: 'min-w-[200px]',
                    name: '# venta',
                    show: true
                },

                {
                    key: 'numero_factura',
                    name: '# Factura',
                    class: 'min-w-[160px]',
                    show: true
                },  {
                    key: 'proveedor',
                    name: 'Proveedor',
                    class: 'min-w-[160px]',
                    show: true
                }, {
                    key: 'total',
                    name: 'Total',
                    show: true
                },

                {
                    key: 'observaciones',
                    class: 'min-w-[200px]',
                    name: 'Observaciones',
                    show: true
                },
                {
                    key: 'usuario',
                    name: 'Empleado',
                    class: 'min-w-[160px]',
                    show: true
                },
                
                {
                    key: 'edit_orden',
                    name: 'Editar',
                    show: true
                },
                {
                    key: 'eliminar',
                    name: 'Eliminar',
                    show: true
                }
            ],

            open: false,
            user: user.getAll(),
            data: [],
            data_productos: [],
            actionModal: null,
            Show_FechaLlegada: false,
            Show_FechaPedido: false,
            Show_FechaPago: false,
            fecha: '',
            showM: false,
            id: 0,
            nombre: '',
            correo: '',
            telefono: '',
            rfc: '',
            buscar: 'all',
            pagina: 1,

        }

    },
    mounted() {

    },
    async created() {
        await this.getData();
        this.getDataProductos();
    },
    methods: {
        setFechaLLegada(row) {
            this.fecha = this.formatDateEN(new Date())
            this.id = row.id
            this.Show_FechaLlegada = true;

        },
        setFechaPedido(row) {
            this.fecha = this.formatDateEN(new Date())
            this.id = row.id
            this.Show_FechaPedido = true;
        },
        setFechaPago(row) {
            this.fecha = this.formatDateEN(new Date())
            this.id = row.id
            this.Show_FechaPago = true;
        },
        async UpdateFechaLLegada() {

            emitter.emit("loading", true)
            let dat = {
                fecha: this.fecha,
                id: this.id
            }

            await ordenes.SET_FECHA_LLEGADA(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.Show_FechaLlegada = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },

        async UpdateFechaPedido() {
            emitter.emit("loading", true)
            let dat = {
                fecha: this.fecha,
                id: this.id
            }

            await ordenes.SET_FECHA_PEDIDO(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.Show_FechaPedido = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async UpdateFechaPago() {
            emitter.emit("loading", true)
            let dat = {
                fecha: this.fecha,
                id: this.id
            }

            await ordenes.SET_FECHA_PAGO(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.Show_FechaPago = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },

        limpiar() {
            this.id = 0;
            this.nombre = '';
            this.telefono = '';
            this.correo = '';

            this.rfc = ''
        },
        edit(row) {
            this.id = row.id;
            this.nombre = row.nombre;
            this.telefono = row.telefono;
            this.correo = row.correo;
            this.rfc = row.rfc;
            this.showM = true;
            this.actionModal = null;
        },

        async getDataProductos() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await productos.OPCION(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data_productos = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)
            let dat = {

                page: this.pagina,
                search: this.search

            }

            await ordenes.GET(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async guardar() {
            if (this.nombre == '') {
                emitter.emit("alerta_show", 'El campo nombre es requerido');
                return false;
            }
            let dat = {
                id: this.id,
                nombre: this.nombre,
                correo: this.correo,
                telefono: this.telefono,

                rfc: this.rfc,

            }
            if (this.id == 0) {
                this.crear(dat)
            } else {
                this.editar(dat)
            }
        },
        async editar(dat) {
            emitter.emit("loading", true)

            await ordenes.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async crear(dat) {
            emitter.emit("loading", true)

            await ordenes.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async eliminar(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await ordenes.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
