
import metodos_pagos from "@/Services/metodos_pagos";
import abonos from "@/Services/abonos";
import usuarios from "@/Services/user";
import cotizaciones from "@/Services/cotizaciones";
import pdf from "@/Services/pdf";
import {
    useInfoStore
} from '../stores/info'
import {
    emitter
} from "@/mitt.js";
export default {
   
  data() {
    const info = useInfoStore()
    return {
        infositio: info.getAll(),
        metodos_data:[],
        obj_abono: {
            factura:0,
            cotizacion: 0,
            tipo_pago: '',
            impuesto: 0,
            comision: 0,
            total: 0,
        }, 
        addAbono: false,
    };
  },

  created() {},

  methods: {


    calcularPrecio(formula) {
        //console.log(String(formula).toString(), '----formullllllll-------------aa---')
        let res2 = Function('return ' + String(formula))
        //console.log(formula, '----fr-----')
        let pr = res2()
        //console.log(pr, '----pr-----')
        return pr;
    },

    
    calcularParametroValor(row,formula){
console.log(formula,'----')
        let formula_formateada = this.CastFormula(row,formula)
            let total = this.calcularPrecio(formula_formateada);
            console.log(total,'-cac---');
            return total;
    },
    calcularParametro(row,formula){
console.log(formula,'----')
        let formula_formateada = this.CastFormula(row,formula)
            let total = this.calcularPrecio(formula_formateada);
            console.log(total,'-cac---');
            return total;
    },
    calcularParametroProd(row,formula){
        console.log(formula,'----')
                let formula_formateada = this.CastFormulaProd(row,formula)
                    let total = this.calcularPrecio(formula_formateada);
                    console.log(total,'-cac---');
                    return total;
            },
    CastFormulaold(row,formula) {
        let val = '';
        //console.log(JSON.parse(row.formula), '----formula.-----')
        JSON.parse(formula).forEach(element => {

            if (element.tipo == 'key') {
                val = val + element.valor;
            } else if (element.tipo == 'catalogo') {
                let param = row.parametros.find(p => p.parametros.id == element.valor);
                //console.log(param, '---param----')
                //let valor_param_prod = param.parametros.productos.find(p => p.id == param.valor)
                val = val + param.precio;
            } else if (element.tipo == 'parametro') {
                let param = row.parametros.find(p => p.parametros.id == element.valor);
                //console.log(param, '---p---');
                if (String(param.nombre).toUpperCase() == 'METROS LARGO' || String(param.nombre).toUpperCase() == 'METROS ANCHO') {
                    val = val + (param.valor < 1 ? 'Number(' + 1 + ')' : 'Number(' + param.valor + ')');

                } else {
                    val = val + 'Number(' + param.valor + ')';

                }
                //val = val + param.valor<1?1:param.valor;
            }

        });
        // console.log(val,'----formula 2---')
        return val;
    },
    CastFormulaProd(row,formula) {
        let val = '';
        console.log('form-----ff',formula)
        //console.log(JSON.parse(row.formula), '----formula.-----')
        JSON.parse(formula).forEach(element => {

            if (element.tipo == 'key') {
                val = val + element.valor;
            } else if (element.tipo == 'catalogo') {
                let param = row.parametros.find(p => p.pm_id == element.valor);
                //console.log(param, '---param----')
                //let valor_param_prod = param.parametros.productos.find(p => p.id == param.valor)
                val = val + param.precio;
            } else if (element.tipo == 'parametro') {
                let param = row.parametros.find(p => p.pm_id== element.valor);
                //console.log(param, '---p---');
                if (String(param.nombre).toUpperCase() == 'METROS LARGO' || String(param.nombre).toUpperCase() == 'METROS ANCHO') {
                    val = val + (param.valor_prod < 1 ? 'Number(' + 1 + ')' : 'Number(' + param.valor_prod + ')');

                } else {
                    val = val + 'Number(' + param.valor_prod + ')';

                }
                //val = val + param.valor<1?1:param.valor;
            }
        else if(element.tipo == 'catalogo_valor_formula'){
            let param = row.parametros.find(function (el) {
                return el.pm_id == Number(element.valor);
            });
            console.log(param,'---param-----',element)
            //let valor_param_prod = param.parametros.productos.find(p => p.id == param.valor)
            val = val + Number(param.producto_lista_prod.valor_formula).toFixed(3);
           

        }
        else if(element.tipo == 'numerico'){
            let param = row.parametros.find(function (el) {
                return el.id == Number(element.valor);
            });
            val = val + 'Number(' + Number(param.valor) + ')';
            //val = val + element.valor;

        }
            else if(element.tipo == 'value_formula'){
                    
                val = val + element.valor;

            }

        });
        // console.log(val,'----formula 2---')
        return val;
    },
    CastFormula(row,formula) {
        let val = '';
        console.log('form-----ff',formula)
        //console.log(JSON.parse(row.formula), '----formula.-----')
        JSON.parse(formula).forEach(element => {

            if (element.tipo == 'key') {
                val = val + element.valor;
            } else if (element.tipo == 'catalogo') {
                let param = row.parametros.find(p => p.pm_id == element.valor);
                //console.log(param, '---param----')
                //let valor_param_prod = param.parametros.productos.find(p => p.id == param.valor)
                val = val + param.precio;
            } else if (element.tipo == 'parametro') {
                let param = row.parametros.find(p => p.pm_id== element.valor);
                //console.log(param, '---p---');
                if (String(param.nombre).toUpperCase() == 'METROS LARGO' || String(param.nombre).toUpperCase() == 'METROS ANCHO') {
                    val = val + (param.valor < 1 ? 'Number(' + 1 + ')' : 'Number(' + param.valor + ')');

                } else {
                    val = val + 'Number(' + param.valor + ')';

                }
                //val = val + param.valor<1?1:param.valor;
            }
        else if(element.tipo == 'catalogo_valor_formula'){
            let param = row.parametros.find(function (el) {
                return el.pm_id == Number(element.valor);
            });
            console.log(param,'---param-----',element)
            //let valor_param_prod = param.parametros.productos.find(p => p.id == param.valor)
            val = val + Number(param.producto_lista.valor_formula).toFixed(3);
           

        }
            else if(element.tipo == 'value_formula'){
                    
                val = val + element.valor;

            }
            else if(element.tipo == 'calc_formula'){
                let param = row.parametros.find(function (el) {
                    return el.id == Number(element.valor);
                });
                val = val + this.CastFormula(row, param.formula_mp);

            }

        });
        // console.log(val,'----formula 2---')
        return val;
    },
    getValueProperty(row,formula) {
        let val = '';
        //console.log(JSON.parse(row.formula), '----formula.-----')
        JSON.parse(formula).forEach(element => {

            if (element.tipo == 'key') {
                val = val + element.valor;
            } else if (element.tipo == 'catalogo') {
                let param = row.parametros.find(p => p.pm_id == element.valor);
                //console.log(param, '---param----')
                //let valor_param_prod = param.parametros.productos.find(p => p.id == param.valor)
                val = val + param.valor_formula;
            } 

        });
        // console.log(val,'----formula 2---')
        return val;
    },
    async getDataMetodos() {

        emitter.emit("loading", true)

        await metodos_pagos.GET().then(response => {

            if (response != null && !response.hasOwnProperty('error')) {

                this.metodos_data = response

              

                //this.$router.push('/app/'); 
            } else {

                emitter.emit("alerta_show", response.error);

            }
            emitter.emit("loading", false)
        });
    },
    calcularPendiente(row) {
        let tot = 0;
        if(row!=undefined&&row!=null&&row.hasOwnProperty('abonos')){
            row.abonos.forEach(element => {
                tot = Number(tot) + Number(element.capital)
            });
            return Number(row.total)-Number(row.descuento) -Number(row.descuento_personalizado)  - Number(tot);
        }else{

            return 'No hay cotización'
           
        }
        
       
    },
    verificarTotalAbonoGuardar(total) {
        console.log(total);
        if (this.obj_abono.impuesto == '' || this.obj_abono.impuesto == null) {
            this.obj_abono.impuesto = 0;
        }
        let calculoCapital = (Number(total) - (Number(total) * Number(this.obj_abono.impuesto))).toFixed(2)
        if (Number(calculoCapital) > (this.CalcularDeuda() + (Number(total) * Number(this.obj_abono.impuesto))).toFixed(2)) {
          return  true
         } else {
          return false
        }
    },
    GetRestanteadeudo($event) {
        console.log($event.target.value);
        if (this.obj_abono.impuesto == '' || this.obj_abono.impuesto == null) {
            this.obj_abono.impuesto = 0;
        }
        let calculoCapital = (Number($event.target.value) - (Number($event.target.value) * Number(this.obj_abono.impuesto))).toFixed(2)
        if (Number(calculoCapital) > (this.CalcularDeuda() + (Number($event.target.value) * Number(this.obj_abono.impuesto))).toFixed(2)) {
            this.obj_abono.total = Number(this.CalcularDeuda() + (Number($event.target.value) * Number(this.obj_abono.impuesto))).toFixed(2);
        } else {
            this.obj_abono.total = Number($event.target.value).toFixed(2)
        }
    },
    verificarTotalAbono($event) {
        console.log($event.target.value);
        if (this.obj_abono.impuesto == '' || this.obj_abono.impuesto == null) {
            this.obj_abono.impuesto = 0;
        }
        let calculoCapital = (Number($event.target.value) - (Number($event.target.value) * Number(this.obj_abono.impuesto))).toFixed(2)
        if (Number(calculoCapital) > (this.CalcularDeuda() + (Number($event.target.value) * Number(this.obj_abono.impuesto))).toFixed(2)) {
            this.obj_abono.total = Number(this.CalcularDeuda() + (Number($event.target.value) * Number(this.obj_abono.impuesto))).toFixed(2);
        } else {
            this.obj_abono.total = Number($event.target.value).toFixed(2)
        }
    },
   
    ActualizarValorMetodo() {
        console.log(this.tipo_metodo)
        this.obj_abono.impuesto = this.tipo_metodo.porcentage_impuestos;
        this.obj_abono.comision = this.tipo_metodo.porcentage_comision;
        this.obj_abono.tipo_pago = this.tipo_metodo.nombre;
        
    },
    CalcularDeuda() {
        if (this.el_cotizacion.id != 0) {
            let total = 0;
            this.el_cotizacion.abonos.forEach(element => {
                total = Number(total) + Number(element.capital)
            });
            return Number(this.el_cotizacion.total) - Number(this.el_cotizacion.descuento) - Number(total)
        } else {
            return 0;
        }

    },
    async eliminarabono(id) {
        emitter.emit("loading", true)
        let dat = {
            id: id,

        }
        await abonos.ELIMINAR(dat).then(response => {

            if (response != null && !response.hasOwnProperty('error')) {
                this.el_cotizacion.abonos = response;
                //this.getData();
                //this.$router.push('/app/'); 
            } else {

                // emitter.emit("alerta_show",response.msg);

            }
            this.actionModal = null
            emitter.emit("loading", false)
        });
    },
    async guardarAbono() {

        if (this.obj_abono.tipo_pago == '') {
            emitter.emit("alerta_show", 'Seleccione un tipo de pago');
            return;
        }
        if (this.obj_abono.total < 1) {
            emitter.emit("alerta_show", 'Es necesario que el total de abono sea mayor a 0');
            return;
        }

if(this.verificarTotalAbonoGuardar(this.obj_abono.total)){
    emitter.emit("alerta_show", 'El monto de el abono es mayor al adeudo restante '+this.CalcularDeuda());
    return;
}

        emitter.emit("loading", true)
        let dat = this.obj_abono
        await abonos.CREAR(dat).then(response => {

            if (response != null && !response.hasOwnProperty('error')) {

                this.el_cotizacion.abonos = response;
                this.addAbono = false;
                emitter.emit("loading", false)
                //this.$router.push('/app/'); 
            } else {

                emitter.emit("alerta_show", response.error);
                emitter.emit("loading", false)
            }
            this.showM = false;
            this.limpiar();

        });
    },
    async enviarWhatsappGarantia(id){
        emitter.emit("loading", true)
        let dat = {
            id:id
        }
        await cotizaciones.SEND_WHATSAPPGARANTIA(dat).then(response => {
    
            if (response != null && !response.hasOwnProperty('error')) {
    
                //this.$router.push('/app/'); 
               
                
                emitter.emit("success", 'Enviada correctamente');
            } else {
    
                emitter.emit("alerta_show", response.error);
    
            }
            emitter.emit("loading", false)
        });
    },
async enviarWhatsapp(id) {
    emitter.emit("loading", true)
    let dat = {
        id:id
    }
    await cotizaciones.SEND_WHATSAPP(dat).then(response => {

        if (response != null && !response.hasOwnProperty('error')) {

            //this.$router.push('/app/'); 
            if(this.el_cotizacion!=undefined&&this.el_cotizacion!=null){
                this.el_cotizacion.whatsacceso_cotizacion.push(response)
            }
            
            emitter.emit("success", 'Enviada correctamente');
        } else {

            emitter.emit("alerta_show", response.error);

        }
        emitter.emit("loading", false)
    });
},

async enviarCorreo(data){
    emitter.emit("loading", true)

    let dat={
        titulo:this.infositio.titulo_correo+' '+data['titulo'],
        correo:data['correo'],//'popcing.agency@gmail.com',//data['correo'],
        asunto:this.infositio.asunto_correo,
        imagen_negocio:this.infositio.logo,
        textoboton:this.infositio.texto_boton_correo,
        texto_boton_contactar:this.infositio.texto_boton_contactar_correo,
        link_contacto_correo:this.infositio.link_contacto_correo,
        urlboton:data['urlboton'],
        id:data['id'],
        descripcion:this.infositio.descripcion_correo,
    }
   let response_send= await cotizaciones.SEND_EMAIL(dat).then(response => {
console.log(response,'error_cot')

        if (response != null && !response.hasOwnProperty('error')) {

            emitter.emit("loading", false)
          return response;
           
        } else {
            emitter.emit("loading", false)
            emitter.emit("alerta_show", response.error);
            return false;

        }
       
    });

    return response_send
},
async onCapture(name='',landscape=false,scale=1,correo=0,data={},titulo=false) {
    let theader='';
    if(titulo){
        theader=document.getElementById("titulo_print").innerHTML;
    }

    let marcaagua=`
   `;
    var divContents = document.getElementById("capture").innerHTML;
    let doc = '<script src="https://cdn.tailwindcss.com">';
    doc += '</scri';
    doc += 'pt><style>.h {    display: none!important} '+marcaagua+'</style>' +theader+ divContents + '';

    let dat = {
        watermark:this.infositio.logo_favicon,
        name:name,
        html: doc,
        scale: scale,
        landscape: landscape,
    }
   let response_pdf= await pdf.SEND_HTML(dat).then(response => {

        if (response != null && !response.hasOwnProperty('error')) {

            if(correo){
                 //enviar pdf por correo
                 //('correo------------ entro')
                 data.urlboton=response.data.FileUrl;
                 emitter.emit("loading", false)
                 return true;
                
                

            }else{
                //abrir pdf
                var temp_link = document.createElement("a");
                console.log(response.data,'-----respuesta---- imprimir');
                // Download csv file
                // temp_link.download = "pdf.pdf";
                temp_link.setAttribute("download", 'name.pdf');
                temp_link.setAttribute("target", '_blank');
    
                temp_link.href = response.data.FileUrl;
    
                // This link should not be displayed
                temp_link.style.display = "none";
                document.body.appendChild(temp_link);
    
                // Automatically click the link to trigger download
                temp_link.click();
                document.body.removeChild(temp_link);
                emitter.emit("loading", false)
                return false;
            }
           
        } else {

            emitter.emit("alerta_show", response.error);
            emitter.emit("loading", false)
            return false;

        }
       
    });

    if(response_pdf){
        return await  this.enviarCorreo(data)
    }else{
       return response_pdf;
    }
   
    // var prnt = window.open('', '', 'height=500, width=500');
    //prnt.document.write(doc);
    //prnt.document.close();
    //prnt.print();

    //  window.print();
   
},
async CancelarVenta(id) {

    if (confirm('¿Desea cancelar este pedido?') == true) {
        emitter.emit("loading", true)
        let dat = {
            id: id,
    
        }
        await cotizaciones.CANCELAR_VENTA(dat).then(response => {
    
            if (response != null && !response.hasOwnProperty('error')) {
              
                this.$parent.getData('cancelar');
                //this.$router.push('/app/'); 
            } else {
    
                // emitter.emit("alerta_show",response.msg);
    
            }
            this.actionModal = null
            emitter.emit("loading", false)
        });
      } else {
        
      }
    
},
  }
}