<template>
<div class="w-full h-5/6 flex flex-wrap justify-center content-start overflow-y-scroll" :class="cotizacion.avance>=100?'':''">
   
    <p v-if="cotizacion.avance>=100" class="text-green-600 font-extrabold px-4">Producción completa</p>

    <!--<div class="w-full flex flex-wrap font-bold text-blue-500 fntrel  border-black">
                <div class="w-2/12 border border-black">Producto</div>
                <div class="w-1/12 border border-black">Tipo de Producto</div>
                <div :class="cotizacion.avance<100?'w-5/12':' w-full '" class=" border border-black ">Detalle</div>
                <div class="w-1/12 border  border-black"> Producción total</div>
                <div class="w-1/12 border  border-black"> Producción generada</div>
                <div v-if="cotizacion.avance<100" class="w-2/12 border border-r border-black"> Actualización </div>
            </div>-->

    <div class="w-full h-full flex flex-wrap justify-center content-start overflow-y-scroll  ">

        <!--<div class="w-full flex flex-wrap font-bold text-blue-500 fntrel  border-black">
                                <div class="w-2/12 border border-black">Producto</div>
                                <div class="w-1/12 border border-black">Tipo de Producto</div>
                                <div :class="cotizacion.avance<100?'w-5/12':' w-full '" class=" border border-black ">Detalle</div>
                                <div class="w-1/12 border  border-black"> Producción total</div>
                                <div class="w-1/12 border  border-black"> Producción generada</div>
                                <div v-if="cotizacion.avance<100" class="w-2/12 border border-r border-black"> Actualización </div>
                            </div>-->

        <div v-for="(row,index) in categorias" v-bind:key="index" class="w-full zoom2   border-black mb-10 content-start justify-start flex flex-wrap overflow-x-scroll" :id="'cat'+index">

            <table class="w-full table-auto text-xl sticky left-0">
                <thead>
                    <tr class="bg-gray-100 h-10">
                        <th>
                            <div class="w-full flex flex-wrap h-10 content-center justify-start px-8 font-extrabold text-base"><span class="flex">{{row.nombre}}</span></div>
                        </th>
                        <th>
                            <div class="w-full flex flex-wrap h-14 content-center justify-center">
                                <span :class="calculaPendientesRevisar(row.datos)?'bg-green-300 ':'bg-gray-400'" @click="onCapture(index,row)" class=" align-middle flex h-10 w-10 rounded-full  text-center  flex-wrap justify-center content-center ">
                                    <i class="cursor-pointer text-3xl las la-print text-black h"></i>
                                </span>
                            </div>

                        </th>

                    </tr>
                </thead>
            </table>

            <template v-for="(r,indice) in row.datos" v-bind:key="indice">

                <div v-if="r.formato_aprobado" class=" w-full    border-black my-10 content-start justify-start flex-wrap" :id="'cat'+index">

                    <div class="w-full  flex flex-wrap " >
                        <div class="w-full flex flex-nowrap bg-blue-100">
                            <div class="w-1/12 align-middle h">
                                Estatus revisión
                            </div>
                            <div class="w-1/12 align-middle h">
                                Usuario
                            </div>
                            <div class="w-1/12 align-middle h">
                                Fecha Aprobado
                            </div>
                            <div class="w-1/12 align-middle h">
                                Fecha Asignación
                            </div>

                            <div class="w-1/12 align-middle">
                                Cantidad pedido
                            </div>
                            <!--<div class="h w-28 align-middle">
                            Producción
                        </div>-->
                        <div class="w-1/12 align-middle">
                            ##
                        </div>
                            <div class="w-1/12 align-middle">
                                Producto
                            </div>
                            <div :class="printing?'w-10/12':'w-5/12'"  class=" align-middle">
                               
                            </div>
                            

                        </div>
                    </div>
                    
                    <div class="w-full  flex flex-nowrap text-xl">
                       
                        <div class="w-full flex flex-nowrap ">

                            <div class="align-middle w-1/12 font-extrabold h">
                                
                                <span :class="r.formato_impreso?'bg-green-400':'bg-red-400'" class="px-2 py-1 rounded-md">{{r.formato_impreso?'Revisado ':'No revisado'}}</span>
                            </div>
                            <div @click="r.usuario==null?AsignarEmpleado(r):null" class=" h border border-black text-center align-middle w-1/12 font-extrabold text-blue-500 bg-green-100 underline ">
                                {{r.usuario==null?'Asignar':r.usuario.name}}

                            </div>
                            <div  :class="r.formato_aprobado?'text-green-500':'text-red-500'" class="h border w-1/12  border-black text-center align-middle  font-extrabold   underline ">
                                <p>
    
                                    {{r.formato_aprobado?formatDateTimeLT(r.fecha_aprobado):''}}
                                </p>
                            </div>
                            <div class="h border border-black text-center align-middle w-1/12 font-extrabold text-green-500 bg-green-100 underline ">
                                {{(r.fecha_asignacion==''||r.fecha_asignacion==null)?'Sin asignar':r.fecha_asignacion}}

                            </div>

                            <div class="border border-black text-center align-middle w-1/12 font-extrabold text-green-500 bg-green-100 underline ">
                                {{Number(r.cantidad).toFixed(0)}}

                            </div>
                            <!-- <div class="border border-black font-extrabold text-green-500 h h-10 w-28">
                                <div class="w-full h-10 border-l border-b border-r border-t border-black text-xs  text-blue-600 font-extrabold justify-center content-center flex flex-wrap">
                                    <div class="w-full flex flex-wrap items-center  h-full">
                                        <div @click="r.produccion_avance>r.produccion?r.produccion_avance--:null" class=" w-4/12  cursor-pointer hover:bg-red-500 flex flex-wrap justify-center items-center  h-full text-blue-500 bg-red-200  dark:text-blue-300 dark:bg-blue-900">
                                            <span class="text-black text-xs font-extrabold">-</span>
                                        </div>
                                        <div class=" w-4/12 flex flex-shrink-0 justify-center items-center  h-full text-blue-500   dark:text-blue-300 dark:bg-blue-900">
                                            <span class="text-black text-xs font-extrabold">{{Number(r.produccion_avance).toFixed(0)}}</span>
                                        </div>
                                        <div @click="r.produccion_avance<r.cantidad?r.produccion_avance++:null;" class=" w-4/12 flex cursor-pointer hover:bg-green-500 flex-shrink-0 justify-center items-center  h-full text-blue-500 bg-green-200  dark:text-blue-300 dark:bg-blue-900">
                                            <span class="text-black text-xs font-extrabold">+</span>
                                        </div>
                                    </div>
        
                                </div>
                            </div>-->
                            <div class="w-1/12 border border-black align-middle text-center">
                                # {{ r.id }} 
                              </div>
                            <div class="w-1/12 border border-black align-middle text-center">
                              {{r.productos.nombre}}
                            </div>
                            <div :class="printing?'w-10/12':'w-5/12'" class=" border border-black align-middle text-center">
                                
                                
                                <template v-for="(p,i) in r.parametros" v-bind:key="i">
                                    <div class="w-full flex flex-wrap border border-black align-middle text-center text-xl" v-if="p.visible_produccion==1">
                                        <div class="w-6/12 border border-black align-middle text-center text-xl">
                                          
                                                <div class="w-full align-middle text-md" v-if="p.visible_produccion==1">
                                                    <strong v-if="p.tipo=='calc_formula'"> {{p.nombre}}  </strong>
                                                    <strong v-if="p.tipo=='value_formula'"> {{p.nombre}}  </strong>
                                                    <strong v-if="p.tipo=='texto_extendido'"> {{p.nombre}}  </strong>
                                                     
                                                   
                                                    <strong v-if="p.tipo=='numerico'">{{p.nombre}} </strong>
                                                    <strong v-if="p.tipo=='texto'">{{p.nombre}} </strong>
                                                    <strong v-if="p.tipo=='catalogo'">{{p.selproductos.nombre}} </strong>
                
                                                </div>
                                           
                                        </div>
                                        <div class="w-6/12 border border-black align-middle text-center text-xl">
                                            <template v-if="p.tipo=='numerico'"> {{p.valor_prod}} </template>
                                            <template v-if="p.tipo=='calc_formula'">   {{' '}} {{calcularParametroProd(r,p.formula_mp)}} {{' '}}</template>
                                            <template v-if="p.tipo=='value_formula'">    {{' '}} {{calcularParametroProd(r,p.formula_mp)}} {{' '}}</template>
                                         
                                         
                                            <template v-if="p.tipo=='texto'">{{p.valor_prod}} </template>
                                            <template v-if="p.tipo=='texto_extendido'">{{p.valor_prod}} </template>
                                            <template v-if="p.tipo=='catalogo'"> ( {{p.valor_prod==0?'N/A': (p.hasOwnProperty('producto_lista_prod')?p.producto_lista_prod==null?'- - LLENAR TODOS LOS DATOS - -':p.producto_lista_prod.nombre:'**ALERTA**')}}  
                                                

                                            /<span class="underline text-red-500"> {{ p.hasOwnProperty('producto_lista_prod')&&p.producto_lista_prod!=null&&p.producto_lista_prod.hasOwnProperty('provedores')&&p.producto_lista_prod.provedores!=null? 
                                                p.producto_lista_prod.provedores!=null?p.producto_lista_prod.provedores.nombre:'':''}}
                                          </span>
                                            
                                            ) </template>
                                           
                                        </div>


                                       <!-- <template v-if="p.tipo=='catalogo'"> ( {{p.val_prod_productos_id==0?'N/A':p.producto_lista.nombre}} ) </template>-->
                                      
                                   
                                   
    
                                       
                                   
                                    </div>
                                </template>

                              </div>

                            

                        </div>
                    </div>

                </div>

                <!-- <table v-if="r.formato_aprobado" class="w-full table-auto text-xs mt-2"  >
                <thead>
                    <tr class="bg-blue-100">
                        <th class="w-28 align-middle">
                            Estatus revisión
                        </th>
                        <th class="w-28 align-middle">
                            Usuario
                        </th>
                        <th class="w-28 align-middle">
                            Fecha Asignación
                        </th>
    
                        <th class="w-28 align-middle">
                            Cantidad pedido
                        </th>
                      
                        <th class="w-28 align-middle">
                            Producto
                        </th>
                        <template v-for="(p,i) in r.parametros" v-bind:key="i">
                            <th class="w-28 align-middle" v-if="p.visible_produccion==1">
    
                                <strong v-if="p.tipo=='numerico'">{{p.nombre}} </strong>
                                <strong v-if="p.tipo=='texto'">{{p.nombre}} </strong>
                                <strong v-if="p.tipo=='catalogo'">{{p.selproductos.nombre}} </strong>
    
                            </th>
                        </template>
    
                    </tr>
                </thead>
                <tbody>
                    <tr class="border-b border-black">
                        <td class="align-middle w-28 font-extrabold">
                            <span :class="r.formato_impreso?'bg-green-400':'bg-red-400'" class="px-2 py-1 rounded-md">{{r.formato_impreso?'Revisado ':'No revisado'}}</span>
                        </td>
                        <td @click="r.usuario==null?AsignarEmpleado(r):null" class="border border-black text-center align-middle w-28 font-extrabold text-blue-500 bg-green-100 underline h-10">
                            {{r.usuario==null?'Asignar':r.usuario.name}}
    
                        </td>
                        <td class="border border-black text-center align-middle w-28 font-extrabold text-green-500 bg-green-100 underline h-10">
                            {{(r.fecha_asignacion==''||r.fecha_asignacion==null)?'Sin asignar':r.fecha_asignacion}}
    
                        </td>
    
                        <td class="border border-black text-center align-middle w-28 font-extrabold text-green-500 bg-green-100 underline h-10">
                            {{Number(r.cantidad).toFixed(0)}}
    
                        </td>
                       
                        <td class="w-28 border border-black align-middle text-center">
                            {{r.productos.nombre}}
                        </td>
                        <template v-for="(p,i) in r.parametros" v-bind:key="i">
                            <td class="w-28 border border-black align-middle text-center" v-if="p.visible_produccion==1">
                                <template v-if="p.tipo=='numerico'"> {{p.valor_prod}} </template>
                                <template v-if="p.tipo=='texto'">{{p.valor_prod}} </template>
                                <template v-if="p.tipo=='texto_extendido'">{{p.valor_prod}} </template>
                                <template v-if="p.tipo=='catalogo'"> ( {{p.valor_prod==0?'N/A':p.producto_lista.nombre}} ) </template>
    
                            </td>
                        </template>
    
                    </tr>
                </tbody>
    
            </table>-->
            </template>

        </div>
        <el-dialog v-model="modalAsignarEmpleado" title="Cambiar vendedor" :width="$isMobile()?'100%':'30%'">
            <div class="w-full px-2 flex flex-wrap    justify-start">
                <div class="mb-5 w-full">
                    <label for="fName" class="mb-3 block text-base font-medium   text-left text-[#07074D]">
                        Usuario Producción
                    </label>
                    <SearchSelectUsuariocitas v-bind:key="usuario_produccion" @carga="buscarGetUsuarios" :rows="usuarios_data" :label="'Usuario Producción'" :valor="usuario_produccion" @seleccionado="action_usuario" />

                </div>

            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="modalAsignarEmpleado = false">Cancel</el-button>
                    <el-button type="primary" @click="actualizarUserProduccion()">
                        Guardar
                    </el-button>
                </span>
            </template>
        </el-dialog>
        <!--<div class="w-full  botton-20" v-if="edit">
        <div class="flex flex-wrap justify-end px-2">
            <button v-if="cotizacion.avance<100" class="font-extrabold bg-blue-500 text-base text-gray-200 mt-2 px-2 py-1 rounded text-center" @click="EditarProduccion()">Guardar producción</button>

        </div>
    </div>-->
</div>
    </div>
</template>

<script>
//@ts-ignore
import {
    emitter
} from "@/mitt.js";
import generales from '@/functions/generales'
import cotizacionesf from '@/functions/cotizaciones'

import cotizaciones from "@/Services/cotizaciones";

import productos_cotizaciones from "@/Services/productos_cotizaciones";

import usuarios from "@/Services/user";

import pdf from "@/Services/pdf";
import {
    throwStatement
} from '@babel/types';

import SearchSelectUsuariocitas from "@/components/SearchSelectUsuariocitas.vue";
//import palabras from "@/mixins/palabras";
import {
    useInfoStore
} from '../stores/info'
export default {
    name: "Home",
    components: {
        SearchSelectUsuariocitas
    },
    mixins: [generales,cotizacionesf],
    props: {

        cotizacion: {
            type: Array,
        },

    },
    data() {
        const info = useInfoStore()
        return {
            printing:true,
            infositio: info.getAll(),
            categorias: {},
            sel_producto: null,
            modalAsignarEmpleado: false,
            usuarios_data: [],
            usuario_produccion: null,
            buscar_usuario: 'all',
            limit_usuario: 10,
            pagina_usuario: 1,

        }
    },

    mounted() {

        this.cotizacion.productos_cotizaciones.forEach(element => {
            element.produccion_avance = element.produccion;
        });

        this.cotizacion.productos_cotizaciones.forEach(element => {
            if (this.categorias.hasOwnProperty(element.productos.categorias_id)) {
                this.categorias[element.productos.categorias_id].datos.push(element)
            } else {
                this.categorias[element.productos.categorias_id] = {
                    datos: [element],
                    nombre: element.productos.categorias.nombre
                }
            }

        });

        console.log(this.cotizacion)

    },

    methods: {
        async actualizarUserProduccion() {

            if (this.usuario_produccion == null) {

                emitter.emit("alerta_show", 'Es necesario selecionar un vendedor');
                return;
            }
            let dat = {
                id: this.sel_producto,
                users_id: this.usuario_produccion.id
            }
            await productos_cotizaciones.ASIGNAR_USUARIO_PRODUCTO(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    this.modalAsignarEmpleado = false;

                    this.cotizacion.productos_cotizaciones.forEach(element => {

                        if (this.sel_producto == element.id) {
                            element.usuario = this.usuario_produccion;
                            element.fecha_asignacion = this.formatTimeName(new Date());
                        }

                    });

                    // this.sel_producto.fecha_asignacion=response.fecha_asignacion;

                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                emitter.emit("loading", false)
            });
        },
        action_usuario(ev) {
            this.usuario_produccion = ev.value;

        },
        buscarGetUsuarios(busqueda) {
            this.buscar_usuario = busqueda;
            this.getDataUsuariosProduccion();

        },
        async getDataUsuariosProduccion() {

            let dat = {
                search: this.buscar_usuario,
                page: this.pagina_usuario,
                limit: this.limit_usuario,
                data: {}
            }

            await usuarios.GET_USUARIOS_PRODUCCION(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.usuarios_data = response;

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }

            });
        },
        AsignarEmpleado(data) {
            this.sel_producto = data.id;
            this.usuario_produccion = data.usuario;
            this.modalAsignarEmpleado = true;

        },
        calculaPendientesRevisar(data) {
            let pos = 0;
            data.forEach(element => {
                if (element.formato_impreso) {

                } else {
                    pos++;
                }
            });

            if (pos > 0) {
                return false;
            } else {
                return true;
            }

        },
        async onCapture(id, row) {
this.printing=true;
            this.revisarCategoria(row)
            let titulotipo=(this.cotizacion.idventa==null)?this.cotizacion.id:this.cotizacion.idventa;
           let nombre_cliente=this.verfiNull(this.cotizacion.clientes.name)+' '+this.verfiNull(this.cotizacion.clientes.apellido_p)+' '+this.verfiNull(this.cotizacion.clientes.apellido_m);
            let name=titulotipo+' -- '+nombre_cliente;
            var cat = document.getElementById("cat" + id).innerHTML;
            let theader='';
  
        theader=document.getElementById("titulo_print").innerHTML;
   
            let doc = ' <script src="https://cdn.tailwindcss.com?plugins=forms,typography,aspect-ratio,line-clamp">';
            doc += '</scri';
            doc += 'pt><style>.h {    display: none}</style>';

            //doc += titlecat + '';
            doc += '<div class="flex flex-wrap justify-center content-start">'+theader+cat + '</div>';

            let dat = {
                name:name,
                html: doc,
                scale: .6,
                landscape: true
            }
            await pdf.SEND_HTML(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    var temp_link = document.createElement("a");

                    // Download csv file
                    // temp_link.download = "pdf.pdf";
                    temp_link.setAttribute("download", name+'.pdf');
                    temp_link.setAttribute("target", '_blank');

                    temp_link.href = response.data.FileUrl;

                    // This link should not be displayed
                    temp_link.style.display = "none";
                    document.body.appendChild(temp_link);

                    // Automatically click the link to trigger download
                    temp_link.click();
                    document.body.removeChild(temp_link);
                    this.printing=false;
                } else {

                    emitter.emit("alerta_show", response.error);
                    this.printing=false;

                }
                emitter.emit("loading", false)
            });
            // var prnt = window.open('', '', 'height=500, width=500');
            //prnt.document.write(doc);
            //prnt.document.close();
            //prnt.print();

            //  window.print();
            return;
        },

        async revisarCategoria(row) {

            emitter.emit("loading", true)
            let ids = [];
            row.datos.forEach(element => {

                if (element.formato_aprobado) {
                    element.formato_impreso = 1;
                    ids.push(element.id)
                }

            });

            let dat = {
                id: this.cotizacion.id,
                productos: ids
            }
            await cotizaciones.REVISAR_CATEGORIA_PRODUCCION(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    //this.cotizacion.avance = response.avance;
                    //this.$parent.closeProduccion();
                    //this.$router.push('/app/'); 

                    // emitter.emit("closeProduccion",true);
                    // emitter.emit("success", 'Producción registrada');
                } else {

                    emitter.emit("alerta_show", response.error);

                }

                //this.limpiar();

                emitter.emit("loading", false)

            });

        },
        async EditarProduccion() {

            emitter.emit("loading", true)

            let productos_update = [];

            this.cotizacion.productos_cotizaciones.forEach(element => {
                productos_update.push({
                    id: element.id,
                    avance: element.produccion_avance
                })
            });

            let dat = {
                id: this.cotizacion.id,
                productos: productos_update
            }
            await cotizaciones.EDITAR_PRODUCCION(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    //this.cotizacion.avance = response.avance;
                    //this.$parent.closeProduccion();
                    //this.$router.push('/app/'); 

                    emitter.emit("closeProduccion", true);
                    emitter.emit("success", 'Producción registrada');
                } else {

                    emitter.emit("alerta_show", response.error);

                }

                //this.limpiar();

                emitter.emit("loading", false)

            });

        }

    }
}
</script>

<style>
td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.vgt-wrap {
    height: 100%;
}

.vgt-inner-wrap {
    height: 100%;
}
</style>
