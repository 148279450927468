

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
 // GET_CLIENTES CREAR_CLIENTES EDITAR_CLIENTES ELIMINAR_CLIENTES

 GET: async function( data){
      let url='/clases/sucursal/'+data.id+'/dia/'+data.fecha
      return initmixin.formatrespuesta('get', data.data,url,true)
    },
    GET_CLIENTE: async function( data){
      let url='/clases/list/'+data.id+'/dia/'+data.fecha
      return initmixin.formatrespuesta('get', data.data,url,true)
    },
    SUSCRIBIRSE: async function( data){
      let url='/clases/suscribirse/'+data.id;
      return initmixin.formatrespuesta('get', data.data,url,true)
    },
    CREAR: async function( data) {
      let url='/clases/'
      return initmixin.formatrespuesta('post', data,url,true)
    },
    EDITAR: async function( data) {
      let url='/clases/'+data.id
      return initmixin.formatrespuesta('put', data,url,true)
    },
    ELIMINAR: async function( data) {
      let url='/clases/'+data.id
      return initmixin.formatrespuesta('delete', data,url,true)
    },
   


}