<template>
<div>
    <div v-if="loading">Cargando...</div>
    
        <canvas ref="chart"  :id="chartId"></canvas>
       
   
</div>
</template>

  

<script>

import graficas from "@/Services/graficas";


export default {
    name: 'DynamicChart',
    mixins: [graficas],
    props: {
        endpoint: {
            type: String,
            required: true
        },
        chartType: {
            type: String,
            required: true,
            validator: (value) => ['line', 'bar', 'doughnut'].includes(value)
        },
        chartLabel: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: true,
            chartData: null,
            chartId: `chart-${Math.random().toString(36).substr(2, 9)}`
        }
    },
    async mounted() {
        try {
            //const response = await axios.get(this.endpoint);

            await graficas.GET_DATA({
                url: this.endpoint
            }).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    const backgroundColors = response.data.map(() => this.getRandomColor());
                    const borderColors = response.data.map(() => this.getRandomBorderColor());

                    this.chartData = {
                        labels: response.labels,
                        datasets: [{
                            label: this.chartLabel,
                            data: response.data,
                            backgroundColor: backgroundColors,
                            borderColor: borderColors
                        }]
                    };
                    this.loading = false;
                    this.drawChart(this.chartType);

                    //this.$router.push('/app/'); 
                } else {

                    //emitter.emit("alerta_show", response.error);

                }

            });

        } catch (error) {
            console.error('Error fetching data', error);
            this.loading = false;
        }
    },
    methods: {
        getRandomColor() {
            const r = Math.floor(Math.random() * 256);
            const g = Math.floor(Math.random() * 256);
            const b = Math.floor(Math.random() * 256);
            return `rgba(${r},${g},${b},0.2)`;
        },
        getRandomBorderColor() {
            const r = Math.floor(Math.random() * 256);
            const g = Math.floor(Math.random() * 256);
            const b = Math.floor(Math.random() * 256);
            return `rgba(${r},${g},${b},1)`;
        },
        drawChart() {
            console.log(this.$refs)
            const ctx = this.$refs.chart.getContext('2d');
            //const ctx = document.getElementById(this.chartId);
            new Chart(ctx, {
                type: this.chartType,
                data: this.chartData,
                options: {
                    responsive: true,
                    maintainAspectRatio: false
                }
            });
        }
    }
}
</script>
