<template>
<section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-start  overflow-hidden ">

    <div class="w-full h-12 bg-gray-200 flex flex-wrap content-center justify-start px-2">
        <div class="flex flex-wrap content-center justify-end w-full sm:w-full md:w-6/12 lg:w-auto xl:auto">
            <button class="px-2 text-xs font-extrabold py-1 h-8 mb-1 bg-yellow-400 text-black ml-1  rounded" @click="tipo='sin_finalizar';getData()">
                <i v-if="tipo=='sin_finalizar'" class="las la-check mx-2"></i> SIN FINALIZAR</button>
            <button class="px-2 text-xs font-extrabold py-1 h-8 mb-1 mr-2 bg-green-400 text-black ml-1  rounded" @click="tipo='ventas';getData()">
                <i v-if="tipo=='ventas'" class="las la-check mx-2"></i> VENTAS</button>

            <el-pagination v-if="data.hasOwnProperty('last_page')&&Number(data['last_page'])>1" v-model:currentPage="pagina" small background layout="prev, pager, next" :page-size="data['per_page']" :total="Number(data['total'])" @current-change="pageChangue" class="" />

        </div>
        <div v-bind:key="tipo" v-if="tipo=='ventas'" class="flex flex-wrap content-start justify-start w-full sm:w-full md:w-6/12 lg:w-auto xl:auto">
            <el-date-picker class="w-full h-12" v-model="fecha" type="daterange" unlink-panels range-separator="-" start-placeholder="De" end-placeholder="Hasta" @change="getData()" />

        </div>

    </div>

    <Tabla :idt="'tablll'" :info="true" v-bind:key="reftable" v-if="data.hasOwnProperty('data')" :add="false" :columns="headings" :rows="data.data" :filtros="['email','phone','id']" :buscarp="buscar" :filtroApi="true" :paginate="true" :page="{
            page:data.current_page,
            total:data.last_page
           
          }" />

    <el-drawer v-model="addAbono" title="" :with-header="false" size="50%">
        <!--content-->
        <div class="h-full    flex flex-wrap content-start justify-center w-full bg-gray-100 outline-none focus:outline-none overflow-y-scroll">
            <!--header-->

            <div class="sticky top-0 flex flex-wrap justify-between p-5 border-b border-solid border-blueGray-200  z-30 bg-white rounded-t w-full">
                <h3 class="text-3xl font-semibold">
                    Crear abono
                </h3>
                <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="showM=false">
                    <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                    </span>
                </button>
            </div>
            <!--body-->
            <div class="w-full   overflow-y-scroll flex  flex-wrap justify-start  content-start z-20 h-full py-10">
                <div class=" flex flex-wrap w-full" v-bind:key="reftable">
                    <div class=" px-3 w-1/2">
                        <div class="mb-5">
                            <label for="fName" class="mb-3 block text-base font-medium text-[#07074D]">
                                Tipo metodo
                            </label>
                            <select @change="ActualizarValorMetodo()" v-model="tipo_metodo" class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 text-center text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md">
                                <option value="0">Seleccione un metodo</option>
                                <option v-for="(row,index) in metodos_data" v-bind:key="index" :value="row">{{row.nombre}}</option>

                            </select>
                        </div>
                    </div>
                    <div class=" px-3 w-1/2">
                        <div class="mb-5">
                            <label for="fName" class="mb-3 block text-base font-medium text-[#07074D]">
                                Factura
                            </label>
                            <select  v-model="obj_abono.factura" class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 text-center text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md">
                                <option value="0">Seleccione un metodo</option>
                                <option v-for="(row,index) in [{id:1,text:'Si'},{id:0,text:'No'}]" v-bind:key="index" :value="row.id">{{row.text}}</option>

                            </select>
                        </div>
                    </div>
                    <div class=" px-3 w-1/2">
                        <div class="mb-5">
                            <label for="fName" class="mb-3 block text-base font-medium text-[#07074D]">
                                % Impuesto
                            </label>
                            <input type="text" disabled v-model="obj_abono.impuesto" placeholder="First Name" class="w-full rounded-md border border-[#e0e0e0] bg-gray-200 py-3  text-center text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                        </div>
                    </div>
                </div>
                <div class=" flex flex-wrap w-full">

                    <div class=" px-3  w-1/2">
                        <div class="mb-5">
                            <label for="lName" class="mb-3 block text-base font-medium text-[#07074D]">
                                Total Abono
                            </label>
                            <input type="number" placeholder="Total Abono" v-model="obj_abono.total" class="w-full  rounded-md border border-[#e0e0e0] text-center py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="dialog-footer">
                <!--<el-button @click="addAbono=false">Cancelar</el-button>
                    <el-button @click="guardarAbono()" type="primary">Registrar abono</el-button>-->

                <btn_component :text="'Registrar abono'" :tipo="'success'" @click="guardarAbono()"></btn_component>
                <btn_component :text="'Cerrar'" :tipo="'info'" @click="addAbono=false"></btn_component>

            </div>
        </template>
    </el-drawer>
    <el-drawer @closed="getData()" v-model="viewCotizacion" title="" :with-header="false" :size="$isMobile()?'100%':'80%'">
        <!--content-->
        <div class="h-full    flex flex-wrap content-start justify-center w-full  outline-none focus:outline-none overflow-y-scroll">
            <!--header-->

            <div class=" flex flex-wrap justify-start w-full h-auto bg-white  py-2">
             <h3 class="text-3xl font-semibold">
                    Detalle venta
                </h3><br>
              
                <btn_component :text="'formato con medidas'" :tipo="'purple'" @click="tipo_formato='con_medidas'"></btn_component>
        
                <btn_component :text="'formato sin medidas'" :tipo="'info'" @click="tipo_formato='sin_medidas'"></btn_component>
    
                <btn_component :text="'Descargar cotizacion'" :tipo="'success'" @click="DescargarCotizacion()"></btn_component>
                <btn_component :text="'Editar Descuento'" :tipo="'purple'" @click="editarDescuento=true"></btn_component>
                <btn_component v-if="(el_cotizacion.id>0&&el_cotizacion.hasOwnProperty('emails_cotizacion'))" :count="el_cotizacion.emails_cotizacion.length" :text="'Enviar por correo'" :tipo="'purple'" @click="enviarCorreoCot"></btn_component>
                <btn_component v-if="(el_cotizacion.id>0&&el_cotizacion.hasOwnProperty('whatsacceso_cotizacion'))" :count="el_cotizacion.whatsacceso_cotizacion.length" :text="'Enviar acceso por whatsapp'" :tipo="'purple'" @click="enviarWhatsapp(el_cotizacion.id)"></btn_component>
                <btn_component  :text="'Enviar poliza de garantia por whatsapp'" :tipo="'success'" @click="enviarWhatsappGarantia(el_cotizacion.id)"></btn_component>
               
                
               
                <btn_component :text="'Cerrar'" :tipo="'info'" @click="viewCotizacion=false"></btn_component>

            </div>
            <!--body-->
            <div class="w-full   overflow-y-scroll flex  flex-wrap justify-start  content-start z-20 max-h-max">

                <div class="h-full bg-white px-2 flex flex-wrap w-full">

                    <div v-if="editarDescuento" class="w-full flex flex-wrap justify-start content-start">
                        <span class="text-black font-medium">Descuento</span>
                        <input type="numeric" @input="formatNumberAnd0($event)" class="h-20 text-left mb-2 w-full px-2 bg-gray-100" v-model="el_cotizacion.descuento">
                        <span class="text-black font-medium">Descuento personalizado</span>
                        <input type="text" placeholder="Concepto descuento personalizado"  class=" text-left mb-2 w-full px-2 bg-gray-100" v-model="el_cotizacion.concepto_descuento_personalizado">
                        <input type="numeric" placeholder="Descuento personalizado" @input="formatNumberAnd0($event)" class="h-20 text-left mb-2 w-full px-2 bg-gray-100" v-model="el_cotizacion.descuento_personalizado">

                        <button @click="editarDescuento=false" class="my-1 bg-blue-200 mx-1 px-2 py-1 text-xs font-medium rounded border-2  border-black">Cancelar</button>
                        <button @click="guardarDescuento()" class="my-1 bg-green-200 mx-1 px-2 py-1 text-xs font-medium rounded border-2  border-black">Guardar</button>
                    </div>
                    <TemplateCotizacion v-if="!editarDescuento" :cotizacion="el_cotizacion" :tipo_formato="tipo_formato" :tipo_view="'pedido'" :infoneg="true" :dn="true" :dc="true" :dtotal="true" :dqr="true" :ddt="true"></TemplateCotizacion>

                </div>

            </div>

        </div>
      
    </el-drawer>

    <el-drawer @closed="getData()" v-model="viewListCitas" title="" :with-header="false" :size="$isMobile()?'100%':'90%'">
        <!--content-->
        <div class="h-full    flex flex-wrap content-start justify-center w-full  outline-none focus:outline-none overflow-y-scroll">
            <!--header-->

            <div class="sticky top-0 flex flex-wrap justify-between p-5 border-b border-solid border-blueGray-200  z-30 bg-white rounded-t w-full">

            </div>
            <!--body-->
            <div class="w-full   overflow-y-scroll flex  flex-wrap justify-start  content-start z-20 h-full">

                <div class="h-full bg-white px-2 flex flex-wrap w-full justify-start">

                    <div class="h-full overflow-y-scroll w-full">
                        <TemplateCitas :cotizacion="el_cotizacion" :instalacion="true"></TemplateCitas>

                    </div>
                </div>

            </div>

        </div>
        <template #footer>
            <div class="dialog-footer">
                <!--<a class=" bg-green-500 mx-1 px-2 py-1 text-black text-xs font-medium rounded border-2  border-black" :href="'/app/cita?idc='+el_cotizacion.id+'&tv=add'">Crear cita</a>
                <a @click="viewListCitas=false" class=" bg-blue-500 mx-1 px-2 py-1 text-black text-xs font-medium rounded border-2  border-black">Cerrar detalle</a>-->
                <btn_component :text="'Crear cita'" :tipo="'success'" @click="$router.push('/app/cita?idv='+el_cotizacion.idventa+'&tv=add')"></btn_component>
                <btn_component :text="'Cerrar detalle'" :tipo="'info'" @click="viewListCitas=false"></btn_component>

            </div>
        </template>
    </el-drawer>

    <el-drawer @closed="getData()" v-model="viewCotizacionAbonos" title="" :with-header="false" :size="$isMobile()?'100%':'60%'">
        <!--content-->
        <div class="h-full    flex flex-wrap content-start justify-center w-full  outline-none focus:outline-none overflow-y-scroll">
            <!--header-->

            <div class="sticky top-0 flex flex-wrap justify-between p-5 border-b border-solid border-blueGray-200  z-30 bg-white rounded-t w-full">
                <h3 class="text-3xl font-semibold">
                    Lista de pagos
                </h3>
                <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="showM=false">
                    <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                    </span>
                </button>
            </div>
            <!--body-->
            <div class="w-full   overflow-y-scroll flex  flex-wrap justify-start  content-start z-20 h-full">

                <div class="h-full bg-gray-100 py-2 flex flex-wrap w-full content-start px-2 ">

                    <div v-if="(el_cotizacion.venta!=null)" class="w-full text-left text-red-500 mt-3">
                        <span class="font-extrabold text-red-500">Saldo pendiente :</span> ${{formatPrice(Number(CalcularDeuda()).toFixed(2)) }}
                    </div>
                    <a v-if="CalcularDeuda()>0&&(el_cotizacion.venta!=null)" class="my-3 text-blue-500" @click="addAbono=true;obj_abono.cotizacion=el_cotizacion.id;">
                        Registrar abono
                    </a>
                    <div v-if="(el_cotizacion.venta!=null)" class="flex flex-wrap justify-between h-48 bg-gray-300 mt-2 overflow-y-scroll w-full content-start">

                        <table class="w-full">
                            <thead>
                                <tr>
                                    <th class="border-2 top-0 sticky border-black bg-gray-200">Total</th>
                                    <th class="border-2 top-0 sticky border-black bg-gray-200">Capital</th>
                                    <th class="border-2 top-0 sticky border-black bg-gray-200">Factura</th>
                                    <th class="border-2 top-0 sticky border-black bg-gray-200">Impuestos</th>
                                    <th class="border-2 top-0 sticky border-black bg-gray-200">Tipo abono</th>

                                    <th class="border-2 top-0 sticky border-black bg-gray-200">Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(row_abono,index_abono) in el_cotizacion.abonos" v-bind:key="index_abono">
                                    <tr>
                                        <td class="border border-black text-blue-500 font-extrabold">${{formatPrice((Number(row_abono.capital)+Number(row_abono.impuestos)).toFixed(0))}}</td>
                                        <td class="border border-black text-green-500 font-extrabold">${{formatPrice((Number(row_abono.capital)).toFixed(2))}}</td>
                                        <td class="border border-black text-green-500 font-extrabold">{{row_abono.factura?'Si':'No'}}</td>
                                        <td class="border border-black text-yellow-600 font-extrabold">${{formatPrice(Number(row_abono.impuestos).toFixed(2))}}</td>

                                        <td class="border border-black text-yellow-600 font-extrabold">{{row_abono.tipo_pago}}</td>

                                        <td @click="eliminarabono(row_abono.id)" class="border cursor-pointer border-black text-red-500 font-extrabold">Eliminar</td>

                                    </tr>
                                </template>

                            </tbody>
                        </table>
                    </div>

                </div>

            </div>

        </div>
        <template #footer>
            <div class="dialog-footer flex flex-wrap justify-between">

                <btn_component :text="'Cerrar detalle'" :tipo="'info'" @click="viewCotizacionAbonos=false"></btn_component>

                <!-- <button @click="viewCotizacionAbonos=false" class="my-1 bg-blue-200 mx-1 px-2 py-1 text-xs font-medium rounded border-2  border-black">Cerrar detalle</button>-->

            </div>
        </template>
    </el-drawer>

    <el-drawer @closed="getData()" v-model="viewListOrdenes" title="" :with-header="false" :size="$isMobile()?'100%':'100%'">
        <!--content-->

        <div class="zoom w-full flex flex-wrap justify-center content-start h-full">
            <div class="w-full xs:w-full sm:w-full md:w-3/6 lg:w-3/6 xl:w-3/6  h-3/6 xs:h-3/6 md:h-full lg:h-full xl:h-full bg-gray-200 overflow-x-scroll">
                <div class="text-black text-xl uppercase bg-gray-300 align-middle">
                    Formato de produccion
                </div>
                <div class="h-10 text-black text-base uppercase bg-gray-300 align-middle w-full flex flex-wrap">
                    <div class="w-3/12 h-10 bg-yellow-300 flex flex-wrap justify-center content-center font-extrabold"><span class="align-middle">#Venta</span></div>
                    <div class="w-3/12 h-10 bg-yellow-200 flex flex-wrap justify-center content-center font-extrabold"><span class="align-middle">{{el_cotizacion.idventa}}</span></div>
                    <div class="w-3/12 h-10 bg-yellow-300 flex flex-wrap justify-center content-center font-extrabold"><span class="align-middle">Cliente</span></div>
                    <div class="w-3/12 h-12 bg-yellow-200 align-middle flex flex-wrap justify-center content-center"><span>{{ el_cotizacion.clientes.name}} {{ el_cotizacion.clientes.apellido_p}} {{ el_cotizacion.clientes.apellido_m}}</span></div>
                </div>
                <VistaAprobacion :cotizacion="el_cotizacion" :abrobar="false"></VistaAprobacion>

            </div>
            <div class="w-full xs:w-full sm:w-full md:w-3/6 lg:w-3/6 xl:w-3/6  h-3/6 xs:h-3/6 md:h-full lg:h-full xl:h-full">
                <div class="h-full    flex flex-wrap content-start justify-center w-full  outline-none focus:outline-none overflow-y-scroll">
                    <!--header-->

                    <!--body-->
                    <div class="w-full   overflow-y-scroll flex  flex-wrap justify-start  content-start z-20 h-full">

                        <div class="h-full bg-white px-2 flex flex-wrap w-full justify-start">

                            <div class="h-full overflow-y-scroll w-full">

                                <div class="bg-gray-300">

                                    <table class="w-full ">
                                        <thead>
                                            <tr>
                                                <th colspan="5">
                                                    <span class="font-extrabold">Materia prima requerida para produccion</span>
                                                </th>
                                            </tr>
                                            <tr class="bg-yellow-200">
                                                <th colspan="3">
                                                    <span class="font-extrabold">Estatus compras</span>
                                                </th>
                                                <th colspan="2">
                                                    <span :class="el_cotizacion.compras_finalizadas?'text-green-500':'text-red-500'" @click="el_cotizacion.compras_finalizadas?ActualizarCompras(el_cotizacion.id,0):ActualizarCompras(el_cotizacion.id,1)" class="font-extrabold">{{el_cotizacion.compras_finalizadas?'Finalizadas':'Finalizar'}}</span>
                                                </th>
                                            </tr>

                                            <tr>
                                                <th>
                                                    Catalogo
                                                </th>
                                                <th>
                                                    Producto
                                                </th>
                                                <th>
                                                    Proveedor
                                                </th>
                                                <th>
                                                    Cantidad
                                                </th>
                                                <th>
                                                    Agregar
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="bg-yellow-100 py-1 px-1" v-for="(row,index) in el_cotizacion.productos_cotizaciones" v-bind:key="index">

                                            <template v-for="(r,i) in row.parametros" v-bind:key="i">
                                                <tr class="cursor-pointer" v-if="r.tipo=='catalogo'&&r.producto_lista_prod!=null&&r.producto_lista_prod.precio>0" @click="AgregarApedido(r,row)">
                                                    <td class="text-left align-middle text-xs ">{{r.nombre}}</td>
                                                    <td class="text-left align-middle text-xs">{{r.producto_lista_prod.nombre}}</td>

                                                    <td class="text-left align-middle text-xs">{{r.producto_lista_prod.provedores==null?0:r.producto_lista_prod.provedores.nombre}}</td>
                                                    <td class="text-left align-middle text-xs">{{r.formula_mp!=null&&r.formula_mp!=''?calcularPrecio(r,row.parametros)*row.cantidad:getValorParam(r)*row.cantidad}}</td>
                                                    <td class="text-left align-middle text-xs text-green-600 font-extrabold text-2xl"><i class="las la-plus-square"></i></td>
                                                </tr>
                                            </template>

                                        </tbody>

                                    </table>

                                </div>
                                <TemplateOrdenes :cotizacion="el_cotizacion" :instalacion="true"></TemplateOrdenes>

                                <el-dialog v-model="addProductoOrdenes" title="Agregar compra" :width="$isMobile()?'100%':'70%'">

                                    <div class="w-full flex flex-wrap content-start">
                                        <div class="w-full flex flex-wrap justify-start">
                                            <label>Producto </label>
                                            <el-select v-model="producto_valor" class="w-full" filterable remote reserve-keyword placeholder="Buscador" :remote-method="remoteMethod" :loading="loading">
                                                <el-option v-for="item in productos_filter" :key="item.id" :label="item.nombre" :value="item" />
                                            </el-select>

                                        </div>
                                        <div v-if="producto_valor!=null" class="w-full flex flex-wrap">

                                            <div class="my-1  justify-start flex flex-wrap w-6/12 px-2">
                                                <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                                                    {{producto_valor.nombre}} - {{producto_valor.nombre}} - {{producto_valor.tag}} / <strong class="text-green-500">${{producto_valor.precio}}</strong>/
                                                    <strong class="text-red-500">${{producto_valor.precio_compra}}</strong>
                                                </span>
                                            </div>
                                            <div class="my-1  justify-start flex flex-wrap w-6/12 px-2">
                                                <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                                                    Color
                                                </span>
                                                <input id="cantidad" v-model="color_sel" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="text" placeholder="Color">

                                            </div>
                                            <div class="my-1  justify-start flex flex-wrap w-6/12 px-2">
                                                <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                                                    # Factura
                                                </span>
                                                <input id="factura" v-model="factura_sel" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="text" placeholder="#Factura">

                                            </div>

                                            <div class="my-1  justify-start flex flex-wrap w-6/12 px-2">
                                                <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                                                    Provedor
                                                </span>
                                                <select class="w-full bg-gray-100 rounded-lg text-center py-2 " v-model="provedor_sel">
                                                    <option value="0">Seleccione una opción</option>
                                                    <option v-for="(row,index) in provedores_data" v-bind:key="index" :value="row.id">{{row.nombre}}</option>
                                                </select>
                                            </div>

                                            <div class="my-1  justify-start flex flex-wrap w-6/12 px-2">
                                                <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                                                    Unidad de medida
                                                </span>
                                                <select class="w-full bg-gray-100 rounded-lg text-center py-2 " v-model="unidad_sel">
                                                    <option value="0">Seleccione una opción</option>
                                                    <option v-for="(row,index) in unidades_data" v-bind:key="index" :value="row.id">{{row.nombre}}</option>
                                                </select>
                                            </div>

                                            <div class="my-1  justify-start flex flex-wrap w-6/12 px-2">
                                                <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                                                    Cantidad
                                                </span>

                                                <input id="cantidad" v-model="cantidad_sel" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="number" placeholder="Cantidad">
                                            </div>
                                            <div class="my-1  justify-start flex flex-wrap w-6/12 px-2">
                                                <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                                                    Precio
                                                </span>
                                                <input id="cantidad" v-model="precio_sel" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="number" placeholder="Precio">
                                            </div>

                                        </div>

                                    </div>

                                    <template #footer>
                                        <span class="dialog-footer">

                                            <btn_component :text="'Cancelar'" :tipo="'danger'" @click="addProductoOrdenes=false"></btn_component>
                                            <btn_component :text="'Guardar'" :tipo="'success'" @click="GuardarProductoOrden"></btn_component>

                                            <!--<el-button @click=" addProductoOrdenes = false">Cancel</el-button>
                                            <el-button type="primary" @click="GuardarProductoOrden">
                                                Guardar
                                            </el-button>-->
                                        </span>
                                    </template>
                                </el-dialog>

                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>

        <template #footer>
            <div class="dialog-footer">

                <btn_component :text="'Agregar Compra'" :tipo="'success'" @click="addProductoOrdenes=true"></btn_component>
                <btn_component :text="'Cerrar detalle'" :tipo="'info'" @click="viewListOrdenes=false"></btn_component>

                <!-- <a @click="addProductoOrdenes=true" class=" bg-green-500 mx-1 px-2 py-1 text-black text-xs font-medium rounded border-2  border-black">Agregar Compra</a>
                <a @click="viewListOrdenes=false" class=" bg-blue-500 mx-1 px-2 py-1 text-black text-xs font-medium rounded border-2  border-black">Cerrar detalle</a>-->

            </div>
        </template>
    </el-drawer>
    <el-dialog v-model="ModalCambiarVendedor" title="Cambiar vendedor" :width="$isMobile()?'100%':'30%'">
        <div class="w-full px-2 flex flex-wrap    justify-start">
            <div class="mb-5 w-full">
                <label for="fName" class="mb-3 block text-base font-medium   text-left text-[#07074D]">
                    Vendedor cotización
                </label>
                <SearchSelectUsuariocitas v-bind:key="usuario_vendedor" @carga="buscarGetUsuarios" :rows="usuarios_data" :label="'Vendedor'" :valor="usuario_vendedor" @seleccionado="action_usuario" />

            </div>

        </div>
        <template #footer>
            <span class="dialog-footer">
                <!--<el-button @click="ModalCambiarVendedor = false">Cancel</el-button>
                <el-button type="primary" @click="actualizarVendedor()">
                    Guardar
                </el-button>-->

                <btn_component :text="'Guardar'" :tipo="'success'" @click="actualizarVendedor()"></btn_component>
                <btn_component :text="'Cerrar'" :tipo="'info'" @click="ModalCambiarVendedor = false"></btn_component>

            </span>
        </template>
    </el-dialog>
    <transition name="fade">
        <div v-if="showInfoData" class="fixed bottom-0 left-0 w-full bg-yellow-500 p-4 transform translate-y-0 z-30 flex flex-wrap justify-between items-center">
          <h2 class="text-black text-xl font-bold">Total de venta</h2>
          <div class="flex flex-wrap justify-center content-center items-center">
            <p class="text-black font-extrabold  ">${{ formatPrice(Number(getTotalVentas()).toFixed(2)) }}
           
            </p>
            <button  class="z-30 btn font-bold  text-black text-3xl w-12 h-12 justify-center content-center flex flex-wrap    float-right m-r-xxs rounded-full    " @click="showInfoData=false;">
              <vue-feather :type="'x-circle'" size="24" class="self-center" :stroke="'white'" />
          </button>
          </div>
         
         
        </div>
      </transition>
</section>
</template>

<script>
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import Tabla from "@/components/Tabla.vue";
import TemplateCotizacion from "@/components/TemplateCotizacion.vue";
import VistaAprobacion from "@/components/VistaAprobacion.vue";
import SearchSelectUsuariocitas from "@/components/SearchSelectUsuariocitas.vue";
import generales from "@/functions/generales";
import metodos_pagos from "@/Services/metodos_pagos";
import abonos from "@/Services/abonos";
import cotizaciones from "@/Services/cotizaciones";
import unidades from "@/Services/unidades";
import provedores from "@/Services/provedores";
import productos from "@/Services/productos";
import productos_ordenes from "@/Services/productos_ordenes";
import TemplateCitas from "@/components/TemplateCitas.vue";
import TemplateOrdenes from "@/components/TemplateOrdenes.vue";
import usuarios from "@/Services/user";
import cotizaciones_g from "@/functions/cotizaciones";
import {
    filter
} from "lodash";
export default {
    components: {
        Tabla,
        TemplateCotizacion,
        VistaAprobacion,
        TemplateCitas,
        TemplateOrdenes,
        SearchSelectUsuariocitas
    },
    mixins: [generales, cotizaciones, metodos_pagos, abonos, productos_ordenes, cotizaciones_g],
    data() {
        const user = useUserStore()

        return {
            headings: [

                {
                    key: 'id',
                    name: '# Cot',
                    class: 'min-w-[100px]',
                    show: true
                },

                {
                    key: 'cancelar_pedido',
                    name: 'Cancelar pedido',
                    show: true
                },
                {
                    key: 'generar_garantia',
                    name: 'Generar garantia',
                    show: true
                },
                {
                    key: 'idventa',
                    name: '# venta',
                    class: 'min-w-[100px]',
                    show: true
                },
                {
                    key: 'garantia',
                    class: 'min-w-[100px]',
                    name: 'Venta origen',
                    show: true
                },
                {
                    key: 'fecha_abono',
                    name: 'Fecha abono',
                    show: true
                },
                {
                    key: 'fecha_venta',
                    name: 'Fecha Pedido',
                    show: true
                },

                {
                    key: 'clientes',
                    name: 'Nombre Cliente',
                    show: true
                },
                {
                    key: 'cambiar_vendedor',
                    name: 'Cambiar vendedor',
                    show: true
                },
                {
                    key: 'usuario',
                    name: 'Vendedora',
                    show: true
                },
                {
                    key: 'ver_cotizacion',
                    name: 'Ver pedido',
                    show: true,
                    class: 'text-green-500 font-extrabold'
                },
                {

                    key: 'descuento',
                    name: 'Descuento',
                    show: true
                },
                {
                    key: 'total_con_descuento',
                    name: 'Total cuenta',
                    show: true,
                    class: 'text-green-500 font-extrabold'
                },

                {
                    key: 'saldo',
                    name: 'Saldo Pendiente',
                    show: true
                },
                {
                    key: 'ordenes',
                    name: 'Compras',
                    show: true
                },
                {
                    key: 'status_verificar',
                    name: 'Estatus verificar',
                    show: true
                },
                {
                    key: 'status_produccion',
                    name: 'Estatus Producción',
                    show: true
                },
                {
                    key: 'status_instalacion',
                    name: 'Estatus instalación',
                    show: true
                },

                {
                    key: 'edit_produccion',
                    name: 'Edit Formato',
                    show: true
                },

            ],
            showInfoData:false,
        
            dates: [],
            upbuton: 0,
            loading: false,
            el_cotizacion: {
                id: 0
            },
            open: false,
            user: user.getAll(),

            data: {},
            actionModal: null,
            viewCotizacion: false,
            viewListOrdenes: false,
            productos_catalogos: [],
            productos_filter: [],
            addProductoOrdenes: false,
            viewCotizacionAbonos: false,
            unidades_data: [],
            editarDescuento:false,
             //busacador usuario
            usuarios_data: [],
            usuario_vendedor: null,
            buscar_usuario: 'all',
            pagina_usuario: 1,
            limit_usuario: 0,
            //en buscador usuario
            provedores_data: [],
            showM: false,
            info: '',
            id: 0,
            producto_valor: null,
            tipo_formato: 'con_medidas',
            precio_sel: 0,
            cantidad_sel: 0,
            provedor_sel: 0,
            unidad_sel: 0,
            color_sel: '',
            factura_sel: '',
            ModalCambiarVendedor:false,
            viewListCitas: false,
            buscar: 'all',
            pagina: 1,
            limit: 0,
            reftable: 0,
            tipo: 'ventas',
            fecha: [this.getFirstDayOfMonth(), this.getLastDayOfMonth()],

        }

    },
    mounted() {

        emitter.on('estatus', ({
            id,
            status
        }) => {

            this.CambiarStatusrProdOrden({
                id,
                status
            })
        });

    },
    async created() {
        emitter.on('eliminar', (id) => {
            console.log('id', id)
            this.eliminarProdOrden(id);

        });
        if (this.$route.query.hasOwnProperty('cid')) {
            this.buscar = this.$route.query.cid;
        }

        await this.getData()
        await this.getDataProvedores();
        await this.getDataUnidades();
        await this.getDataProductosCatalogo();
        await this.getDataUsuarios();
        // await this.getDataMetodos();
    },
    methods: {
        getTotalVentas(){
            let total=0;
this.data.data.forEach(element => {
if(element.garantia<=0){
    total+=(Number(element.total)-Number(element.descuento)-Number(element.descuento_personalizado));
}
   
});
return total;
        },
        showInfo(){
this.showInfoData=true;
        },
        async DescargarCotizacion() {

            let nombre_cliente = this.verfiNull(this.el_cotizacion.clientes.name) + ' ' + this.verfiNull(this.el_cotizacion.clientes.apellido_p) + ' ' + this.verfiNull(this.el_cotizacion.clientes.apellido_m);

            let titulotipo = (this.el_cotizacion.idventa == null) ? this.el_cotizacion.id : this.el_cotizacion.idventa;
            let name = '#' + titulotipo + ' -- ' + nombre_cliente;

            await this.onCapture(name, false, .7)
        },
        async enviarCorreoCot() {

            let data = {
                titulo: this.verfiNull(this.el_cotizacion.clientes.name) + ' ' + this.verfiNull(this.el_cotizacion.clientes.apellido_p) + ' ' + this.verfiNull(this.el_cotizacion.clientes.apellido_m),
                correo: this.el_cotizacion.clientes.email, //'popcing.agency@gmail.com',//this.el_cotizacion.clientes.email,
                id: this.el_cotizacion.id,
            }
            let enviarCorreop = 1;

           

            let IsSend = await this.onCapture(data.titulo, false, .7, enviarCorreop, data)
            if (IsSend == false) {
                emitter.emit("alerta_show", 'Ocurrio un error al enviar correo');
            } else {
                this.el_cotizacion.emails_cotizacion.push({
                    id: true
                })

                emitter.emit("success", 'El Correo a sido enviado');

            }

        },
        AgregarApedido(prod, prod_cot) {
            console.log(prod, '....')
            this.producto_valor = prod.producto_lista_prod;
            this.provedor_sel = prod.producto_lista_prod.provedores == null ? 0 : prod.producto_lista_prod.provedores.id;
            this.addProductoOrdenes = true;
            console.log(prod.formula_mp)
            this.cantidad_sel = prod.formula_mp != null && prod.formula_mp != '' ? this.calcularPrecio(prod, prod_cot.parametros)*prod_cot.cantidad : this.getValorParam(prod)*prod_cot.cantidad
            this.precio_sel = Number(this.producto_valor.precio_compra) * Number(this.cantidad_sel)
            console.log(this.producto_valor.precio_compra, '-pc-')
            /*
            
            <td class="text-left align-middle text-xs ">{{r.nombre}}</td>
            <td class="text-left align-middle text-xs">{{r.producto_lista.nombre}}</td>
            <td class="text-left align-middle text-xs">{{r.producto_lista.provedores==null?0:r.producto_lista.provedores.nombre}}</td>
            <td class="text-left align-middle text-xs">{{r.formula_mp!=null&&r.formula_mp!=''?calcularPrecio(r,row.parametros):getValorParam(r)}}</td>*/

        },
        getValorParam(r) {
            switch (r.tipo) {

                case 'catalogo':
                    return 1;

                    //let valor_param_prod = param.selproductos.productos.find(p => {(p.id) == Number(param.valor)})

                    break;
                case 'parametro':
                    return r.valor_prod
                    break;

                default:
                    return 0;
                    break;
            }

        },
        calcularPrecio(row, parametros) {

            let formula = this.CastFormula(row, parametros,row.formula_mp);
            //console.log(String(formula).toString(), '----formullllllll-------------aa---')
            let res2 = Function('return ' + String(formula))
            //console.log(formula, '----fr-----')
            let pr = res2()
            //console.log(pr, '----pr-----')
            return Number(pr).toFixed(2);
        },
        CastFormula(row, parametros,formula) {
            let val = '';
            //console.log(JSON.parse(row.formula), '----formula.-----')
            JSON.parse(formula).forEach(element => {

                if (element.tipo == 'key') {
                    val = val + element.valor;
                } else if (element.tipo == 'catalogo') {
                    //let param = row.parametros.find(p => {(p.id) == Number(element.valor)});
                    let param = parametros.find(function (el) {
                        return el.pm_id == (element.valor);
                    });
                    let valor_param_prod = param.producto_lista;
                    //let valor_param_prod = param.selproductos.productos.find(p => {(p.id) == Number(param.valor)})
                    val = val + valor_param_prod.precio;
                } else if (element.tipo == 'parametro') {

                    //let param = row.parametros.find(p => Number(p.id) == Number(element.valor));
                    let param = parametros.find(function (el) {
                        return el.pm_id == (element.valor);
                    });
                    //console.log(param, '---p---');
                    //if (String(param.nombre).toUpperCase() == 'METROS LARGO' || String(param.nombre).toUpperCase() == 'METROS ANCHO') {
                    //      val = val + (param.valor < 1 ? 'Number(' + 1 + ')' : 'Number(' + param.valor + ')');

                    //  } else {
                    val = val + 'Number(' + param.valor_prod + ')';

                    //  }
                    //val = val + param.valor<1?1:param.valor;
                }
                else if(element.tipo == 'catalogo_valor_formula'){
            let param = row.parametros.find(function (el) {
                return el.pm_id == Number(element.valor);
            });
            console.log(param,'---param-----',element)
            //let valor_param_prod = param.parametros.productos.find(p => p.id == param.valor)
            val = val + Number(param.producto_lista.valor_formula).toFixed(3);
           

        }
            else if(element.tipo == 'value_formula'){
                    
                val = val + element.valor;

            }
            else if(element.tipo == 'calc_formula'){
                    let param = parametros.find(function (el) {
                        return el.id == Number(element.valor);
                    });
                    val = val + this.CastFormula(row,parametros, param.formula_mp);

                }

            });
            // console.log(val,'----formula 2---')
            if (val == '') {
                return '0';
            }
            return val;
        },


        action_usuario(ev) {
            this.usuario_vendedor = ev.value;

        },
        buscarGetUsuarios(busqueda) {
            this.buscar_usuario = busqueda;
            this.getDataUsuarios();

        },
        async getDataUsuarios() {

            let dat = {
                search: this.buscar_usuario,
                page: this.pagina_usuario,
                limit: this.limit_usuario,
                data: {}
            }

            await usuarios.GET_USUARIOS(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.usuarios_data = response.data;

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }

            });
        },
        cambiarUsuario(row) {
            this.el_cotizacion = row;
            console.log(this.el_cotizacion.usuario, '------usaurio-----')
            this.usuario_vendedor = this.el_cotizacion.usuario;
            this.ModalCambiarVendedor = true;
        },
        async actualizarVendedor() {
            if (this.usuario_vendedor == null) {

                emitter.emit("alerta_show", 'Es necesario selecionar un vendedor');
                return;
            }
            let dat = {
                id: this.el_cotizacion.id,
                users_id: this.usuario_vendedor.id
            }
            await cotizaciones.EDITAR_PARAMS(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    this.ModalCambiarVendedor = false;
                    this.getData('editar');

                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                emitter.emit("loading", false)
            });
        },
        async ActualizarCompras(
            id,
            status
        ) {

            emitter.emit("loading", true)
            let dat = {
                id: id,
                status: status

            }
            await cotizaciones.UPDATE_COMPRAS(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.el_cotizacion.compras_finalizadas = status;
                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }

                emitter.emit("loading", false)
            });
        },

        async CambiarStatusrProdOrden({
            id,
            status
        }) {

            emitter.emit("loading", true)
            let dat = {
                id: id,
                status: status

            }
            await productos_ordenes.CAMBIAR_ESTATUS(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.el_cotizacion.productos_ordenes = this.el_cotizacion.productos_ordenes.filter(element => {

                        if (element.id == id) {
                            element.status = status;
                        }
                        return true;
                    });
                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },

        async eliminarProdOrden(id) {

            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await productos_ordenes.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.el_cotizacion.productos_ordenes = this.el_cotizacion.productos_ordenes.filter(element => {
                        return element.id != id
                    });
                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },

        async GuardarProductoOrden() {
            this.loader = true;
            if (this.precio_sel <= 0) {
                emitter.emit("alerta_show", 'Es necesario ingresar el precio');
                return;

            }
            if (this.unidad_sel <= 0) {
                emitter.emit("alerta_show", 'Es necesario seleccionar una unidad');
                return;

            }
            if (this.cantidad_sel <= 0) {
                emitter.emit("alerta_show", 'Es necesario ingresar el cantidad');
                return;

            }
            if (this.provedor_sel <= 0) {
                emitter.emit("alerta_show", 'Es necesario selecionar un provedor');
                return;

            }

            emitter.emit("loading", true)

            let dat = {

                provedor: this.provedor_sel,
                precio: this.precio_sel,
                unidad: this.unidad_sel,
                cotizacion: this.el_cotizacion.id,
                cantidad: this.cantidad_sel,
                color: this.color_sel,
                factura: this.factura_sel,
                producto: this.producto_valor.id,
                total: Number(this.precio_sel) //* Number(this.cantidad_sel)

            }
            await productos_ordenes.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    console.log(response)
                    this.el_cotizacion.productos_ordenes.push(response)
                    this.limpiarProductoOrdenes();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });

        },
        limpiarProductoOrdenes() {

            this.provedor_sel = 0;
            this.precio_sel = 0;
            this.unidad_sel = 0;

            this.cantidad_sel = 0;
            this.color_sel = '';
            this.factura_sel = '';
            this.producto_valor = null
        },
        remoteMethod(query) {
            if (query) {
                this.loading = true
                setTimeout(() => {
                    this.loading = false
                    this.productos_filter = this.productos_catalogos.filter((item) => {
                        return item.nombre.toLowerCase().includes(query.toLowerCase())
                    })
                }, 200)
            } else {
                this.productos_filter = []
            }
        },
        IsMov() {

            //return true;
            return this.$isMobile()
        },
        async selOrdenes(el) {
            await this.getDataCotizacionId(el.id);
            this.viewListOrdenes = true;
            ///this.el_cotizacion = el;

        },
        selStatusInstalacion(el) {
            this.viewListCitas = true;
            this.el_cotizacion = el;

        },
        verAbonos(row) {
            this.getDataMetodos();
            this.viewCotizacionAbonos = true;
            this.el_cotizacion = row;
        },
        verCotizacion(row) {
            this.getDataCotizacionId(row.id);
         this.viewCotizacion = true;

        },
        async pageChangue(val) {

            this.page = val;
            await this.getData();

        },

        infoModal(row) {
            this.info = row;
            console.log(row, '---info---')
        },
        paginaSearch(pagina) {
            console.log('---- dat---' + pagina)
            this.pagina = pagina;

            this.getData();
        },

        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },
        limpiar() {
            this.viewCotizacion = false;
        },
        buscador(row) {
            if (String(row.telefono).toUpperCase().search(String(this.buscar).toUpperCase()) != -1 || (String(row.nombre)).toUpperCase().search(String(this.buscar).toUpperCase()) != -1) {
                return true;
            } else {
                return false;
            }
        },
        getColumns() {
            let col = 0;
            this.headings.forEach(element => {
                if (element.show) {
                    col = col + 1
                }
            });
            return col;
        },

        buscarGet(text) {
            this.buscar = text;
            if (text == '') {
                this.buscar = 'all';
            }
            this.getData();
        },
        async getDataCotizacionId(id) {
            emitter.emit("loading", true)

            let dat = {
                id: id
            }

            let r = await cotizaciones.SHOW(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.el_cotizacion = response;

                   
                    emitter.emit("loading", false)
                    //this.$router.push('/app/'); 
                } else {
                    console.log(response)
                    emitter.emit("alerta_show", response.error);
                    emitter.emit("loading", false)
                }

                return;
            });
            return r;
        },
        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            let dat = {}
            if (this.tipo != 'sin_finalizar') {
                dat = {
                    search: this.buscar,
                    page: this.pagina,
                    tipo: this.tipo,
                    inicio: this.buscar == 'all' ? this.formatDateEN(this.fecha[0]) : '2021-01-28',
                    fin: this.formatDateEN(this.fecha[1]),
                    data: {}
                }
            } else {
                dat = {
                    search: this.buscar,
                    page: this.pagina,
                    tipo: this.tipo,

                }
            }

            await cotizaciones.VISTA_VENTAS(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response;

                    if (this.$route.query.hasOwnProperty('cid')) {
                        let fresult = response.data.filter(r => r.id == this.$route.query.cid)

                        if (fresult.length > 0) {

                            let tv = this.$route.query.hasOwnProperty('tv') ? this.$route.query.tv : '';
                            this.el_cotizacion = fresult[0];
                            if (tv == 'pagos') {
                                //vista de abonos
                                this.getDataMetodos();
                                this.viewCotizacionAbonos = true;
                            } else if (tv == 'detalle') {
                                this.getDataCotizacionId(this.$route.query.cid)
                                this.viewCotizacion = true;
                            } else {
                                this.getDataCotizacionId(this.$route.query.cid)
                                this.viewCotizacion = true;
                            }

                            this.$router.replace({
                                name: 'detalle_ventas',
                                force: true
                            });
                            //this.el_cotizacion = fresult[0];
                            this.viewCotizacion = true;
                        }
                        //console.log(this.$route.query.r,fresult,'recovery')
                    }
                    this.reftable = this.reftable + 1;

                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                emitter.emit("loading", false)
            });
        },
        async getDataProvedores() {

            emitter.emit("loading", true)

            await provedores.GET().then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.provedores_data = response;

                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                emitter.emit("loading", false)
            });
        },
        async getDataUnidades() {

            emitter.emit("loading", true)

            await unidades.GET().then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.unidades_data = response;

                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                emitter.emit("loading", false)
            });
        },
        async getDataProductosCatalogo() {

            emitter.emit("loading", true)

            await productos.OPCION().then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.productos_catalogos = response;
                    this.productos_filter = response;

                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                emitter.emit("loading", false)
            });
        },

        async Verificar(status) {

            let dat = {
                id: this.el_cotizacion.id,
                status_verificar: status,
                observaciones_verificar: this.observaciones_verificar

            }
            if (status == 'ACEPTADA') {
                dat.status_produccion = 'EN PRODUCCION'
            }

            if (this.el_cotizacion.id == 0) {
                //this.crear(dat)
            } else {
                this.editar(dat)
            }
        },

        async editar(dat) {
            emitter.emit("loading", true)

            await cotizaciones.EDITAR_PARAMS(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.el_cotizacion = response;
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async guardarDescuento() {
            let dat = {
                id: this.el_cotizacion.id,
                descuento: this.el_cotizacion.descuento,
                concepto_descuento_personalizado: this.el_cotizacion.concepto_descuento_personalizado,
                descuento_personalizado: this.el_cotizacion.descuento_personalizado,

            }
            if (this.el_cotizacion.id == 0) {
                //this.crear(dat)
            } else {
                this.Editar_Descuento(dat)
            }
        },
        async Editar_Descuento(dat) {
            emitter.emit("loading", true)

            await cotizaciones.EDITAR_DESCUENTO(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    // this.el_cotizacion.descuento = response;
                    this.reftable = this.reftable + 1;
                    this.editarDescuento = false;
                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
<style>
.slide-up-enter-active {
    transition: transform 0.5s;
  }
  .slide-up-enter,
  .slide-up-leave-to {
    transform: translateY(100%);
  }
</style>
