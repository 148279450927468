

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
 // GET_CLIENTES CREAR_CLIENTES EDITAR_CLIENTES ELIMINAR_CLIENTES

 GET_INSTALACIONES: async function( data){
      let url='/tablero/instalaciones/ano/'+data.ano+'/mes/'+data.mes
      return initmixin.formatrespuesta('get', data,url,true)
    },
    GET_COTIZACIONES: async function( data){
      let url='/tablero/cotizaciones/ano/'+data.ano+'/mes/'+data.mes
      return initmixin.formatrespuesta('get', data,url,true)
    },
    GET_PAGOS: async function( data){
      let url='/tablero/pagos/ano/'+data.ano+'/mes/'+data.mes
      return initmixin.formatrespuesta('get', data,url,true)
    },
    GET_VENDEDORES: async function( data){
      let url='/tablero/vendedores/ano/'+data.ano+'/mes/'+data.mes
      return initmixin.formatrespuesta('get', data,url,true)
    },
    
    GET_VENTAS: async function( data){
      let url='/tablero/ventas/de/'+data.inicio+'/a/'+data.fin
      return initmixin.formatrespuesta('get', data,url,true)
    },
    
   


}