<template>
<div class="overflow-y-auto">
    <div :class="{ 'dark': isDark }" class="h-screen flex flex-wrap bg-black-100 overflow-y-auto">

        <div class="h-screen w-full flex flex-col  bg-gray-100 dark:bg-gray-750 overflow-hidden font-sans select-none relative" data-v-178d8661="">
            <!---->
            <!--<header class="h-14 flex flex-wrap  dark:bg-gray-700 w-full bg-white" :class="''" data-v-178d8661="">
                <div class="flex items-center flex-1 h-14 ">
                    <div class="flex items-center relative bg-white h-14 border-b gap-1.5 md:w-64 lg:w-64 xl:w-64 border-gray-200 px-4">

                        <img @click="isSidebarOpen=!isSidebarOpen;" class="w-7  h-7  rounded object-cover" :class="info.color_logo" :src="info.logo_favicon_black" :alt="info.nombre" />

                        <div class="notranslate text-gray-800 dark:text-gray-100 text-base font-bold"> {{info.nombre}} </div>
                    </div>
                </div>
                <div class="flex dark:text-gray-100"><button @click="salir()" type="button" class="action focus-on-key w-14 h-14 border-l border-b hidden xl:flex flex-wrap justify-center content-center">
                        <i class="las text-2xl la-sign-out-alt"></i>
                    </button>
                    <button type="button" title="Switch theme" @click="toggleTheme()" class="action focus-on-key w-14 h-14 border-l border-b hover:text-indigo-500 dark:hover:text-amber-400 flex-wrap justify-center content-center">

                        <i class="las text-2xl la-palette"></i>
                    </button></div>
            </header>-->
            
            <main class="max-w-screen  flex-1 flex relative h-full overflow-y-auto overflow-x-hidden" data-v-178d8661="">
                <div :class="info.fondo_menu+' '+( this.window.width>766?menu_open?'md:w-64 lg:w-64 xl:w-64':'md:w-0 lg:w-0 xl:w-0':menu_open?'fullw':'notfullw')" class=" transition-all flex flex-col   overflow-x-hidden overflow-y-auto  w-full  sm:w-full  shrink-0 xs:border-r absolute md:static left-0 inset-y-0 z-20 " data-v-178d8661="">
                    <header class="h-36 flex flex-wrap w-full p-0">
                        <div class="flex items-center border-b w-full p-0 ">
                            <div class="text-gray-800 dark:text-gray-100 font-semibold px-4 flex flex-wrap justify-between xl:justify-center lg:justify-center content-center items-center w-full">
                                <img @click="isSidebarOpen=!isSidebarOpen;"  :class="info.class_logo" :src="info.logo" :alt="info.nombre" />

                                <div class=" xl:hidden lg:hidden notranslate text-gray-800 dark:text-gray-100 text-base  font-bold w-14">
                                    <button v-if="menu_open" @click="menu_open=!menu_open" type="button" title="Hide collections" class="z-30 action focus-on-key w-14 h-14 border-b border-l xs:hidden flex flex-wrap justify-center content-center bg-gray-200"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" class="w-5 h-auto">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                                            </path>
                                        </svg></button>
                                    <button v-if="!menu_open" @click="menu_open=!menu_open" type="button" title="Hide collections" class="z-30 action focus-on-key w-14 h-14 border-b border-l xs:hidden flex flex-wrap justify-center content-center bg-gray-200">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" class="w-5 h-5   pointer-events-none">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
                                        </svg>
                                    </button> </div>
                            </div>
                        </div>

                    </header>

                    <div class="h-14 hidden flex items-center shrink-0 bg-gray-300 dark:bg-gray-750 border-b relative"><input filter="" placeholder="Filter …" class="focus-on-key w-full h-full bg-transparent text-gray-800 dark:text-gray-100 relative z-10 pl-5 pr-20"><span class="notranslate hidden lg:inline-block text-xs text-gray-400 border border-gray-300 dark:border-gray-500 rounded pointer-events-none absolute right-5 z-10 px-2 py-1">⌥
                            F</span></div>
                    <nav class="flex flex-col flex-1 overflow-y-auto  pb-24 " style="height:calc(100% - 166px)">
                        <div class="h-full w-full flex flex-col flex-1">
                            <template v-for="(row,index) in menu_set" :key="index">

                                <button v-if="(row.permisos).includes(user.role)&&isset(row.id)" type="button" @click="row.rutas.length>0?changueHidden(index):$router.push(row.ruta)" class="action-active cursor-pointer focus-on-key h-14 justify-between shrink-0 text-gray-800 dark:text-gray-100 border-b relative px-5 flex flex-wrap justify-center content-center">
                                    <span  class=" h-full  absolute left-0 inset-y "></span><span :class="info.color_a_ul" class="name">{{ row.titulo }}</span>
                                    <span :class="info.color_a_ul" class=" flex justify-center   text-sm rounded transition duration-100 px-2 py-0.5">

                                        <i :class="row.hidden?'w-auto':'w-0'" class="las text-2xl la-caret-square-right overflow-hidden"></i>
                                        <i :class="row.hidden?'w-0':'w-auto'" class="las text-2xl  la-caret-square-down overflow-hidden"></i>
                                    </span>
                                </button>
                                <ul v-if="row.rutas.length>0"  class="transition ease-in-out    w-full" :class="info.color_ul_menu+' '+(row.hidden?'h-0 overflow-hidden':'h-auto')">

                                    <template v-for="(ruta,index_ruta) in row.rutas" :key="index_ruta">

                                        <li v-if="(ruta.permisos).includes(user.role)&&!ruta.hasOwnProperty('hidden')&&isset(ruta.id)" class="">

                                            <a @click="$router.push(ruta.ruta)" href="#" :class="info.color_li_menu+' '+($route.path==ruta.ruta?'bg-white bg-opacity-20':'')" >

                                                <i class="text-xl mr-2 las hidden " :class="ruta.icon"></i>
                                                <!-- <vue-feather :type="ruta.icon" size="10"  class="flex-shrink-0 w-4 h-4 mr-4 text-white transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" :stroke="'gray'" />
                                    --> <span class="text-left">{{ ruta.tag }}</span></a>
                                        </li>
                                    </template>

                                </ul>

                            </template>
                        </div>

                    </nav>
                    <div style="position:fixed;bottom:0px;" :class="info.bg_menu" class="hidden z-20 h-14 w-full   flex flex-wrap justify-center content-center">
                        <div class="flex items-center justify-between md:items-center lg:justify-between ">
                            <div class="flex">
                                <p class="!mb-0 text-sm font-bold text-white">Powered by </p>
                            </div>
                            <a href="https://www.instagram.com/popcingmx/" target="_blank" class="mx-2 linear rounded-[20px] bg-black px-4 py-1 text-xs font-extrabold text-yellow-600 transition duration-200 hover:text-yellow-600 active:bg-brand-700">Popcing</a>
                        </div>
                    </div>

                </div>
                <div class="h-full flex-1 -mx-px overflow-y-auto" data-v-178d8661="">
                    <section class="flex flex-col border-t -mt-px w-full h-full   border-l border-r will-change-transform overflow-y-auto"  data-v-178d8661="">

                        <header class="h-14 flex shrink-0" :class="info.fondo_menu">
                            <div :class="info.texto_menu_top" class="flex items-center flex-1 border-b  w-full justify-end pr-3"><span class="text-gray-800 dark:text-gray-100 font-extrabold  overflow-y-auto">v3.0
                                    <!--{{$route.name}}--> </span>

                            </div>
                            <nav class="flex shrink-0 overflow-y-auto " :class="info.bg_menu+' '+info.texto_menu_top ">

                                <button v-if="menu_open" @click="menu_open=!menu_open" type="button" title="Hide collections" class="z-30 action focus-on-key w-14 h-14 border-b border-l xs:hidden flex flex-wrap justify-center content-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" class="w-5 h-auto">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                                        </path>
                                    </svg></button>
                                <button v-if="!menu_open" @click="menu_open=!menu_open" type="button" title="Hide collections" class="z-30 action focus-on-key w-14 h-14 border-b border-l xs:hidden flex flex-wrap justify-center content-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" class="w-5 h-5   pointer-events-none">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
                                    </svg>
                                </button></nav>
                        </header>
                        <div class="flex-1 relative overflow-y-auto">
                            <div class="h-full absolute w-full inset-0 overflow-y-auto  will-change-transform">
                                <router-view />
                            </div>
                        </div>
                    </section>
                </div>

            </main>
            <!---->
            <!---->
        </div>

    </div>

</div>
</template>

<script>
import userService from "@/Services/user";

import {
    useUserStore
} from '../stores/user'
import {
    useConfigStore
} from '../stores/config'
import {
    useInfoStore
} from '../stores/info'

import infosistem from "@/Services/infosistem";
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    includes
} from "lodash";
export default {
    name: "Root",
    components: {

    },
    mixins: [infosistem],
    data() {
        const getTheme = () => {

            if (window.localStorage.hasOwnProperty('dark')) {
                return JSON.parse(window.localStorage.getItem('dark'))
            } else {
                return false;
            }
            return !!window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
        }

        const setTheme = (value) => {

            if (document.documentElement.classList.includes('dark')) {
                document.documentElement.classList.add('light')
                document.documentElement.classList.remove('dark')
            } else {
                document.documentElement.classList.remove('light')
                document.documentElement.classList.add('dark')
            }
            /*
                        if (window.localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
              document.documentElement.classList.add('dark')
            } else {
              document.documentElement.classList.remove('dark')
            }*/

            // window.localStorage.setItem('dark', false)
        }
        let menu_set = [

            {
                id: 'catalogos_menu',
                titulo: 'Catalogos',
                icon: 'la-cubes',
                hidden: true,
                permisos: ['vendedor', 'admin', 'superadmin'],
                rutas: [

                    {
                        id: 'unidadesmedida',
                        tag: 'Unidades de medida',
                        ruta: '/app/unidades',
                        active: false,
                        child: false,
                        icon: 'la-icons',
                        permisos: ['admin', 'superadmin']
                    },
                    {
                        id: 'motivosgarantia',
                        tag: 'Motivos garantia',
                        ruta: '/app/motivos_garantia',
                        active: false,
                        child: false,
                        icon: 'la-icons',

                        permisos: ['admin', 'superadmin']
                    },
                    {

                        id: 'origenes',
                        tag: 'Origenes',
                        ruta: '/app/origenes',
                        active: false,
                        child: false,
                        icon: 'la-icons',
                        permisos: ['admin', 'superadmin']
                    },
                    {
                        id: 'compras',
                        tag: 'Compras',
                        ruta: '/app/compras',
                        active: false,
                        child: false,
                        icon: 'la-file-invoice-dollar',
                        permisos: ['admin','produccion']
                    },

                    {
                        id: 'proveedores',
                        tag: 'Provedores',
                        ruta: '/app/provedores',
                        active: false,
                        child: false,
                        icon: 'la-handshake',
                        permisos: ['admin', 'superadmin']
                    },

                    {
                        id: 'metodospago',
                        tag: 'Métodos de pago',
                        ruta: '/app/metodos_pago',
                        active: false,
                        child: false,
                        icon: 'la-money-check-alt',
                        permisos: ['admin', 'superadmin']
                    },

                    {
                        id: 'usuarios',
                        tag: 'Usuarios',
                        ruta: '/app/usuarios',
                        active: false,
                        child: false,
                        icon: 'la-user-plus',
                        permisos: ['admin', 'superadmin']
                    },

                ]
            },
            {
                id: 'cotizaciones_men',
                titulo: 'Cotizaciones',
                hidden: false,
                permisos: ['vendedor', 'admin', 'superadmin'],
                rutas: [{
                        id: 'nuevacotizacion',
                        tag: 'Nueva Cotización',
                        ruta: '/app/nueva/cotizacion',
                        active: false,
                        child: false,
                        icon: 'la-file-invoice',
                        permisos: ['vendedor', 'admin', 'superadmin']
                    },
                    {
                        id: 'cotizaciones',
                        tag: '#1 Cotizaciones',
                        ruta: '/app/cotizaciones',
                        active: false,
                        child: false,
                        icon: 'la-th-list',
                        permisos: ['vendedor', 'admin', 'superadmin']
                    },

                    {
                        id: 'clientes',
                        tag: 'Clientes',
                        ruta: '/app/clientes',
                        active: false,
                        child: false,
                        icon: 'la-user-friends',
                        permisos: ['vendedor', 'admin']
                    },
                ]
            },

            {
                id: 'Produccion_menu',

                titulo: 'Producción',
                permisos: ['vendedor', 'admin','produccion','instalador'],
                hidden: true,
                rutas: [{
                        id: 'detalle_ventas',
                        tag: 'Estatus de ventas',
                        ruta: '/app/detalle/ventas',
                        active: false,
                        child: false,
                        icon: 'la-clipboard-list',
                        permisos: ['vendedor', 'admin']
                    },
                    {
                        id: 'detalle_aprobar',
                        tag: '#2 Formato de pedido a aprobacion',
                        ruta: '/app/detalle/aprobar',
                        active: false,
                        child: false,
                        icon: 'la-clipboard-check',
                        permisos: ['verificador', 'vendedor', 'admin']
                    },

                    {
                        id: 'detalle_produccion',
                        tag: '#3 Lista de producción revisar',
                        ruta: '/app/detalle/revisar/produccion',
                        active: false,
                        child: false,
                        icon: 'la-tasks',
                        permisos: ['produccion', 'admin']
                    },
                    {
                        id: 'avence_produccion',
                        tag: '#4 Lista de producción avance',
                        ruta: '/app/detalle/avance/produccion',
                        active: false,
                        child: false,
                        icon: 'la-tasks',
                        permisos: ['produccion', 'admin']
                    },
                    {
                        id: 'avance_instalacion',
                        tag: '#5 lista por instalar',
                        ruta: '/app/detalle/avance/instalacion',
                        active: false,
                        child: false,
                        icon: 'la-tasks',
                        permisos: [ 'admin','instalador']
                    },
                    {
                        id: 'cotizaciones_instalados',
                        tag: '#6 Productos Instalados',
                        ruta: '/app/detalle/avance/instalados',
                        active: false,
                        child: false,
                        icon: 'la-tasks',
                        permisos: [ 'admin','instalador']
                    },
                    {
                        id: 'reporte_produccion_formatos_terminados',
                        tag: '#7 formatos terminados',
                        ruta: '/app/detalle/avance/formatos',
                        active: false,
                        child: false,
                        icon: 'la-tasks',
                        permisos: [ 'admin','produccion']
                    },
                    
                   

                ]
            },

            {
                id: 'rev',
                titulo: 'Revendedores',
                hidden: true,
                permisos: ['revendedor'],
                rutas: [{
                        id: 'lista_revendedores',
                        tag: 'Cotizaciones',
                        ruta: '/app/revendedor/cot',
                        active: false,
                        child: false,
                        icon: 'la-th-list',
                        permisos: ['revendedor']
                    },

                ]
            },

            {
                id: 'menu_revendedores',
                titulo: 'Revendedores',
                hidden: true,
                permisos: ['revendedor', 'admin', 'superadmin'],
                rutas: [{
                        id: 'cotizaciones_revendedor',
                        tag: 'Cotizaciones',
                        ruta: '/app/rev/cot',
                        active: false,
                        child: false,
                        icon: 'la-th-list',
                        permisos: ['revendedor', 'admin', 'superadmin']
                    },
                    {
                        id: 'revendedores_ventas',
                        tag: 'Ventas',
                        ruta: '/app/rev/det/ventas',
                        active: false,
                        child: false,
                        icon: 'la-clipboard-list',
                        permisos: ['revendedor', 'admin', 'superadmin']
                    },

                ]
            },

            {
                id: 'calendario_menu',
                titulo: 'Calendario',
                icon: 'la-cubes',
                hidden: true,
                permisos: ['vendedor', 'admin','instalador'],
                rutas: [

                    {
                        id: 'calendario_citas',
                        tag: 'Calendario de citas',
                        ruta: '/app/calendario/citas/ventas',
                        active: false,
                        child: false,
                        icon: 'la-calendar-day',
                        permisos: ['vendedor', 'admin']
                    },
                    {
                        id: 'calendario_instalaciones',
                        tag: 'Calendario de instalaciones',
                        ruta: '/app/cita',
                        active: false,
                        child: false,
                        icon: 'la-calendar-day',
                        permisos: ['admin']
                    },
                    {
                        id: 'calendario_instaladores',
                        tag: 'Calendario de instaladores',
                        ruta: '/app/detalle/instaladores',
                        active: false,
                        child: false,
                        icon: 'la-user-cog',
                        permisos: ['vendedor', 'instalador', 'admin']
                    },
                ]
            },
            {
                id: 'donfiguracion_menu',
                titulo: 'Configuración',
                icon: 'la-cubes',
                hidden: true,
                permisos: ['vendedor', 'admin', 'superadmin'],
                rutas: [{
                        id: 'configuraciones_notificaciones',
                        tag: 'Notificaciones',
                        ruta: '/app/notificaciones',
                        active: false,
                        child: false,
                        icon: 'la-paper-plane',
                        permisos: ['admin', 'superadmin']
                    },
                    {
                        id: 'configuraciones_generales',
                        tag: 'Valores globales',
                        ruta: '/app/configuraciones',
                        icon: 'la-cubes',
                        active: false,
                        child: false,
                        icon: 'la-settings',
                        permisos: ['admin', 'superadmin']
                    },
                    {
                        id: 'roles_config',
                        tag: 'Roles',
                        ruta: '/app/roles',
                        icon: 'la-cubes',
                        active: false,
                        child: false,
                        icon: 'la-settings',
                        permisos: ['admin', 'superadmin']
                    },
                    {
                        id: 'configuraciones_botones',
                        tag: 'Configuracion Botones',
                        ruta: '/app/configuraciones/estilo/botones',
                        icon: 'la-cubes',
                        active: false,
                        child: false,
                        icon: 'la-settings',
                        permisos: ['admin', 'superadmin']
                    }
                ]
            },

            {
                id: 'reportes',
                titulo: 'Reportes',
                hidden: true,
                permisos: ['vendedor', 'admin','produccion'],
                rutas: [{
                        id: 'reporte_cotizaciones_ventas',
                        tag: 'Cotizaciones y ventas',
                        ruta: '/app/reporte/cotizaciones',
                        active: false,
                        child: false,
                        icon: 'la-chart-area',
                        permisos: ['admin']
                    },
                    {
                        id: 'reporte_ventas',
                        tag: 'Ventas',
                        ruta: '/app/reporte/ventas',
                        active: false,
                        child: false,
                        icon: 'la-chart-bar',
                        permisos: ['admin','vendedor']
                    },
                    {
                        id: 'reporte_produccion',
                        tag: 'Producción',
                        ruta: '/app/reporte/produccion',
                        active: false,
                        child: false,
                        icon: 'la-chart-bar',
                        permisos: ['admin','produccion']
                    },
                    {
                        id: 'reporte_vendedores_metricas',
                        tag: 'Metricas vendedores',
                        ruta: '/app/reporte/vendedores/metricas',
                        active: false,
                        child: false,
                        icon: 'la-chart-bar',
                        permisos: ['admin','vendedor']
                    },
                    {
                        id: 'reporte_vendedores_ventas',
                        tag: 'Ventas vendedores',
                        ruta: '/app/reporte/vendedores/ventas',
                        active: false,
                        child: false,
                        icon: 'la-chart-bar',
                        permisos: ['admin','vendedor']
                    },
                   
                    {
                        id: 'reporte_compras_almacen',
                        tag: 'Compras almacen',
                        ruta: '/app/reporte/compras/almacen',
                        active: false,
                        child: false,
                        icon: 'la-chart-bar',
                        permisos: ['admin','produccion']
                    },
                    {
                        id: 'reporte_vendedoras',
                        tag: 'Vendedores',
                        ruta: '/app/reporte/vendedores',
                        active: false,
                        child: false,
                        icon: 'la-chart-bar',
                        permisos: ['admin']
                    },
                    {
                        id: 'reporte_personalizados',
                        tag: 'Bigquery',
                        ruta: '/app/reporte/bigquery',
                        active: false,
                        child: false,
                        icon: 'la-chart-bar',
                        permisos: ['admin']
                    },
                    {
                        id: 'metricos_vendedores',
                        tag: 'Metricos Ponderaciones',
                        ruta: '/app/reporte/metricos_vendedores',
                        active: false,
                        child: false,
                        icon: 'la-chart-bar',
                        permisos: ['admin','vendedor']
                    },
                    {
                        id: 'reporte_nomina',
                        tag: 'Reporte de nomina',
                        ruta: '/app/reporte/nomina',
                        active: false,
                        child: false,
                        icon: 'la-chart-bar',
                        permisos: ['admin']
                    },
                    {
                        id: 'reporte_citas',
                        tag: 'Reporte de citas',
                        ruta: '/app/reporte/citas',
                        active: false,
                        child: false,
                        icon: 'la-chart-bar',
                        permisos: ['admin']
                    },
                    {
                        id: 'reporte_prospectos',
                        tag: 'Reporte de prospectos',
                        ruta: '/app/reporte/prospectos',
                        active: false,
                        child: false,
                        icon: 'la-chart-bar',
                        permisos: ['admin']
                    },
                    {
                        id: 'reporte_seguimientos',
                        tag: 'Reporte de seguimientos',
                        ruta: '/app/reporte/seguimiento',
                        active: false,
                        child: false,
                        icon: 'la-chart-bar',
                        permisos: ['admin']
                    },

                ]
            },

            {
                id: 'Inventario_menu',
                titulo: 'Compras / inventario',
                icon: 'la-cubes',
                hidden: true,
                permisos: ['vendedor', 'admin','produccion'],
                rutas: [{
                        id: 'inventario_nueva_compra',
                        tag: 'Nueva Compra',
                        ruta: '/app/ordenes/nueva',
                        active: false,
                        child: false,
                        icon: 'la-cubes',
                        permisos: ['admin','produccion']
                    },
                    {
                        id: 'inventario_compras',
                        tag: 'Compras',
                        ruta: '/app/ordenes/compras',
                        active: false,
                        child: false,
                        icon: 'la-cubes',
                        permisos: ['admin','produccion']
                    },
                    {
                        id: 'reporte_ordenes',
                        tag: 'Ordenes',
                        ruta: '/app/reporte/ordenes',
                        active: false,
                        child: false,
                        icon: 'la-chart-bar',
                        permisos: ['admin','produccion']
                    },
                ]
            },
            {
                id: 'catalogos_menu',
                titulo: 'Productos',
                icon: 'la-cubes',
                hidden: true,
                permisos: ['vendedor', 'admin', 'superadmin'],
                rutas: [{
                        id: 'catalogo_categorias',
                        tag: 'Categorías',
                        ruta: '/app/categorias',
                        active: false,
                        child: false,
                        icon: 'la-object-group',
                        permisos: ['admin', 'superadmin']
                    },

                    {
                        id: 'catalogo_productos_cotizaciones',
                        tag: 'Productos de cotizacíon',
                        ruta: '/app/productos',
                        active: false,
                        child: false,
                        icon: 'la-cubes',
                        permisos: ['admin']
                    },
                    {
                        id: 'catalogo_productos_num_1',
                        tag: 'Catalogos de producto v1',
                        ruta: '/app/productos/v1/catalogo',
                        active: false,
                        child: false,
                        icon: 'la-cubes',
                        permisos: ['admin']
                    },
                    {
                        id: 'catalogo_productos_num_2',
                        tag: 'Catalogos de producto v2',
                        ruta: '/app/productos/v2/catalogo',
                        active: false,
                        child: false,
                        icon: 'la-cubes',
                        permisos: ['admin']
                    },

                ]
            },

        ];
       return {

            menu_open: false,
            window: {
                width: 0,
                height: 0
            },
            loading: true,
            menu_set: menu_set,
            isDark: getTheme(),
            toggleTheme() {
                this.isDark = !this.isDark
                setTheme(this.isDark)
            },
            setLightTheme() {
                this.isDark = false
                setTheme(this.isDark)
            },
            setDarkTheme() {
                this.isDark = true
                setTheme(this.isDark)
            },
            watchScreen() {
                if (window.innerWidth <= 1024) {
                    this.isSidebarOpen = false
                } else if (window.innerWidth >= 1024) {
                    this.isSidebarOpen = true
                }
            },
            isSidebarOpen: window.innerWidth >= 1024 ? true : false,
            toggleSidbarMenu() {
                this.isSidebarOpen = !this.isSidebarOpen
            },
            isNotificationsPanelOpen: false,
            openNotificationsPanel() {
                this.isNotificationsPanelOpen = true
                this.$nextTick(() => {
                    this.$refs.notificationsPanel.focus()
                })
            },
            isSettingsPanelOpen: false,
            openSettingsPanel() {
                this.isSettingsPanelOpen = true
                this.$nextTick(() => {
                    this.$refs.settingsPanel.focus()
                })
            },
            isSearchPanelOpen: false,
            openSearchPanel() {
                this.isSearchPanelOpen = true
                this.$nextTick(() => {
                    this.$refs.searchInput.focus()
                })
            },
        }
    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const user = useUserStore()
        const info = useInfoStore()

        const config = useConfigStore();

        /* if (user.getAll().role == 'admin') {
             menu_set = menu_admin;
         } else {
             menu_set = menu_cliente;
         }*/
        const salir = () => {
            user.limpiar([]);
            router.push('/');
        }
        return {
            active: 0,
            config,
            info: info.getAll(),
            infoset: info,
            user: user.getAll(),

            menu_admin: [],
            menu: [],
            salir

        }

    },
    mounted() {
        this.get_info();

    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;

        if (this.window.width > 766) {
            this.menu_open = true;
        }
    },

    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        isset(id){
            //console.log(id,'----id----',this.info.opciones_menu)
            //console.log(id,'----id----',String(this.info.opciones_menu).split(','))
            return String(this.info.opciones_menu).split(',').includes(id)
        },
        async get_info() {
            //console.log('data------')
            let data = await infosistem.GET().then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    let infov = {};
                    response.forEach(element => {
                        infov[element.id] = element.valor;
                    });
                    this.infoset.set(
                        infov
                    );
                    //infoData.value=(infov);
                    //if(response.user.role=='admin'){

                    //this.$router.push('/app/'); 
                    //}

                }
            });
        },
        changueHidden(isel) {
            console.log(isel)
            this.menu_set.forEach((element, index) => {
                if (index == isel) {
                    element.hidden = !element.hidden;
                } else {
                    element.hidden = true;
                }
            });

        },

        showMenu3(flag) {
            let menu3 = document.getElementById("menu3");
            if (flag) {
                icon3.classList.toggle("rotate-180");
                menu3.classList.toggle("hidden");
            }
        },
        years() {
            const year = new Date().getFullYear()
            return Array.from({
                length: year - 1900
            }, (value, index) => 1901 + index)
        },
        validateEmail(value) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                this.msg['email'] = '';
            } else {
                this.msg['email'] = 'Invalid Email Address';
            }
        },

    }
}
</script>

<style>
/* This example part of kwd-dashboard see https://kamona-wd.github.io/kwd-dashboard/ */
/* So here we will write some classes to simulate dark mode and some of tailwind css config in our project */
:root {
    --light: #edf2f9;
    --dark: #152e4d;
    --darker: #12263f;
}

.hover\:overflow-y-auto:hover {
    overflow-y: auto;
}

.fullw {
    width: 100vw !important;
}

.notfullw {
    width: 0vw !important;
}
</style>
