<template lang="es">
<section class=" w-full mx-auto h-full px-4 sm:px-6 lg:px-4 py-4 bg-yellow-600 bg-opacity-10 flex flex-wrap  ">

    <div class="w-3/12 h-full py-4 flex flex-wrap">
        <div class="grid grid-cols-1  gap-4 w-full ">

            <div class="w-full  px-8 bg-white rounded-lg   flex flex-col justify-center items-center content-center ">

                <h3 class="font-bold text-2xl px-12 pb-4 font-heading text-gray-900">
                    Ventas por rango de fecha

                </h3>
                <h1 class="font-bold   font-heading text-gray-900 w-full flex flex-wrap justify-center content-center">
                    <div class="w-full flex flex-wrap">

                        <el-date-picker class="w-full" v-model="fecha" type="daterange" unlink-panels range-separator="-" start-placeholder="De" end-placeholder="Hasta" @change="getData()" />

                    </div>
                </h1>
            </div>
            <div class="w-full bg-white rounded-lg p-6 flex flex-col justify-start items-center" v-if="data_venta!=null">
                <div class="grid grid-cols-1  gap-2 w-full h-full">
                    <div class="w-full bg-yellow-600 bg-opacity-10  rounded-lg p-6 flex flex-wrap justify-between content-center items-center">
                        <p class="text-xl text-gray-700 font-bold ">
                            Total ventas
                        </p><strong class="text-blue-500 font-bold">${{data_venta.total}}</strong>

                    </div>
                     <div class="w-full bg-yellow-600 bg-opacity-10  rounded-lg p-6 flex flex-wrap justify-between content-center items-center">

                        <p class="text-xl text-gray-700 font-bold ">
                            Descuento
                        </p>
                        <strong class="text-blue-500 font-bold">${{data_venta.sdescuento}}</strong>
                    </div>
                    <div class="w-full bg-yellow-600 bg-opacity-10  rounded-lg p-6 flex flex-wrap justify-between content-center items-center">

                        <p class="text-xl text-gray-700 font-bold ">
                            Efectivo
                        </p>
                        <strong class="text-blue-500 font-bold">${{data_venta.efectivo}}</strong>
                    </div>
                    <div class="w-full bg-yellow-600 bg-opacity-10  rounded-lg p-6 flex flex-wrap justify-between content-center items-center">

                        <p class="text-xl text-gray-700 font-bold ">
                            Tarjeta Crédito
                        </p><strong class="text-blue-500 font-bold">${{data_venta.tc}}</strong>
                    </div>
                    <div class="w-full bg-yellow-600 bg-opacity-10  rounded-lg p-6 flex flex-wrap justify-between content-center items-center">
                        <p class="text-xl text-gray-700 font-bold ">
                            Tarjeta Débito
                        </p><strong class="text-blue-500 font-bold">${{data_venta.td}}</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-9/12 h-full ">
        <Tabla :columns="headings" :rows="data" :filtros="['total','descuento']" :add="false" />
    </div>

    <div v-if="actionModal!=null" style="z-index:9999900" class="bg-slate-800 bg-opacity-50  h-screen w-full flex content-center justify-center items-center fixed  top-0 right-0 bottom-0 left-0">
        <div class=" bg-white w-72 h-72 self-center  flex-wrap px-5 py-14 rounded-md text-start flex content-center justify-center items-center">
            <div class="text-base mb-4 font-bold text-slate-500">
                ¿Qué desea hacer?
            </div>
            <div class="flex-wrap flex justify-between  w-full">
                <div class="w-3/6 px-2">
                    <button class="w-full bg-blue-500 bg-opacity-20 px-4 py-2 rounded-md text-md text-blue-500 border-2 border-blue-500" @click="edit(actionModal);">
                        Editar
                    </button>
                </div>
                <div class="w-3/6 px-2">
                    <button class="bg-red-500 px-4 py-2 bg-opacity-20 rounded-md text-md text-red-500 w-full  border-2 border-red-500" @click="eliminar(actionModal.id)">
                        Eliminar
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import moment from 'moment'
import provedores from "@/Services/provedores";
import ventas from "@/Services/ventas";
import tablero from "@/Services/tablero";
import Tabla from "@/components/Tabla.vue";
export default {
    components: {
        Tabla,

    },
    mixins: [provedores, ventas, tablero],
    data() {
        const user = useUserStore()

        return {
            moment: null,
            fecha: [new Date(), new Date()],
            value2: null,
            series: [{
                data: []
            }],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: ['Total vendido', 'Total en efectivo', 'Total tarjeta crédito', 'Total tarjeta de Débito', ],
                }
            },
            headings: [

                {
                    key: 'id',
                    name: '#',
                    show: true
                }, {
                    key: 'total',
                    name: 'Total',
                    show: true
                },
                {
                    key: 'descuento',
                    name: 'Descuento',
                    show: true
                },
                {
                    key: 'tarjeta_credito',
                    name: 'Tarjeta de crédito',
                    show: true
                },
                {
                    key: 'tarjeta_debito',
                    name: 'Tarjeta de Débito',
                    show: true
                },
                {
                    key: 'efectivo',
                    name: 'Efectivo',
                    show: true
                },
                {
                    key: 'detalle_ventas',
                    name: 'Productos venta',
                    show: true
                },
                {
                    key: 'eliminar',
                    name: 'Eliminar',
                    show: true
                }

            ],
            buscar: '',
            data_venta: null,
            open: false,
            user: user.getAll(),
            data: [],
            actionModal: null,
            showM: false,
            id: 0,
            nombre: '',
            correo: '',
            telefono: '',
            rfc: '',
            provedores_data: [],

        }

    },
    mounted() {

    },
    created() {
        this.moment = moment;
        //this.getData();
        this.getData()
    },
    methods: {
        formatDateEN(date) {
            return this.moment(date).format("YYYY-MM-DD")
        },
        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },

        limpiar() {
            this.id = 0;
            this.nombre = '';
            this.telefono = '';
            this.correo = '';

            this.rfc = ''
        },

       
        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)
            let dat = {
                inicio: this.formatDateEN(this.fecha[0]),
                fin: this.formatDateEN(this.fecha[1]),
            }

            await tablero.GET_VENTAS(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    //this.data = response

                    this.data_venta = response.ventas;
                    this.data = response.data_ventas;

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async eliminar(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await ventas.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },

    }
}
</script>

<style scoped>
.demo-date-picker {
    display: flex;
    width: 100%;
    padding: 0;
    flex-wrap: wrap;
}

.demo-date-picker .block {
    padding: 0px 0;
    text-align: center;
    border-right: solid 1px var(--el-border-color);
    flex: 1;
}

.demo-date-picker .block:last-child {
    border-right: none;
}

.demo-date-picker .demonstration {
    display: block;
    color: var(--el-text-color-secondary);
    font-size: 14px;
    margin-bottom: 20px;
}
</style>
