
<template>
  <section class="h-full w-full flex flex-wrap bg-gray-50 justify-center content-start">
    <div class="flex items-wrap justify-start content-center space-x-4 py-2 w-full px-5">
      <el-date-picker
        v-model="fechaInicio"
        type="date"
        placeholder="Fecha de inicio"
        :default-value="getDefaultStartDate"
      ></el-date-picker>
      <el-date-picker
        v-model="fechaFin"
        type="date"
        placeholder="Fecha de fin"
        :default-value="getDefaultEndDate"
      ></el-date-picker>
      <el-button type="primary" @click="applyDateRange">Aplicar</el-button>
    </div>
    <Tabla
      :idt="'tablll'"
      :key="reftable"
      v-if="data"
      :add="false"
      :columns="headings"
      :rows="data"
      :filtros="['email', 'phone', 'id']"
      :buscarp="buscar"
      :filtroApi="true"
  
     
    />
    <div class="fixed bottom-0 bg-yellow-500 h-12 flex flex-wrap justify-start content-center w-full">
      <p>Total:<span class="font-extrabold">{{formatPrice(getTotal())}}</span></p>

    </div>
  </section>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useUserStore } from '@/stores/user';
import Tabla from "@/components/Tabla.vue";
import reportes from "@/Services/reportes";
import productos from "@/Services/productos";
import { emitter } from "@/mitt.js";
import { useMixin } from '@/functions/useMixins';
export default {
  components: {
    Tabla
  },
  setup() {
    const { formatPrice } = useMixin();
    const user = useUserStore();
    const headings = ref([
                  {
                      key: 'id',
                      name: '#',
                      class: 'min-w-[120px]',
                      show: true
                  },
                  {
                      key: 'pedido_id',
                      name: '# pedido',
                      class: 'min-w-[120px]',
                      show: true
                  },
                  {
                      key: 'clientes_cotizaciones',
                      name: 'Cliente',
                      show: true
                  }
                  , {
                      key: 'status_ordenes',
                      class: 'min-w-[200px]',
                      name: 'Estatus Orden',
                      show: true
                  },
                  {
                      key: 'fecha_creacion',
                      name: 'Fecha de creación',
                      class: 'min-w-[160px]',
                      show: true
                  },
                  {
                      key: 'fecha_pedido',
                      name: 'Fecha de pedido',
                      class: 'min-w-[160px]',
                      show: true
                  },
                  {
                      key: 'fecha_llegada',
                      class: 'min-w-[160px]',
                      name: 'Fecha llegada',
                      show: true
                  },
                  {
                      key: 'fecha_pago',
                      name: 'Fecha de pago',
                      class: 'min-w-[160px]',
                      show: true
                  }
                 
                  , {
                      key: 'cotizaciones_id',
                      class: 'min-w-[200px]',
                      name: '# venta',
                      show: true
                  },
  
                  {
                      key: 'numero_factura',
                      name: '# Factura',
                      class: 'min-w-[160px]',
                      show: true
                  },  {
                      key: 'proveedor',
                      name: 'Proveedor',
                      class: 'min-w-[160px]',
                      show: true
                  }, {
                      key: 'total',
                      name: 'Total',
                      show: true
                  },
  
                  {
                      key: 'observaciones',
                      class: 'min-w-[200px]',
                      name: 'Observaciones',
                      show: true
                  },
                  {
                      key: 'usuario',
                      name: 'Empleado',
                      class: 'min-w-[160px]',
                      show: true
                  },
                  
                  {
                      key: 'edit_orden',
                      name: 'Editar',
                      show: true
                  },
                  {
                      key: 'eliminar',
                      name: 'Eliminar',
                      show: true
                  }
              ],);

    const data = ref({
      data: []
    });

    const showM = ref(false);
    const id = ref(0);
    const nombre = ref('');
    const telefono = ref('');
    const correo = ref('');
    const rfc = ref('');
    const buscar = ref('all');
    const pagina = ref(1);
    const fechaInicio = ref(getDefaultStartDate());
      const fechaFin = ref(getDefaultEndDate());
      const applyDateRange = () => {
        getData();
      }
    const getData = async () => {
      let dat = {
        //  page: pagina.value,
          fechaInicio: formatFecha(fechaInicio.value), // Pasa la fecha de inicio
          fechaFin: formatFecha(fechaFin.value),     // Pasa la fecha de fin
        }
      emitter.emit("loading", true);
      try {
        const response = await reportes.GET_ORDENES(dat);
        if (response && !response.hasOwnProperty('error')) {
          data.value = response;
        }
      } catch (error) {
        // Handle error
      }
      emitter.emit("loading", false);
    };
const getTotal=()=>{
  let tot = 0;

if (data.value && Array.isArray(data.value)) {
  data.value.forEach(element => {
    if (element &&  !isNaN(element.total)) {
      tot += Number(element.total);
    }
  });
}
return (tot).toFixed(2);
 
}
    const getDataProductos = async () => {
      showM.value = false;
      emitter.emit("loading", true);
      try {
        const response = await productos.OPCION(user.value.token);
        if (response) {
          data_productos.value = response;
        }
      } catch (error) {
        // Handle error
      }
      emitter.emit("loading", false);
    };

    const addRow = () => {
      showM.value = !showM.value;
      limpiar();
    };

    const limpiar = () => {
      id.value = 0;
      nombre.value = '';
      telefono.value = '';
      correo.value = '';
      rfc.value = '';
    };
    const formatFecha=(fecha) =>{
        const dateObj = new Date(fecha);
        return dateObj.toISOString();
      const formattedDate = `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
      return formattedDate;
    }
      // Función para obtener el primer día del mes actual
      function getDefaultStartDate() {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), 1);
      }
  
      // Función para obtener el último día del mes actual
      function getDefaultEndDate() {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth() + 1, 0);
      }

    onMounted(async () => {
      await getData();
      getDataProductos();
    });

    return {
      formatPrice,
      getTotal,
      applyDateRange,
      formatFecha,
      headings,
      data,
      showM,
      id,
      nombre,
      telefono,
      correo,
      rfc,
      buscar,
      pagina,
      getData,
      getDataProductos,
      addRow,
      limpiar,
      fechaInicio,
      fechaFin
    };
  },
};
</script>

  