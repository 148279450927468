<template>
<section class="w-full mx-auto h-full px-4 sm:px-6 lg:px-4 py-4 bg-gray-100 content-start flex flex-wrap">

    <div class="w-full flex flex-wrap justify-start conten-start h-full">
        <div class="  w-full flex flex-wrap justify-start content-start h-2/6 py-2" >
            <div class="bg-white rounded-lg p-12 flex flex-col justify-center content-start w-2/6">
                <h1 class="font-bold text-2xl md:text-2xl lg:text-3xl font-heading text-gray-900">
                   Seguimientos

                </h1>
                <h1 class="font-bold mt-2  font-heading text-gray-900 w-full flex flex-wrap justify-center content-center">
                    <el-date-picker v-model="fechaInicio" type="date" placeholder="Fecha de inicio" :default-value="getDefaultStartDate"></el-date-picker>
                    <el-date-picker v-model="fechaFin" type="date" placeholder="Fecha de fin" :default-value="getDefaultEndDate"></el-date-picker>
                    <el-button type="primary" @click="applyDateRange">Aplicar</el-button>
                </h1>
            </div>
            <div class="w-4/6 px-5 py-1 h-full">
              <div class="rounded-md bg-gray-100">
                </div>

          </div>

        </div>
        <div class=" w-full bg-white rounded-lg px-2 py-2 flex  justify-start content-start h-4/6">
            <Tabla v-if="data_reporte != null" :columns="headings_vendedores" :rows="data_reporte" :filtros="['cliente','vendedor']" :buscarp="buscar" :filtroApi="false" />
          
        </div>
    </div>

   
    <!-- Selector de fecha inicio y fecha fin -->

    <!-- Resto del contenido del componente -->
    <!-- <div v-if="data_reporte != null" :class="{'flex-row': !IsMov(), 'flex-row-reverse': IsMov()}" class="w-full h-12 bg-gray-100 flex flex-wrap content-center justify-between px-2">
        <div class="w-10/12" :class="{'justify-end': IsMov(), 'justify-center': !IsMov()}">
          <el-pagination v-model:currentPage="pagina" small background layout="prev, pager, next" :page-size="data_reporte['per_page']" :total="Number(data_reporte['total'])" @current-change="pageChangue" />
        </div>
      </div>-->

</section>
</template>

  
  
<script>
import {
    ref,
    onMounted,
    onBeforeUnmount
} from 'vue';
import {
    emitter
} from '@/mitt.js';
import {
    useUserStore
} from '@/stores/user';
import generales from '@/functions/generales';
import reportes from '@/Services/reportes';
import DynamicChart from '@/components/charts/DynamicChart.vue';
import {
    filter
} from 'lodash';
import Tabla from "@/components/Tabla.vue";

export default {
    components: {
        Tabla
    },
    mixins: [generales],
    setup() {
        const user = useUserStore();
        const key = ref(0);
        const buscar = ref('all');
        const pagina = ref(1);
        const fechaInicio = ref(getDefaultStartDate());
        const fechaFin = ref(getDefaultEndDate());
        const data_reporte = ref(null);
const headings_vendedores=ref([
    {
        key: 'idventa',
        name: 'ID Venta',
        show: true,
        class: 'text-green-500 font-extrabold w-[200px]',
        value: null // Aquí se agrega el valor del campo 'idventa' del objeto original
    },
    {
        key: 'cotizaciones_id',
        name: 'ID Cotización',
        show: true,
        class: 'text-green-500 font-extrabold w-[200px]',
        value: 3464 // Aquí se agrega el valor del campo 'cotizaciones_id' del objeto original
    },
    {
        key: 'vendedor',
        name: 'Vendedor',
        show: true,
        class: 'text-green-500 font-extrabold w-[200px]',
        value: 36 // Aquí se agrega el valor del campo 'users_id' del objeto original
    },
    
    {
        key: 'tipo',
        name: 'Tipo',
        show: true,
        class: 'text-green-500 font-extrabold w-[200px]',
        value: 'cita' // Aquí se agrega el valor del campo 'tipo' del objeto original
    },
    
    {
        key: 'venta',
        name: 'Venta',
        show: true,
        class: 'text-green-500 font-extrabold w-[200px]',
        value: null // Aquí se agrega el valor del campo 'venta' del objeto original
    },
    {
        key: 'total',
        name: 'Total Cotizacion',
        show: true,
        class: 'text-green-500 font-extrabold w-[200px]',
        value: '32831.00' // Aquí se agrega el valor del campo 'total' del objeto original
    },
    {
        key: 'total_venta',
        name: 'Total Venta',
        show: true,
        class: 'text-green-500 font-extrabold w-[200px]',
        value: '0' // Aquí se agrega el valor del campo 'total_venta' del objeto original
    },
    {
        key: 'cliente',
        name: 'Cliente',
        show: true,
        class: 'text-green-500 font-extrabold w-[200px]',
        value: 'CONNY GARCIA' // Aquí se agrega el valor del campo 'cliente' del objeto original
    },
    {
        key: 'origen',
        name: 'Origen',
        show: true,
        class: 'text-green-500 font-extrabold w-[200px]',
        value: 'FACEBOOK' // Aquí se agrega el valor del campo 'origen' del objeto original
    },
    {
        key: 'categorias',
        name: 'Categorías',
        show: true,
        class: 'text-green-500 font-extrabold w-[200px]',
        value: 'Cortinas MarBel' // Aquí se agrega el valor del campo 'categorias' del objeto original
    },
    {
        key: 'direccion',
        name: 'Dirección',
        show: true,
        class: 'text-green-500 font-extrabold w-[200px]',
        value: 'PRIVADAS DEL CANADAS, SECTOR ONTARIO Hamilton ESCOBEDO 219' // Aquí se agrega el valor del campo 'direccion' del objeto original
    },
   
   
    {
        key: 'fecha_inicio',
        name: 'Fecha de Inicio',
        show: true,
        class: 'text-green-500 font-extrabold w-[200px]',
        value: '2024-02-09 14:00:00' // Aquí se agrega el valor del campo 'fecha_inicio' del objeto original
    },
   
    {
        key: 'status',
        name: 'Estado',
        show: true,
        class: 'text-green-500 font-extrabold w-[200px]',
        value: 'confirmada' // Aquí se agrega el valor del campo 'status' del objeto original
    },
    {
        key: 'observaciones',
        name: 'Observaciones',
        show: true,
        class: 'text-green-500 font-extrabold w-[200px]',
        value: 'CITA PUNTUAL PARA CORTINAS (2), TIENE PERSIANA, QUIERE CAMBIARLAS' // Aquí se agrega el valor del campo 'observaciones' del objeto original
    },
   
           
])

        const pageChangue = (val) => {
            pagina.value = val;
            getData();
        }

        const applyDateRange = () => {
            getData();
        }

        const getData = async () => {
            emitter.emit('loading', true);
            let dat = {
                //  page: pagina.value,
                fechaInicio: formatFecha(fechaInicio.value), // Pasa la fecha de inicio
                fechaFin: formatFecha(fechaFin.value), // Pasa la fecha de fin
            }
            await reportes.GET_REPORTE_SEGUIMIENTOS(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    console.log(response,'---SEGUIMIENTOS--')

                    data_reporte.value=response;
                } else {
                    // emitter.emit("alerta_show",response.msg);
                }
                emitter.emit('loading', false);
            });
        }
        const formatFecha = (fecha) => {
            const dateObj = new Date(fecha);
            return dateObj.toISOString();
            const formattedDate = `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
            return formattedDate;
        }
        // Función para obtener el primer día del mes actual
        function getDefaultStartDate() {
            const today = new Date();
            return new Date(today.getFullYear(), today.getMonth(), 1);
        }

        // Función para obtener el último día del mes actual
        function getDefaultEndDate() {
            const today = new Date();
            return new Date(today.getFullYear(), today.getMonth() + 1, 0);
        }

        onMounted(() => {
            getData();
        })

        onBeforeUnmount(() => {
            // Add any cleanup logic here.
        })

        return {
          headings_vendedores,
            getData,
            pageChangue,
            buscar,
            
            key,
            data_reporte,
            fechaInicio,
            fechaFin,
            applyDateRange
        };
    },
};
</script>
  
  
<style scoped>
/* Your component-specific styles here */
</style>
