<template lang="es">
<section class=" w-full mx-auto h-full px-4 sm:px-6 lg:px-4 py-4 bg-gray-100  content-start flex flex-wrap ">

    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-3 ">

        <div class="col-span-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-3">
            <div class=" col-span-2 w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">

                <h1 class="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900">
                    Estadísticas de vendedores
    
                </h1>
                <h1 class="font-bold mt-2  font-heading text-gray-900 w-full flex flex-wrap justify-center content-center">
                    <month-picker-input class="self-center flex flex-wrap z-40  rounded " v-model="mes" lang="es" :default-month="mes.monthIndex" :default-year="mes.selectedYear" @change="recargar" />
                </h1>
            </div>
            <div class=" col-span-2 w-full bg-white rounded-lg px-2 py-2 flex  justify-start items-center">
    
                <apexchart v-if="series[0].data.length>0&&series[0].data.length>0" class="w-full" type="line" :options="chartOptions" :series="series"></apexchart>
    
            </div>
            </div>

        <div class="col-span-2 h-42 bg-white rounded-lg px-2 py-2 flex  justify-start items-center">

            <apexchart v-if="series_ventas[0].data.length>0&&series_ventas[0].data.length>0" class="w-full" type="bar" :options="chartOptions_ventas" :series="series_ventas"></apexchart>

        </div>
      
        

    </div>

</section>
</template>

    
    
<script>
import {
    emitter
} from "@/mitt.js";
import {
    useUserStore
} from '@/stores/user'
import Tabla from "@/components/Tabla.vue";
import TemplateCotizacion from "@/components/TemplateCotizacion.vue";
import generales from "@/functions/generales";
import tablero from "@/Services/tablero";
import abonos from "@/Services/abonos";
import cotizaciones from "@/Services/cotizaciones";
import unidades from "@/Services/unidades";
import provedores from "@/Services/provedores";
import productos from "@/Services/productos";
import productos_ordenes from "@/Services/productos_ordenes";
import TemplateCitas from "@/components/TemplateCitas.vue";
import TemplateOrdenes from "@/components/TemplateOrdenes.vue";

import {
    filter
} from "lodash";
import {
    MonthPickerInput
} from 'vue-month-picker'

import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
export default {
    components: {
        MonthPickerInput,
        VueCal
    },
    mixins: [generales],
    data() {
        const user = useUserStore()

        return {
            events: [{
                    start: '2022-11-02 14:00',
                    end: '2022-11-02 18:00',
                    title: 'Need to go shopping',
                    icon: 'shopping_cart', // Custom attribute.
                    content: 'Click to see my shopping list',
                    contentFull: 'My shopping list is rather long:<br><ul><li>Avocados</li><li>Tomatoes</li><li>Potatoes</li><li>Mangoes</li></ul>', // Custom attribute.
                    class: 'leisure'
                },
                {
                    start: '2022-11-01 10:00',
                    end: '2022-11-01 15:00',
                    title: 'Golf with John',
                    icon: 'golf_course', // Custom attribute.
                    content: 'Do I need to tell how many holes?',
                    contentFull: 'Okay.<br>It will be a 18 hole golf course.', // Custom attribute.
                    class: 'sport'
                }
            ],
            productos_ventas: [],
            promociones_ventas: [],
            //grafica de cotizaciones por mes
            series: [{
                name: 'Cotizaciones totales',
                data: []
            }],

            chartOptions: {
            chart: {
              height: 350,
              type: 'line',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#77B6EA', '#545454'],
            dataLabels: {
              enabled: true,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: 'Cotizaciones por vendedor',
              align: 'left'
            },
            grid: {
              borderColor: '#e7e7e7',
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            markers: {
              size: 1
            },
            xaxis: {
              categories: [],
              title: {
                text: 'Vendedores'
              }
            },
            yaxis: {
              title: {
                text: 'Cotizaciones'
              },
              min: 5,
              max: 40
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            }
          },

            // end grafica de cotizaciones por mes
            //grafica de ventas por mes
            series_ventas: [{
                name: 'Ventas totales',
                data: []
            }],

            chartOptions_ventas: {
                title: {
                    text: 'Total en ventas por vendedor'
                },
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            xaxis: {
              categories: [
              ],
              tickAmount: 7,
                    labels: {
                        formatter: function (val, i) {
                            val=Number(val).toFixed(0);
                            val = val.toString().replace(".00", "");
      return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                        
                        }
                    }
            },
           
          },
          
          
        
            // end grafica de ventas por mes

            //series_multiline
            series_multiline: [{
                    name: 'Cotizaciones totales',
                    data: []
                },
                {
                    name: 'ventas totales',
                    data: []
                }
            ],
            chartOptions_multiline: {
                chart: {
                    height: 350,
                    type: 'area'
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                title: {
                    text: 'Comparativa entre cotizaciones y ventas por mes',
                    align: 'left'
                },
                xaxis: {
                    type: 'string',
                    categories: []
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
            },
            mes: {

                "month": this.getmes(),
                monthIndex: new Date().getMonth() + 1,
                year: this.getYear(),
                selectedYear: this.getYear(),
            },

        }

    },
    mounted() {

    },
    created() {
        this.getDataTablero()
    },
    methods: {
        getYear() {

            return new Date().getFullYear();

        },
        getDayMonth() {
            console.log(this.mes.selectedYear + '-' + this.mes.monthIndex + '-01')
            return this.mes.selectedYear + '-' + this.mes.monthIndex + '-01';
        },
        calcTotalPromociones() {
            let total = 0;
            this.promociones_ventas.forEach(element => {
                if (element.ventas.length > 0) {
                    total = Number(total) + Number(element.ventas[0].cantidad_usada);
                }

            });
            return total;
        },
        calcTotalProductos() {
            let total = 0;
            this.productos_ventas.forEach(element => {
                if (element.sum_ventas.length > 0) {
                    total = Number(total) + Number(element.sum_ventas[0].total);
                }

            });
            return total;
        },
        recargar($ev) {
            console.log($ev)
            this.mes = $ev;
            this.getDataTablero();
        },
        getmes() {
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            const d = new Date();
            return monthNames[d.getMonth()];
        },
        formatArray(data) {
            let format_data = [];
            let total_ventas=[];
            let labels = [];
            Object.entries(data).forEach(([key, value]) => {
                total_ventas.push(

                    value.total
                )
                format_data.push(

                    value.cantidad
                )
                labels.push(

                    value.name
                )
            });

            return [labels, format_data,total_ventas]
        },
       
        async setGraficaCotizacionesVendedores(data) {

            let datt = await this.formatArray(data);
         

            this.chartOptions.xaxis.categories = datt[0];
            this.chartOptions_ventas.xaxis.categories = datt[0];
           //this.chartOptions_multiline.xaxis.categories = datt[0];
            this.series[0].data = datt[1];
            this.series_ventas[0].data = datt[2];
           // this.series_multiline[1].data = datt[1];
        },

        async getDataTablero() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)
            let dat = {
                mes: this.mes.monthIndex,
                ano: this.mes.year,
            }

            await tablero.GET_VENDEDORES(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    this.series[0].data = [];
                    this.series_ventas[0].data = [];
            this.chartOptions.xaxis.categories =[];
            this.chartOptions_ventas.xaxis.categories=[];
                    this.setGraficaCotizacionesVendedores(response.cotizaciones_vendedor)
                    // this.setGraficaMesesCotizaciones((response.cotizaciones_mes))
                    // this.setGraficaMesesVentas((response.ventas_mes))

                    console.log(response, 'response')
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
    
    
<style scoped>
/* Dot indicator */

/* Dash indicator */
.vuecal__cell-events-count {}

/* Dot indicator */
.vuecal__cell-events-count {

    color: white;
    font-weight: 800;

    background: green;
}

/* Cell background indicator */
.vuecal__cell--has-events {
    background-color: #fffacd;
}

.vuecal__cell-events-count {}
</style>
