<template>
<div class="bg-white text-gray-600 work-sans leading-normal text-base tracking-normal">

    <!--Nav-->
    <nav id="header" class="w-full z-30 top-0 py-1">
        <div class="w-full container mx-auto flex flex-wrap  justify-between mt-0 px-6 py-3">

            <label for="menu-toggle" class="cursor-pointer md:hidden block">
                <svg class="fill-current text-gray-900" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <title>menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                </svg>
            </label>
            <input class="hidden" type="checkbox" id="menu-toggle" />

            <div class="hidden md:flex md:items-center md:w-auto w-full order-3 md:order-1" id="menu">
                <nav>
                    <ul class="md:flex items-center justify-between text-base text-gray-700 pt-4 md:pt-0">
                       
                       <!-- <li><a class="inline-block no-underline hover:text-black hover:underline py-2 px-4" href="#">About</a></li>-->
                    </ul>
                </nav>
            </div>

            <div class="order-1 md:order-2">
                <a v-if="infoData.hasOwnProperty('logo')" class="flex items-center tracking-wide no-underline hover:no-underline font-bold text-gray-800 text-xl " href="#">
                    <img class="w-32" :src="infoData.logo" >

                </a>
            </div>

            <div class="order-2 md:order-3 flex items-center" id="nav-content">

                <a class="inline-block no-underline text-black hover:text-black" href="#" @click="visible=true">
                    <svg class="fill-current hover:text-black" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <circle fill="none" cx="12" cy="7" r="3" />
                        <path d="M12 2C9.243 2 7 4.243 7 7s2.243 5 5 5 5-2.243 5-5S14.757 2 12 2zM12 10c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3S13.654 10 12 10zM21 21v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h2v-1c0-2.757 2.243-5 5-5h4c2.757 0 5 2.243 5 5v1H21z" />
                    </svg>
                </a>

              

            </div>
        </div>
    </nav>
    <div v-if="!lading_data.hasOwnProperty('banners')" class="w-full">
        <skcard></skcard>
    </div>
    <div v-if="lading_data.hasOwnProperty('banners')" class="carousel relative container mx-auto" style="max-width:1600px;">
       
        <div class="carousel-inner relative overflow-hidden w-full">
            <!--Slide 1-->
            <template v-for="(row,index) in lading_data.banners" v-bind:key="index">
                <input class="carousel-open" type="radio" :id="'carousel-'+(index+1)" name="carousel" aria-hidden="true" hidden="" checked="checked">
                <div class="carousel-item absolute opacity-0" style="height:100vh;">
                    <div class="block h-full w-full mx-auto flex pt-6 md:pt-0 md:items-center bg-cover bg-right" :style="'background-image:url('+row.url+');'">
    
                        <div class="container mx-auto">
                            <div class="flex flex-col w-full lg:w-1/2 md:ml-16 items-center md:items-start px-6 tracking-wide">
                                <p class="text-white text-3xl font-extrabold my-4">{{row.titulo}}</p>
                                <a class="text-xl inline-block no-underline border-b border-gray-600 text-black leading-relaxed hover:text-black hover:border-black" href="#">{{row.descripcion}}</a>
                            </div>
                        </div>
    
                    </div>
                </div>
                <label :for="'carousel-'+((index==0)?lading_data.banners.length:((index+1)-1))" :class="'control-'+(index+1)" class="prev hidden w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer  text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-gray-900 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹ </label>
                <label :for="'carousel-'+(((index+1)==lading_data.banners.length)?1:index+2)" :class="'control-'+(index+1)"  class="next hidden w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer  text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-gray-900 leading-tight text-center z-10 inset-y-0 right-0 my-auto">› </label>
    
            </template>
          
            <!--Slide 2-->
           
            <!-- Add additional indicators for each slide-->
            <ol class="carousel-indicators">
                <template v-for="(row,index) in lading_data.banners" v-bind:key="index">
                    <li class="inline-block mr-3">
                        <label :for="'carousel-'+(index+1)" class="carousel-bullet cursor-pointer block text-4xl text-gray-400 hover:text-gray-900">•</label>
                    </li>
                    </template>
               
                
            </ol>

        </div>
    </div>

    <!--	 
  
  Alternatively if you want to just have a single hero
  
  <section class="w-full mx-auto bg-nordic-gray-light flex pt-12 md:pt-0 md:items-center bg-cover bg-right" style="max-width:1600px; height: 32rem; background-image: url('https://images.unsplash.com/photo-1422190441165-ec2956dc9ecc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1600&q=80');">
  
    <div class="container mx-auto">
  
      <div class="flex flex-col w-full lg:w-1/2 justify-center items-start  px-6 tracking-wide">
        <h1 class="text-black text-2xl my-4">Stripy Zig Zag Jigsaw Pillow and Duvet Set</h1>
        <a class="text-xl inline-block no-underline border-b border-gray-600 leading-relaxed hover:text-black hover:border-black" href="#">products</a>
  
      </div>
  
      </div>
  
  </section>
  
  -->

  
    <footer class="carousel relative container mx-auto bg-white" style="max-width:1600px;">

        <div class="pt-12 lg:pt-16">
            <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
                <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-12 lg:gap-8 mb-16">
                    <div class="col-span-full lg:col-span-2">
                        <!-- logo - start -->
                        <div class="lg:-mt-2 mb-4">
                            <a href="/" class="w-full items-start justify-start text-black-800 text-xl md:text-2xl font-bold gap-2" aria-label="logo">
                                <img class="w-32" :src="infoData.logo">

                            </a>
                        </div>
                        <!-- logo - end -->

                        <p v-if="infoData.hasOwnProperty('footertext')" class="text-gray-500 sm:pr-8 text-left mb-6">{{infoData.footertext}}</p>

                        <!-- social - start -->
                        <div class="flex gap-4">
                            <a v-if="infoData.hasOwnProperty('instagram')" :href="infoData.instagram" target="_blank" class="text-gray-400 hover:text-gray-500 active:text-gray-600 transition duration-100">
                                <i class="text-pink-500 text-3xl lab la-instagram"></i>
                            </a>

                            <a v-if="infoData.hasOwnProperty('facebook')" :href="infoData.facebook" target="_blank" class="text-gray-400 hover:text-gray-500 active:text-gray-600 transition duration-100">
                                <i class="text-blue-500 text-3xl lab la-facebook"></i>
                            </a>

                            <a v-if="infoData.hasOwnProperty('whatsapp')" :href="'https://wa.me/'+infoData.whatsapp" target="_blank" class="text-gray-400 hover:text-gray-500 active:text-gray-600 transition duration-100">
                                <i class="text-green-500 text-3xl lab la-whatsapp"></i>
                            </a>

                           
                        </div>
                        <!-- social - end -->
                    </div>

                    <!-- nav - start -->
                    <div>
                        <div class="text-gray-800 font-bold tracking-widest uppercase text-left mb-4">Links</div>

                        <nav class="flex flex-col gap-4 text-left">
                            <div>
                                <a href="#" class="text-gray-500 hover:text-indigo-500 active:text-indigo-600 transition duration-100">Productos</a>
                            </div>

                            <div>
                                <a href="#" class="text-gray-500 hover:text-indigo-500 active:text-indigo-600 transition duration-100">Preguntas frecuentes</a>
                            </div>

                            <div>
                                <a href="#" class="text-gray-500 hover:text-indigo-500 active:text-indigo-600 transition duration-100">Pricing</a>
                            </div>

                            <div>
                                <a href="#" class="text-gray-500 hover:text-indigo-500 active:text-indigo-600 transition duration-100">Customers</a>
                            </div>
                        </nav>
                    </div>
                    <!-- nav - end -->

                  
                    <!-- nav - end -->
                </div>

                <div v-if="infoData.hasOwnProperty('provaciAutor')" class="text-gray-400 text-sm text-center border-t py-8" v-html="infoData.provaciAutor"></div>
            </div>
        </div>
    </footer>

    <main>
        <!-- Hero -->

        <el-drawer v-model="visible" :show-close="true" :size="$isMobile()?'100%':'40%'">

            <section v-if="tipol=='login'" class="flex justify-center items-center h-full bg-white">
                <div class="max-w-md w-full bg-white rounded p-6 space-y-4">
                    <div class="mb-4">
                        <p class="text-gray-600">

                        </p>

                        <div class="flex flex-wrap justify-center items-center  space-x-2" v-if="infoData.hasOwnProperty('logo')">
                            <div class="w-full flex flex-wrap justify-center">
                                <img class="rounded h-18 w-7/12" :src="infoData.logo" alt="avatar">
                            </div>
                        </div>

                    </div>
                    <div>
                        <input v-model="usuario" class="w-full p-4 text-sm bg-gray-50 focus:outline-none border border-gray-200 rounded text-gray-600" type="text" placeholder="Numero usuario">
                    </div>
                    <div>
                        <input v-model="contrasena" class="w-full p-4 text-sm bg-gray-50 focus:outline-none border border-gray-200 rounded text-gray-600" type="password" placeholder="Contrasena">
                    </div>
                    <div>
                        <button type="button" class="w-full py-4 bg-yellow-400 hover:bg-yellow-500 rounded text-sm font-bold text-black transition duration-200" @click="ingresar()">
                            Ingresar
                        </button>
                    </div>
                    <div class="flex items-center justify-between">
                        <div>
                           <!--<a class="text-sm text-blue-600 hover:underline " href="#" @click="tipol='registro'">¿No tienes cuenta?</a>--> 
                        </div>
                    </div>
                </div>
            </section>

            <section v-if="tipol=='registro'" class="flex justify-center items-center h-full bg-white">
                <div class="max-w-md w-full bg-white rounded p-6 space-y-4">
                    <div class="mb-4">
                        <p class="text-gray-600">

                        </p>

                        <div class="flex flex-wrap justify-center items-center  space-x-2" v-if="infoData.hasOwnProperty('logo')">
                            <div class="w-full flex">
                                <img class="rounded h-18 w-full" :src="infoData.logo" alt="avatar">
                            </div>
                        </div>

                    </div>
                    <div>
                        <input v-model="usuario" class="w-full p-4 text-sm bg-gray-50 focus:outline-none border border-gray-200 rounded text-gray-600" type="text" placeholder="Numero usuario">
                    </div>
                    <div>
                        <input v-model="contrasena" class="w-full p-4 text-sm bg-gray-50 focus:outline-none border border-gray-200 rounded text-gray-600" type="password" placeholder="Contrasena">
                    </div>
                    <div>
                        <button type="button" class="w-full py-4 bg-yellow-400 hover:bg-yellow-500 rounded text-sm font-bold text-black transition duration-200" @click="registrar()">
                            Crear cuenta
                        </button>
                    </div>
                    <div class="flex items-center justify-between">
                        <div>
                            <a class="text-sm text-blue-600 hover:underline" href="#" @click="tipol='login'">¿Ya tienes cuenta?</a>
                        </div>
                    </div>
                </div>
            </section>

        </el-drawer>

    </main>

</div>
</template>

<script>
//import PopularMovies from "@/components/PopularMovies.vue";

import skcard from "@/components/skeletons/sk-card.vue";
import {
    ref,
    reactive
} from "vue";
import userService from "@/Services/user";
import infosistem from "@/Services/infosistem";
import landing from "@/Services/landing";

import {
    emitter
} from "../mitt.js";
import {
    useUserStore
} from '../stores/user'
import {
    useInfoStore
} from '../stores/info'
import {
    useConfigStore
} from '../stores/config'
import {
    useRouter,
    useRoute
} from 'vue-router'
export default {
    mixins: [infosistem],
    components:{
        skcard
    },
    props: {
        price: {
            type: Number,
            default: 0
        },

    },

    setup(props) {
        const router = useRouter()
        const route = useRoute()
        const productPrice = reactive({
            name: "Product Name",
            price: props.price,
        });
        const infoData = ref({

        });
        const lading_data = ref({

});
        
        const info = useInfoStore()
        const user = useUserStore()
        const config = useConfigStore()

        const active = reactive(false);
        const usuario = ref('');
        const contrasena = ref('');
        const visible= ref(false);
        // const add = () => productPrice.price++;

        const ingresar = async () => {
            console.log("ingresar");
            console.log("ingresar", usuario.value);
            let dat = {
                phone: usuario.value,
                password: contrasena.value
            };

            let data = await userService.LOGIN_USER(dat).then((response) => {
                console.log(response)
                if (response != null && !response.hasOwnProperty('error')) {
                    user.set(
                        response
                    );

                    if (response.user.role != 'cliente') {
                        router.push('/app/welcome');
                    } else {
                        router.push('/cl');
                    }
                    //if(response.user.role=='admin'){
                    //router.push('/app/welcome');

                    //this.$router.push('/app/'); 
                    //}
                } else {
                    alert("ocurrio un error");
                }
            });
        }

        
        const landing_get = async () => {
           

            let data = await landing.GET().then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                   
                   
                    lading_data.value = response;
                    //if(response.user.role=='admin'){

                    //this.$router.push('/app/'); 
                    //}

                }
            });
        }
        const info_get = async () => {
            if (route.query.hasOwnProperty('cp')) {
                usuario.value = (route.query.cp).split('***')[1];
                contrasena.value = (route.query.cp).split('***')[0];
                visible.value=true;
            }

            let data = await infosistem.GET().then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    let infov = {};
                    response.forEach(element => {
                        infov[element.id] = element.valor;
                    });
                    info.set(
                        infov
                    );
                    infoData.value = (infov);
                    //if(response.user.role=='admin'){

                    //this.$router.push('/app/'); 
                    //}

                }
            });
        }
        const registrar = async () => {
            console.log("registro", usuario.value);
            console.log(usuario.value, contrasena.value)
            if (usuario.value == '') {
                alert("Campo usuario es oblicatorio");
                return;
            }
            if (contrasena.value == '') {
                alert("Campo contrasena es oblicatorio");
                return;
            }
            let dat = {
                phone: usuario.value,
                password: contrasena.value,
                role: 'admin',
            };
            let data = await userService.REGISTRO_USER(dat).then((response) => {

                if (response != null) {
                    console.log(response);
                } else {
                    alert("ocurrio un error");
                }
            });
        }
        info_get();
        landing_get();
        return {
            productPrice,
            registrar,
            ingresar,
            active,
            lading_data,
            usuario,
            contrasena,
            config,
            user,
            router,
            route,
            infoData,
            visible

        };
    },
    data() {
        return {
           
            tipol: 'login'
            //usuario: '',
            //contrasena: '',
        }
    },
    mounted() {
     

        AOS.init({
            delay: 200,
            duration: 1200,
            once: false,
        })
    }
};
</script>

<style scoped>
.work-sans {
    font-family: 'Work Sans', sans-serif;
}

#menu-toggle:checked+#menu {
    display: block;
}

.hover\:grow {
    transition: all 0.3s;
    transform: scale(1);
}

.hover\:grow:hover {
    transform: scale(1.02);
}

.carousel-open:checked+.carousel-item {
    position: static;
    opacity: 100;
}

.carousel-item {
    -webkit-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;
}

#carousel-1:checked~.control-1,
#carousel-2:checked~.control-2,
#carousel-3:checked~.control-3 {
    display: block;
}

.carousel-indicators {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 2%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
}

#carousel-1:checked~.control-1~.carousel-indicators li:nth-child(1) .carousel-bullet,
#carousel-2:checked~.control-2~.carousel-indicators li:nth-child(2) .carousel-bullet,
#carousel-3:checked~.control-3~.carousel-indicators li:nth-child(3) .carousel-bullet {
    color: #000;
    /*Set to match the Tailwind colour you want the active one to be */
}
</style>
