<template>
<section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-center content-center ">
    <div x-show="selectedRows.length" class="bg-teal-200 fixed top-0 left-0 right-0 z-40 w-full shadow" />

    <Tabla :columns="headings" :rows="data" :add="false" :filtros="['nombre','descripcion','descuento']" />

    <el-drawer v-model="showM" title="" :with-header="false" size="50%">
        <!--content-->
        <div class="h-full    flex flex-wrap content-start justify-center w-full bg-gray-100 outline-none focus:outline-none overflow-y-scroll">
            <!--header-->

            <div class="sticky top-0 flex flex-wrap justify-between p-5 border-b border-solid border-blueGray-200  z-30 bg-white rounded-t w-full">
                <h3 class="text-3xl font-semibold">
                    Editar notificacion
                </h3>
                <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="showM=false">
                    <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                    </span>
                </button>
            </div>
            <!--body-->
            <div class="w-full   overflow-y-scroll flex  flex-wrap justify-start  content-start z-20 h-full py-10">
                <div class="mb-2 mt-2  justify-start flex flex-wrap w-full px-2">
                    <span class="px-4 py-2 block ¿ text-start text-sm text-blue-600 font-semibold w-full " htmlFor="nombre">
                        {{nombre}}
                    </span>
                    
                </div>
                <div class="px-2 py-2 w-full flex flex-wrap">
<div class="w-full text-left px-3 text-red-500 text-xs font-extrabold"><span>Parametros mensajes, de click para agregar a su mensaje</span></div>
                  <template v-for="(row,index) in parametros"  v-bind:key="index">
                   
                    <el-tag @click="msg=msg+' '+row.id" class="ml-2" type="info">{{row.nombre}}</el-tag>
                   
                  </template>
                </div>
                <div class="mb-2 mt-2  justify-start flex flex-wrap w-full px-2">
                    <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                        Mensaje
                    </span>

                   

                    <textarea id="cantidad" v-model="msg" class="text-sm appearance-none rounded h-48 w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="text" placeholder="Mensaje" />
                </div>
            <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
             
              <select class="w-6/12 h-12  text-center " v-model="activo">
                <option value="0">Inactivo</option>
                <option value="1">Activo</option>
              </select>
            </div>
           
          </div>
        </div>
          <template #footer>
              <div class="dialog-footer">
                  <el-button @click="showM=false">Cancelar</el-button>
                  <el-button @click="guardar()" type="primary">Actualizar Notificación</el-button>
  
              </div>
          </template>
      </el-drawer>
    <div
      v-if="actionModal!=null"
      style="z-index:9999900"
      class="bg-slate-800 bg-opacity-50  h-screen w-full flex content-center justify-center items-center fixed  top-0 right-0 bottom-0 left-0"
    >
      <div class=" bg-white w-72 h-72 self-center  flex-wrap px-5 py-14 rounded-md text-start flex content-center justify-center items-center">
        <div class="text-base mb-4 font-bold text-slate-500">
          ¿Qué desea hacer?
        </div>
        <div class="flex-wrap flex justify-between  w-full">
          <div class="w-3/6 px-2">
            <button
              class="w-full bg-blue-500 bg-opacity-20 px-4 py-2 rounded-md text-md text-blue-500 border-2 border-blue-500"
              @click="edit(actionModal);"
            >
              Editar
            </button>
          </div>
          <div class="w-3/6 px-2">
            <button
              class="bg-red-500 px-4 py-2 bg-opacity-20 rounded-md text-md text-red-500 w-full  border-2 border-red-500"
              @click="eliminar(actionModal.id)"
            >
              Eliminar
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import notificaciones from "@/Services/notificaciones";
import Tabla from "@/components/Tabla.vue";
export default {
    components: {
        Tabla
    },
    mixins: [notificaciones],
    data() {
        const user = useUserStore()

        return {
            headings: [

                {
                    key: 'id',
                    name: 'Identificador ',
                    show: true
                }, {
                    key: 'nombre',
                    name: 'Nombre notificación',
                    show: true
                },

                {
                    key: 'msg',
                    name: 'Mensaje a enviar',
                    show: true
                },
                {
                    key: 'canal',
                    name: 'Canal',
                    show: true
                },

                {
                    key: 'edit',
                    name: 'Editar',
                    show: true
                },

            ],
            buscar: '',
            open: false,
            user: user.getAll(),
            data: [],
            actionModal: null,
            showM: false,
            id: 0,
            nombre: '',
            descripcion: '',
            descuento: 10,
            msg: '',
            activo: 1,
            parametros:[
              {nombre:'NOMBRE CLIENTE',id:'{{nombre_cliente}}'},
        {nombre:'NUMERO COTIZACIÓN',id:'{{numero_cotizacion}}'},
        {nombre:'TOTAL COTIZACION',id:'{{total_cotizacion}}'},
        {nombre:'ADEUDO',id:'{{adeudo}}'},
        {nombre:'AVANCE VENTA',id:'{{avance_venta}}'},
        {nombre:'ESTATUS VERIFICAR',id:'{{estatus_verificar}}'},
        {nombre:'ESTATUS PRODUCCIÓN',id:'{{estatus_produccion}}'},
        {nombre:'ESTATUS INSTALACIÓN',id:'{{estatus_instalacion}}'},
        {nombre:'VENDEDOR',id:'{{vendedor}}'},
        {nombre:'ENLACE COTIZACIÓN CLIENTE',id:'{{enlace_cotizacion}}'},
        {nombre:'ENLACE COTIZACION EDICIÓN',id:'{{enlace_cotizacion_edit}}'},
        {nombre:'ENLACE ACCESO CLIENTE',id:'{{cliente_acceso}}'},
        {nombre:'URL DE SITIO',id:'{{cliente_acceso}}'},
        {nombre:'# COTIZACION',id:'{{idcotizacion}}}'},
        {nombre:'# PEDIDO',id:'{{idpedido}}'},

            ]

        }

    },
    mounted() {

    },
    created() {
        this.getData()
    },
    methods: {
        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },
        buscador(row) {
            if (String(row.telefono).toUpperCase().search(String(this.buscar).toUpperCase()) != -1 || (String(row.nombre)).toUpperCase().search(String(this.buscar).toUpperCase()) != -1) {
                return true;
            } else {
                return false;
            }
        },
        getColumns() {
            let col = 0;
            this.headings.forEach(element => {
                if (element.show) {
                    col = col + 1
                }
            });
            return col;
        },
        limpiar() {
            this.id = 0;
            this.nombre = '';
            this.msg = '';
            this.activo = 1;

        },
        edit(row) {
            this.id = row.id;
            this.nombre = row.nombre;
            this.msg = row.msg;
            this.activo = row.activo;

            this.showM = true;
            this.actionModal = null;
        },
        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await notificaciones.GET(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async guardar() {
            let dat = {
                id: this.id,
                msg: this.msg,

            }
            if (this.id == 0) {
                // this.crear(dat)
            } else {
                this.editar(dat)
            }
        },
        async editar(dat) {
            emitter.emit("loading", true)

            await notificaciones.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async crear(dat) {
            emitter.emit("loading", true)

            await notificaciones.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async eliminar(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await notificaciones.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
