<template>
<section class="h-full w-full flex flex-wrap bg-blue-50 overflow-y-hidden justify-center content-center">
    <div class="w-full flex flex-wrap justify-center h-full bg-white">
        <div class="w-full h-2/6 flex flex-wrap content-start bg-yellow-400 bg-opacity-10 justify-between">
            <p class="font-extrabold my-2 text-base text-left px-4 w-full">
                Categorias
            </p>

            <div class="w-8/12 sm:w-8/12 md:w-8/12 lg:w-8/12 xl:w-8/12 h-full flex flex-wrap justify-start content-start overflow-y-scroll py-4 px-4">
                <a v-for="(row, index) in categorias_data" v-bind:key="index" @click="filtro = row.id" class="bg-red-100 my-1 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded flex flex-wrap content-center justify-start">
                    <span v-if="filtro == row.id" class="absolute l-0 -ml-4 -mt-2 inline-flex items-center p-1 text-sm font-semibold text-yellow-800 bg-yellow-400 rounded-full dark:bg-blue-200 dark:text-blue-800">
                        <svg aria-hidden="true" class="w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                        </svg>
                        <span class="sr-only">Icon description</span>
                    </span>
                    <span class="self-center w-full text-center">
                        {{ row.nombre }}
                    </span>
                </a>

                <!--<div @click="filtro='opcion_catalogo'" class="bg-blue-100 col-span-2 row-span-1 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded  flex flex-wrap content-center justify-center">

                    <span v-if="filtro=='opcion_catalogo'" class="absolute l-0 -ml-4 -mt-2 inline-flex items-center p-1  text-sm font-semibold text-yellow-800 bg-yellow-400 rounded-full dark:bg-blue-200 dark:text-blue-800">
                        <svg aria-hidden="true" class="w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                        </svg>
                        <span class="sr-only">Icon description</span>
                    </span>
                    <span>Productos Catálogos</span>
                </div>-->
            </div>
        </div>
        <div class="w-full flex flex-wrap h-4/6 justify-between content-start bg-red-100 overflow-y-auto">
            <div v-if="filtro == 0" class="justify-center content-center flex flex-wrap h-full w-full">
                <span class="text-red-500 text-2xl font-extrabold">
                    Sin categoria selecionada
                </span>
            </div>
            <div v-if="filtro != 0" class="h-full w-full flex flex-wrap justify-center content-start xs:justify-center sm:justify-center md:justify-start lg:justify-start xl:justify-start">
                <template v-for="(row, index) in data" v-bind:key="index">
                    <div v-if="filtro == row.tipo || filtro == row.categorias_id" class="w-10/12 xs:w-10/12 sm:w-6/12 md:w-4/12 lg:w-3/12 xl:w-2/12 px-2 py-2 flex flex-wrap justify-start content-center">
                        <a href="#" @click="edit(row)" class="w-full flex flex-wrap bg-white rounded-lg border overflow-hidden max-h-72 shadow-md md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                            <img v-if="row.tipo=='producto_venta'&&(row.url!=''&&row.url!=null)" class="object-cover w-2/6 h-full rounded-t-lg md:rounded-none md:rounded-l-lg" :src="row.url" alt="" />
                            <div :class="
                    row.tipo == 'producto_venta' &&
                    (row.url == '' || row.url == null)
                      ? 'w-full'
                      : row.tipo == 'producto_venta'
                      ? 'w-4/6'
                      : 'w-full'
                  " class="h-full flex flex-col justify-between text-clip p-4 leading-normal">
                                <h5 class="text-xs h-8 overflow-hidden text-ellipsis text-left font-bold tracking-tight text-gray-900 dark:text-white">
                                    {{ row.nombre }}
                                </h5>
                                <p class="mb-1 text-xs max-h-12 truncate text-left font-normal text-gray-700 dark:text-gray-400">
                                    {{ row.descripcion }}
                                </p>
                                <div class="flex justify-between items-center">
                                    <span class="text-xl font-bold text-gray-900 dark:text-white" v-if="row.tipo == 'opcion_catalogo'">
                                        ${{ row.precio }}
                                    </span>
                                </div>
                                <a href="#" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-2 py-2 text-center dark:bg-blue-500 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Editar
                                </a>
                            </div>
                        </a>
                    </div>
                </template>
            </div>
        </div>
    </div>

    <el-drawer v-model="showM" title="" @closed="getData()" :with-header="false" :size="id == 0 ? '50%' : '100%'" class="flex flex-wrap justify-center">
        <div class="w-full flex flex-wrap h-full">
            <div class="border-b border-b-gray-200 w-full sticky top-0 z-30 bg-blue-200">
                <ul class="-mb-px flex items-center gap-4 text-xs font-medium">
                    <li class="flex-1">
                        <a @click="tab = 'general'" href="#" class="flex items-center justify-center gap-2 px-1 py-3" :class="
                  tab == 'general'
                    ? 'relative text-blue-700 after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full after:bg-blue-700 hover:text-blue-700'
                    : 'text-gray-500 hover:text-blue-700'
                ">
                            General
                        </a>
                    </li>

                    <li class="flex-1" v-if="id != 0">
                        <a @click="tab = 'parametros'" href="#" class="flex items-center justify-center gap-2 px-1 py-3" :class="
                  tab == 'parametros'
                    ? 'relative text-blue-700 after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full after:bg-blue-700 hover:text-blue-700'
                    : 'text-gray-500 hover:text-blue-700'
                ">
                            Parametros
                        </a>
                    </li>
                    <li class="flex-1" v-if="id != 0">
                        <a @click="tab = 'subproductos'" href="#" class="flex items-center justify-center gap-2 px-1 py-3" :class="
                  tab == 'editor_formula'
                    ? 'relative text-blue-700 after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full after:bg-blue-700 hover:text-blue-700'
                    : 'text-gray-500 hover:text-blue-700'
                ">
                            Subproductos
                        </a>
                    </li>
                    <li class="flex-1" v-if="id != 0">
                        <a @click="tab = 'editor_formula'" href="#" class="flex items-center justify-center gap-2 px-1 py-3" :class="
                  tab == 'editor_formula'
                    ? 'relative text-blue-700 after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full after:bg-blue-700 hover:text-blue-700'
                    : 'text-gray-500 hover:text-blue-700'
                ">
                            Editor formula avanzado
                        </a>
                    </li>
                    <li class="flex-1" v-if="id != 0">
                        <a @click="tab = 'editor_formula_basico'" href="#" class="flex items-center justify-center gap-2 px-1 py-3" :class="
                  tab == 'editor_formula'
                    ? 'relative text-blue-700 after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full after:bg-blue-700 hover:text-blue-700'
                    : 'text-gray-500 hover:text-blue-700'
                ">
                            Editor formula basico
                        </a>
                    </li>
                </ul>
            </div>
            <div class="flex h-full w-full flex-wrap justify-start content-start z-20">
                <!--content-->

                <div v-if="tab == 'general'" class="w-full flex flex-wrap justify-center content-start">
                    <div class="relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <!--header-->
                        <div class="flex items-start justify-between p-2 border-b border-solid border-blueGray-200 rounded-t">
                            <h3 class="text-xl font-semibold">
                                {{ id == 0 ? 'Crear Elemento' : 'Editar Elemento' }}
                            </h3>
                        </div>
                        <!--body-->
                        <div class="relative pb-6 pt-2 overflow-y-scroll flex flex-wrap">
                            <div v-if="this.id != 0" class="w-full flex flex-wrap justify-end px-3">
                                <button @click="eliminarProducto(this.id)" class="bg-red-500 rounded text-xs text-white px-3 py-1">
                                    Eliminar producto
                                </button>
                            </div>

                            <div class="mb-1 mt-1 justify-start flex flex-wrap w-6/12 px-2">
                                <span class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold" htmlFor="nombre">
                                    Nombre
                                </span>
                                <input id="cantidad" v-model="nombre" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="text" placeholder="Nombre" />
                            </div>
                            <div class="mb-1 mt-1 justify-start flex flex-wrap w-6/12 px-2 content-start">
                                <span class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold" htmlFor="nombre">
                                    Nombre plural
                                </span>
                                <input id="cantidad" v-model="nombre_plural" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="text" placeholder="Nombre" />
                            </div>

                            <div class="mb-1 mt-1 justify-start flex flex-wrap w-6/12 px-2">
                                <span class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold" htmlFor="nombre">
                                    Descripción
                                </span>
                                <textarea id="cantidad" v-model="descripcion" class="text-sm appearance-none h-24 rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline" placeholder="Descripción" />
                                </div>
                <div
                  class="mb-1 mt-1 justify-start flex flex-wrap w-6/12 px-2 content-start"
                >
                  <span
                    v-if="imageUrl == '' && imageUrl == null"
                    class="py-2 block text-gray-700 text-start text-sm font-semibold"
                    htmlFor="nombre"
                  >
                    Imagen
                  </span>
                  <button
                    v-if="imageUrl == '' || imageUrl == null"
                    @click="onPickFile"
                    id="cantidad"
                    class="text-xs w-full h-10 text-center bg-blue-500 py-1 px-2 font-extrabold text-white"
                  >
                    Seleccionar imagen
                  </button>
                  <div
                    class="px-2 py-2 border-2 border-blue-400 flex-wrap justify-center content-center"
                    v-if="imageUrl != '' && imageUrl != null"
                  >
                    <span
                      class="mb-2 bg-blue-500 text-white font-extrabold text-xs left-2 top-2 z-40 px-4 rounded cursor-pointer"
                      @click="imageUrl = ''"
                    >
                      Cambiar imagen
                    </span>
                    <img
                      class="h-28 w-full items-center self-center"
                      :src="imageUrl"
                    />
                  </div>
                  <input
                    type="file"
                    style="display: none;"
                    ref="fileInput"
                    accept="image/*"
                    @change="onFileChange"
                  />
                </div>

                <div
                  class="mb-2 mt-2 justify-start content-start flex flex-wrap w-6/12 px-2 z-40"
                >
                  <span
                    class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
                    htmlFor="nombre"
                  >
                    Produccion interna
                  </span>
                  <select
                    id="cantidad"
                    v-model="produccion_interna"
                    class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
                  >
                    <option :value="0" :selected="produccion_interna == 0">
                      No
                    </option>

                    <option :value="1" :selected="produccion_interna == 1">
                      Si
                    </option>
                  </select>
                </div>
                <div
                  class="mb-2 mt-2 justify-start content-start flex flex-wrap w-6/12 px-2 z-40"
                >
                  <span
                    class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
                    htmlFor="nombre"
                  >
                    Categoria
                  </span>
                  <select
                    id="cantidad"
                    v-model="categoria_elemento"
                    class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
                  >
                    <option :value="0" :selected="categria_elemento == 0">
                      Seleccione un categoria
                    </option>
                    <template
                      v-for="(row, index) in categorias_data"
                      :key="index"
                    >
                      <option
                        :value="row.id"
                        :selected="categoria_elemento == row.id"
                      >
                        {{ row.nombre }}
                      </option>
                    </template>
                  </select>
                </div>

                <div
                  v-if="id != 0"
                  class="mb-2 mt-2 justify-start flex flex-wrap w-full px-2"
                >
                  <div class="w-full flex flex-wrap">
                    <button
                      class="w-full h-10 bg-green-700 text-white"
                      @click="managerFormula = true"
                    >
                      Abrir editor avanzado de formula
                    </button>
                  </div>
                </div>
              </div>
              <!--footer-->
            </div>
          </div>
          <div
            v-if="tab == 'editor_formula'"
            class="w-full flex flex-wrap justify-start content-start h-full"
          >
            <EditorFormula
              :formula="formulaexcel"
              :parametros="parametros_data"
            ></EditorFormula>
          </div>

          <div
            v-if="tab == 'editor_formula_basico'"
            class="w-full flex flex-wrap justify-start content-start"
          >
            <div
              class="mx-auto overflow-hidden mt-10 shadow-lg mb-2 bg-gray-800 w-full border rounded-lg"
            >
              <div class="">
                <div class="p-5 text-white text-center text-3xl bg-gray-800">
                  <span class="text-blue-500">Editor</span>
                  de formula

                  <span
                    class="mb-2 py-2 block text-white text-start text-sm font-semibold"
                    htmlFor="nombre"
                  >
                    <strong class="text-xs text-blue-500">
                      Seleccione los elementos para agregar a la formula
                    </strong>
                  </span>
                  <div
                    class="w-full flex flex-wrap justify-start mb-2 text-black"
                  >
                    <template
                      v-for="(row, index) in parametros_data"
                      v-bind:key="index"
                    >
                      <span
                        class="px-2 py-1 text-xs uppercase bg-yellow-500 bg-opacity-30 text-yellow-500 ml-1 mb-1 rounded"
                        v-if="id_param != row.id"
                        @click="
                          formula_json.push({
                            tipo:
                              row.tipo, //== 'catalogo' ? 'catalogo' : 'parametro',
                            valor: row.id,
                          })
                        "
                      >
                     {{ row.nombre }}
                      </span>
                      <span v-if="row.tipo == 'catalogo'&&id_param!=row.id"
                      class="px-2 py-1 text-xs uppercase bg-red-500 bg-opacity-30 text-red-500 ml-1 mb-1 rounded"
                     
                      @click="
                        formula_mp.push({
                          tipo: 'catalogo_valor_formula',
                          valor: row.id,
                        })
                      "
                    >
                   VALOR FORM:  {{ row.id }} - {{ row.nombre }}
                    </span>
                    </template>
                  </div>
                  <div
                    class="px-2 py-10 text-white text-xs bg-black w-full flex flex-wrap"
                  >
                    <template
                      v-for="(row, index) in formula_json"
                      v-bind:key="index"
                      class=""
                    >
                      <code
                        v-if="row.tipo == 'key'"
                        class="my-2 text-green-200 mx-1 hover:bg-red-500 cursor-pointer p-1 px-2 text-xs rounded-full hover:text-black bg-blue-500 bg-opacity-30"
                      >
                        {{ row.valor }}
                      </code>
                      <code
                        v-else
                        class="my-2 text-yellow-400 mx-1 hover:bg-yellow-500 hover:text-black cursor-pointer p-1 px-2 rounded-full text-xs bg-yellow-500 bg-opacity-30"
                      >
                        {{ getParamName(row.valor) }}
                      </code>
                    </template>
                  </div>
                  <div class="flex items-start bg-gray-900 flex-wrap">
                    <div
                      class="h-24 flex-1 px-2 py-2 justify-center flex items-center text-white text-xs font-semibold"
                    >
                      <div
                        @click="eliminarUltimoFormula()"
                        class="rounded-full h-20 w-20 flex items-center bg-red-600 justify-center shadow-lg border-2 border-red-800 hover:border-2 hover:border-red-500 hover:text-2xl hover:border-2 focus:outline-none px-3"
                      >
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAACXBIWXMAAAsTAAALEwEAmpwYAAADOElEQVR4nO2bv2sUQRTHJ9FgEYQ0qaKg+AM1KeTAxoDCEbAUi6QQBBu9xi6FZXr/AFMlCAeKBNGIFmIjHocgggQMWNjZaKH4AxEF/ciQd7Bu9vb2bmcuc3PvAyluZu7d22923r55M2uMoiiKoiiKoiiKoiiKoiiKogwEwDiwSZx8A+4BR3wKWCd+PgFTPsSryQ98B46byLCiAY/kGu+6Nl4BforxiyZSgP1yjV9cGp0A3onhmyZyWvPYlbER4L7YfAXsSfU3gUYsn30IuCj2PgMHM/obwPNYPjsVEJgFfgN/gfOpvjETKU4EBCaB92LrRqpvE3hhIqW0gMAo8ETs2Ft8d6p/HVg1EeAlBgJLYuOjl4QyIJzHQGAO+CN/c4n2U8Az4KSJnJ4FlEzc3nWWpVTfJWmfN5HTk4A2zsntjMS/0YwxUyYynMVA+6SV79kn72SifR5YcOjzNoDprH9Ym4fbtAktBtocT3I9m/PNpvo+2Gnt0Of/AKrAD2AlT0QRb0XGVo1HuhLQri5klWFZbJNMn/bk6IwI0iJTxIR4Lex3Znz41JWAdl0r61tkvTuSiIdWuF2+nGwjzDYRi4zZsRhoKysy1lZaJhLtl6W95sLJPPIE8i1eqRhoa3oyztb4KhnT+hZwzJWjeeQI5VW8HH/yBQSOSv3fcsUEAFtls9aMyKIv4nUUUDaF3siYeqr9JbDRDye7uBO9itd1DOwg4FPgoRkuARtdx0CZwl9l3FUTzhRezpnCq0FM4YIPEVs4eNCvnTcG7SFSII251q/CATufxpTKAzMTaek75MrJwBPpcmvhAku5BeCsC2ejW8p1KiaI47/sFPfobHWgiwkFylnngDM+HA2knOWsHphbUBXnT5jIcLon0qGkf13aL5jI6VnAAptKd4ADJnJKCTiE25pNH/vCbTfW2VpybSTX0YOMt7Mx7Y52AGPAa+C2iRQnAuYdLiKxYokRZwIWPN7WDOl8X4jnAzsdsGyEdL4vqPOBCYN7gbdid9lEjnMBxWhFD5mXF7EW+WsO+4DHco1rvn6kznC8aHPYl4DjEb/qZfeJ1ryJpyiKoiiKoiiKoiiKoiiKoiimKP8ANK5ZZ/5igGEAAAAASUVORK5CYII="
                        />
                      </div>
                    </div>
                    <div
                      v-for="(pf, index) in elementos_editor"
                      v-bind:key="index"
                      class="h-24 flex-1 px-2 py-2 justify-center flex items-center text-white text-xs font-semibold"
                    >
                      <div
                        @click="formula_json.push(pf)"
                        class="rounded-full h-20 w-20 flex items-center bg-blue-900 justify-center shadow-lg border-2 border-blue-700 hover:border-2 hover:border-blue-500 hover:text-2xl hover:border-2 focus:outline-none"
                      >
                        {{ pf.valor }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="w-full bg-black py-2 h-12 rounded-t-md grid grid-flow-col px-4 gap-1 hidden"
              >
                <kbd

                v-for="(pf, index) in elementos_editor"
                v-bind:key="index"
                  @click="formula_json.push(pf)"
                  class="px-0 py-1.5 h-8 hover:bg-yellow-400 hover:text-black cursor-pointer text-xs font-semibold text-white border-0 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500"
                >
                {{ pf.valor }}
                </kbd>

               
              </div>
              <p
                class="hidden rounded-b-md text-gray-500 dark:text-gray-400 mt-0 mb-2 flex flex-wrap justify-start w-full bg-gray-500 px-2 py-2"
              >
                <template
                  v-for="(row, index) in formula_json"
                  v-bind:key="index"
                >
                  <div class="ml-1" v-if="row.tipo == 'key'">
                    <!-- <span class="bg-yellow-500 h-8 px-1 text-white rounded">&#5130;
                                            </span>-->
                    <kbd
                      class="px-2 py-1.5 h-8 hover:bg-yellow-400 cursor-pointer text-xs font-semibold text-gray-800 bg-gray-100 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500"
                    >
                      {{ row.valor }}
                    </kbd>
                    <!--<span class="bg-yellow-500 h-8 px-1 text-white rounded">&#5125;
                                            </span>-->
                  </div>
                  <div
                    class="ml-1"
                    :data-popover-target="'popover-default' + index"
                    v-else
                  >
                    <!--<span class="bg-yellow-500 h-8 px-1 text-white rounded">&#5130;
                                            </span>-->
                    <kbd
                      class="px-2 py-1.5 h-8 bg-yellow-400 cursor-pointer text-xs font-semibold text-gray-800 bg-green-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500"
                    >
                      PARM:{{ row.valor }}
                    </kbd>
                    <!--<span class="bg-yellow-500 h-8 px-1 text-white rounded">&#5125;
                                            </span>  -->
                  </div>
                </template>
              </p>
              <p
                class="text-gray-500 dark:text-gray-400 mt-0 bg-gray-500 mb-1 hidden w-full py-1 rounded-b-md"
              >
                <template
                  v-for="(row, index) in formula_json"
                  v-bind:key="index"
                >
                  <kbd
                    v-if="row.tipo == 'key'"
                    class="px-2 text-white py-1.5 mx-1 h-8 hover:bg-yellow-400 cursor-pointer text-xs font-semibold rounded-lg dark:text-gray-100 dark:border-gray-500"
                  >
                    {{ row.valor }}
                  </kbd>
                  <kbd
                    v-else-if="
                      row.tipo == 'parametro' || row.tipo == 'catalogo'
                    "
                    class="mx-1 px-1 py-1 h-8 bg-grey-200 cursor-pointer text-xs font-semibold text-green-500 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500"
                  >
                    PARM:{{ row.valor }}
                  </kbd>
                </template>
              </p>
            </div>
          </div>
          <div
            v-if="tab == 'parametros'"
            class="flex bg-gray-200 h-full w-full flex-wrap"
          >
            <div class="flex flex-col h-full w-full">
              <p class="text-red-500 text-xs font-extrabold">
                Los cambios realizados en la lista de parametros se guardan al
                momento
              </p>
              <div class="flex-grow overflow-auto">
                <table class="relative w-full border">
                  <thead class="">
                    <tr>
                      <th
                        class="border border-black bg-yellow-400 font-sans tracking-wide normal-case text-xs bg-opacity-20 h-11 sticky top-0 px-6 py-2 text-gray-600 font-bold"
                        v-text="'#'"
                      />

                      <th
                        class="border border-black bg-yellow-400 font-sans tracking-wide normal-case text-xs bg-opacity-20 h-11 sticky top-0 px-3 py-2 text-gray-600 font-bold"
                        v-text="'Tipo parametro'"
                      />

                      <th
                        class="border border-black bg-yellow-400 font-sans tracking-wide normal-case text-xs bg-opacity-20 h-11 sticky top-0 px-3 py-2 text-gray-600 font-bold"
                        v-text="'Nombre parametro'"
                      />
                      <th
                        class="border border-black bg-yellow-400 font-sans tracking-wide normal-case text-xs bg-opacity-20 h-11 sticky top-0 px-3 py-2 text-gray-600 font-bold"
                        v-text="'Nombre de Catalogo'"
                      />
                      <th
                        class="border border-black bg-yellow-400 font-sans tracking-wide normal-case text-xs bg-opacity-20 h-11 sticky top-0 px-3 py-2 text-gray-600 font-bold"
                        v-text="'Eliminar'"
                      />
                      <th
                        class="border border-black bg-yellow-400 font-sans tracking-wide normal-case text-xs bg-opacity-20 h-11 sticky top-0 px-3 py-2 text-gray-600 font-bold"
                      >
                        <span
                          @click="
                            AddParametro = true;
                            this.id_param = 0
                          "
                          class="bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900"
                        >
                          Agregar
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-for="(row, index) in parametros_data"
                      v-bind:key="index"
                    >
                      <tr>
                        <td class="border border-black px-3 py-1">
                          <div
                            @click="
                              formula_json.push({
                                tipo:
                                  row.tipo == 'catalogo'
                                    ? 'catalogo'
                                    : 'parametro',
                                valor: row.id,
                              })
                            "
                            class="bg-black h-6 px-3 self-center text-white rounded w-full text-center"
                          >
                            {{ row.id }}
                          </div>
                        </td>
                        <td class="border border-black text-xs">
                          {{ row.tipo }}
                        </td>
                        <td class="border border-black text-xs">
                          {{ row.nombre }}
                        </td>
                        <td
                          class="border border-black text-xs text-blue-500 font-extrabold"
                        >
                          {{
                            row.sel_productos_id != null
                              ? row.selproductos.nombre
                              : 'N/A'
                          }}
                        </td>
                        <td class="border border-black text-xs font-extrabold">
                          <button
                            class="text-black bg-red-500 font-extrabold text-xs text-center px-2 py-1 rounded-md"
                            @click="EliminarParam(row.id)"
                          >
                            Eliminar
                          </button>
                        </td>

                        <td class="border border-black text-xs font-extrabold">
                          <button
                            class="text-black bg-blue-500 font-extrabold text-xs text-center px-2 py-1 rounded-md"
                            @click="editParametro(row)"
                          >
                            Editar
                          </button>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            v-if="tab == 'subproductos'"
            class="flex bg-gray-200 h-full w-full flex-wrap"
          >
            <div class="flex flex-col h-full w-full">
              <p class="text-red-500 text-xs font-extrabold">
                Lista de subproductos, estos productos seran agregados a la
                producción en base a la formula de cantidad
              </p>
              <div class="flex-grow overflow-auto">
                <table class="relative w-full border" >
                  <thead class="">
                    <tr>
                      <th
                        class="border border-black bg-yellow-400 font-sans tracking-wide normal-case text-xs bg-opacity-20 h-11 sticky top-0 px-6 py-2 text-gray-600 font-bold"
                        v-text="'#'"
                      />

                      <th
                        class="border border-black bg-yellow-400 font-sans tracking-wide normal-case text-xs bg-opacity-20 h-11 sticky top-0 px-3 py-2 text-gray-600 font-bold"
                        v-text="'Producto'"
                      />

                      <th
                        class="border border-black bg-yellow-400 font-sans tracking-wide normal-case text-xs bg-opacity-20 h-11 sticky top-0 px-3 py-2 text-gray-600 font-bold"
                        v-text="'Formula cantidad'"
                      />
                      <th
                        class="border border-black bg-yellow-400 font-sans tracking-wide normal-case text-xs bg-opacity-20 h-11 sticky top-0 px-3 py-2 text-gray-600 font-bold"
                        v-text="'Eliminar'"
                      />
                      <th
                        class="border border-black bg-yellow-400 font-sans tracking-wide normal-case text-xs bg-opacity-20 h-11 sticky top-0 px-3 py-2 text-gray-600 font-bold"
                      >
                        <span
                          v-on:click="
                           addNewSubproducto()
                          "
                          class="bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900"
                        >
                          Agregar subproducto
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-for="(row) in subproductos_data"
                      v-bind:key="row"
                    >
                      <tr >
                        <td class="border border-black px-3 py-1">
                          {{ row.id }}
                        </td>
                        <td class="border border-black text-xs">
                          {{ row.producto.nombre }}
                        </td>
                        <td class="border border-black text-xs">
                          {{ row.formula_cantidad }}
                        </td>
                        <td class="border border-black text-xs font-extrabold">
                          <button
                            class="text-black bg-red-500 font-extrabold text-xs text-center px-2 py-1 rounded-md"
                            @click="EliminarSubproducto(row)"
                          >
                            Eliminar
                          </button>
                        </td>

                        <td class="border border-black text-xs font-extrabold">
                          <button
                            class="text-black bg-blue-500 font-extrabold text-xs text-center px-2 py-1 rounded-md"
                            @click="editSubproducto(row)"
                          >
                            Editar
                          </button>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!----editor de dormula avanzado-->

      <!--------editor de firmula -->

      <el-dialog v-model="AddSubproducto" :show-close="false">
        <template>
          <div class="my-header">
            <h4 :class="titleClass">
              {{ id_param == 0 ? 'Crear Subproducto' : 'Editar Subproducto' }}
            </h4>
          </div>
        </template>
        <div class="flex flex-wrap">
          <div class="mb-2 mt-2 justify-start flex flex-wrap w-full px-2">
            <span
              class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
              htmlFor="nombre"
            >
              Producto
            </span>
            <select v-model="subproductos.productos_id">
              <option v-for="(row,i) in data" :value="row.id" v-bind:key="i" :selected="row.id==subproductos.productos_id">{{row.nombre}}</option>
            </select>
            <!--<v-select
              class="w-full"
              :reduce="(option) => option.id"
              v-model="subproductos.productos_id"
              :options="data"
              
              label="nombre"
              placeholder="Seleccione un producto"
            >
              <template #selected-option="option">
                {{ option.nombre }} - {{ option.descripcion }}
              </template>
              <template v-slot:option="option">
                {{ option.nombre }} - {{ option.descripcion }}
              </template>
            </v-select>-->
          </div>
          <span
          class="mb-2 py-2 block text-gray-700 text-start text-sm font-semibold"
          htmlFor="nombre"
        >
          Formula
          <strong class="text-xs text-red-500">
            seleccione los elementos para agregar a la formula
          </strong>
        </span>
        <div class="w-full flex flex-wrap justify-start">
          <template
            v-for="(row, index) in parametros_data"
            v-bind:key="index"
          >
            <span
              class="px-2 py-1 text-xs upercase bg-blue-100 ml-1 mb-1 rounded"
              @click="
              subproductos.formula_cantidad.push({
                  tipo: row.tipo == 'catalogo' ? 'catalogo' : 'parametro',
                  valor: row.id,
                })
              "
            >
            VALOR:  {{ row.nombre }}
            </span>
            <span v-if="row.tipo == 'catalogo'&&id_param!=row.id"
            class="px-2 py-1 text-xs lowercase bg-red-500 bg-opacity-30 text-red-500 ml-1 mb-1 rounded"
           
            @click="
              formula_mp.push({
                tipo: 'catalogo_valor_formula',
                valor: row.id,
              })
            "
          >
          valor formula:  {{ row.id }} - {{ row.nombre }}
          </span>
          </template>
        </div>
          <div class="w-full flex flex-wrap justify-start content-start">
            
            <div
              class="w-full bg-black py-2 h-12 rounded-t-md grid grid-flow-col px-4 gap-1"
            >
            
              <kbd

              v-for="(pf, index) in elementos_editor_php"
              v-bind:key="index"
                @click="subproductos.formula_cantidad.push(pf)"
               
                class="px-0 py-1.5 h-8 hover:bg-yellow-400 hover:text-black cursor-pointer text-xs font-semibold text-white border-0 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500"
              >
                {{ pf.valor }}
              </kbd>

              
             
            </div>
            <p
              class="rounded-b-md text-gray-500 dark:text-gray-400 mt-0 mb-2 flex flex-wrap justify-start w-full bg-gray-500 px-2 py-2"
            >
              <template
                v-for="(row, index) in subproductos.formula_cantidad"
                v-bind:key="index"
              >
                <div class="ml-1" v-if="row.tipo == 'key'">
                  <!-- <span class="bg-yellow-500 h-8 px-1 text-white rounded">&#5130;
                                    </span>-->
                  <kbd
                    class="px-2 py-1.5 h-8 hover:bg-yellow-400 cursor-pointer text-xs font-semibold text-gray-800 bg-gray-100 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500"
                  >
                    {{ row.valor }}
                  </kbd>
                  <!--<span class="bg-yellow-500 h-8 px-1 text-white rounded">&#5125;
                                    </span>-->
                </div>
                <div
                  class="ml-1"
                  :data-popover-target="'popover-default' + index"
                  v-else
                >
                  <!--<span class="bg-yellow-500 h-8 px-1 text-white rounded">&#5130;
                                    </span>-->
                  <kbd
                    class="px-2 py-1.5 h-8 bg-yellow-400 cursor-pointer text-xs font-semibold text-gray-800 bg-green-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500"
                  >
                    PARM:{{ row.valor }}
                  </kbd>
                  <!--<span class="bg-yellow-500 h-8 px-1 text-white rounded">&#5125;
                                    </span>  -->
                </div>
              </template>
            </p>
            <button
              class="text-black bg-red-500 font-extrabold text-xs text-center px-2 py-1 rounded-md"
              @click="eliminarUltimoFormulaSuproducto()"
            >
              Eliminar ultimo parametro formula
            </button>
          </div>
        </div>

        <template #footer>
          <span class="dialog-footer">
            <button
              class="text-black bg-red-500 font-extrabold text-xs text-center px-2 py-1 rounded-md"
              @click="AddSubproducto = false"
            >
              Cancelar
            </button>
            <button
              class="text-black bg-blue-500 font-extrabold text-xs text-center px-2 py-1 rounded-md"
              @click="guardarSubproductos()"
            >
              Guardar
            </button>
          </span>
        </template>
      </el-dialog>

      <el-dialog v-model="AddParametro" :show-close="false">
        <template #header="{  titleId, titleClass }">
          <div class="my-header">
            <h4 :id="titleId" :class="titleClass">
              {{ id_param == 0 ? 'Crear Parametro' : 'Editar parametro' }}
            </h4>
          </div>
        </template>
        <div class="flex flex-wrap">
          <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2 z-40">
            <span
              class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
              htmlFor="nombre"
            >
              Tipo Parametro
            </span>
            <select
              id="cantidad"
              v-model="tipo_parametro"
              class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
            >
              <option :value="0" :selected="tipos_parametros == 0">
                Seleccione un tipo
              </option>
              <template
                v-for="(row, index) in tipos_parametros"
                v-bind:key="index"
              >
                <option :value="row.id" :selected="tipo_parametro == row.id">
                  {{ row.nombre }}
                </option>
              </template>
            </select>
          </div>
          <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2">
            <span
              class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
              htmlFor="nombre"
            >
              Nombre
            </span>
            <input
              id="cantidad"
              v-model="nombre_parametro"
              class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
              type="text"
              placeholder="Nombre"
            />
          </div>
          <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2 z-40">
            <span
              class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
              htmlFor="nombre"
            >
              Visible en formato
            </span>
            <select
              id="cantidad"
              v-model="visible_formato"
              class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
            >
              <template
                v-for="(row, index) in [
                  { nombre: 'Si', value: 1 },
                  { nombre: 'No', value: 0 },
                ]"
                v-bind:key="index"
              >
                <option
                  :value="row.value"
                  :selected="visible_formato == row.value"
                >
                  {{ row.nombre }}
                </option>
              </template>
            </select>
          </div>
          <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2 z-40">
            <span
              class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
              htmlFor="nombre"
            >
              Visible en formato #1 de cotización(con medidas)
            </span>
            <select
              id="cantidad"
              v-model="visible"
              class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
            >
              <template
                v-for="(row, index) in [
                  { nombre: 'Si', value: 1 },
                  { nombre: 'No', value: 0 },
                ]"
                v-bind:key="index"
              >
                <option :value="row.value" :selected="visible == row.value">
                  {{ row.nombre }}
                </option>
              </template>
            </select>
          </div>
          <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2 z-40">
            <span
              class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
              htmlFor="nombre"
            >
              Visible en formato #2 de cotización(sin medidas)
            </span>
            <select
              id="cantidad"
              v-model="visible_cotizacion_t2"
              class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
            >
              <template
                v-for="(row, index) in [
                  { nombre: 'Si', value: 1 },
                  { nombre: 'No', value: 0 },
                ]"
                v-bind:key="index"
              >
                <option
                  :value="row.value"
                  :selected="visible_cotizacion_t2 == row.value"
                >
                  {{ row.nombre }}
                </option>
              </template>
            </select>
          </div>

          <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2 z-40">
            <span
              class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
              htmlFor="nombre"
            >
              Visible en la instalacion
            </span>
            <select
              id="cantidad"
              v-model="visible_instaladores"
              class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
            >
              <template
                v-for="(row, index) in [
                  { nombre: 'Si', value: 1 },
                  { nombre: 'No', value: 0 },
                ]"
                v-bind:key="index"
              >
                <option
                  :value="row.value"
                  :selected="visible_instaladores == row.value"
                >
                  {{ row.nombre }}
                </option>
              </template>
            </select>
          </div>

          <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2 z-40">
            <span
              class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
              htmlFor="nombre"
            >
              Visible en formato de produccion
            </span>
            <select
              id="cantidad"
              v-model="visible_produccion"
              class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
            >
              <template
                v-for="(row, index) in [
                  { nombre: 'Si', value: 1 },
                  { nombre: 'No', value: 0 },
                ]"
                v-bind:key="index"
              >
                <option
                  :value="row.value"
                  :selected="visible_produccion == row.value"
                >
                  {{ row.nombre }}
                </option>
              </template>
            </select>
          </div>
          <div
            v-if="tipo_parametro == 'catalogo'"
            class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2 z-40"
          >
            <span
              class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
              htmlFor="nombre"
            >
              Catalogo
            </span>
            <select
              id="cantidad"
              v-model="parametro_catalogo"
              class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
            >
              <option :value="0" :selected="parametro_catalogo == 0">
                Seleccione un catalogo
              </option>
              <template v-for="(row, index) in data_catalogo" v-bind:key="index">
                <option
                  v-if="row.tipo == 'catalogo'"
                  :value="row.id"
                  :selected="parametro_catalogo == row.id"
                >
                  {{ row.nombre }}
                </option>
              </template>
            </select>
          </div>

          <div
            v-if="
              tipo_parametro == 'catalogo' || tipo_parametro == 'calc_formula'||tipo_parametro == 'value_formula'
            "
            class="w-full flex flex-wrap justify-start content-start"
          >
            <span
              class="mb-2 py-2 block text-gray-700 text-start text-sm font-semibold"
              htmlFor="nombre"
            >
              Formula
              <strong class="text-xs text-red-500">
                seleccione los elementos para agregar a la formula
              </strong>
            </span>
            <div class="w-full flex flex-wrap justify-start">
              <template
                v-for="(row, index) in parametros_data"
                v-bind:key="index"
              >
              <span
                class="px-2 py-1 text-xs lowercase bg-blue-100 ml-1 mb-1 rounded"
                v-if="id_param != row.id"
                @click="
                  formula_mp.push({
                    tipo: row.tipo == 'catalogo' ? 'catalogo' : 'parametro',
                    valor: row.id,
                  })
                "
              >
              VALOR:  {{ row.id }} - {{ row.nombre }}
              </span>
              <span v-if="row.tipo == 'catalogo'&&id_param!=row.id"
              class="px-2 py-1 text-xs lowercase bg-red-500 bg-opacity-30 text-red-500 ml-1 mb-1 rounded"
             
              @click="
                formula_mp.push({
                  tipo: 'catalogo_valor_formula',
                  valor: row.id,
                })
              "
            >
            VALOR FORM:  {{ row.id }} - {{ row.nombre }}
            </span>
               
              </template>
            </div>
            <div
              class="w-full bg-black py-2 h-12 rounded-t-md grid grid-flow-col px-4 gap-1"
            >
              
             
            <kbd

            v-for="(pf, index) in elementos_editor"
            v-bind:key="index"
              @click="formula_mp.push(pf)"
              class="px-0 py-1.5 h-8 hover:bg-yellow-400 hover:text-black cursor-pointer text-xs font-semibold text-white border-0 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500"
            >
            {{ pf.valor }}
            </kbd>
              
             
              

              
             

             
             
            </div>
            <p
              class="rounded-b-md text-gray-500 dark:text-gray-400 mt-0 mb-2 flex flex-wrap justify-start w-full bg-gray-500 px-2 py-2"
            >
              <template v-for="(row, index) in formula_mp" v-bind:key="index">
                <div class="ml-1" v-if="row.tipo == 'key'">
                  <!-- <span class="bg-yellow-500 h-8 px-1 text-white rounded">&#5130;
                                                </span>-->
                  <kbd
                    class="px-2 py-1.5 h-8 hover:bg-yellow-400 cursor-pointer text-xs font-semibold text-gray-800 bg-gray-100 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500"
                  >
                    {{ row.valor }}
                  </kbd>
                  <!--<span class="bg-yellow-500 h-8 px-1 text-white rounded">&#5125;
                                                </span>-->
                </div>
                <div
                  class="ml-1"
                  :data-popover-target="'popover-default' + index"
                  v-else
                >
                  <!--<span class="bg-yellow-500 h-8 px-1 text-white rounded">&#5130;
                                                </span>-->
                  <kbd
                    class="px-2 py-1.5 h-8 bg-yellow-400 cursor-pointer text-xs font-semibold text-gray-800 bg-green-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500"
                  >
                    {{row.tipo == 'catalogo'?'PARAM:':row.tipo == 'catalogo_valor_formula'?'VALOR FORM:':'PARAM'}}{{ row.valor }}
                  </kbd>
                  <!--<span class="bg-yellow-500 h-8 px-1 text-white rounded">&#5125;
                                                </span>  -->
                </div>
              </template>
            </p>
            <button
              class="text-black bg-red-500 font-extrabold text-xs text-center px-2 py-1 rounded-md"
              @click="eliminarUltimoFormulaParametro()"
            >
              Eliminar ultimo parametro formula
            </button>
          </div>
        </div>

        <template #footer>
          <span class="dialog-footer">
            <button
              class="text-black bg-red-500 font-extrabold text-xs text-center px-2 py-1 rounded-md"
              @click="AddParametro = false"
            >
              Cancelar
            </button>
            <button
              class="text-black bg-blue-500 font-extrabold text-xs text-center px-2 py-1 rounded-md"
              @click="guardarParametro()"
            >
              Guardar
            </button>
          </span>
        </template>
      </el-dialog>

      <template #footer>
        <div style="flex: auto;">
          <!-- <button class="text-red-500 bg-red-500 bg-opacity-20 border border-solid border-red-500 hover:bg-red-500 hover:bg-opacity-30 hover:text-red-900 active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="showM=false">
                                    Cancelar
                                </button>
                                <button class="text-blue-800 bg-blue-500 bg-opacity-40 border hover:text-white  hover:bg-blue-700 border-solid border-blue-800 font-bold uppercase px-6 py-3 text-sm outline-none  rounded focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="guardar()">
                                    Guardar
                                </button>-->
          <btn_component
            :text="'Cancelar'"
            :tipo="'danger'"
            @click="showM = false"
          ></btn_component>
          <btn_component
            :text="'Guardar'"
            :tipo="'info'"
            @click="guardar()"
          ></btn_component>
        </div>
      </template>
    </el-drawer>

    <button
      class="btn font-bold bg-blue-800 text-white text-3xl w-12 h-12 justify-center content-center flex flex-wrap fixed z-20 float-right m-r-xxs rounded-full fixed bottom-5 right-5"
      @click="agregarNuevo()"
    >
      <vue-feather
        :type="'plus-circle'"
        size="24"
        class="self-center"
        :stroke="'white'"
      />
    </button>
  </section>
</template>

<script>
import {
    emitter
} from '../../../mitt.js'
import {
    useUserStore
} from '../../../stores/user'
import categorias from '@/Services/categorias'
import productos from '@/Services/productos'
import parametros from '@/Services/parametros'
import provedores from '@/Services/provedores'
import subproductos_s from '@/Services/subproductos'
import generales from '@/functions/generales'

import EditorFormula from '@/components/EditorFormula'
export default {
    components: {
        EditorFormula,
    },
    mixins: [categorias, generales, productos, parametros],
    data() {
        const user = useUserStore()

        return {
            buscar: '',
            open: false,
            filtro: 0,
            user: user.getAll(),
            data: [],
            tag: '',
            tab: 'general',
            formulaexcel: '',
            provedores_data: [],
            categorias_data: [],
            tipos_productos: [{
                    id: 'catalogo',
                    nombre: 'Catalogo',
                    show: true,
                },
                {
                    id: 'producto_venta',
                    nombre: 'Producto venta',
                    show: true,
                },
            ],
            tipos_parametros: [{
                    id: 'numerico',
                    nombre: 'Numerico',
                    show: true,
                },
                {
                    id: 'texto',
                    nombre: 'Texto',
                    show: true,
                },
                {
                    id: 'texto_extendido',
                    nombre: 'Texto extendido',
                    show: true,
                },
                {
                    id: 'catalogo',
                    nombre: 'Catalogo',
                    show: true,
                },
                {
                    id: 'calc_formula',
                    nombre: 'Calculado con formula',
                    show: true,
                },
                {
                    id: 'value_formula',
                    nombre: 'Valor especial',
                    show: true,
                },
            ],
            elementos_editor_php: [{
                    tipo: 'key',
                    valor: 1,
                },
                {
                    tipo: 'key',
                    valor: 2,
                },
                {
                    tipo: 'key',
                    valor: 3,
                },
                {
                    tipo: 'key',
                    valor: 4,
                },
                {
                    tipo: 'key',
                    valor: 5,
                },
                {
                    tipo: 'key',
                    valor: 6,
                },
                {
                    tipo: 'key',
                    valor: 7,
                },
                {
                    tipo: 'key',
                    valor: 8,
                },
                {
                    tipo: 'key',
                    valor: 9,
                },
                {
                    tipo: 'key',
                    valor: 0,
                },
                {
                    tipo: 'key',
                    valor: '(',
                },
                {
                    tipo: 'key',
                    valor: ')',
                },
                {
                    tipo: 'key',
                    valor: '+',
                },
                {
                    tipo: 'key',
                    valor: '-',
                },
                {
                    tipo: 'key',
                    valor: '*',
                },
                {
                    tipo: 'key',
                    valor: '/',
                },

                {
                    tipo: 'key',
                    valor: '%',
                },
                {
                    tipo: 'key',
                    valor: '>',
                },
                {
                    tipo: 'key',
                    valor: '<',
                },
                {
                    tipo: 'key',
                    valor: '=',
                },
                {
                    tipo: 'key',
                    valor: ':',
                },
                {
                    tipo: 'key',
                    valor: '?',
                },
                {
                    tipo: 'key',
                    valor: 'floor',
                },
                {
                    tipo: 'key',
                    valor: 'ceil',
                },
                {
                    tipo: 'key',
                    valor: '.',
                },
               
            ],
            elementos_editor: [{
                    tipo: 'key',
                    valor: 1,
                },
                {
                    tipo: 'key',
                    valor: 2,
                },
                {
                    tipo: 'key',
                    valor: 3,
                },
                {
                    tipo: 'key',
                    valor: 4,
                },
                {
                    tipo: 'key',
                    valor: 5,
                },
                {
                    tipo: 'key',
                    valor: 6,
                },
                {
                    tipo: 'key',
                    valor: 7,
                },
                {
                    tipo: 'key',
                    valor: 8,
                },
                {
                    tipo: 'key',
                    valor: 9,
                },
                {
                    tipo: 'key',
                    valor: 0,
                },
                {
                    tipo: 'key',
                    valor: '(',
                },
                {
                    tipo: 'key',
                    valor: ')',
                },
                {
                    tipo: 'key',
                    valor: '+',
                },
                {
                    tipo: 'key',
                    valor: '-',
                },
                {
                    tipo: 'key',
                    valor: '*',
                },
                {
                    tipo: 'key',
                    valor: '/',
                },

                {
                    tipo: 'key',
                    valor: '%',
                },
                {
                    tipo: 'key',
                    valor: '>',
                },
                {
                    tipo: 'key',
                    valor: '<',
                },
                {
                    tipo: 'key',
                    valor: '=',
                },
                {
                    tipo: 'key',
                    valor: ':',
                },
                {
                    tipo: 'key',
                    valor: '?',
                },
                {
                    tipo: 'key',
                    valor: 'Math.floor',
                },
                {
                    tipo: 'key',
                    valor: 'Math.ceil',
                },
                {
                    tipo: 'key',
                    valor: '.',
                },
                {
                    tipo: 'key',
                    valor: 'window.PAR',
                },
            ],
            actionModal: null,
            showM: false,
            id: 0,
            tipo_producto: 'producto_venta',
            nombre: '',
            descripcion: '',
            color: 'rgba(255,30,100,1)',
            formula_json: [],
            formula_mp: [],
            precio: null,
            categoria_elemento: 0,
            imageUrl: null,
            nombre_plural: '',
            data_file: null,
            AddParametro: false,
            AddSubproducto: false,
            tipo_parametro: 'numerico',
            produccion_interna: 0,
            nombre_parametro: '',
            parametro_catalogo: 0,
            id_param: 0,
            producto_id: null,
            parametros_data: [],
            popoversel: 0,
            visible_formato: 1,
            visible: 1,
            visible_produccion: 1,
            visible_instaladores: 1,
            visible_cotizacion_t2: 1,
            menor: '<',
            AddProductoCatalogo: false,
            nombre_prod: '',
            formula_excel: '',
            precio_prod: 0,
            precio_compra: 0,
            provedor: 0,
            id_prod: 0,
            productos_data: [],
            data_catalogo:[],
            managerFormula: false,
            subproductos: {
                id: 0,
                productos_id: 0,
                formula_mp: [],
            },
        }
    },
    mounted() {
        emitter.on('actualizacionformula', (data) => {
            //this.closeProduccion();
            this.managerFormula = false
            this.formula_json = data
        })
    },
    async created() {
        await this.getData()
        await this.getDataCategorias()
        await this.getDataProvedores()
        this.getDataCatalogo();
    },
    methods: {
        addNewSubproducto(){
            this.AddSubproducto = true;
            this.subproductos.id=0;
            this.subproductos.formula_cantidad=[];
            this.subproductos.productos_id=null;
            this.subproductos.parent_id=this.id;
        },
        setearFormula(formula) {},
        agregarNuevo() {
            this.showM = true
            this.tab = 'general'
            this.limpiar()
        },
        eliminarUltimoFormula() {
            this.formula_json.pop()
        },
        eliminarUltimoFormulaParametro() {
            this.formula_mp.pop()
        },

        async onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return

            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.data_file = files[0]
            console.log(e)
        },
        onPickFile() {
            this.$refs.fileInput.click()
        },
        async getDataCategorias() {
            this.showM = false
            this.actionModal = null
            emitter.emit('loading', true)

            await categorias.GET(this.user.token).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    this.categorias_data = response
                    //this.$router.push('/app/');
                } else {
                    // emitter.emit("alerta_show",response.msg);
                }
                emitter.emit('loading', false)
            })
        },
        aplicarFormula() {},
        limpiar() {
            this.id = 0

            this.nombre = ''
            this.nombre_plural = ''
            this.descripcion = ''
            this.tipo_producto = 'producto_venta'
            this.categoria_elemento = 0
            this.precio = null
            this.imageUrl = ''
            this.tag = ''
            this.formula_json = []
            this.formula_mp = []
            this.parametros_data = []
            this.productos_data = []
        },
        limpiarProdOpcion() {
            this.nombre_prod = ''

            this.precio_prod = 0
            this.precio_compra = 0
            this.provedor = 0
            this.id_prod = 0
        },

        editParametro(row) {
            console.log(row, '----param')
            this.id_param = row.id
            this.nombre_parametro = row.nombre
            this.parametro_catalogo = row.sel_productos_id
            this.visible_formato = row.visible_formato
            this.visible = row.visible
            this.visible_produccion = row.visible_produccion
            this.visible_instaladores = row.visible_instaladores
            this.visible_cotizacion_t2 = row.visible_cotizacion_t2
            this.formula_mp = row.formula_mp == null ? [] : JSON.parse(row.formula_mp)
            this.tipo_parametro = row.tipo

            this.AddParametro = true
        },
        ShowModalAddProductosCatalogo() {},
        editProducto(row) {
            console.log(row, '----param')
            this.id_prod = row.id
            this.nombre_prod = row.nombre

            this.tag = row.tag
            this.descripcion_prod = row.descripcion
            this.precio_prod = row.precio
            this.precio_compra = row.precio_compra
            if (row.provedores_id >= 0) {
                this.provedor = row.provedores_id
            } else {
                this.provedor = 0
            }

            this.AddProductoCatalogo = true
        },
        editSubproducto(row) {
            this.subproductos.id = row.id
            this.subproductos.productos_id = row.productos_id
            this.subproductos.parent_id = this.id

            this.subproductos.formula_cantidad = JSON.parse(row.formula_cantidad)

            this.AddSubproducto = true
        },
        edit(row) {
            this.limpiar()
            this.limpiarProdOpcion()
            this.tab = 'general'
            this.id = row.id
            this.nombre = row.nombre

            this.nombre_plural = row.nombre_plural
            this.descripcion = row.descripcion
            this.tipo_producto = row.tipo
            this.categoria_elemento = row.categorias_id
            this.produccion_interna = row.produccion_interna
            this.precio = row.precio
            this.imageUrl = row.url

            this.formula_json = JSON.parse(row.formula)
            this.parametros_data = row.parametros
            this.productos_data = row.productos_edit
            //console.log(row.subproductos,'subproductos')
            this.subproductos_data=row.subproductos;
            this.showM = true
            this.actionModal = null
        },
        async getDataProvedores() {
            this.showM = false
            this.actionModal = null
            emitter.emit('loading', true)

            await provedores.GET(this.user.token).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    this.provedores_data = response
                    //this.$router.push('/app/');
                } else {
                    // emitter.emit("alerta_show",response.msg);
                }
                emitter.emit('loading', false)
            })
        },
        async getData() {
            this.limpiar()
            this.showM = false
            this.actionModal = null
            emitter.emit('loading', true)

            await productos.VENTA(this.user.token).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    this.data = response
                    //this.$router.push('/app/');
                } else {
                    // emitter.emit("alerta_show",response.msg);
                }
                emitter.emit('loading', false)
            })
        },
        async getDataCatalogo() {
            this.limpiar()
            this.showM = false
            this.actionModal = null
            emitter.emit('loading', true)

            await productos.CATALOGO(this.user.token).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    this.data_catalogo = response
                    //this.$router.push('/app/');
                } else {
                    // emitter.emit("alerta_show",response.msg);
                }
                emitter.emit('loading', false)
            })
        },
        async guardar() {
            var formData = new FormData()
            if (this.tipo_producto == 'producto_venta' && this.data_file != null) {
                formData.append('doc', this.data_file)
            }
            formData.append('id', this.id)
            formData.append('nombre', this.nombre)
            formData.append('nombre_plural', this.nombre_plural)

            formData.append('descripcion', this.descripcion)
            formData.append('produccion_interna', this.produccion_interna)

            formData.append('tipo_producto', this.tipo_producto)
            formData.append('formula', JSON.stringify(this.formula_json))
            if (this.tipo_producto == 'producto_venta') {
                formData.append('categoria', this.categoria_elemento)
            }
            if (
                this.tipo_producto != 'catalogo' &&
                this.tipo_producto != 'producto_venta'
            ) {
                formData.append('precio', this.precio)
            }

            let dat = {
                id: this.id,
                data: formData,
            }

            if (this.id == 0) {
                this.crear(dat)
            } else {
                this.editar(dat)
            }
        },

        async guardarProducto() {
            var formData = new FormData()

            formData.append('id', this.id_prod)
            formData.append('nombre', this.nombre_prod)
            formData.append('tag', this.tag)

            formData.append('tipo_producto', 'opcion_catalogo')
            formData.append('precio', this.precio_prod)
            formData.append('precio_compra', this.precio_compra)

            if (this.provedor > 0) {
                formData.append('provedores_id', this.provedor)
            }
            if (this.descripcion_prod != '' && this.descripcion_prod != null) {
                formData.append('descripcion', this.descripcion_prod)
            }

            formData.append('productos_id', this.id)

            let dat = {
                id: this.id_prod,
                data: formData,
            }

            if (this.id_prod == 0) {
                this.crearProducto(dat)
            } else {
                this.editarProducto(dat)
            }
        },
        async editar(dat) {
            emitter.emit('loading', true)

            await productos.EDITAR(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    this.getData()
                    this.showM = false
                    this.limpiar()
                    //this.$router.push('/app/');
                } else {
                    // emitter.emit("alerta_show",response.msg);
                }

                emitter.emit('loading', false)
            })
        },
        async crear(dat) {
            emitter.emit('loading', true)

            await productos.CREAR(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    this.getData()
                    this.showM = false
                    this.limpiar()
                    //this.$router.push('/app/');
                } else {
                    emitter.emit('alerta_show', response.error)
                }

                emitter.emit('loading', false)
            })
        },
        async editarProducto(dat) {
            emitter.emit('loading', true)

            await productos.EDITAR(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    this.productos_data = this.productos_data.filter(
                        (row) => row.id != dat.id,
                    )
                    this.productos_data.push(response)
                    //this.getData();
                    //this.$router.push('/app/');
                } else {
                    emitter.emit('alerta_show', response.error)
                }
                this.AddProductoCatalogo = false
                this.limpiarProdOpcion()
                emitter.emit('loading', false)
            })
        },
        async crearProducto(dat) {
            emitter.emit('loading', true)

            await productos.CREAR(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    this.productos_data.push(response)
                    //this.getData();
                    //this.$router.push('/app/');
                    this.AddProductoCatalogo = false
                    this.limpiarProdOpcion()
                } else {
                    emitter.emit('alerta_show', response.error)
                }

                emitter.emit('loading', false)
            })
        },

        async eliminarProducto(id) {
            emitter.emit('loading', true)
            let dat = {
                id: id,
            }
            await productos.ELIMINAR(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    this.getData()
                    //this.$router.push('/app/');
                } else {
                    emitter.emit('alerta_show', response.error)
                }
                this.actionModal = null
                emitter.emit('loading', false)
            })
        },
        async EliminarParam(id) {
            emitter.emit('loading', true)
            let dat = {
                id: id,
            }
            await parametros.ELIMINAR(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    this.parametros_data = this.parametros_data.filter(
                        (row) => row.id != id,
                    )
                    //this.getData();
                    //this.$router.push('/app/');
                    this.actionModal = null
                } else {
                    emitter.emit('alerta_show', response.error)
                }

                emitter.emit('loading', false)
            })
        },

        //parametros

        async guardarParametro() {
            if (this.tipo_parametro != 'catalogo') {
                this.parametro_catalogo = null
            } else {
                if (this.parametro_catalogo == 0) {
                    emitter.emit('alerta_show', 'Es necesario seleccionar un catalogo')
                    // return
                }
            }

            let dat = {
                id: this.id_param,
                nombre: this.nombre_parametro,
                tipo_parametro: this.tipo_parametro,
                visible: this.visible,
                formula_mp: this.formula_mp,
                visible_formato: this.visible_formato,
                visible_produccion: this.visible_produccion,
                visible_instaladores: this.visible_instaladores,
                visible_cotizacion_t2: this.visible_cotizacion_t2,
                producto_sel: this.parametro_catalogo,
                producto: this.id,
            }
            if (this.id_param == 0) {
                this.crearParametro(dat)
            } else {
                this.editarParametro(dat)
            }
        },
        async editarParametro(dat) {
            emitter.emit('loading', true)

            await parametros.EDITAR(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    this.parametros_data = this.parametros_data.filter(
                        (row) => row.id != dat.id,
                    )
                    this.parametros_data.push(response)
                    //this.getData();
                    //this.$router.push('/app/');
                } else {
                    emitter.emit('alerta_show', response.error)
                }
                // this.showM = false;
                // this.limpiar();
                emitter.emit('loading', false)
            })
        },
        async crearParametro(dat) {
            emitter.emit('loading', true)

            await parametros.CREAR(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    this.parametros_data.push(response)
                    //this.getData();
                    //this.$router.push('/app/');
                } else {
                    emitter.emit('alerta_show', response.error)
                }
                //this.showM = false;
                //this.limpiar();
                emitter.emit('loading', false)
            })
        },

        async guardarSubproductos() {
            if (this.subproductos.id == 0) {
                this.crearSubproductos(this.subproductos)
            } else {
                this.editarSubproductos(this.subproductos)
            }
        },
        async editarSubproductos(dat) {
            emitter.emit('loading', true)

            await subproductos_s.EDITAR(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    // this.subproductos.push(response)
                    this.AddSubproducto = false;
                    this.subproductos_data=response;

                    //this.$router.push('/app/');
                } else {
                    // emitter.emit("alerta_show",response.msg);
                }

                emitter.emit('loading', false)
            })
        },
        async crearSubproductos(dat) {
            emitter.emit('loading', true)

            await subproductos_s.CREAR(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    //this.subproductos_data.push(response)
                    this.subproductos_data=response;
                    this.AddSubproducto = false
                    //this.$router.push('/app/');
                } else {
                    emitter.emit('alerta_show', response.error)
                }

                emitter.emit('loading', false)
            })
        },
        async EliminarSubproducto(row) {
            emitter.emit('loading', true)
            let dat = {
                parent_id: row.parent_id,
                id:row.id
            }
            await subproductos_s.ELIMINAR(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    this.subproductos_data=[];
                   
                    this.tab = ''
                    this.subproductos_data = response;
                    this.tab = 'subproductos'
                    emitter.emit('loading', false)
                    //this.getData();
                    //this.$router.push('/app/');
                  
                } else {
                    emitter.emit('alerta_show', response.error)
                    emitter.emit('loading', false)
                }

              
            })
        },
        eliminarUltimoFormulaSuproducto(){
            this.subproductos.formula_cantidad.pop()
        },

        getParamName(id) {
            let nombre = ''
            Object.values(this.parametros_data).forEach((val) => {
                if (val.id == id) {
                    nombre = val.nombre
                }
            })
            return nombre
        },
    },
}
</script>
