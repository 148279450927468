<template>
<!--style="height:calc(100% - 96px)"-->
<section class="bg-white  w-full flex flex-wrap h-full">

    <div v-if="showconfirm" style="z-index:9999999" class="w-full transition-all justify-center content-center flex flex-wrap fixed top-0 left-0 bg-red-700 bg-opacity-40 h-full ">
        <div class="w-64 h-auto bg-white rounded self-center">

            <svg fill="#ff0000" class="mx-auto mt-8 h-16 w-16 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z" /></svg>
            <h1 class="mt-2 text-center text-2xl font-bold text-gray-500">Confirmación</h1>
            <p class="my-4 text-center text-sm text-gray-500">¿Desea eliminar este elemento?</p>
            <div class="space-x-2 bg-gray-100 py-4 text-center px-2">
                <button class="inline-block text-xs rounded-md bg-red-500 mb-2 px-2 py-2 font-semibold text-green-100 shadow-md duration-75 hover:bg-red-400" @click="showconfirm=false">Cancelar</button>
                <button class="inline-block text-xs rounded-md bg-green-500 px-2 py-2 font-semibold text-green-100 shadow-md duration-75 hover:bg-green-400" @click="showconfirm=false;$parent.eliminar(valor)">Aceptar</button>
            </div>
        </div>
    </div>

    <div class=" w-full p-0 m-0 h-full">
        <div class="w-full flex flex-wrap h-full">
            <div class="w-full h-full">

                <div class="max-w-full overflow-x-scroll overflow-y-auto content-start h-full pb-20">

                    <button v-if="add!=false" class="z-30 btn font-bold bg-blue-800 text-white text-3xl w-12 h-12 justify-center content-center flex flex-wrap fixed   float-right m-r-xxs rounded-full   bottom-5 right-5 " @click="$parent.addRow()">
                        <vue-feather :type="'plus-circle'" size="24" class="self-center" :stroke="'white'" />
                    </button>

                    <button v-if="refr!=false" class="z-30 btn font-bold bg-yellow-300 text-white text-3xl w-12 h-12 justify-center content-center flex flex-wrap fixed bottom-5  right-20 float-right m-r-xxs rounded-full" @click="$parent.getData()">
                        <vue-feather :type="'refresh-cw'" size="24" class="self-center" :stroke="'white'" />
                    </button>
                    <button v-if="info!=false" class="z-30 btn font-bold bg-blue-300 text-white text-3xl w-12 h-12 justify-center content-center flex flex-wrap fixed bottom-5  right-40 float-right m-r-xxs rounded-full" @click="$parent.showInfo()">
                        <vue-feather :type="'info'" size="24" class="self-center" :stroke="'white'" />
                    </button>

                    <div v-if="buscador" class="text-left  sticky left-0 w-full bg-gray-300">
                        <div :colspan="getColumns()" class=" sticky top-0  border-b border-gray-200  py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                            <div class=" flex justify-between items-center w-full flex-wrap">
                                <div class="flex flex-wrap w-full">
                                    <div class="relative w-full flex flex-wrap ">

                                        <div v-if="filtroApi==true" class="flex flex-wrap justify-start w-3/6 px-2">
                                            <input v-model="buscarapi" type="search" class="rounded-md border-0 w-full pl-10 pr-4 py-2  shadow focus:outline-none focus:shadow-outline text-gray-600 font-medium" placeholder="Buscador...">

                                        </div>
                                        <div v-if="filtroApi==true" class="flex flex-wrap justify-start w-2/6 xs:w-2/6 sm:w-2/6 md:w-24 lg:w-24 xl:w-24 px-1">
                                            <button class="rounded-lg text-blue-800 w-full bg-blue-500 bg-opacity-40   hover:text-white  hover:bg-blue-700 border-solid border-blue-800 font-bold uppercase  py-1 text-sm outline-none   focus:outline-none   ease-linear transition-all duration-150" type="button" @click="$parent.buscarGet(buscarapi)">
                                                Filtrar
                                            </button>

                                        </div>
                                        <div class="flex flex-wrap justify-start w-1/6 hidden ">
                                            <div class="relative">
                                                <button v-if="showcols" class="rounded-lg inline-flex items-center bg-white hover:text-blue-500 focus:outline-none focus:shadow-outline text-gray-500 font-semibold py-2 px-2 md:px-4" @click.prevent="open = !open">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 md:hidden" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <rect x="0" y="0" width="24" height="24" stroke="none" />
                                                        <path d="M5.5 5h13a1 1 0 0 1 0.5 1.5L14 12L14 19L10 16L10 12L5 6.5a1 1 0 0 1 0.5 -1.5" />
                                                    </svg>
                                                    <span class="select-all hidden md:block">Columnas</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 ml-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <rect x="0" y="0" width="24" height="24" stroke="none" />
                                                        <polyline points="6 9 12 15 18 9" />
                                                    </svg>
                                                </button>

                                                <div v-show="open" class=" absolute z-30 top-0 right-0 w-40 bg-white rounded-lg shadow-lg mt-12 -mr-1 block py-1 overflow-hidden" @click="open = false">
                                                    <template v-for="heading in columns" :key="heading.key">
                                                        <label v-if="!heading.hasOwnProperty('children')" class="flex justify-start items-center text-truncate hover:bg-gray-100 px-4 py-2">
                                                            <div class="text-teal-600 mr-3">
                                                                <input v-model="heading.show" type="checkbox" class="form-checkbox focus:outline-none focus:shadow-outline" :checked="heading.show" :value="heading.key">
                                                            </div>
                                                            <div class="select-none text-gray-700" v-text="heading.name" />
                                                        </label>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="filtroApi==false" class="flex flex-wrap justify-start w-full px-2">
                                            <input v v-model="buscar" type="search" class="w-full pl-10 pr-4 py-2 rounded-md border-0 shadow focus:outline-none focus:shadow-outline text-gray-600 font-medium" placeholder="Buscador...">
                                            <div class="absolute top-0 left-2 inline-flex items-center p-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-gray-400" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <rect x="0" y="0" width="24" height="24" stroke="none" />
                                                    <circle cx="10" cy="10" r="7" />
                                                    <line x1="21" y1="21" x2="15" y2="15" />
                                                </svg>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <table v-if="true" class="zoom  w-full   table-auto">
                        <thead class="">
                            <tr class="border border-grey-500 ">

                                <template v-for="(heading,index) in columns" :key="index">
                                    <th v-show="heading.show&&!heading.hasOwnProperty('children')" :class="heading.key=='clientes'?'min-w-[200]':heading.class" class=" bg-gray-300 h-10 border border-gray-400  justify-center font-sans     sticky top-0     py-1 text-gray-600 font-bold  uppercase text-xs z-30">
                                        <div class="flex flex-wrap h-10 w-full justify-center content-center">
                                            <span>{{heading.name}}</span>
                                        </div>
                                    </th>
                                </template>
                            </tr>

                        </thead>
                        <tbody class="">
                            <template v-for="(row,index) in rows">
                                <tr v-if="buscadorpropiedades(row)" :key="index">
                                    <template v-for="(row_col,index_col) in columns" :key="index_col">
                                        <td v-if="row_col.key=='files'" :class="getCellClass(row_col, index_col)">
                                            <div class="mb-5  relative flex flex-wrap z-10">
                                                <img src="https://res.cloudinary.com/dgzea9m7q/image/upload/v1656487048/software/icons8-plus-48.png" class="z-10 w-8 h-8 rounded-full border-2 " @click="$parent.uploadsel(row.id)">
                                                <img v-for="(row_f,index) in row[row_col.key]" :key="index" :src="'https://ik.imagekit.io/popcing/'+row_f.name+'?t=50'" class="w-8 h-8 z-10 rounded-full border-2  ">
                                            </div>
                                        </td>
                                        
                                        <td v-else-if="row_col.show && row_col.key === 'seleccionar_pedido'" :class="row_col.class+' '+getCellClass(row_col, index_col)" >
                                            <span v-if="row.id_cotizacion_final==null" class=" font-extrabold text-blue-500 cursor-pointer text-xs px-3 rounded-lg py-1 text-center items-center " @click="$parent.SetCotizacionFinal(row)">
                                              {{   'Seleccionar cotización'}}
                                            </span>
                                            <span v-else class=" font-extrabold text-xs px-3 rounded-lg py-1 text-center items-center ">
                                                {{ row.id_cotizacion_final }}
                                              </span>
                                        </td>
                                        <!--nuevas con formatos-->
                                        <td v-else-if="row_col.show && row_col.tipo === 'format_price'" :class="row_col.class+' '+getCellClass(row_col, index_col)">
                                            <span class="text-black font-extrabold text-xs px-3 rounded-lg py-1 text-center items-center">
                                                ${{formatPrice( Number(row[row_col.key]).toFixed(2)) }}
                                            </span>
                                        </td>
                                        <td v-else-if="row_col.show && row_col.key === 'dias_instalacion_habiles'" :class="row_col.class+' '+getCellClass(row_col, index_col)">
                                            <span :class="row.dias_instalacion_habiles<15?'bg-green-100':'bg-yellow-500'" class="text-black font-extrabold text-xs px-3 rounded-lg py-1 text-center items-center">
                                                {{ row.dias_instalacion_habiles }}
                                            </span>
                                        </td>
                                        <td v-else-if="row_col.show && row_col.key === 'dias_produccion_habiles'" :class="row_col.class+' '+getCellClass(row_col, index_col)">
                                            <span :class="row.dias_produccion_habiles<10?'bg-green-100':'bg-yellow-500'" class="text-black font-extrabold text-xs px-3 rounded-lg py-1 text-center items-center">
                                                {{ row.dias_produccion_habiles }}
                                            </span>
                                        </td>
                                        

                                        <!-----fin formatos generales-->

                                        <td v-else-if="row_col.show&&row_col.key=='inventario'" :class="colores(row)+' '+getCellClass(row_col, index_col)">
                                            <span class="select-all text-black  font-extrabold text-xs px-3 rounded-lg py-1  text-center  items-center">
                                                {{ CalcExistencias(row) }}
                                            </span>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='pedido_id'" :class="colores(row)+' '+getCellClass(row_col, index_col)">
                                            <span v-if="row.cotizaciones!=null" class="text-black  font-extrabold text-xs px-3 rounded-lg py-1  text-center  items-center">
                                                {{row.cotizaciones_id }}
                                            </span>
                                            <span v-else class="text-black  font-extrabold text-xs px-3 rounded-lg py-1  text-center  items-center">
                                                <strong v-if="row.cotizaciones_id==null||row.cotizaciones_id==' NUMERO DE PEDIDO NO ASIGNADO'"></strong>
                                                <strong v-else>NUMERO DE PEDIDO NO VALIDO</strong>

                                            </span>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='link'" :class="getCellClass(row_col, index_col)">
                                            <a v-if="row.link!=null" class="text-green-500   font-extrabold text-xs px-3 rounded-lg py-1  text-center  items-center" target="_blank" :href="'http://localhost:8080/r?u='+row.id+'&code='+row.link">
                                                /r?u={{row.id}}&code={{row.link}}
                                            </a>
                                            <span v-else>N/A</span>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='color'" :class="getCellClass(row_col, index_col)">
                                            <span class="select-all text-black w-12 h-12  font-extrabold text-xs px-3 rounded-lg py-1  text-center  items-center" :style="'background:'+row.color">

                                            </span>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='valor'" :class="getCellClass(row_col, index_col)">

                                            <div v-if="row.tipo_valor=='img'" class="flex flex-wrap w-full content-start justify-center">
                                                <img class="w-14" :src="row.valor+'?tr=w-80'">
                                            </div>
                                            <span v-if="row.tipo_valor=='text'" v-html="row.valor"></span>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='url'" :class="getCellClass(row_col, index_col)">
                                            <div style="width:30px;height:30px;background-size:cover;" class="text-black   font-extrabold text-xs px-3 rounded-lg py-1  text-center  items-center" :style="'background:url('+row.url+'?tr=w-30)'" />

                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='clientes'" :class="'w-2/6 bg-'+(impar(index_col))+'  min-w-[320px] '+class_general+' border-['+'#E8E8E8'+']'">
                                            <span v-if="row.clientes!=null" class="w-full  h-12  font-extrabold text-sm px-3 rounded-lg py-1  text-start  items-start">
                                                {{row.clientes.name}} {{row.clientes.apellido_p}} {{row.clientes.apellido_m}}
                                            </span>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='clientes_cotizaciones'" :class="'w-2/6 bg-'+(impar(index_col))+'  min-w-[320px] '+class_general+' border-['+'#E8E8E8'+']'">
                                            <span v-if="row.cotizaciones!=null&&row.cotizaciones.clientes!=null" class="w-full  h-12  font-extrabold text-sm px-3 rounded-lg py-1  text-start  items-start">
                                                {{row.cotizaciones.clientes.name}} {{row.cotizaciones.clientes.apellido_p}} {{row.cotizaciones.clientes.apellido_m}}
                                            </span>
                                        </td>

                                        <td v-else-if="row_col.show&&row_col.key=='usuario'" :class="'w-2/6 bg-'+(impar(index_col))+'  min-w-[320px] '+class_general+' border-['+'#E8E8E8'+']'">
                                            <span v-if="row.usuario!=null" class="w-full  h-12  font-extrabold text-sm px-3 rounded-lg py-1  text-start  items-start">
                                                {{row.usuario.name}}
                                            </span>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='status_ordenes'" :class="'w-2/6  '+row_col.class+'  '+class_general+' border-['+'#E8E8E8'+'] '+(row.status==3?'bg-red-400':(row.status==2?'bg-blue-400':'bg-green-400'))">
                                            <span class="select-all w-full  h-12  font-extrabold text-sm px-3 rounded-lg py-1  text-start  items-start">
                                                {{row.status==3?'CREADA':(row.status==2?'EN TRANSITO':'EN ALMACEN')}}
                                            </span>
                                        </td>

                                        <td v-else-if="row_col.show&&row_col.key=='cambiar_vendedor'" class="text-red-500 font-extrabold" :class="getCellClass(row_col, index_col)" @click="$parent.cambiarUsuario(row)">
                                            {{row_col.name}}

                                        </td>

                                        <td v-else-if="row_col.show&&row_col.key=='proveedor'" :class="getCellClass(row_col, index_col)">
                                            <span class="select-all  w-12 h-12  font-extrabold text-sm px-3 rounded-lg py-1  text-center  items-center">
                                                {{row.proveedor==null?'No tiene proveedor asignado':row.proveedor.nombre}}
                                            </span>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='verificar_aprobar'" :class="(statusVerificar(row.productos_cotizaciones)=='APROBADA'?'bg-green-500':'bg-blue-400')+'  w-1/6 min-w-[160px] '+class_general+' border-['+'#E8E8E8'+']'">

                                            <span v-if="row.status_verificar=='PENDIENTE DE REVISIÓN'" @click="$parent.verCotizacion(row)" class=" w-12 h-12  font-extrabold text-black cursor-pointer text-sm   text-center  items-center">
                                                {{statusVerificar(row.productos_cotizaciones)}}
                                            </span>
                                            <a v-else class="text-black  font-extrabold text-center uppercase items-center" href="#">
                                                FORMATO PENDIENTE
                                            </a>

                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='verificar'" :class="(statusVerificar(row.productos_cotizaciones)=='APROBADA'?'bg-green-500':'bg-blue-400')+'  w-1/6 min-w-[160px] '+class_general+' border-['+'#E8E8E8'+']'">

                                            <span @click="$parent.verCotizacion(row)" class=" w-12 h-12  font-extrabold text-black cursor-pointer text-sm   text-center  items-center">
                                                {{statusVerificar(row.productos_cotizaciones)}}
                                            </span>

                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='ver_cotizacion'" :class="getCellClass(row_col, index_col)">

                                            <span @click="$parent.verCotizacion(row)" class=" w-12 h-12  font-extrabold text-blue-700 cursor-pointer text-sm px-3 rounded-lg py-1  text-center  items-center">
                                                {{row_col.name}}
                                            </span>
                                        </td>

                                        <td v-else-if="row_col.show&&row_col.key=='fecha_ago'" :class="getCellClass(row_col, index_col)">

                                            {{formatDateEN(row.created_at)}}

                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='fecha_venta'" :class="getCellClass(row_col, index_col)">

                                            <span>
                                                {{ row[row_col.key] }}
                                            </span>

                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='fecha_creacion'" :class="getCellClass(row_col, index_col)">

                                            <span>
                                                {{formatDateEN(row.created_at)}}
                                            </span>

                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='fecha_pedido'" :class="getCellClass(row_col, index_col)">
                                            <span v-if="row.fecha_pedido==null&&row.status==3" @click="$parent.setFechaPedido(row)" class="  cursor-pointer font-extrabold  text-xs px-3 rounded-lg py-1  text-center  items-center text-blue-600">
                                                Asignar fecha
                                            </span>
                                            <span v-if="row.fecha_pedido!=null&&row.fecha_pedido!=''">
                                                {{formatDateEN(row.fecha_pedido)}}
                                            </span>

                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='fecha_pago'" :class="getCellClass(row_col, index_col)">

                                            <span v-if="row.fecha_pago==null" @click="$parent.setFechaPago(row)" class="  cursor-pointer font-extrabold  text-xs px-3 rounded-lg py-1  text-center  items-center text-blue-600">
                                                Asignar fecha
                                            </span>
                                            <span v-if="row.fecha_pago!=null&&row.fecha_pago!=''">
                                                {{formatDateEN(row.fecha_pago)}}
                                            </span>

                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='fecha_llegada'" :class="getCellClass(row_col, index_col)">
                                            <span v-if="row.fecha_llegada==null&&row.status==2" @click="$parent.setFechaLLegada(row)" class="  cursor-pointer font-extrabold  text-xs px-3 rounded-lg py-1  text-center  items-center text-blue-600">
                                                Asignar fecha
                                            </span>
                                            <span v-if="row.fecha_llegada!=null&&row.fecha_llegada!=''">
                                                {{formatDateEN(row.fecha_llegada)}}
                                            </span>

                                        </td>

                                        <td v-else-if="row_col.show&&row_col.key=='fecha_abono'" :class="getCellClass(row_col, index_col)">

                                            <div v-html="getFechaAbono(row.abonos)"></div>

                                        </td>

                                        <td v-else-if="row_col.show&&row_col.key=='sucursal'" :class="getCellClass(row_col, index_col)">
                                            <span class="select-all  w-12 h-12  font-extrabold text-base px-3 rounded-lg py-1  text-center  items-center" :style="'color:'+row.sucursales.color">
                                                {{row.sucursales.nombre}}
                                            </span>
                                        </td>

                                        <td v-else-if="row_col.show&&row_col.key=='descargar_cotizacion'" :class="getCellClass(row_col, index_col)">
                                            <span @click="$parent.onCapture(false,.7)" class="  cursor-pointer font-extrabold  text-3xl px-3 rounded-lg py-1  text-center  items-center text-blue-600">
                                                <i class="las la-file-download"></i>
                                            </span>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='crear_cita'" :class="getCellClass(row_col, index_col)">
                                            <a :href="'/app/calendario/citas/ventas?idc='+row.id+'&tv=add'" class=" cursor-pointer  text-3xl  font-extrabold px-3 rounded-lg py-1  text-center  items-center text-green-600">
                                                <i class="las la-calendar-plus"></i>
                                            </a>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='enviar_whatsapp'" :class="getCellClass(row_col, index_col)">
                                            <span @click="enviarWhatsapp(row.id)" class=" cursor-pointer   font-extrabold  text-3xl px-3 rounded-lg py-1  text-center  items-center text-green-500">
                                                <i class="lab la-whatsapp"></i>
                                            </span>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='ver_citas'" :class="getCellClass(row_col, index_col)">
                                            <span @click="$parent.viewCitas(row)" class=" cursor-pointer    font-extrabold  text-3xl px-3 rounded-lg py-1  text-center  items-center text-blue-500">
                                                <i class="las la-calendar-day"></i>
                                            </span>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='editar_cot'" :class="getCellClass(row_col, index_col)">
                                            <a v-if="row.status_verificar!='APROBADA'" target="_blank" :href="'/app/nueva/cotizacion?select='+row.id" class=" cursor-pointer    font-extrabold  text-3xl px-3 rounded-lg py-1  text-center  items-center text-blue-500">
                                                <i class="las la-pen-square"></i>
                                            </a>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='cancelar_pedido'" :class="'cursor-pointer bg-red-500 w-1/6 min-w-[160px] '+class_general+' border-['+'#E8E8E8'+']'">

                                            <span v-if="row.venta!=null&&row.venta!=''" @click="CancelarVenta(row.id)" class="  text-xs font-extrabold text-black ">Cancelar pedido</span>

                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='generar_garantia'" :class="'cursor-pointer bg-yellow-500 w-1/6 min-w-[160px] '+class_general+' border-['+'#E8E8E8'+']'">

                                            <a class="text-black  px-3 uppercase rounded-lg py-1  text-centerflex items-center" :href="geturlGarantia(row)">
                                                {{ row_col.name }}
                                            </a>
                                        </td>

                                        <td v-else-if="row_col.show&&row_col.key=='productos'" :class="getCellClass(row_col, index_col)">
                                            <span class="select-all  w-12 h-12  font-extrabold text-xs px-3 rounded-lg py-1  text-center  items-center">
                                                {{row.productos.nombre}}
                                            </span>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='status_compra'" :class="getCellClass(row_col, index_col)">
                                            <span class="select-all   h-12 uppercase text-blue-500  font-extrabold text-xs px-3 rounded-lg py-1  text-center  items-center">
                                                {{row.status}}
                                            </span>
                                        </td>

                                        <td v-else-if="row_col.show&&row_col.key=='unidades'" :class="getCellClass(row_col, index_col)">
                                            <span class="select-all  w-12 h-12  font-extrabold text-xs px-3 rounded-lg py-1  text-center  items-center">
                                                {{row.unidades.nombre}}
                                            </span>
                                        </td>

                                        <td v-else-if="row_col.show&&row_col.key=='colores_productos'" :class="getCellClass(row_col, index_col)">
                                            <span class="select-all  w-12 h-12  font-extrabold text-xs px-3 rounded-lg py-1  text-center  items-center">
                                                {{row.colores_productos.nombre}}
                                            </span>
                                        </td>

                                        <td v-else-if="row_col.show&&row_col.key=='status_verificar'" :class="(statusVerificar(row.productos_cotizaciones)=='APROBADA'?'bg-green-500':'bg-blue-400')+' w-1/6 min-w-[160px] '+class_general+' border-['+'#E8E8E8'+']  '">
                                            <a class="font-extrabold text-center cursor-pointer text-black items-center " :target="'_blank'" :href="statusVerificar(row.productos_cotizaciones)!='APROBADA'?'/app/detalle/aprobar?cid='+row.id:'#'">
                                                {{statusVerificar(row.productos_cotizaciones)}}
                                            </a>

                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='status_instalacion'" :class="colorInstalacion(getStatusInstalacion(row))+' w-1/6 min-w-[160px] '+class_general+' border-['+'#E8E8E8'+']'" @click="$parent.selStatusInstalacion(row)">
                                            <span class="select-all    cursor-pointer  text-center  items-center">
                                                {{ getStatusInstalacion(row) }}

                                            </span>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='status_produccion'" :class="colorProduccion(verificarStatusProduccionMarbel(row))+'  w-1/6 min-w-[160px] '+class_general+' border-['+'#E8E8E8'+']'" @click="$parent.accionProduccion(row)">

                                            {{ verificarStatusProduccionMarbel(row) }}

                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='status_revisar'" :class="colorProduccion(row.status_revisar)+'text-black  w-1/6 min-w-[160px] '+class_general+' border-['+'#E8E8E8'+']'" @click="$parent.accionProduccion(row)">

                                            {{ row.status_revisar }} 

                                        </td>

                                        <td v-else-if="row_col.show&&row_col.key=='saldo'" :class="(Number(calcularPendiente(row)).toFixed(0)==0?'bg-green-500':'bg-red-500')+' w-1/6 min-w-[160px] '+class_general+' border-['+'#E8E8E8'+']'">
                                            <a v-if="row.venta!=''||row.venta!=null" @click="$parent.verAbonos(row)" class="text-black font-extrabold     text-center  items-center">
                                                ${{formatPrice( calcularPendiente(row) )}}
                                            </a>
                                            <a v-else class=" text-black extrabold">
                                                N/A
                                            </a>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='total_con_descuento'" :class="' bg-green-500 w-1/6 min-w-[160px] '+class_general+' border-['+'#E8E8E8'+']'">
                                            <span class="select-all text-black font-extrabold   text-center  items-center">
                                                ${{formatPrice( Number(row.total)-Number(row.descuento)-Number(row.descuento_personalizado))}}
                                            </span>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='total'" :class="' bg-green-500 w-1/6 min-w-[160px] '+class_general+' border-['+'#E8E8E8'+']'">
                                            <span class="select-all text-black font-extrabold   text-center  items-center">
                                                ${{formatPrice( Number(row.total) )}}
                                            </span>
                                        </td>

                                        <td v-else-if="row_col.show&&row_col.key=='descuento'" :class="' bg-yellow-500 w-1/6 min-w-[160px] '+class_general+' border-['+'#E8E8E8'+']'">
                                            <span class="select-all text-black font-extrabold   text-center  items-center">
                                                ${{formatPrice( Number(row.descuento)+Number(row.descuento_personalizado) )}}
                                            </span>
                                        </td>

                                        <td v-else-if="row_col.show&&(row_col.key=='tarjeta_debito' )" :class="getCellClass(row_col, index_col)">
                                            <span class="select-all text-black bg-yellow-500 px-3 rounded-lg py-1  text-xs font-bold  text-center  items-center" @click="$parent.addVariante(row)">

                                                ${{ formatPrice(Number(row.tarjeta_debito)) }}
                                            </span>
                                        </td>
                                        <td v-else-if="row_col.show&&(row_col.key=='efectivo')" :class="getCellClass(row_col, index_col)">
                                            <span class="select-all text-black bg-yellow-500 px-3 rounded-lg py-1  text-xs font-bold text-center  items-center" @click="$parent.addVariante(row)">
                                                ${{ formatPrice(Number(row.efectivo)) }}
                                            </span>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='ordenes'" :class="getColorCompras(getStatusOrdenesCompra(row.productos_ordenes,row.compras_finalizadas))+' text-black  font-extrabold uppercase   w-1/6 min-w-[160px] '+class_general+' border-['+'#E8E8E8'+']'" @click="$parent.selOrdenes(row)">

                                            {{getStatusOrdenesCompra(row.productos_ordenes,row.compras_finalizadas) }}

                                        </td>
                                        <td v-else-if="row_col.show&&(row_col.key=='tarjeta_credito' )" :class="getCellClass(row_col, index_col)">
                                            <span class="select-all text-black bg-yellow-500 px-3 rounded-lg py-1  text-xs font-bold  text-center  items-center" @click="$parent.addVariante(row)">
                                                ${{ formatPrice(Number(row.tarjeta_credito)) }}
                                            </span>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='info'" :class="'text-white bg-pink-500 w-1/6 min-w-[160px] '+class_general+' border-['+'#E8E8E8'+']'" @click="$parent.infoModal(row)">

                                            Informacion

                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='eliminar_cotizacion'" :class="getCellClass(row_col, index_col)">
                                            <span v-if="row.venta==''||row.venta==null" class="text-white bg-red-700 px-3 rounded-lg py-1  text-centerflex items-center" @click="showconfirm=true;valor=row.id">
                                                Eliminar
                                            </span>
                                            <a v-else class="text-black bg-green-500 px-3 rounded-lg py-1  text-base text-centerflex items-center" :href="'/app/detalle/ventas?cid='+row.id">
                                                <i class="las la-coins"></i>
                                            </a>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='eliminar'" :class="getCellClass(row_col, index_col)">
                                            <span class="select-all text-white bg-red-700 px-3 rounded-lg py-1  text-centerflex items-center" @click="showconfirm=true;valor=row.id">
                                                Eliminar
                                            </span>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='edit'" :class="getCellClass(row_col, index_col)">
                                            <span class="select-all text-white bg-blue-700 px-3 rounded-lg py-1  text-centerflex items-center" @click="$parent.edit(row)">
                                                Editar
                                            </span>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='edit_orden'" :class="getCellClass(row_col, index_col)">
                                            <a class="text-white bg-blue-700 px-3 rounded-lg py-1  text-centerflex items-center" :href="'/app/ordenes/nueva?id='+row.id">
                                                Editar
                                            </a>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='edit_produccion'" :class="' '+(row.status_verificar=='PENDIENTE DE REVISIÓN'?'bg-pink-500':(((Number(calcularPendiente(row))>(Number(row.total)/2)))?' bg-yellow-500':'bg-red-500'))+'  w-1/6 min-w-[160px] '+class_general+' border-['+'#E8E8E8'+']'">

                                            <!--color rojo si lo pendiente no excede 50}%-->
                                            <!--color amarillo si excede el 50%-->
                                            <a v-if="(row.status_verificar==''||row.status_verificar==null)" class="text-black  font-extrabold text-center uppercase items-center" :href="'/app/nueva/cotizacion?select='+row.id+'&a=produccion'">
                                                Enviar a formato
                                            </a>
                                            <a v-if="(Number(calcularPendiente(row))<(Number(row.total)/2))&&(row.status_verificar==''||row.status_verificar==null)" class="text-black  font-extrabold text-center uppercase items-center" :href="'/app/nueva/cotizacion?select='+row.id+'&a=produccion'">
                                                PENDIENTE DE PAGO
                                            </a>
                                            <a v-if="row.status_verificar=='PENDIENTE DE REVISIÓN'" class="text-black  font-extrabold text-center uppercase items-center" :href="'/app/nueva/cotizacion?select='+row.id+'&a=produccion_edit'">
                                                {{ row_col.name }}
                                            </a>
                                        </td>
                                        <td v-else-if="row_col.show&&row_col.key=='citas'" :class="' bg-blue-400 w-1/6 min-w-[160px] '+class_general+' border-['+'#E8E8E8'+']'">
                                            <a class="text-white  font-extrabold flex-wrap text-center  items-center" @click="$parent.viewCitas(row)">
                                                {{ row.citas.length}} - {{ row_col.name }}
                                            </a>
                                        </td>

                                        <td v-else v-show="row_col.show&&!row_col.hasOwnProperty('children')" :class="' bg-'+(impar(index_col))+'   '+class_general+' border-['+'#E8E8E8'+']'+' '+row_col.class ">
                                            <span class="select-all text-gray-700   text-center w-full px-2 flex items-center ">
                                                {{ row[row_col.key] }}
                                            </span>
                                        </td>
                                    </template>
                                </tr>

                            </template>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</section>
</template>

<script>
//@ts-ignore
import generales from '@/functions/generales'
import fun_cotizacion from '@/functions/cotizaciones'
//import palabras from "@/mixins/palabras";
export default {
    name: "Home",
    components: {

    },
    mixins: [generales, fun_cotizacion],
    props: {

        showcols: {
            type: Boolean,
            default: true
        },
        coloresparam: {
            type: Array,

        },
        rows: {
            type: Array,

        },
        columns: {
            type: Array,

        },
        filtros: {
            type: Array,

        },
        page: {
            type: Object,

        },
        buscarp: {
            type: String,
            default: ''
        },
        buscador: {
            type: Boolean,
            default: true
        },
        refr: {
            type: Boolean,
            default: true
        },
        info: {
            type: Boolean,
            default: false
        },
        add: {
            type: Boolean,
            default: true
        },
        filtroApi: {
            type: Boolean,
            default: false
        },
        paginate: {
            type: Boolean,
            default: false
        },

    },
    data() {
        return {

            metodos: ['nada',
                'Efectivo',
                'T. Crédito',
                'T. Débito'
            ],

            showconfirm: false,
            valor: 0,
            mensajeconfirm: '',
            buscar: '',
            buscarapi: '',
            show: false,
            open: false,
            class_generalold: ' font-sans  normal-case   text-xs     border-b border-l ' + (this.IsMov() ? ' text-left px-1 flex flex-wrap content-center uppercase justify-end h-12 ' : ' px-3 py-3 align-middle  h-6'),
            class_general: ' font-sans  normal-case   text-xs     border-b border-l px-3 py-3 align-middle  h-6'

        }
    },

    mounted() {

        console.log('ss-----')
        console.log(this.columns)
        //this.formatrow();
        this.show = true;
        console.log(this.buscarp)
        if (this.buscarp != 'all') {
            console.log('entre--', this.buscarp)
            this.buscar = this.buscarp;
            this.buscarapi = this.buscarp;
        }

    },
    methods: {
        descargar() {
            this.table.download("string", "data.txt");
        },
        getCellClass(row_col, index_col) {
            const classes = [
                'bg-' + (this.impar(index_col)),
                'w-1/6 select-all',
                'min-w-[160px]',
                this.class_general,
                'border-[' + '#E8E8E8' + ']'
            ];

            if (row_col.key === 'files') {
                classes.push('cell-files');
            }

            return classes.join(' ');
        },

        getColorCompras(status) {
            switch (status) {
                case 'FINALIZADAS':
                    return 'bg-green-600'
                    break;
                case 'PENDIENTE':
                    return 'bg-yellow-700'
                    break;
                case 'SIN COMPRAS':
                    return 'bg-red-500'
                    break;

                default:
                    break;
            }
        },
        getStatusOrdenesCompra(ordenes, status_compras) {
            if (ordenes.length > 0 && status_compras == 0) {
                return 'PENDIENTE'

            } else if (status_compras == 1) {
                return 'FINALIZADAS'

            } else {
                return 'SIN COMPRAS'

            }
        },

        verificarStatusProduccionMarbel(row) {
            let impresos = 0;
            let aprobados = 0;
            let total = 0;
            let cantidad = 0;
            let produccion = 0;
            row.productos_cotizaciones.forEach(element => {
                if (element.formato_impreso) {
                    impresos++;
                }
                if (element.formato_aprobado) {
                    aprobados++;
                }
                cantidad = cantidad + Number(element.cantidad)
                produccion = produccion + Number(element.produccion)
                total++;

            });

            if (aprobados == impresos) {
                if (aprobados == total) {
                    if (produccion == cantidad) {
                        //se aprobaron todos los formato y se ha producido todo
                        return 'TERMINADO'
                    } else {
                        //se aprobo el formato pero no se ha producido todo
                        return 'EN PRODUCCION'
                    }

                } else {
                    if (aprobados > 0) {
                        return (impresos) + '/' + total + ' - POR APLICAR'
                        //return   (total-impresos)+'/'+total+' - PENDIENTES DE REVISAR'
                    } else {
                        //nunca pasa
                        return 'PENDIENTE'
                    }
                    //se ha aprobado formato pero no todos

                }
            } else {
                if (impresos == 0) {
                    return 'SIN REVISAR';
                } else
                if (aprobados > 0) {
                    return (impresos) + '/' + total + ' - POR APLICAR'
                } else {
                    //se a aprobado formato pero no todos
                    return 'PENDIENTE'
                }

            }
            //row.status_produccion==null||row.status_produccion==''?'PENDIENTE':row.status_produccion

        },
        getStatusInstalacion(row) {
            let count = 0;
            row.citas.forEach(element => {
                if (element.tipo == 'instalacion') {
                    count++
                }
            });

            if (count > 0) {
                if (row.avance_instalacion > 0) {
                    if (row.instalacion_finalizada) {
                        return 'FINALIZADA'
                    } else {
                        return 'AVANCE DE ' + row.avance_instalacion;
                    }
                } else {
                    return 'EN PROGRESO';
                }

            } else {
                return 'SIN PROGRAMAR'
            }
        },
        IsMov() {

            //return true;
            return this.$isMobile()
        },
        getFechaAbono(abonos) {

            if (abonos.length > 0) {
                return this.formatDateEN(abonos[0].created_at) + '<br>' + abonos[0].tipo_pago
            } else {
                return 'PENDIENTE';
            }

        },
        colorProduccion(status) {
            switch (status) {

                case 'EN PRODUCCION':
                    return 'bg-yellow-400 text-black font-extrabold';
                    break;
                case 'TERMINADO':
                    return 'bg-green-500 text-black font-extrabold';
                    break;
                    case 'PENDIENTE':
                    return 'bg-yellow-500 text-black font-extrabold';
                    break;
                case 'SIN REVISAR':
                    return 'bg-red-500 text-black font-extrabold';
                    break;

                default:
                    return 'bg-blue-400 text-black font-extrabold';
                    break;
            }
        },
        colorInstalacion(status) {
            switch (status) {

                case 'INCOMPLETO':
                    return 'bg-orange-500 text-black font-extrabold';
                    break;
                case 'INSTALADO':
                    return 'bg-green-500 text-black font-extrabold';
                    break;
                case 'SIN PROGRAMAR':
                    return 'bg-red-500 text-black font-extrabold';
                    break;
                case 'EN PROGRESO':
                    return 'bg-yellow-600 text-black font-extrabold';
                    break;

                default:
                    return 'bg-red-500 text-black font-extrabold';
                    break;
            }
        },
        geturlGarantia(row) {
            if (row.garantia != null && row.garantia != '') {
                return '/app/nueva/cotizacion?garantia_de=' + row.garantia + '&clid=' + row.clientes_id
            } else {
                return '/app/nueva/cotizacion?garantia_de=' + row.id + '&clid=' + row.clientes_id
            }

        },
        statusVerificar(row) {
            let total = 0;
            let max = 0;
            row.forEach(element => {
                max++;
                (!element.formato_aprobado) ? total++ : null;
            });
            if (total) {
                return total + ' de ' + max + ' por aplicar'
            } else {
                return 'APROBADA'
            }

        },

        impar(numero) {
            //?'':'white'
            if (numero % 2 == 0)

            {

                return 'gray-400 bg-opacity-10';

            } else {
                return 'white';
            }

        },
        colores(row) {
            let color = 'bg-gray-100';
            console.log(this.coloresparam)
            if (this.coloresparam != null && this.coloresparam.length > 0) {
                //let parametro=this.coloresparam[0];
                let casos = this.coloresparam;

                casos.forEach(element => {

                    if (this.CalcExistencias(row) <= element.precio) {
                        color = element.color;
                    }
                });

                return color;

            } else {
                return color;
            }
        },
        CalcExistencias(row) {
            let total = 0;
            let total_vendidos = 0;
            row.existencias.forEach(element => {
                total = Number(total) + element.cantidad
            });
            row.detalle_ventas.forEach(element => {
                total_vendidos = Number(total_vendidos) + element.cantidad
            });
            return total - total_vendidos
        },

        getColumnsContain(key) {
            let c = 0;
            this.columns.forEach(element => {
                if (key.includes(element.key)) {
                    c = c + 1
                }
            });
            if (c > 0) {
                return true
            } else {
                return false;
            }
        },
        getColumns() {
            let col = 0;
            this.columns.forEach(element => {
                if (element.show && !element.hasOwnProperty('children')) {
                    col = col + 1
                }
            });
            return col;
        },
        verificarTipo(tipo) {
            switch (tipo) {
                case 'distancia_permitir':
                    return 'Permite estas Cordenadas'
                    break;
                case 'distancia_bloquear':
                    return 'Bloquea estas Cordenadas'
                    break;
                case 'bloqueo_hora':
                    return 'Bloquea por horas'
                    break;

                default:
                    break;
            }
        },
        buscadorpropiedades(row) {
            if (this.filtroApi) {
                return true;
            }
            let mostrar = 0;

            if (this.buscador == false) {
                return true;
            }
            if (this.buscar == '') {
                return true;
            }

            /*
             Array(this.filtros).forEach(element => {
             
                     //se busca por esta columna
                     if (this.columns[element].search(this.buscar) != -1) {
                         //si contiene si muestra
                     } else {
                         //no contiene no muestra
                         ocultar =ocultar+ 1;
                     }
               
             });*/
            this.columns.forEach(element => {

                if ((this.filtros).includes(element.key)) {
                    //console.log(' filtrar',element.key,row[element.key])
                    //console.log(String(row[element.key]))
                    //se busca por esta columna

                    if (String(row[element.key]).toUpperCase().search(String(this.buscar).toUpperCase()) != -1) {
                        //si contiene si muestra
                        mostrar = mostrar + 1;
                        // console.log('si muestra',this.buscar,row[element.data])
                    } else {
                        //console.log('no muestra',this.buscar,row[element.data])
                        //no contiene no muestra

                    }
                } else {
                    //console.log('no filtrar',[element.data],this.filtros)
                    //no se busca por esta columna
                }
            });
            if (mostrar > 0) {
                return true
            } else {
                return false;
            }

        },
        formatDat(date) {
            var options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            };
            let dat = new Date(date);
            return dat.toLocaleDateString("es-ES", options)
        },
        getExistTag(prop) {
            let valores = this.columns.filter((row) => row.key == prop)
            if (valores.length > 0) {
                return true
            } else {
                return false;
            }
        },
        formatrow() {
            let data = [];
            let labels = this.es.table;
            //console.log(labels);
            this.columns.forEach(element => {

                data.push({
                    label: labels[element.tag],
                    field: element.data,
                    filterOptions: {
                        enabled: element.active, // enable filter for this column
                        placeholder: 'Buscar', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        //filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        //filterFn: this.columnFilterFn, //custom filter function that
                        trigger: 'keyup', //only trigger on enter not on keyup 
                    }
                });

            })
            return data;
        }
    }
}
</script>

<style>
#journal-scroll::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;
    /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/

}

#journal-scroll::-webkit-scrollbar-track {
    background-color: rgba(229, 231, 235, var(--bg-opacity));
    cursor: pointer;
    /*background: red;*/
}

#journal-scroll::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #a0aec0;
    /*outline: 1px solid slategrey;*/
}

.container {
    width: 100%;
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 1536px) {
    .container {
        max-width: 1536px;
    }
}

.table-auto {
    table-layout: auto;
}
</style>
