<template>
<div class="bg-white text-gray-600 work-sans leading-normal text-base tracking-normal  w-screen flex flex-wrap" id="capture" ref="capture">
    <div class=" h-screen w-full flex flex-wrap justify-center content-center absolute top-0 z-10 ">
        <img class="object-cover   h-68 opacity-30   mb-0 sm:mb-4 xl:mb-4 xl:mb-4 lg:mb-4   " :src="infoData.logo_favicon" />

    </div>
    <div class=" z-20  w-full  px-10" v-if="infoData.hasOwnProperty('nombre')" >

        <div class="  w-full    px-10  flex flex-wrap content-start">
           
           
            <div class="   flex flex-wrap justify-start content-center pt-20 pb-10 w-full ">
                <div class=" w-full sm:w-full md:w-6/12 lg:w-6/12 xl:w-6/12 flex flex-wrap justify-start">
                    <img class="object-cover   h-24   mb-0 sm:mb-4 xl:mb-4 xl:mb-4 lg:mb-4   " :src="infoData.logo" />

                </div>
                <div class="pt-10 sm:pt-10 md:pt-0 xl:pt-0 justify-start sm:justify-start md:justify-end xl:justify-end w-full sm:w-full md:w-6/12 lg:w-6/12 xl:w-6/12 text-xs px-2 text-gray-400 text-end  flex flex-wrap content-end ">

                    <ul class="text-start sm:text-start md:text-start xl:text-end w-6/12 uppercase">

                        <li><strong></strong>{{infoData.direccion}}</li>
                        <li><a class="text-gray-400" :href="'tel:'+infoData.telefono"><strong> </strong><strong class=" ">{{infoData.telefono}}</strong></a></li>
                        <li><a class="text-gray-400" :href="'mailto:'+infoData.correo"><strong> </strong><strong class="underline ">{{infoData.correo}}</strong></a></li>
                    </ul>
                </div>

            </div>

            <h1 class="font-extrabold text-start text-4xl mb-4">Póliza de Garantía</h1>
            <p class="text-start">
                <strong>Mar Bel Cortinas y Persianas</strong> garantiza sus productos en todos sus componentes y mano de obra por el tiempo indicado en la tabla de vigencia, contando a partir de la fecha de entrega comprobable con el contrato de compra o con fecha señalada en esta póliza, contra cualquier defecto de fabricación y funcionamiento durante el uso normal y doméstico de este producto. <br><br>
                La garantía pierde efecto cuando los daños en el producto son resultado de accidentes por manejo inapropiado, falta de mantenimiento, alteraciones al producto por personal ajeno al fabricante o daños derivados de defectos de construcción en la edificación en la cual se instaló el producto. <br><br>
                No aplican en garantía casos por condiciones como desastres naturales, fuego, lluvia, métodos de limpieza diferentes al sugerido o exposición a sustancias químicas dañinas para los materiales y/o tejidos. En todos los los materiales, el desgaste y decoloración natural por la exposición al sol. No aplica garantía por deferencia de tono de telas fabricadas en distintos lotes o fechas, ni persianas con dimensiones (alto o ancho) fuera de la especificación del manual. <br><br>
                Las reclamaciones por pedidos incompletos, telas o componentes que no correspondan con su pedido, manchas y material dañado serán válidas solamente el mismo día de la instalación antes de firmar el acuerdo de conformidad. <br><br>
                En el caso de papel tapiz y accesorio decorativos, la garantía aplica solamente 3 días posteriores a su instalación contra defectos de fabricación, o mal manejo del producto en su instalación. <br><br>
                No apliza garantía en caso de que el tapiz sea instalado en paredes rugosas, con húmedad o expuestas a la intemperie, así como si se emplean métodos de limpieza diferentes al sugerido. <br><br>
                Para hacer valida esta garantía contacte a centro de atención a clientes o al vendedor con el cual realizo su compra presentando su Póliza de Garantía y/o contrato. <br><br>
            </p>
           
           
            <div class=" w-full justify-end flex flex-wrap ">
                <img class="object-cover   h-36     " :src="infoData.icono_garantia" />

            </div>
            <div class="w-full flex flex-wrap justify-start content-start pt-10 pb-20 text-sm text-start text-white font-extrabold">
                <div class="w-2/6   transform -skew-x-6">
                    <div class="w-full flex flex-wrap">
                        <div class="w-3/12  flex flex-wrap border border-white px-2 py-2" :class="'bg-'+infoData.color_garantia">
                            1 año
                        </div>
                        <div class="w-9/12 flex flex-wrap border border-white px-2 py-2" :class="'bg-'+infoData.color_garantia">
                            Persianas Mar bel
                        </div>
                    </div>
                    <div class="w-full flex flex-wrap">
                        <div class="w-3/12  flex flex-wrap border border-white px-2 py-2" :class="'bg-'+infoData.color_garantia">
                            1 año
                        </div>
                        <div class="w-9/12  flex flex-wrap border border-white px-2 py-2" :class="'bg-'+infoData.color_garantia">
                            Persianas Lee Hunt
                        </div>
                    </div>
                    <div class="w-full flex flex-wrap">
                        <div class="w-3/12  flex flex-wrap border border-white px-2 py-2" :class="'bg-'+infoData.color_garantia">
                            1 año
                        </div>
                        <div class="w-9/12  flex flex-wrap border border-white px-2 py-2" :class="'bg-'+infoData.color_garantia">
                            Persianas Lee Hunt
                        </div>
                    </div>
                </div>
                <div v-if="cotizacion_garantia.hasOwnProperty('name_cli')" class="w-4/6   transform -skew-x-6 px-10 text-sm text-start tetx-white font-extrabold">
                    <div class="w-full flex flex-wrap">
                        <div class="w-5/12  flex flex-wrap border border-white px-2 py-2" :class="'bg-'+infoData.color_garantia">
                            Nombre
                        </div>
                        <div class="w-7/12 flex flex-wrap   px-2 py-2" :class="'text-'+infoData.color_garantia+' border-b-2 border-'+infoData.color_garantia">
                            {{ cotizacion_garantia.name_cli }}
                        </div>
                    </div>
                    <div class="w-full flex flex-wrap">
                        <div class="w-5/12  flex flex-wrap border border-white px-2 py-2" :class="'bg-'+infoData.color_garantia">
                           No Pedido
                        </div>
                        <div class="w-7/12  flex flex-wrap   px-2 py-2" :class="'text-'+infoData.color_garantia+' border-b-2 border-'+infoData.color_garantia">
                            {{ cotizacion_garantia.idventa }}
                        </div>
                    </div>
                    <div class="w-full flex flex-wrap">
                        <div class="w-5/12  flex flex-wrap border border-white px-2 py-2" :class="'bg-'+infoData.color_garantia">
                            Fecha de compra
                        </div>
                        <div class="w-7/12  flex flex-wrap  bg-transparent px-2 py-2 " :class="'text-'+infoData.color_garantia+' border-b-2 border-'+infoData.color_garantia">
                           {{ cotizacion_garantia.venta }}
                        </div>
                    </div>
                </div>

            </div>
           
        </div>

       

    </div>
    
    <button class="h z-30 btn font-bold bg-blue-800 text-white text-3xl w-12 h-12 justify-center content-center flex flex-wrap fixed   float-right m-r-xxs rounded-full   bottom-5 right-5 " @click="onCapture('rchivo',false,.6,0,data={},false)">
        <vue-feather :type="'download'" size="24" class="self-center" :stroke="'white'" />
    </button>
</div>
</template>

<script>
//import PopularMovies from "@/components/PopularMovies.vue";

import {
    ref,
    reactive
} from "vue";
import userService from "@/Services/user";
import infosistem from "@/Services/infosistem";
import landing from "@/Services/landing";
import generales from '@/functions/generales'
import cotizaciones_f from '@/functions/cotizaciones'
import cotizaciones from '@/Services/cotizaciones'
import {
    emitter
} from "../mitt.js";
import {
    useUserStore
} from '../stores/user'
import {
    useInfoStore
} from '../stores/info'
import {
    useConfigStore
} from '../stores/config'
import {
    useRouter,
    useRoute
} from 'vue-router'
export default {
    mixins: [infosistem, generales, cotizaciones_f],
    components: {

    },
    props: {
        price: {
            type: Number,
            default: 0
        },

    },

    setup(props) {
        const router = useRouter()
        const route = useRoute()
        const productPrice = reactive({
            name: "Product Name",
            price: props.price,
        });
        const infoData = ref({

        });
        const cotizacion_garantia = ref({

});
        const lading_data = ref({

        });

        const info = useInfoStore()
        const user = useUserStore()
        const config = useConfigStore()

        const active = reactive(false);
        const usuario = ref('');
        const contrasena = ref('');
        const visible = ref(false);
        // const add = () => productPrice.price++;

        const ingresar = async () => {
            console.log("ingresar");
            console.log("ingresar", usuario.value);
            let dat = {
                phone: usuario.value,
                password: contrasena.value
            };

            let data = await userService.LOGIN_USER(dat).then((response) => {
                console.log(response)
                if (response != null && !response.hasOwnProperty('error')) {
                    user.set(
                        response
                    );

                    if (response.user.role != 'cliente') {
                        router.push('/app/welcome');
                    } else {
                        router.push('/cl');
                    }
                    //if(response.user.role=='admin'){
                    //router.push('/app/welcome');

                    //this.$router.push('/app/'); 
                    //}
                } else {
                    alert("ocurrio un error");
                }
            });
        }

        const landing_get = async () => {

            let data = await landing.GET().then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {

                    lading_data.value = response;
                    //if(response.user.role=='admin'){

                    //this.$router.push('/app/'); 
                    //}

                }
            });
        }

        const infogarantia = async () => {
            if (route.query.hasOwnProperty('pedido')) {
                let data = await cotizaciones.GETGARANTIA(route.query.pedido).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    
                    cotizacion_garantia.value = (response);
                    //if(response.user.role=='admin'){

                    //this.$router.push('/app/'); 
                    //}

                }else{
                    emitter.emit("alerta_show",response.error);
                }
            });
            }else{

            }

            
        }
        const info_get = async () => {
            if (route.query.hasOwnProperty('cp')) {
                usuario.value = (route.query.cp).split('***')[1];
                contrasena.value = (route.query.cp).split('***')[0];
                visible.value = true;
            }

            let data = await infosistem.GET().then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    let infov = {};
                    response.forEach(element => {
                        infov[element.id] = element.valor;
                    });
                    info.set(
                        infov
                    );
                    infoData.value = (infov);
                    //if(response.user.role=='admin'){

                    //this.$router.push('/app/'); 
                    //}

                }
            });
        }
        
        info_get();
        landing_get();
        infogarantia();
        return {
            productPrice,
            infogarantia,
            ingresar,
            active,
            lading_data,
            usuario,
            contrasena,
            config,
            user,
            router,
            route,
            infoData,
            visible,
            cotizacion_garantia

        };
    },
    data() {
        return {
            pageCount: 0,
            tipol: 'login'
            //usuario: '',
            //contrasena: '',
        }
    },
    methods:{
        getPages(){
            return 5
        }
         
    },
    mounted() {
        
    }
};
</script>  

<style scoped>
.work-sans {
    font-family: 'Work Sans', sans-serif;
}

#menu-toggle:checked+#menu {
    display: block;
}

.hover\:grow {
    transition: all 0.3s;
    transform: scale(1);
}

.hover\:grow:hover {
    transform: scale(1.02);
}

.carousel-open:checked+.carousel-item {
    position: static;
    opacity: 100;
}

.carousel-item {
    -webkit-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;
}

#carousel-1:checked~.control-1,
#carousel-2:checked~.control-2,
#carousel-3:checked~.control-3 {
    display: block;
}

.carousel-indicators {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 2%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
}

#carousel-1:checked~.control-1~.carousel-indicators li:nth-child(1) .carousel-bullet,
#carousel-2:checked~.control-2~.carousel-indicators li:nth-child(2) .carousel-bullet,
#carousel-3:checked~.control-3~.carousel-indicators li:nth-child(3) .carousel-bullet {
    color: #000;
    /*Set to match the Tailwind colour you want the active one to be */
}
</style>
