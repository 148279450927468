<template>
  <section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-center content-start ">
      <div x-show="selectedRows.length" class="bg-teal-200 fixed top-0 left-0 right-0 z-40 w-full shadow" />
  
      <Tabla :columns="headings" :rows="data" :showcols="false" :filtros="['nombre','precio','meses','tipo']" />
      <el-drawer v-model="showM" title="" @closed="getData()" :with-header="false" :size="'50%'" class="flex flex-wrap justify-center">
        <div class="w-full flex flex-wrap h-full">

            <div  class="flex    h-full w-full" >
                <!--content-->

                <div class=" relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <!--header-->
                    <div class="flex items-start justify-between p-2 border-b border-solid border-blueGray-200 rounded-t">
                        <h3 class="text-xl font-semibold">
                            {{ id==0?'Crear Categoria':'Editar Categoria' }}
                        </h3>

                    </div>
                    <!--body-->
                    <div class="relative p-6 pt-2   overflow-y-scroll flex flex-wrap">
                        <div class="mb-2 mt-2  justify-start flex flex-wrap w-full px-2">
                          <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                              Nombre
                          </span>
                          <input id="cantidad" v-model="nombre" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="text" placeholder="Nombre">
                      </div>
                      <div class="mb-2 mt-2  justify-start flex flex-wrap w-full px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Nombre Corto
                        </span>
                        <input id="cantidad" v-model="nombre_corto" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="text" placeholder="Nombre Corto">
                    </div>
                      <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                          <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                              Descripción
                          </span>
                          <textarea id="cantidad" v-model="descripcion" class="text-sm appearance-none h-24 rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline " placeholder="Descripción" />
                          </div>
                         
  
                          <div  class="mb-1 mt-1  justify-start flex flex-wrap w-6/12 px-2 content-start">
                            <span v-if="imageUrl==''&&imageUrl==null" class=" py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                                Imagen
                            </span>
                            <button v-if="imageUrl==''||imageUrl==null" @click="onPickFile"  id="cantidad" class="text-xs w-full h-10 text-center bg-blue-500 py-1 px-2 font-extrabold text-white"  >Seleccionar imagen</button>
                            <div class="w-full px-2 py-2  border-2 border-blue-400 flex-wrap  justify-center content-center" v-if="imageUrl!=''&&imageUrl!=null">
                                <span class=" mb-2 bg-blue-500 text-white font-extrabold text-xs  left-2 top-2 z-40 px-4 rounded cursor-pointer" @click="imageUrl=''" >Cambiar imagen</span>
                                <img class="h-28 w-full items-center object-cover self-center" :src="imageUrl"/>
                            </div> 
                            <input type="file" style="display: none" ref="fileInput" accept="image/*" @change="onFileChange" />
                        </div>

                              
  
       
                      
                      
                     
                  </div>
                  <!--footer-->
                 
              </div>
          </div>
        </div>

        <template #footer>
            <div style="flex: auto">
            <!--<button class="text-red-500 bg-red-500 bg-opacity-20 border border-solid border-red-500 hover:bg-red-500 hover:bg-opacity-30 hover:text-red-900 active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="showM=false">
                              Cancelar
                          </button>
                          <button class="text-blue-800 bg-blue-500 bg-opacity-40 border hover:text-white  hover:bg-blue-700 border-solid border-blue-800 font-bold uppercase px-6 py-3 text-sm outline-none  rounded focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="guardar()">
                              Guardar
                          </button>-->


                          <btn_component :text="'Guardar'" :tipo="'success'"   @click="guardar()"></btn_component>
                          <btn_component :text="'Cerrar'" :tipo="'info'"   @click="showM=false"></btn_component>
          
            </div>
          </template>
        </el-drawer>
      <div v-if="actionModal!=null" style="z-index:9999900" class="bg-slate-800 bg-opacity-50  h-screen w-full flex content-center justify-center items-center fixed  top-0 right-0 bottom-0 left-0">
          <div class=" bg-white w-72 h-72 self-center  flex-wrap px-5 py-14 rounded-md text-start flex content-center justify-center items-center">
              <div class="text-base mb-4 font-bold text-slate-500">
                  ¿Qué desea hacer?
              </div>
              <div class="flex-wrap flex justify-between  w-full">
                  <div class="w-3/6 px-2">
                      <!--<button class="w-full bg-blue-500 bg-opacity-20 px-4 py-2 rounded-md text-md text-blue-500 border-2 border-blue-500" @click="edit(actionModal);">
                          Editar
                      </button>-->
                      <btn_component :text="'Editar'" :tipo="'info'"   @click="edit(actionModal);"></btn_component>
                  </div>
                  <div class="w-3/6 px-2">
                      <!--<button class="bg-red-500 px-4 py-2 bg-opacity-20 rounded-md text-md text-red-500 w-full  border-2 border-red-500" @click="eliminar(actionModal.id)">
                          Eliminar
                      </button>-->
                      <btn_component :text="'Eliminar'" :tipo="'danger'"   @click="eliminar(actionModal.id)"></btn_component>
                  </div>
              </div>
          </div>
      </div>
  </section>
  </template>
  
  <script>
  import {
      emitter
  } from "../../../mitt.js";
  import {
      useUserStore
  } from '../../../stores/user'
  import categorias from "@/Services/categorias";
  
  import Tabla from "@/components/Tabla.vue";
  export default {
      components: {
          Tabla
      },
      mixins: [categorias],
      data() {
          const user = useUserStore()
  
          return {
              headings: [
  
                  {
                      key: 'id',
                      name: '#',
                      show: true
                  },
                  {
                      key: 'nombre',
                      name: 'Nombre',
                      show: true
                  },
                  {
                      key: 'nombre_corto',
                      name: 'Nombre Corto',
                      show: true
                  },
                  {
                      key: 'descripcion',
                      name: 'Descripción',
                      show: true
                  },
                  
                  {
                      key: 'url',
                      name: 'Foto',
                      show: true,
  
                  },
                 
                  {
                      key: 'edit',
                      name: 'Editar',
                      show: true
                  },
                  {
                      key: 'eliminar',
                      name: 'Eliminar',
                      show: true
                  }
              ],
              buscar: '',
              open: false,
              user: user.getAll(),
              data: [],
              actionModal: null,
              showM: false,
              id: 0,
              data_file: null,
              imageUrl: '',
              nombre: '',
              nombre_corto: '',
              descripcion: '',
  
              telefono: '',
              especializacion: '',
              email: '',
              url: '',
              color: 'rgba(255,30,100,1)',
  
          }
  
      },
      mounted() {
  
      },
      created() {
          this.getData()
      },
      methods: {
          
          addRow() {
              this.showM = !this.showM;
              this.limpiar()
          },
          async onFileChange(e) {
              var files = e.target.files || e.dataTransfer.files;
              if (!files.length) return;
  
              const fileReader = new FileReader()
              fileReader.addEventListener('load', () => {
                  this.imageUrl = fileReader.result
              })
              fileReader.readAsDataURL(files[0])
              this.data_file = files[0];
              console.log(e)
  
          },
          onPickFile() {
              this.$refs.fileInput.click()
          },
          limpiar() {
  
              this.id = 0;
              this.nombre = '';
              this.nombre_corto = '';
              this.descripcion = '';
              this.imageUrl = '';
  
  
          },
          edit(row) {
              this.id = row.id;
              this.nombre = row.nombre;
              this.nombre_corto = row.nombre_corto;
              this.descripcion = row.descripcion;
              this.imageUrl = row.url;
  
  
              this.showM = true;
              this.actionModal = null;
          },
          async getData() {
              this.showM = false;
              this.actionModal = null;
              emitter.emit("loading", true)
  
              await categorias.GET(this.user.token).then(response => {
  
                  if (response != null && !response.hasOwnProperty('error')) {
  
                      this.data = response
                      //this.$router.push('/app/'); 
                  } else {
  
                      // emitter.emit("alerta_show",response.msg);
  
                  }
                  emitter.emit("loading", false)
              });
          },
          async guardar() {
  
              var formData = new FormData();
  
              formData.append("doc", this.data_file, );
              formData.append("id", this.id, );
              formData.append("nombre", this.nombre, );
              formData.append("nombre_corto", this.nombre_corto, );
              formData.append("descripcion", this.descripcion, );
  
              let dat = {
                  id: this.id,
                  data: formData
              };
  
              if (this.id == 0) {
                  this.crear(dat)
              } else {
                  this.editar(dat)
              }
          },
          async editar(dat) {
              emitter.emit("loading", true)
  
              await categorias.EDITAR(dat).then(response => {
  
                  if (response != null && !response.hasOwnProperty('error')) {
  
                      this.getData();
                      //this.$router.push('/app/'); 
                  } else {
  
                      // emitter.emit("alerta_show",response.msg);
  
                  }
                  this.showM = false;
                  this.limpiar();
                  emitter.emit("loading", false)
              });
          },
          async crear(dat) {
              emitter.emit("loading", true)
  
              await categorias.CREAR(dat).then(response => {
  
                  if (response != null && !response.hasOwnProperty('error')) {
  
                      this.getData();
                      //this.$router.push('/app/'); 
                  } else {
  
                      // emitter.emit("alerta_show",response.msg);
  
                  }
                  this.showM = false;
                  this.limpiar();
                  emitter.emit("loading", false)
              });
          },
          async eliminar(id) {
              emitter.emit("loading", true)
              let dat = {
                  id: id,
  
              }
              await categorias.ELIMINAR(dat).then(response => {
  
                  if (response != null && !response.hasOwnProperty('error')) {
  
                      this.getData();
                      //this.$router.push('/app/'); 
                  } else {
  
                      // emitter.emit("alerta_show",response.msg);
  
                  }
                  this.actionModal = null
                  emitter.emit("loading", false)
              });
          },
  
      }
  }
  </script>
  