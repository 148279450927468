

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
 // GET_CLIENTES CREAR_CLIENTES EDITAR_CLIENTES ELIMINAR_CLIENTES
 ACTUALIZAR_PRODUCCION: async function( data) {
  let url='/cotizaciones/'+data.idcot+'/produccion/producto/'+data.idprod
  return initmixin.formatrespuesta('put', data,url,true)
},
SEPARAR: async function( data) {
  let url='/cotizaciones/'+data.idcot+'/separar/producto/'+data.id
  return initmixin.formatrespuesta('put', data,url,true)
},
ACTUALIZAR_INSTALADOS: async function( data) {
  let url='/cotizaciones/'+data.idcot+'/instalados/producto/'+data.idprod
  return initmixin.formatrespuesta('put', data,url,true)
},
ACTUALIZAR_FECHA_TENTATIVA: async function( data) {
  let url='/cotizaciones/'+data.idcot+'/instalados/producto/fecha_tentativa/'+data.idprod
  return initmixin.formatrespuesta('put', data,url,true)
},


    ASIGNAR_USUARIO_PRODUCTO: async function( data) {
      let url='/cotizaciones/'+data.idcot+'/produccion/usuario/producto/'+data.id
      return initmixin.formatrespuesta('put', data,url,true)
      },
      ASIGNAR_FECHA_PROMETIDA: async function( data) {
        let url='/producto_cotizaciones/fecha/prometida/'+data.id
        return initmixin.formatrespuesta('put', data,url,true)
      },
    ACEPTAR_FORMATO_PRODUCTO: async function( data) {
      let url='/cotizaciones/'+data.idcot+'/produccion/formato/producto/aceptar/'+data.id
      return initmixin.formatrespuesta('put', data,url,true)
    },
  
    


}