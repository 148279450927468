<template>
<section class="w-full mx-auto h-full px-4 sm:px-6 lg:px-4 py-4 bg-gray-100 content-start flex flex-wrap">

    <div class="w-full flex flex-wrap justify-start conten-start h-full">
        <div class="  w-full flex flex-wrap justify-start content-start h-2/6 py-2">
            <div class="bg-white rounded-lg p-12 flex flex-col justify-center content-start w-3/6">
                <h1 class="font-bold text-xl  font-heading text-gray-900">
                    Reporte nomina

                </h1>
                <h1 class="font-bold mt-2  font-heading text-gray-900 w-full flex flex-wrap justify-center content-center">
                    <select class="w-full bg-gray-100 my-1 rounded-md h-12 text-center " v-model="vendedor">
                        <option v-for="(row,i) in data_vendedores" :value="row.id" v-bind:key="i">{{row.name}}</option>
                    </select>
                    <div class="w-full flex flex-wrap justify-center content-center my-1">
                        <div class="w-6/12  flex flex-wrap justify-start">
                            <el-date-picker class="w-full" v-model="fechaInicio" type="date" placeholder="Fecha de inicio" :default-value="getDefaultStartDate"></el-date-picker>

                        </div>
                        <div class="w-6/12 flex flex-wrap justify-start">
                            <el-date-picker v-model="fechaFin" type="date" placeholder="Fecha de fin" :default-value="getDefaultEndDate"></el-date-picker>

                        </div>
                    </div>

                    <el-button class="w-full" type="primary" @click="applyDateRange">Aplicar</el-button>
                </h1>
            </div>

            <div class="w-3/6 p-2 h-full overflow-hidden">
               

            </div>
           

        </div>
        <div class=" w-full   flex flex-wrap  justify-start content-start h-4/6 py-2 space-x-1 bg-gray-100">

           

        </div>
    </div>

    <!-- Selector de fecha inicio y fecha fin -->

    <!-- Resto del contenido del componente -->
    <!-- <div v-if="data_reporte != null" :class="{'flex-row': !IsMov(), 'flex-row-reverse': IsMov()}" class="w-full h-12 bg-gray-100 flex flex-wrap content-center justify-between px-2">
        <div class="w-10/12" :class="{'justify-end': IsMov(), 'justify-center': !IsMov()}">
          <el-pagination v-model:currentPage="pagina" small background layout="prev, pager, next" :page-size="data_reporte['per_page']" :total="Number(data_reporte['total'])" @current-change="pageChangue" />
        </div>
      </div>-->

</section>
</template>

<script>
import {
    ref,
    onMounted,
    onBeforeUnmount
} from 'vue';
import {
    emitter
} from '@/mitt.js';
import {
    useUserStore
} from '@/stores/user';
import generales from '@/functions/generales';
import reportes from '@/Services/reportes';
import vendedores from '@/Services/user';
import DynamicChart from '@/components/charts/DynamicChart.vue';
import {
    filter
} from 'lodash';
import Tabla from "@/components/Tabla.vue";

export default {
    components: {
        //Tabla
    },
    mixins: [generales],
    setup() {
        const user = useUserStore();
        const key = ref(0);
        const openTab= ref(1)
        const buscar = ref('all');
        const pagina = ref(1);
        const fechaInicio = ref(getDefaultStartDate());
        const fechaFin = ref(getDefaultEndDate());
        const data_reporte = ref(null);
        const data_ventas = ref(null);
        const data_citas = ref(null);
        const data_garantias = ref(null);
        const vendedor = ref(null);

        const data_vendedores = ref(null)
        const headings_ventas = ref([

            {
                key: 'id',
                tipo: '',
                name: '# Cotización ',
                show: true,
                class: '!bg-blue-100 text-blue-500 font-extrabold w-[200px]'
            },

            {
                key: 'idventa',
                tipo: '',
                name: '# Pedido ',
                show: true,
                class: '!bg-blue-100 text-blue-500 font-extrabold w-[200px]'
            },

            {
                key: 'fecha_pedido',
                tipo: '',
                name: 'Fecha de pedido ',
                show: true,
                class: '!bg-blue-100 text-blue-500 font-extrabold w-[200px]'
            },
            {
                key: 'contacto',
                tipo: '',
                name: 'Cliente ',
                show: true,
                class: '!bg-blue-100 text-blue-500 font-extrabold w-[200px]'
            },

            {
                key: 'total',
                tipo: '',
                name: 'Total ',
                show: true,
                class: '!bg-blue-100 text-blue-500 font-extrabold w-[200px]'
            },
            {
                key: 'origen',
                tipo: '',
                name: 'Origen',
                show: true,
                class: '!bg-green-100 text-green-500 font-extrabold w-[200px]'
            },

        ])
        const headings_citas = ref([

{
    key: 'cotizaciones_id',
    tipo: '',
    name: '# Cotización ',
    show: true,
    class: '!bg-blue-100 text-blue-500 font-extrabold w-[200px]'
},

{
    key: 'idventa',
    tipo: '',
    name: '# Pedido ',
    show: true,
    class: '!bg-blue-100 text-blue-500 font-extrabold w-[200px]'
},
{
                key: 'cliente',
                tipo: '',
                name: 'Cliente ',
                show: true,
                class: '!bg-blue-100 text-blue-500 font-extrabold w-[200px]'
            },
{
    key: 'origen',
    tipo: '',
    name: 'Origen ',
    show: true,
    class: '!bg-blue-100 text-blue-500 font-extrabold w-[200px]'
},

{
    key: 'monto_cotizacion',
    tipo: 'format_price',
    name: 'monto_cotizacion ',
    show: true,
    class: '!bg-blue-100 text-blue-500 font-extrabold w-[200px]'
},
{
    key: 'monto_venta',
    tipo: 'format_price',
    name: 'monto_venta',
    show: true,
    class: '!bg-green-100 text-green-500 font-extrabold w-[200px]'
},
{
    key: 'fecha_inicio',
    tipo: '',
    name: 'fecha',
    show: true,
    class: '!bg-green-100 text-green-500 font-extrabold w-[200px]'
},

])

const headings_garantias = ref([

{
    key: 'id',
    tipo: '',
    name: '# Cotización Garantia ',
    show: true,
    class: '!bg-blue-100 text-blue-500 font-extrabold w-[200px]'
},
{
    key: 'garantia',
    tipo: '',
    name: '# Cotización origen',
    show: true,
    class: '!bg-blue-100 text-blue-500 font-extrabold w-[200px]'
},

{
    key: 'idventa',
    tipo: '',
    name: '# Pedido ',
    show: true,
    class: '!bg-blue-100 text-blue-500 font-extrabold w-[200px]'
},
{
                key: 'contacto',
                tipo: '',
                name: 'Cliente ',
                show: true,
                class: '!bg-blue-100 text-blue-500 font-extrabold w-[200px]'
            },

{
    key: 'fecha_pedido',
    tipo: '',
    name: 'Fecha de pedido ',
    show: true,
    class: '!bg-blue-100 text-blue-500 font-extrabold w-[200px]'
},

{
    key: 'total',
    tipo: '',
    name: 'Total ',
    show: true,
    class: '!bg-blue-100 text-blue-500 font-extrabold w-[200px]'
},
{
    key: 'detalle_garantia',
    tipo: '',
    name: 'Detalle Garantia',
    show: true,
    class: '!bg-green-100 text-green-500 font-extrabold w-[200px]'
},

])

        const pageChangue = (val) => {
            pagina.value = val;
            getData();
        }
        const getData = async () => {
            await getDataVentas();
            await getDataCitas();
            await getDataGarantias();
        }
        const applyDateRange = () => {
            getData();
        }

        const getDataVentas = async () => {
            emitter.emit('loading', true);
            let dat = {
                //  page: pagina.value,
                fechaInicio: formatFecha(fechaInicio.value), // Pasa la fecha de inicio
                fechaFin: formatFecha(fechaFin.value),
                id: vendedor.value // Pasa la fecha de fin
            }
            await reportes.GET_VENDEDOR_VENTAS(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    data_ventas.value = response;
                } else {
                    // emitter.emit("alerta_show",response.msg);
                }
                emitter.emit('loading', false);
            });
        }
        const getVendedores = async () => {
            emitter.emit('loading', true);
            let dat = {
                page: 1,
                limit: 200,
                search: 'all'
            }
            await vendedores.GET_USUARIOS(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    data_vendedores.value = response;

                    if(response.length>0){
                        vendedor.value=response[0].id;
                        getData();
                    }
                } else {
                    // emitter.emit("alerta_show",response.msg);
                }
                emitter.emit('loading', false);
            });
        }
        const getDataCitas = async () => {
            emitter.emit('loading', true);
            let dat = {
                //  page: pagina.value,
                fechaInicio: formatFecha(fechaInicio.value), // Pasa la fecha de inicio
                fechaFin: formatFecha(fechaFin.value),
                id: vendedor.value // Pasa la fecha de fin
            }
            await reportes.GET_VENDEDOR_CITAS(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    data_citas.value = response;
                } else {
                    // emitter.emit("alerta_show",response.msg);
                }
                emitter.emit('loading', false);
            });
        }
        const getDataGarantias = async () => {
            emitter.emit('loading', true);
            let dat = {
                //  page: pagina.value,
                fechaInicio: formatFecha(fechaInicio.value), // Pasa la fecha de inicio
                fechaFin: formatFecha(fechaFin.value),
                id: vendedor.value // Pasa la fecha de fin
            }
            await reportes.GET_VENDEDOR_GARANTIAS(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    data_garantias.value = response;
                } else {
                    // emitter.emit("alerta_show",response.msg);
                }
                emitter.emit('loading', false);
            });
        }
        const formatFecha = (fecha) => {
            const dateObj = new Date(fecha);
            return dateObj.toISOString();
            const formattedDate = `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
            return formattedDate;
        }
        // Función para obtener el primer día del mes actual
        function getDefaultStartDate() {
            const today = new Date();
            return new Date(today.getFullYear(), today.getMonth(), 1);
        }

        // Función para obtener el último día del mes actual
        function getDefaultEndDate() {
            const today = new Date();
            return new Date(today.getFullYear(), today.getMonth() + 1, 0);
        }

        onMounted(async () => {
            getVendedores();
            /* await getDataVentas();
           await getDataCitas();
           await getDataGarantias();
*/
        })

        onBeforeUnmount(() => {
            // Add any cleanup logic here.
        })

        return {
            getVendedores,
            data_vendedores,
            headings_ventas,
            getData,
            pageChangue,
            buscar,
            openTab,
            key,
            data_reporte,
            fechaInicio,
            fechaFin,
            applyDateRange,
            vendedor,
            data_ventas,
            data_citas,
            data_garantias,
            headings_garantias,
            headings_citas
        };
    },
};
</script>

<style scoped>
/* Your component-specific styles here */
</style>
