<template>
<div class="h-screen bg-white w-full">
    <div class="realtive w-full h-1/6   flex bg-black w-full overflow-y-hidden content-center justify-start  bg-center cursor-pointer object-cover z-10 shadow-lg" style="background-size:100% 100%;background-position:bottom;background:url(https://res.cloudinary.com/dgzea9m7q/image/upload/v1660121807/software/magno/282996030_2810877179218570_8778179936326173190_n.jpg)">
        <div class="px-3 rounded-lg  flex flex-col w-full self-center">
            <h4 class="text-white text-xl absolute font-semibold  leading-tight truncate z-30">{{$route.name}}
            </h4>

        </div>

    </div>
    <div class="relative h-4/6 flex-auto  overflow-y-scroll flex flex-wrap">
        <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
            <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                Nombre<strong class="text-red-500">*</strong>
            </span>
            <input id="cantidad" v-model="nombre" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="text" placeholder="Correo">
        </div>
        <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
            <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                Contrasena<strong class="text-red-500">*</strong>
            </span>
            <input id="cantidad" v-model="contrasena" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="text" placeholder="Contraseña">
        </div>
        <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
            <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                Nacimiento<strong class="text-red-500">*</strong>
            </span>
            <input id="cantidad" v-model="nacimiento" class="border border-black text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="date" placeholder="Fecha nacimiento">
        </div>
        <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
            <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                Correo<strong class="text-red-500">*</strong>
            </span>
            <input id="cantidad" v-model="email" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="text" placeholder="Correo">
        </div>
        <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
            <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                Teléfono<strong class="text-red-500">*</strong>
            </span>
            <input id="cantidad" v-model="phone" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10 border border-black" type="numeric" placeholder="Teléfono">
        </div>
        <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
            <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                Genero<strong class="text-red-500">*</strong>
            </span>
            <select id="cantidad" v-model="genero" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                <option :value="0" :selected="genero==0">
                    Seleccione una opcion
                </option>

                <option :value="'HOMBRE'" :selected="genero=='HOMBRE'">
                    Hombre
                </option>

                <option :value="'MUJER'" :selected="genero=='MUJER'">
                    Mujer
                </option>

            </select>
        </div>

        <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
            <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                Nombre Contacto <strong class="text-red-500">*</strong>
            </span>
            <input id="cantidad" v-model="nombre_contacto" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="text" placeholder="Nombre contacto">
        </div>
        <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
            <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                Numero Contacto<strong class="text-red-500">*</strong>
            </span>
            <input id="cantidad" v-model="numero" class="text-sm border border-black appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="numeric" placeholder="Numero Contacto">
        </div>
        <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
            <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                Parentesco<strong class="text-red-500">*</strong>
            </span>
            <select id="cantidad" v-model="parentesco" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                <option :value="0" :selected="parentesco==0">
                    Seleccione una opcion
                </option>

                <option :value="'HERMAN@'" :selected="parentesco=='HERMAN@'">
                    Herman@
                </option>

                <option :value="'MADRE'" :selected="parentesco=='MADRE'">
                    Madre
                </option>
                <option :value="'PADRE'" :selected="parentesco=='PADRE'">
                    Padre
                </option>
                <option :value="'PRIM@'" :selected="parentesco=='PRIM@'">
                    Prim@
                </option>
                <option :value="'TI@'" :selected="parentesco=='TI@'">
                    TI@
                </option>
                <option :value="'AMIG@'" :selected="parentesco=='AMIG@'">
                    Amig@
                </option>
                <option :value="'NOVI@'" :selected="parentesco=='NOVI@'">
                    Novi@
                </option>
                <option :value="'ESPOS@'" :selected="parentesco=='ESPOS@'">
                    Espos@
                </option>
                <option :value="'OTR@'" :selected="parentesco=='OTR@'">
                    Otro
                </option>

            </select>
        </div>

        <p class="font-extrabold text-base mt-10 mb-10 text-blue-500">
            La actividad física regular es saludable. Ser activo es seguro para la mayoría de las personas. Sin embargo, algunos
            deben consultar a su médico antes de iniciar un programa de entrenamiento físico.
            Favor de responder honestamente SÍ o NO encerrando la respuesta correspondiente:
        </p>

        <div class="mb-2 mt-2  justify-start flex flex-wrap w-full px-2">
            <div class="w-6/12 px-2">
                <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                    ¿Se te ha diagnosticado alguna enfermedad cardíaca?<strong class="text-red-500">*</strong>
                </span>
                <select id="cantidad" v-model="enfermedad_cardiaca" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">

                    <option :value="1" :selected="genero==1">
                        Si
                    </option>

                    <option :value="0" :selected="enfermedad_cardiaca==0">
                        No
                    </option>

                </select>
            </div>
            <div class="w-6/12 px-2">
                <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                    Regularmente, ¿tienes dolor en el pecho? ¿Sucede durante el ejercicio?<strong class="text-red-500">*</strong>
                </span>
                <select id="cantidad" v-model="dolor_pecho" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">

                    <option :value="1" :selected="dolor_pecho==1">
                        Si
                    </option>

                    <option :value="0" :selected="dolor_pecho==0">
                        No
                    </option>

                </select>
            </div>

            <div class="w-6/12 px-2">
                <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                    Actualmente, ¿tienes algún tratamiento médico para la tensión arterial o problema cardíaco?<strong class="text-red-500">*</strong>
                </span>
                <select id="cantidad" v-model="tratamiento_cardiaco" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">

                    <option :value="1" :selected="tratamiento_cardiaco==1">
                        Si
                    </option>

                    <option :value="0" :selected="tratamiento_cardiaco==0">
                        No
                    </option>

                </select>
            </div>
            <div class="w-6/12 px-2">
                <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                    ¿Tienes problemas/lesiones en huesos o articulaciones (espalda, rodillas, cadera, tobillos...)?<strong class="text-red-500">*</strong>
                </span>
                <select id="cantidad" v-model="problemas" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">

                    <option :value="1" :selected="problemas==1">
                        Si
                    </option>

                    <option :value="0" :selected="problemas==0">
                        No
                    </option>

                </select>
            </div>
            <div class="w-full flex flex-col justify-start ">
                <p class="text-base font-extrabold mt-10 text-blue-500">
                    *Si respondiste NO a todas las preguntas, puedes realizar actividad física de forma gradual y progresiva.<br>
                    *Si respondiste SÍ a una o más preguntas o tienes alguna situación especial, comunícalo en este momento.<br>
                    *Si eres mayor de 60 años, y no estás acostumbrado a realizar ejercicio, consúltalo con tu médico.</p>

                <p class="text-base  mt-10 ">
                    <strong class="text-base  text-blue-500 ">INFORMACIÓN PERSONAL:</strong> Tu información será exclusiva para uso interno y estrictamente confidencial.
                    Confirmo que la información otorgada en este formulario es propia y verídica.<br>
                    <strong class="text-base  text-blue-500 ">ASUNCIÓN DE RIESGO:</strong> Soy consciente de que existen riesgos al practicar cualquier actividad física. Entiendo que
                    los programas y clases ofrecidos por MAGNO Training suelen ser extenuantes, por lo que puedo presentar malestar
                    o accidentes tales como: mareo, dolor de cabeza, desmayo, vómito, problemas renales, caídas y/o lesiones; es
                    importante comentarle a mi entrenador cualquier situación para darme un mejor seguimiento.<br>
                    Acepto asumir todos los riesgos asociados a mi participación en los programas y clases de MAGNO Training.<br>
                    <strong class="text-base  text-blue-500 ">FOTO, IMAGEN Y SEMEJANZA:</strong> Otorgo a MAGNO Training el permiso, sin costo alguno, de usar mi imagen en fotos
                    y videos en cualquier publicación de redes sociales, incluidas Facebook, Instagram y sitio web, así como en
                    cualquier publicidad impresa, folletos, volantes o promocional, únicamente con fines publicitarios y promocionales.<br>
                    <strong class="text-base  text-blue-500 ">COMPROMISO DE PAGO:</strong> Estoy enterado de que la compra y/o membresía adquirida no es cancelable, pausable o
                    transferible bajo ninguna circunstancia. Estoy enterado de las diferentes opciones de pago: Efectivo, Tarjetas, Pago
                    en Línea y Crédito. Así como de las modalidades: Inscripción, Visita, Semana, Mensualidad y Paquetes: 3-6-12
                    meses. Se me notificará vía correo electrónico y/o presencial cuando mi membresía esté por vencer, una vez
                    vencida deberé hacer un nuevo pago en cualquiera de dichas opciones. En caso de asistir a MAGNO con membresía
                    vencida se me hará la renovación automática de membresía asumiendo el compromiso de pago.<br>
                    En caso de solicitar un crédito de productos deberé liquidarlo como límite al corte de mi mensualidad.<br>
                    Los precios, descripciones y detalles de los productos se encuentran sujetos a cambio, sin previo aviso.<br>
                    <strong class="text-base  text-blue-500 ">Servicio de Nutrición, Terapia física, Programación adicional, Cafetería, Artículos y/o Accesorios:</strong> Es de mi
                    conocimiento que dichos servicios son externos a mi acuerdo mensual, por lo tanto libero a MAGNO Training de
                    toda responsabilidad debido a mi participación en alguno en ellos.<br>
                    <strong class="text-base  text-blue-500 ">COVID-19:</strong> MAGNO Training en su compromiso con tu salud nos apegamos a las normas establecidas por
                    disposición oficial.<br>
                    Es necesaria la reservación diaria de tu espacio en clase, cada acceso deberá ser registrado. Agenda desde 24
                    horas hasta 5 min antes de tu clase. Contamos con política de cancelación de reservas, cancela o modifica tu
                    reserva con al menos 1 hora de anticipación, revisa las políticas para más detalles y evita sanciones.<br>
                    Al firmar reconozco que he leído, entendido y estoy de acuerdo con todos los términos y condiciones en este
                    acuerdo de prestación de servicios con MAGNO Training.</p>
            </div>

        </div>
        <!--footer-->
        <div class="flex items-center w-full bottom-0 fixed  justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
            <button class="text-red-500 bg-red-500 bg-opacity-20 border border-solid border-red-500 hover:bg-red-500 hover:bg-opacity-30 hover:text-red-900 active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="$router.push('/')">
                Cancelar
            </button>
            <button class="text-blue-800 bg-blue-500 bg-opacity-40 border hover:text-white  hover:bg-blue-700 border-solid border-blue-800 font-bold uppercase px-6 py-3 text-sm outline-none  rounded focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="accion()">
                Guardar
            </button>
        </div>
    </div>
</div>
</template>

<script>
//import PopularMovies from "@/components/PopularMovies.vue";

//import TopRated from "@/components/TopRated.vue";
import {
    ref,
    reactive
} from "vue";
import userService from "@/Services/user";
import infosistem from "@/Services/infosistem";

import {
    emitter
} from "../mitt.js";
import {
    useUserStore
} from '../stores/user'
import {
    useInfoStore
} from '../stores/info'
import {
    useConfigStore
} from '../stores/config'
import {
    useRouter,
    useRoute
} from 'vue-router'
export default {
    mixins: [infosistem],
    props: {
        price: {
            type: Number,
            default: 0
        },

    },

    setup(props) {
        const router = useRouter()
        const route = useRoute()
        const productPrice = reactive({
            name: "Product Name",
            price: props.price,
        });
        const infoData = ref({

        });
        const info = useInfoStore()
        const user = useUserStore()
        const config = useConfigStore()

        const active = reactive(false);
        const usuario = ref(8714393131);
        const contrasena = ref('');
        var id = ref(0);

        const genero = ref('HOMBRE');
        const numero = ref('');
        const phone = ref('');
        const nombre = ref('');
        const nacimiento = ref('');
        const email = ref('');
        const nombre_contacto = ref('');
        const parentesco = ref('');
        const enfermedad_cardiaca = ref(0);
        const dolor_pecho = ref(0);
        const problemas = ref(0);
        const tratamiento_cardiaco = ref(0);
        var link = ref('');

        // const add = () => productPrice.price++;

        const info_get = async () => {

            let data = await infosistem.GET().then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    let infov = {};
                    response.forEach(element => {
                        infov[element.id] = element.valor;
                    });
                    info.set(
                        infov
                    );
                    infoData.value = (infov);
                    //if(response.user.role=='admin'){

                    //this.$router.push('/app/'); 
                    //}

                }
            });

            if (route.query.hasOwnProperty('code')) {
                link.value = route.query.code;
            } else {
                link.value = ''
            }
            if (route.query.hasOwnProperty('u')) {
                id.value = route.query.u;
            } else {
                id.value = 0;
            }

        }
        const accion = () => {
            console.log('entre gurdar')
            if (id.value == 0) {
                guardar()
            } else {
                link_f()
            }
        }
        const link_f = async () => {
            console.log('link', id.value, '--')
            console.log(usuario.value, contrasena.value)
            let dat = {
                id: id.value,
                phone: phone.value,
                link: link.value,
                genero: genero.value,
                email: email.value,
                password: contrasena.value,
                nombre: nombre.value,
                nacimiento: nacimiento.value,

                usuario: usuario.value,
                nombre_contacto: nombre_contacto.value,
                parentesco: parentesco.value,
                numero: numero.value,
                problemas: problemas.value,
                tratamiento_cardiaco: tratamiento_cardiaco.value,
                enfermedad_cardiaca: enfermedad_cardiaca.value,
                dolor_pecho: dolor_pecho.value
            };
            let data = await userService.LINK_CLIENTE(dat).then((response) => {

                if (response.hasOwnProperty('error')) {
                    console.log(response);
                    emitter.emit("alerta_show", response.error);
                } else {
                    emitter.emit("success", 'Datos registrados exitosamente');
                    router.push('/');
                }
            });
        }
        const guardar = async () => {
            console.log("registro", usuario.value);
            console.log(usuario.value, contrasena.value)
            let dat = {
                id: id.value,
                phone: phone.value,
                link: link.value,
                genero: genero.value,
                email: email.value,
                password: contrasena.value,
                nombre: nombre.value,
                nacimiento: nacimiento.value,

                usuario: usuario.value,
                nombre_contacto: nombre_contacto.value,
                parentesco: parentesco.value,
                numero: numero.value,
                problemas: problemas.value,
                tratamiento_cardiaco: tratamiento_cardiaco.value,
                enfermedad_cardiaca: enfermedad_cardiaca.value,
                dolor_pecho: dolor_pecho.value
            };
            let data = await userService.REGISTRO_CLIENTE(dat).then((response) => {

                if (response.hasOwnProperty('error')) {
                    console.log(response);
                    emitter.emit("alerta_show", response.error);
                } else {
                    emitter.emit("success", 'Datos registrados exitosamente');
                    router.push('/');
                }
            });
        }
        const registrar = async () => {
            console.log("registro", usuario.value);
            console.log(usuario.value, contrasena.value)
            let dat = {
                phone: usuario.value,
                password: contrasena.value,
                role: 'admin',
            };
            let data = await userService.REGISTRO_USER(dat).then((response) => {

                if (response != null) {
                    console.log(response);
                } else {
                    alert("ocurrio un error");
                }
            });
        }
        info_get()
        return {
            productPrice,
            registrar,
            guardar,
            accion,
            link,
            genero,
            email,
            phone,
            nombre,
            nacimiento,
            active,
            usuario,
            contrasena,
            nombre_contacto,
            parentesco,
            tratamiento_cardiaco,
            enfermedad_cardiaca,
            problemas,
            dolor_pecho,
            numero,
            config,
            user,
            router,
            route,
            infoData,
            link_f
        };
    },
    data() {
        return {
            visible: false
            //usuario: '',
            //contrasena: '',
        }
    },
    mounted() {

    }
};
</script>

<style scoped>
</style>
