

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
 // GET_CLIENTES CREAR_CLIENTES EDITAR_CLIENTES ELIMINAR_CLIENTES

 GET: async function( data){
  let url='/productos_ordenes/?page='+data.page+'&search='+data.search+'&inicio='+data.inicio+'&fin='+data.fin+'&tipo='+data.tipo
      //let url='/productos_ordenes/'
      return initmixin.formatrespuesta('get', data,url,true)
    },
    CREAR: async function( data) {
      let url='/productos_ordenes/'
      return initmixin.formatrespuesta('post', data,url,true)
    },
    CAMBIAR_ESTATUS: async function( data) {
      let url='/productos_ordenes/estatus/'+data.id
      return initmixin.formatrespuesta('put', data,url,true)
    },
    EDITAR: async function( data) {
      let url='/productos_ordenes/'+data.id
      return initmixin.formatrespuesta('put', data,url,true)
    },
    ELIMINAR: async function( data) {
      let url='/productos_ordenes/'+data.id
      return initmixin.formatrespuesta('delete', data,url,true)
    },
   


}