<template>
<section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-center  ">

    <div style="height:64px" class="bg-white w-full flex  justify-start  py-10 px-10 content-center flex-wrap">
        <el-date-picker @change="getData()" v-model="actual_day" type="date" placeholder="Seleccione un dia" :size="'default'" class="mr-2" />
        <el-radio-group v-model="sucursal" @change="getData()">
            <el-radio-button v-for="item in sucursales_data" :key="item.id" :label="item.id">{{item.nombre}}</el-radio-button>

        </el-radio-group>
        <button type="button" @click="showM=true" class="border border-pink-500 text-base bg-pink-500 px-4 font-extrabold text-white rounded shadow shadow-pink-800 ml-2" data-ripple-dark="true">Crear clase</button>

        <!--<select class="self-center relative h-12 px-5 border border-gray-500 text-gray-500 rounded" v-model="sucursal" placeholder="Seleccionar Sucursal">
    <option
      v-for="item in sucursales_data"
      :key="item.id"
   
      :value="item.id"
     
    >
     <span :style="'float: left;background:'+item.color">{{ item.nombre }}</span>
      <span v-if="sucursal==item.id"
        style="
          float: right;
          color: green;
          font-size: 13px;
        "
        ></span
      >
    </option>
  </select>-->
    </div>
    <vue-cal 
    ref="vuecal" class=" demo   full-cal border-t border-gray-200 vuecal--full-height-delete" :selected-date="actual_day" today-button twelveHour=true, :sticky-split-labels="false" hide-weekends hide-months locale="es" active-view="day" :disable-views="['years', 'year', 'week','month']" :time-from="9 * 60" :time-to="23 * 60" :time-cell-height="parseInt(height/25)" :time-step="30" :editable-events="{ title: true, drag: true, resize: false, delete: false, create: false }" :events="events" :split-days="sectores" :on-event-click="onEventClick" @event-drop="eventoDrag" @cell-click="logEvents('cell-click', $event)" @cell-dblclick="logEvents('cell-dblclick', $event)">
        <template #event="{ event }">

            <div class="vuecal__event-title text-base text-green-600">

                <div>
                    <span class="bg-gray-200 px-2 py-1 rounded text-black font-extrabold text-xl">{{event.title}}</span>
                    <span class="bg-black px-2 py-1 rounded text-white font-extrabold text-xs">{{ event.data.users_clases.length }} / {{ event.data.capacidad_personas }} </span></div>

            </div>
            <!-- Or if your events are editable: -->
            <!--<div class="vuecal__event-title vuecal__event-title--edit"
         contenteditable
         @blur="event.title = $event.target.innerHTML"
         v-html="event.title" />-->

            <small class="vuecal__event-time text-sm">
                <!-- Using Vue Cal Date prototypes -->
                <strong>De</strong> <span>{{ formatGetTime(String(event.start)) }}</span>
                <strong> a</strong> <span>{{ formatGetTime(String(event.end))  }}</span>
            </small>
        </template>

        <template v-slot:split-label="{ split }">
            <i class="icon material-icons">person</i>
            <strong> {{ split.label }}</strong>
        </template>
    </vue-cal>
    <div v-if="asistentes!=null" @click.self="asistentes=null" style="z-index:9999900" class="bg-black bg-opacity-50  h-screen w-full flex content-center justify-center items-center fixed  top-0 right-0 bottom-0 left-0">
        <div class=" bg-white w-98  self-center  flex-wrap px-5 py-14 rounded-md text-start flex content-center justify-center items-center">
            <div class="text-base mb-4 font-bold text-slate-500">
                Asistentes
            </div>
            <div class="  justify-between  w-full h-72 bg-green-100 px-2 py-2 overflow-y-auto">
                 <table class="border-collapse table-auto w-full whitespace-no-wrap  table-striped relative">
                    <thead class="bg-gray-50 z-30">
                        <tr>
                            <th class="bg-gray-100 h-11 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs z-30">#</th>
                            <th class="bg-gray-100 h-11 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs z-30">Nombre</th>
                      
                      <th class="bg-gray-100 h-11 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs z-30">Telefono</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr v-for="(row,index) in asistentes.users_clases" v-bind:key="index">
                            <td>
                                {{row.user.id}}
                            </td>
                            <td>
                                {{row.user.name}}
                            </td>
                             <td>
                                {{row.user.phone}}
                            </td>
                        </tr>

                    </tbody>

                </table>

            </div>
        </div>
    </div>
    <div v-if="showM" @click.self="showM=null" style="z-index:9000" class="overflow-x-hidden overflow-y-auto fixed inset-0  outline-none focus:outline-none justify-center bg-black bg-opacity-50 items-center flex">
        <div class="relative  my-6 mx-auto md:w-8/12 xl:w-6/12 lg:w-6/12">
            <!--content-->
            <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <!--header-->
                <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 class="text-3xl font-semibold">
                        {{ id==0?'Crear Clase':'Editar Clase' }}
                    </h3>
                    <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="showM=false">
                        <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                        </span>
                    </button>
                </div>
                <!--body-->
                <div class="relative p-6 flex-auto  flex flex-wrap">
 <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold    " htmlFor="nombre">
                            Nombre
                        </span>
                        <hr class="w-full">
                        <input id="cantidad" type="text" v-model="nombre" class="text-sm appearance-none  rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline " placeholder="Nombre" />

                    </div>
                    <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold    " htmlFor="nombre">
                            Fecha
                        </span>
                        <hr class="w-full">
                        <el-date-picker v-model="fecha" type="date" placeholder="Seleccione un dia" :size="'default'" class="mr-2 w-full" />
                    </div>
                    <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold    " htmlFor="nombre">
                            Cantidad personas
                        </span>
                        <hr class="w-full">
                        <input id="cantidad" type="number" v-model="cantidad_personas" class="text-sm appearance-none  rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline " placeholder="Cantidad" />

                    </div>
                    <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Horario
                        </span>
                        <hr class="w-full">
                        <div class="demo-time-range w-full">
                            <el-time-select v-model="hora_inicio" :max-time="hora_fin" class="w-6/12" placeholder="Hora inicio" start="08:30" step="00:15" end="22:30" />
                            <el-time-select class="w-6/12" v-model="hora_fin" :min-time="hora_inicio" placeholder="Hora fin" start="08:30" step="00:15" end="22:30" />
                        </div>
                    </div>

                    <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Instructor
                        </span>
                        <select id="cantidad" v-model="instructor" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            <template v-for="(row,index) in instructores_data" :key="index">
                                <option :value="row.id" :selected="instructor==row.id">
                                    {{ row.nombre }}
                                </option>
                            </template>
                        </select>
                    </div>
                    <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Sector
                        </span>
                        <select id="cantidad" v-model="sector" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            <template v-for="(row,index) in clasesdata.sectores" :key="index">
                                <option :value="row.id" :selected="sector==row.id">
                                    {{ row.nombre }}
                                </option>
                            </template>
                        </select>
                    </div>
                </div>
                <!--footer-->
                <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button class="text-red-500 bg-red-500 bg-opacity-20 border border-solid border-red-500 hover:bg-red-500 hover:bg-opacity-30 hover:text-red-900 active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="showM=false">
                        Cancelar
                    </button>
                    <button class="text-blue-800 bg-blue-500 bg-opacity-40 border hover:text-white  hover:bg-blue-700 border-solid border-blue-800 font-bold uppercase px-6 py-3 text-sm outline-none  rounded focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="guardar()">
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div @click.self="actionModal=null" v-if="actionModal!=null" style="z-index:9999900" class="bg-black bg-opacity-50  h-screen w-full flex content-center justify-center items-center fixed  top-0 right-0 bottom-0 left-0">
        <div class=" bg-white w-72 h-72 self-center  flex-wrap px-5 py-14 rounded-md text-start flex content-center justify-center items-center">
            <div class="text-base mb-4 font-bold text-slate-500">
                ¿Qué desea hacer?
            </div>
            <div class="flex-wrap flex justify-between  w-full">
                <div class="w-3/6 px-2">
                    <button class="w-full bg-blue-500 bg-opacity-20 px-4 py-2 rounded-md text-md text-blue-500 border-2 border-blue-500" @click="edit(actionModal);">
                        Editar
                    </button>
                </div>

                <div class="w-3/6 px-2 ">
                    <button class="bg-red-500 px-4 py-2 bg-opacity-20 rounded-md text-md text-red-500 w-full  border-2 border-red-500" @click="eliminar(actionModal.id)">
                        Eliminar
                    </button>
                </div>
                <div class="w-5/6 px-2 mt-1">
                    <button class="w-full bg-green-500 bg-opacity-20 px-4 py-2 rounded-md text-md text-green-500 border-2 border-green-500" @click="asistentes=actionModal;actionModal=null">
                        Ver Asistentes
                    </button>
                </div>

            </div>
        </div>
    </div>
</section>
</template>

<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import clases from "@/Services/clases";
import sucursales from "@/Services/sucursales";
import instructores from "@/Services/instructores";
import generales from "@/functions/generales";
import {
    dateEquals
} from 'element-plus';

var body = document.body,
    html = document.documentElement;

var height = Math.max(body.scrollHeight, body.offsetHeight,
    html.clientHeight, html.scrollHeight, html.offsetHeight);
export default {
    components: {

        VueCal
    },
    mixins: [clases, generales, sucursales, instructores],
    data() {
        const user = useUserStore()

        return {
            asistentes: null,
            height: height - 110,
            buscar: '',
            open: false,
            user: user.getAll(),
            clasesdata: [],
            sucursales_data: [],
            actionModal: null,
            showM: false,
            id: 0,
            sucursal: 0,
            nombre: '',
            cantidad_personas: 0,
            modificadores_id: null,
            actual_day: new Date(),
            sector: 0,
            instructor: 0,
            fecha: new Date(),
            hora_inicio: '09:00',
            hora_fin: '10:00',
            sectores: [],
            horas: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
            events: [],
            instructores_data: []

        }

    },
    mounted() {

    },
    created() {
        this.getDataSucursales();
        this.getDataInstructores();
    },
    methods: {

        eventoDrag(event) {
            console.log(event, '-----drag---')
            if (event.event.data.hasOwnProperty('asignacion')) {
                // event.stopPropagation();

            }

            this.returnVal(event)

        },

        formatTime(time) {
            let tiempo = time.split(':')
            return tiempo[0] + ":" + tiempo[1];
        },
        logEvents(type, event) {
            //console.log(event,'-----evento---',type)

        },
        onEventClick(event, e) {
            console.log(event)
            this.selectedEvent = event
            this.showDialog = true;
            console.log('click')
            this.actionModal = event.data;

            // Prevent navigating to narrower view (default vue-cal behavior).
            e.stopPropagation()
        },
        async getDataInstructores() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await instructores.GET().then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.instructores_data = response

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async getDataSucursales() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await sucursales.GET(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.sucursales_data = response
                    if (this.sucursales_data.length > 0) {
                        this.sucursal = this.sucursales_data[0].id;
                        this.getData();
                    }

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },

        limpiar() {

            this.id = 0;
            this.nombre = '';
            this.fecha = new Date();
            this.hora_inicio = '09:30';
            this.hora_fin = '10:30';
            this.sector = 0;
            this.instructor = 0;

        },
        edit(row) {
            this.id = row.id;
            this.nombre = row.nombre;
            this.fecha = row.fecha;
            this.hora_inicio = row.hora_inicio;
            this.hora_fin = row.hora_fin;
            this.cantidad = row.capacidad_personas;
            this.sector = row.sectores_id;
            this.instructor = row.instructores_id;

            this.showM = true;
            this.actionModal = null;
        },
        async returnVal(val) {
            let format1 = (n) => `${n / 60 ^ 0}:` + n % 60
            this.$root.$emit("loading", true);

            let data_c = {

                nombre: val.event.data.nombre,
                fecha: this.formatFullDate(val.event.data.fecha),
                hora_inicio: this.formatMinutesToTime(val.event.startTimeMinutes),
                hora_fin: this.formatMinutesToTime(val.event.endTimeMinutes),
                sector: val.event.split,
                sucursal: this.sucursal,
                instructor: val.event.data.instructores_id,
                id: val.event.data.id,
                cantidad: val.event.data.capacidad_personas

            };

            this.editar(data_c);
        },
        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            let dat = {
                fecha: this.formatFullDate(this.actual_day),
                id: this.sucursal
            }
            this.sectores = [];
            this.events = [];
            await clases.GET(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.clasesdata = response
                    this.clasesdata.sectores.forEach(element => {
                        this.sectores.push({
                            id: element.id,
                            label: element.nombre,
                            class: 'bg-gray-500 bg-opacity-50 border border-gray-500'
                        })

                        /*this.events.push({

                            drag: false,
                            asignacion: true,
                            clickeable: false,
                            draggable: false,
                            background: true,
                            id: element.id + '0',
                            start: this.formatFullDate(this.actual_day) + ' 09:00',
                            end: this.formatFullDate(this.actual_day) + ' 23:00',
                            title: element.nombre,
                            content: '<div class="bg-opacity-60  h-full absolute top-0 left-0 w-full opacity-60 " style="background:' + element.color + '"></div>',

                            data: element,

                            resizable: false,

                            split: element.id,

                        })*/
                        element.clases.forEach(clase => {
                            this.events.push({

                                drag: true,
                                asignacion: true,
                                clickeable: false,
                                draggable: true,
                                background: false,
                                id: clase.id + '0',
                                start: clase.fecha + ' ' + clase.hora_inicio,
                                end: clase.fecha + ' ' + clase.hora_fin,
                                title: clase.nombre+'/'+clase.instructores.nombre,
                                content: '<div class="bg-opacity-60  h-full absolute top-0 left-0 w-full opacity-60  text-black" style="background:white"></div>',

                                data: clase,

                                resizable: false,

                                split: element.id,

                            })
                            console.log(this.events,'----events--')
                        });
                    });
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async guardar() {
            let dat = {
                id: this.id,
                nombre: this.nombre,
                fecha: this.formatFullDate(this.fecha),
                hora_inicio: this.hora_inicio,
                hora_fin: this.hora_fin,
                sector: this.sector,
                sucursal: this.sucursal,
                instructor: this.instructor,
                cantidad: this.cantidad_personas

            }
            if (this.id == 0) {
                this.crear(dat)
            } else {
                this.editar(dat)
            }
        },
        async editar(dat) {
            emitter.emit("loading", true)

            await clases.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async crear(dat) {
            emitter.emit("loading", true)

            await clases.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async eliminar(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await clases.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
