<template>
  <section
    class="h-full w-full flex flex-wrap bg-blue-50 overflow-y-hidden justify-center content-center"
  >
    <div class="w-full flex flex-wrap justify-center h-full bg-white">
      <div
        class="w-full flex flex-wrap h-full justify-between content-start bg-red-100 overflow-y-auto"
      >
        <div
          class="h-full w-full flex flex-wrap justify-center content-start xs:justify-center sm:justify-center md:justify-start lg:justify-start xl:justify-start"
        >
       
          <template v-for="(row, index) in data" v-bind:key="index">
            <div
              class="w-10/12 xs:w-10/12 sm:w-6/12 md:w-4/12 lg:w-3/12 xl:w-2/12 px-2 py-2 flex flex-wrap justify-start content-center"
            >
              <a
                href="#"
               
                class="w-full flex flex-wrap bg-white rounded-lg border overflow-hidden max-h-72 shadow-md md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
              >
                <div
                  class="w-full h-full flex flex-col justify-between text-clip p-4 leading-normal"
                >
                  <h5
                    class="text-xs h-8 overflow-hidden text-ellipsis text-left font-bold tracking-tight text-gray-900 dark:text-white"
                  >
                    {{ row.nombre }}
                  </h5>
                  <p
                    class="mb-1 text-xs max-h-12 truncate text-left font-normal text-gray-700 dark:text-gray-400"
                  >
                    {{ row.descripcion }}
                  </p>
                  <div class="flex  flex-wrap justify-end space-x-1 contentr-start items-center">
                  <a  @click="edit(row,'catalogo')"
                    href="#"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-2 py-2 text-center dark:bg-blue-500 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                  <i class="las la-edit"></i>
                  </a>
                  <a  @click="edit(row,'productos_catalogo')"
                  href="#"
                  class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-2 py-2 text-center dark:bg-green-500 dark:hover:bg-green-700 dark:focus:ring-green-800"
                >
                <i class="las la-th-list"></i>
                </a>
              </div>
                </div>
              </a>
            </div>
          </template>
        </div>
      </div>
    </div>
    

    <el-drawer
    v-model="showList"
    title=""
    @closed="getData()"
    :with-header="false"
    :size="id == 0 ? '50%' : '100%'"
    class="flex flex-wrap justify-center"
  >
    <div class="w-full flex flex-wrap h-full">
      

      <!----editor de dormula avanzado-->

      <!--------editor de firmula -->
      <div
        class="flex bg-gray-200 h-full"
        :class="'w-full'"
      >
        <div class="flex flex-col h-full w-full">
        
          <div class="flex-grow overflow-auto">
            <table class="relative w-full border">
              <thead class="">
                <tr>
                  <th class="sticky top-0 h-8 bg-blue-200" colspan="4">
                   
                      <div class="flex flex-wrap justify-center content-center  h-full py-2">
                       

                        <a class=" rounded-md  text-black text-base "  @click="downloadCSV(this.id)">
                          Descargar catalogo 
                         <strong class="bg-green-500 rounded-md text-xl text-black p-1"> <i class="las la-cloud-download-alt"></i></strong></a>
                   

                      </div>
                  </th>
                  <th class="sticky top-0 h-8 bg-blue-300" colspan="4">
                    <div class="flex flex-wrap justify-center content-center  h-full py-2">
                       

                      <a class=" rounded-md  text-black text-base "  @click="openFileInput()">
                        Subir productos de manera masiva
                       <strong class="bg-blue-500 rounded-md text-xl text-white p-1">
                        
                        <i class="las la-cloud-upload-alt"></i></strong></a>
                        <input id="fileInput" type="file" style="display: none" @change="handleFileUpload" />


                    </div>
                   
               
                </th>
                </tr>
                
                <tr>
                  <th
                    class="border border-black bg-yellow-400 font-sans tracking-wide normal-case text-xs  h-11 sticky top-8 px-3 py-2 text-gray-600 font-bold"
                    v-text="'#'"
                  />

                  <th
                    class="border border-black bg-yellow-400 font-sans tracking-wide normal-case text-xs  h-11 sticky top-8 px-3 py-2 text-gray-600 font-bold"
                    v-text="'Nombre Elemento'"
                  />
                  <th
                    class="border border-black bg-yellow-400 font-sans tracking-wide normal-case text-xs  h-11 sticky top-8 px-3 py-2 text-gray-600 font-bold"
                    v-text="'Estilo'"
                  />

                  <th
                    class="border border-black bg-yellow-400 font-sans tracking-wide normal-case text-xs  h-11 sticky top-8 px-3 py-2 text-gray-600 font-bold"
                    v-text="'Precio venta'"
                  />

                  <th
                    class="border border-black bg-yellow-400 font-sans tracking-wide normal-case text-xs  h-11 sticky top-8 px-3 py-2 text-gray-600 font-bold"
                    v-text="'Precio compra'"
                  />
                  <th
                    class="border border-black bg-yellow-400 font-sans tracking-wide normal-case text-xs  h-11 sticky top-8 px-3 py-2 text-gray-600 font-bold"
                    v-text="'Valor formula'"
                  />

                  <th
                    class="border border-black bg-yellow-400 font-sans tracking-wide normal-case text-xs  h-11 sticky top-8 px-3 py-2 text-gray-600 font-bold"
                    v-text="'Eliminar'"
                  />
                  <th
                    class="border border-black bg-yellow-400 font-sans tracking-wide normal-case text-xs  h-11 sticky top-8 px-3 py-2 text-gray-600 font-bold"
                  >
                    <span
                      @click="AddProductoCatalogo = true;limpiarProdOpcion();"
                      class="bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900"
                    >
                      Agregar
                    </span>
                  </th>
                </tr>
               
              </thead>
              <tbody>
                <template
                  v-for="(row, index) in productos_data"
                  v-bind:key="index"
                >
                  <tr>
                    <td class="border border-black py-1 px-1">
                      <div
                        class="bg-black flex flex-wrap justify-center content-center text-xs h-6 px-3 self-center text-white rounded w-full text-center"
                      >
                        <span>{{ row.id }}</span>
                      </div>
                    </td>

                    <td class="border border-black text-xs">
                      {{ row.nombre }}
                    </td>
                    <td class="border border-black text-xs">{{ row.tag }}</td>
                    <td class="border border-black text-xs">
                      {{ row.precio }}
                    </td>
                    <td class="border border-black text-xs">
                      {{ row.precio_compra }}
                    </td>
                    <td class="border border-black text-xs">
                      {{ row.valor_formula }}
                    </td>

                    <td class="border border-black text-xs font-extrabold p-1">
                      <button
                        class="text-black bg-red-500 font-extrabold text-base text-center px-2 py-1 rounded-md"
                        @click="eliminarProducto(row.id)"
                      >
                      <i class="las la-trash"></i>
                      </button>
                    </td>

                    <td class="border border-black text-xs font-extrabold p-1">
                      <button
                        class="text-white bg-blue-500 font-extrabold text-base text-center px-2 py-1 rounded-md"
                        @click="editProducto(row)"
                      >
                      <i class="las la-edit"></i>
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div style="flex: auto;">
        <!-- <button class="text-red-500 bg-red-500  border border-solid border-red-500 hover:bg-red-500 hover:bg-opacity-30 hover:text-red-900 active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="showM=false">
                          Cancelar
                      </button>
                      <button class="text-blue-800 bg-blue-500 bg-opacity-40 border hover:text-white  hover:bg-blue-700 border-solid border-blue-800 font-bold uppercase px-6 py-3 text-sm outline-none  rounded focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="guardar()">
                          Guardar
                      </button>-->
        <btn_component
          :text="'Cancelar'"
          :tipo="'danger'"
          @click="showList = false"
        ></btn_component>
       
      </div>
    </template>
  </el-drawer>
  <div v-if="showPreview" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center " style="z-index:9999">
    <div class="bg-white p-6 rounded shadow-md w-5/6">
      <h2 class="text-xl font-semibold mb-4">Previsualización de Datos</h2>
     
      <div class="w-full flex overflow-y-scroll overflow-x-scroll max-h-80">
        <table class="w-full border">
          <thead>
            <tr>
              <th class="border px-4 py-2">id</th>
              <th class="border px-4 py-2">nombre</th>
              <th class="border px-4 py-2">descripcion</th>
              <th class="border px-4 py-2">tag</th>
              <th class="border px-4 py-2">provedores_id</th>
              <th class="border px-4 py-2">valor_formula</th>
              <th class="border px-4 py-2">precio</th>
              <th class="border px-4 py-2">precio_compra</th>
              <th class="border px-4 py-2">activo</th>
              <!-- Agrega más columnas según las necesidades de tu CSV -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in csvData" :key="index">
              <td class="border p-0">{{ row.id }}</td>
              <td class="border p-0">
                <input v-model="row.nombre" class="bg-yellow-50" />
              </td>
              <td class="border p-0">
                <input v-model="row.descripcion" class="bg-yellow-50" />
              </td>
              <td class="border p-0">
                <input v-model="row.tag" class="bg-yellow-50" />
              </td>
              <td class="border p-0">
                <input v-model="row.provedores_id" class="bg-yellow-50" />
              </td>
              <td class="border p-0">
                <input v-model="row.valor_formula" class="bg-yellow-50" />
              </td>
              <td class="border p-0">
                <input v-model="row.precio" class="bg-yellow-50" />
              </td>
              <td class="border p-0">
                <input v-model="row.precio_compra" class="bg-yellow-50" />
              </td>
              <td class="border p-0">
                <input v-model="row.activo" class="bg-yellow-50" />
              </td>
              <!-- Agrega más columnas según las necesidades de tu CSV -->
            </tr>
          </tbody>
        </table>
      </div>
    
      <div class="sticky bottom-0">
        <button @click="ImportarDatos" class="mt-4 bg-blue-500 text-white px-4 py-2 rounded">Confirmar Importacion</button>
        <button @click="showPreview=false" class="mt-4 bg-red-500 text-white px-4 py-2 rounded">Cerrar</button>
      </div>
     
    </div>
  </div>
    <el-drawer
      v-model="showM"
      title=""
      @closed="getData()"
      :with-header="false"
      :size="id == 0 ? '50%' : '50%'"
      class="flex flex-wrap justify-center"
    >
      <div class="w-full flex flex-wrap h-full">
        <div
          v-if="!managerFormula"
          class="flex h-full"
          :class="'w-full'
            
          "
        >
          <!--content-->

          <div
            class="relative flex flex-col w-full bg-white outline-none focus:outline-none"
          >
            <!--header-->
            <div
              class="flex items-start justify-between p-2 border-b border-solid border-blueGray-200 rounded-t"
            >
              <h3 class="text-xl font-semibold">
                {{ id == 0 ? 'Crear Elemento' : 'Editar Elemento' }}
              </h3>
            </div>
            <!--body-->
            <div class="relative pb-6 pt-2 overflow-y-auto flex flex-wrap">
              <div class="w-full flex flex-wrap justify-end px-3">
              
                <button
                  v-if="this.id != 0"
                  @click="eliminarProducto(this.id)"
                  class="bg-red-500 rounded text-xs text-white px-3 py-1"
                >
                  Eliminar producto
                </button>
              </div>

              <div class="mb-1 mt-1 justify-start flex flex-wrap w-6/12 px-2">
                <span
                  class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
                  htmlFor="nombre"
                >
                  Nombre
                </span>
                <input
                  id="cantidad"
                  v-model="nombre"
                  class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
                  type="text"
                  placeholder="Nombre"
                />
              </div>
              <div
                class="mb-1 mt-1 justify-start flex flex-wrap w-6/12 px-2 content-start"
              >
                <span
                  class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
                  htmlFor="nombre"
                >
                  Nombre plural
                </span>
                <input
                  id="cantidad"
                  v-model="nombre_plural"
                  class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
                  type="text"
                  placeholder="Nombre"
                />
              </div>

              <div class="mb-1 mt-1 justify-start flex flex-wrap w-6/12 px-2">
                <span
                  class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
                  htmlFor="nombre"
                >
                  Descripción
                </span>
                <textarea
                  id="cantidad"
                  v-model="descripcion"
                  class="text-sm appearance-none h-24 rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Descripción"
                />
              </div>

              <div
                v-if="tipo_producto == 'opcion_catalogo'"
                class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2"
              >
                <span
                  class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
                  htmlFor="nombre"
                >
                  Precio
                </span>
                <input
                  maxlength="5"
                  v-on:keyup="formatNumber($event)"
                  class="border border-black bg-gray-200 text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-10"
                  id="cantidad"
                  v-model="precio"
                  type="text"
                  placeholder="Precio"
                />
              </div>
              <div
              v-if="tipo_producto == 'opcion_catalogo'"
              class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2"
            >
              <span
                class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
                htmlFor="nombre"
              >
                Valor Formula
              </span>
              <input
                maxlength="5"
                v-on:keyup="formatNumber($event)"
                class="border border-black bg-gray-200 text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-10"
                id="cantidad"
                v-model="valor_formula"
                type="text"
                placeholder="Valor formula"
              />
            </div>
            </div>
            <!--footer-->
          </div>
        </div>

        <!----editor de dormula avanzado-->

        <!--------editor de firmula -->
      
      </div>

      <template #footer>
        <div style="flex: auto;">
          <!-- <button class="text-red-500 bg-red-500  border border-solid border-red-500 hover:bg-red-500 hover:bg-opacity-30 hover:text-red-900 active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="showM=false">
                            Cancelar
                        </button>
                        <button class="text-blue-800 bg-blue-500 bg-opacity-40 border hover:text-white  hover:bg-blue-700 border-solid border-blue-800 font-bold uppercase px-6 py-3 text-sm outline-none  rounded focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="guardar()">
                            Guardar
                        </button>-->
          <btn_component
            :text="'Cancelar'"
            :tipo="'danger'"
            @click="showM = false"
          ></btn_component>
          <btn_component
            :text="'Guardar'"
            :tipo="'info'"
            @click="guardar()"
          ></btn_component>
        </div>
      </template>
    </el-drawer>
    <el-dialog v-model="AddProductoCatalogo" :show-close="false">
      <template #header="{  titleId, titleClass }">
        <div class="my-header">
          <h4 :id="titleId" :class="titleClass">
            {{ id_param == 0 ? 'Crear Producto' : 'Editar Producto' }}
          </h4>
        </div>
      </template>
      <div class="flex flex-wrap">
        <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2">
          <span
            class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
            htmlFor="nombre"
          >
            Nombre
          </span>
          <input
            id="cantidad"
            v-model="nombre_prod"
            class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
            type="text"
            placeholder="Nombre"
          />
        </div>
        <div class="mb-1 mt-1 justify-start flex flex-wrap w-6/12 px-2">
          <span
            class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
            htmlFor="nombre"
          >
            Estilo
          </span>
          <input
            id="cantidad"
            v-model="tag"
            class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
            type="text"
            placeholder="Estilo"
          />
        </div>
        <div class="mb-2 mt-2 justify-start flex flex-wrap w-full px-2">
          <span
            class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
            htmlFor="nombre"
          >
            Descripcion
          </span>
          <textarea
            id="cantidad"
            v-model="descripcion_prod"
            class="text-sm appearance-none h-48 rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Descripcion"
          />
        </div>
        <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2">
          <span
            class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
            htmlFor="nombre"
          >
            Precio venta
          </span>
          <!-- v-on:keyup="formatNumber($event)"-->
          <input
            maxlength="6"
           
            class="border border-black bg-gray-200 text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-10"
            id="cantidad"
            v-model="precio_prod"
            type="number"
            placeholder="Precio Venta"
          />
        </div>
        <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2">
          <span
            class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
            htmlFor="nombre"
          >
            Precio compra
          </span>
          <input
            maxlength="6"
           
            class="border border-black bg-gray-200 text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-10"
            id="cantidad"
            v-model="precio_compra"
            type="number"
            placeholder="Precio Compra"
          />
        </div>
        <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2">
            <span
              class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
              htmlFor="nombre"
            >
              Valor formula
            </span>
            <input
              maxlength="5"
           
              class="border border-black bg-gray-200 text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-10"
              id="cantidad"
              v-model="valor_formula"
              type="number"
              placeholder="Valor formula"
            />
          </div>
        <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2 z-40">
          <span
            class="py-2 mb-0 block text-gray-700 text-start text-sm font-semibold"
            htmlFor="nombre"
          >
            Provedor
          </span>
          <select
            id="cantidad"
            v-model="provedor"
            class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
          >
            <option :value="0" :selected="provedor == 0">
              Seleccione un provedor
            </option>
            <template
              v-for="(row, index) in provedores_data"
              v-bind:key="index"
            >
              <option :value="row.id" :selected="provedor == row.id">
                {{ row.nombre }}
              </option>
            </template>
          </select>
        </div>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <button
            class="text-black bg-red-500 font-extrabold text-xs text-center px-2 py-1 rounded-md"
            @click="AddProductoCatalogo = false"
          >
            Cancelar
          </button>
          <button class="text-black bg-blue-500 font-extrabold text-xs text-center px-2 py-1 rounded-md" type="primary" @click="guardarProducto()">Guardar</button>
        </div>
      </template>
    </el-dialog>
    <button
      class="btn font-bold bg-blue-800 text-white text-3xl w-12 h-12 justify-center content-center flex flex-wrap fixed z-20 float-right m-r-xxs rounded-full fixed bottom-5 right-5"
      @click="agregarNuevo()"
    >
      <vue-feather
        :type="'plus-circle'"
        size="24"
        class="self-center"
        :stroke="'white'"
      />
    </button>
  </section>
</template>
<script>
import { ref, onMounted, onCreated } from 'vue';
import { emitter } from '../../../mitt.js';
import { useUserStore } from '../../../stores/user';
import categorias from '@/Services/categorias';
import productos from '@/Services/productos';
import parametros from '@/Services/parametros';
import provedores from '@/Services/provedores';
import generales from '@/functions/generales';
import { saveAs } from 'file-saver'
export default {
  components: {},
  setup() {
    const user = useUserStore();

    const buscar = ref('');
    const open = ref(false);
    const filtro = ref(0);
  
    const data = ref([]);
    const tag = ref('');
    const formulaexcel = ref('');
    const provedores_data = ref([]);
    const categorias_data = ref([]);
    const tipos_productos = ref([
      { id: 'catalogo', nombre: 'Catalogo', show: true },
      { id: 'producto_venta', nombre: 'Producto venta', show: true },
    ]);

    const tipos_parametros = ref([
      { id: 'numerico', nombre: 'Numerico', show: true },
      { id: 'texto', nombre: 'Texto', show: true },
      { id: 'texto_extendido', nombre: 'Texto extendido', show: true },
      { id: 'catalogo', nombre: 'Catalogo', show: true },
      { id: 'calc_formula', nombre: 'Calculado con formula', show: true },
    ]);

    const actionModal = ref(null);
    const showM = ref(false);
    const showList = ref(false);
    
    const id = ref(0);
    const tipo_producto = ref('catalogo');
    const nombre = ref('');
    const descripcion = ref('');
    const color = ref('rgba(255,30,100,1)');
    const formula_json = ref([]);
    const formula_mp = ref([]);
    const precio = ref(null);
    const categoria_elemento = ref(0);
    const imageUrl = ref(null);
    const nombre_plural = ref('');
    const data_file = ref(null);
    const AddParametro = ref(false);
    const tipo_parametro = ref('numerico');
    const produccion_interna = ref(0);
    const nombre_parametro = ref('');
    const parametro_catalogo = ref(0);
    const id_param = ref(0);
    const producto_id = ref(null);
    const parametros_data = ref([]);
    const popoversel = ref(0);
    const visible_formato = ref(1);
    const visible = ref(1);
    const visible_produccion = ref(1);
    const visible_instaladores = ref(1);
    const visible_cotizacion_t2 = ref(1);
    const descripcion_prod=ref('');
    const menor = ref('<');
    const AddProductoCatalogo = ref(false);
    const nombre_prod = ref('');
    const formula_excel = ref('');
    const precio_prod = ref(0);
    const precio_compra = ref(0);
    const valor_formula = ref(0);
    const provedor = ref(0);
    const id_prod = ref(0);
    const productos_data = ref([]);
    const managerFormula = ref(false);
    const showPreview = ref(false);
    const csvData = ref([]);
    const fileInput = ref(null);

    const ImportarDatos=async ()=>{

      emitter.emit('loading', true);
      let dat = {
        data: csvData.value,
      id:id.value
      };

      try {
        const response = await productos.IMPORT_MASIVO(dat);
        if (response != null && !response.hasOwnProperty('error')) {
          emitter.emit('success', response.message);
          showM.value=false;
          showPreview.value=false;
          id.value=null;
          showList.value=false;
          getData();
        } else {
          emitter.emit('alerta_show', response.error);
        }
        actionModal.value = null;
      } catch (error) {
        emitter.emit('alerta_show', 'Error al eliminar producto.');
      }

      emitter.emit('loading', false);

    }
    const openFileInput = () => {
    //  const showPreview = ref(false);
      const fileInput = document.getElementById('fileInput');
      console.log(fileInput,'-----a------')
      fileInput.click();
    };

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        parseCSV(file);
      }
    };

    
const parseCSV = (file) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const content = e.target.result;
    const rows = content.split('\n');
    const headers = rows[0].split(',');
    const data = [];

    for (let i = 1; i < rows.length; i++) {
      const row = rows[i].split(',');
      if (row.length === headers.length) {
        const rowData = {};
        rowData['id'] = getValue(row, 0);
        rowData['nombre'] = getValue(row, 1);
        rowData['descripcion'] = getValue(row, 2);
        rowData['tag'] = getValue(row, 3);
        rowData['provedores_id'] = getNumericValue(row, 4);
        rowData['valor_formula'] = getNumericValue(row, 5);
        rowData['precio'] = getNumericValue(row, 6);
        rowData['precio_compra'] = getNumericValue(row, 7);
        rowData['activo'] = getNumericValue(row, 8);
        data.push(rowData);
      }
    }

    csvData.value = data;
    showPreview.value = true;
    document.getElementById('fileInput').value = '';
  };

  reader.readAsText(file);
};

// Helper function to get value or return empty string
const getValue = (row, index) => {
  return row[index] ? row[index].trim() : '';
};

// Helper function to get numeric value or return null
const getNumericValue = (row, index) => {
  const value = parseFloat(row[index].trim());
  return !isNaN(value) ? value : null;
};

   
    const agregarNuevo = () => {
      showM.value = true;
      limpiar();
    };

    const limpiar = () => {
      id.value = 0;
      nombre.value = '';
      nombre_plural.value = '';
      descripcion.value = '';
      tipo_producto.value = 'catalogo';
      categoria_elemento.value = 0;
      precio.value = null;
      imageUrl.value = '';
      tag.value = '';
      formula_json.value = [];
      formula_mp.value = [];
      parametros_data.value = [];
      productos_data.value = [];
    };

    const limpiarProdOpcion = () => {
      nombre_prod.value = '';
      precio_prod.value = 0;
      precio_compra.value = 0;
      valor_formula.value = 0;
      tag.value = '';
      provedor.value = 0;
      id_prod.value = 0;
    };

    const editProducto = (row) => {
      id_prod.value = row.id;
      nombre_prod.value = row.nombre;
      tag.value = row.tag;
      descripcion_prod.value = row.descripcion;
      precio_prod.value = row.precio;
      precio_compra.value = row.precio_compra;
      valor_formula.value = row.valor_formula;
      provedor.value = row.provedores_id >= 0 ? row.provedores_id : 0;
      AddProductoCatalogo.value = true;
    };

    
    const edit = (row,tipo) => {
      limpiar();
      limpiarProdOpcion();
      id.value = row.id;
      nombre.value = row.nombre;
      nombre_plural.value = row.nombre_plural;
      descripcion.value = row.descripcion;
      tipo_producto.value = row.tipo;
      categoria_elemento.value = row.categorias_id;
      produccion_interna.value = row.produccion_interna;
      precio.value = row.precio;
      imageUrl.value = row.url;
      formula_json.value = JSON.parse(row.formula);
      parametros_data.value = row.parametros;
      productos_data.value = row.productos_edit;
      if(tipo=='catalogo'){
        showM.value = true;
        showList.value = false;
      }else{
        showM.value = false;
        showList.value = true;
      }
     
      actionModal.value = null;
    };

    const getDataProvedores = async () => {
      showM.value = false;
      actionModal.value = null;
      emitter.emit('loading', true);

      try {
        const response = await provedores.GET(user.token);
        if (response != null && !response.hasOwnProperty('error')) {
          provedores_data.value = response;
        } else {
          emitter.emit('alerta_show', response.msg);
        }
      } catch (error) {
        emitter.emit('alerta_show', 'Error al obtener proveedores.');
      }

      emitter.emit('loading', false);
    };

    const getDataCategorias = async () => {
      showM.value = false;
      actionModal.value = null;
      emitter.emit('loading', true);

      try {
        const response = await categorias.GET(user.token);
        if (response != null && !response.hasOwnProperty('error')) {
          categorias_data.value = response;
        } else {
          emitter.emit('alerta_show', response.msg);
        }
      } catch (error) {
        emitter.emit('alerta_show', 'Error al obtener categorías.');
      }

      emitter.emit('loading', false);
    };

    const getData = async () => {
      limpiar();
      showM.value = false;
      actionModal.value = null;
      emitter.emit('loading', true);

      try {
        const response = await productos.CATALOGO(user.token);
        if (response != null && !response.hasOwnProperty('error')) {
          data.value = response;
        } else {
          emitter.emit('alerta_show', response.msg);
        }
      } catch (error) {
        emitter.emit('alerta_show', 'Error al obtener productos.');
      }

      emitter.emit('loading', false);
    };

    const guardar = async () => {
      var formData = new FormData();
      if (tipo_producto.value === 'producto_venta' && data_file.value != null) {
        formData.append('doc', data_file.value);
      }
      formData.append('id', id.value);
      formData.append('nombre', nombre.value);
      formData.append('nombre_plural', nombre_plural.value);
      formData.append('descripcion', descripcion.value);
      formData.append('produccion_interna', produccion_interna.value);
      formData.append('tipo_producto', tipo_producto.value);
      formData.append('formula', JSON.stringify(formula_json.value));
      if (tipo_producto.value === 'producto_venta') {
        formData.append('categoria', categoria_elemento.value);
      }
      if (
        tipo_producto.value !== 'catalogo' &&
        tipo_producto.value !== 'producto_venta'
      ) {
        formData.append('precio', precio.value);
      }

      let dat = {
        id: id.value,
        data: formData,
      };

      if (id.value === 0) {
        crear(dat);
      } else {
        editar(dat);
      }
    };

    const editar = async (dat) => {
  emitter.emit('loading', true);

  try {
    const response = await productos.EDITAR(dat);
    if (response != null && !response.hasOwnProperty('error')) {
      getData();
      showM.value = false;
      limpiar();
    } else {
      // emitter.emit("alerta_show",response.msg);
    }
  } catch (error) {
    // Handle error
  } finally {
    emitter.emit('loading', false);
  }
};

const crear = async (dat) => {
  emitter.emit('loading', true);

  try {
    const response = await productos.CREAR(dat);
    if (response != null && !response.hasOwnProperty('error')) {
      getData();
      showM.value = false;
      limpiar();
    } else {
      emitter.emit('alerta_show', response.error);
    }
  } catch (error) {
    // Handle error
  } finally {
    emitter.emit('loading', false);
  }
};


    const guardarProducto = async () => {
      var formData = new FormData();
      formData.append('id', id_prod.value);
      formData.append('nombre', nombre_prod.value);
      formData.append('tag', tag.value);
      formData.append('tipo_producto', 'opcion_catalogo');
      formData.append('precio', precio_prod.value);
      formData.append('precio_compra', precio_compra.value);
      formData.append('valor_formula', valor_formula.value);

      if (provedor.value > 0) {
        formData.append('provedores_id', provedor.value);
      }
      if (descripcion_prod.value !== '' && descripcion_prod.value !== null) {
        formData.append('descripcion', descripcion_prod.value);
      }

      formData.append('productos_id', id.value);

      let dat = {
        id: id_prod.value,
        data: formData,
      };

      if (id_prod.value === 0) {
        crearProducto(dat);
      } else {
        editarProducto(dat);
      }
    };

    const editarProducto = async (dat) => {
      emitter.emit('loading', true);

      try {
        const response = await productos.EDITAR(dat);
        if (response != null && !response.hasOwnProperty('error')) {
          productos_data.value = productos_data.value.filter(
            (row) => row.id !== dat.id
          );
          productos_data.value.push(response);
        } else {
          emitter.emit('alerta_show', response.error);
        }
        AddProductoCatalogo.value = false;
        limpiarProdOpcion();
      } catch (error) {
        emitter.emit('alerta_show', 'Error al editar producto.');
      }

      emitter.emit('loading', false);
    };

    const crearProducto = async (dat) => {
      emitter.emit('loading', true);

      try {
        const response = await productos.CREAR(dat);
        if (response != null && !response.hasOwnProperty('error')) {
          productos_data.value.push(response);
          AddProductoCatalogo.value = false;
          limpiarProdOpcion();
        } else {
          emitter.emit('alerta_show', response.error);
        }
      } catch (error) {
        emitter.emit('alerta_show', 'Error al crear producto.');
      }

      emitter.emit('loading', false);
    };

    const eliminarProducto = async (id) => {
      emitter.emit('loading', true);
      let dat = {
        id: id,
      };

      try {
        const response = await productos.ELIMINAR(dat);
        if (response != null && !response.hasOwnProperty('error')) {
          getData();
        } else {
          emitter.emit('alerta_show', response.error);
        }
        actionModal.value = null;
      } catch (error) {
        emitter.emit('alerta_show', 'Error al eliminar producto.');
      }

      emitter.emit('loading', false);
    };
    const downloadCSV = async (id) => {
      

      emitter.emit('loading', true)

      try {
      

        if (productos_data.value.length>0) {
          const formattedData = formatDataForCSV(productos_data.value)
          downloadFile(formattedData, `catalogo_${id}_${nombre.value}.csv`)
        } else {
          // Manejar el caso de error en la respuesta
          console.error('No hay productos')
        }
      } catch (error) {
        // Manejar errores de la solicitud
        console.error('Error en la solicitud:', error)
      } finally {
        emitter.emit('loading', false)
      }
    }

    const formatDataForCSV = (data) => {
    
      return data.map(item => ({
        id: item.id,
        nombre: item.nombre,
        descripcion: item.descripcion,
      
        tag: item.tag || '',
        provedores_id: item.provedores_id || '',
        valor_formula: item.valor_formula || '0',
        precio: item.precio,
        precio_compra: item.precio_compra || '0.00',
      
     
        activo: item.activo || '0',
      }))
    }
 const uploadCSV=()=>{

 }
    const downloadFile = (data, fileName) => {
      const csvContent = createCSVContent(data)

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' })
      saveAs(blob, fileName)
    }

    const createCSVContent = (data) => {
      const header = Object.keys(data[0]).join(',')
      const rows = data.map(item => Object.values(item).join(','))

      return `${header}\n${rows.join('\n')}`
    }

    onMounted(async() => {
      emitter.on('actualizacionformula', (data) => {
        managerFormula.value = false;
        formula_json.value = data;
      });
      await getData();
      await getDataCategorias();
      await getDataProvedores();
    });

    

    return {
      ImportarDatos,
      showPreview, csvData, openFileInput, handleFileUpload, parseCSV,
      uploadCSV,
      downloadCSV,
      buscar,
      open,
      filtro,
      user,
      data,
      tag,
      formulaexcel,
      provedores_data,
      categorias_data,
      tipos_productos,
      tipos_parametros,
      actionModal,
      showM,
      showList,
      id,
      tipo_producto,
      nombre,
      descripcion,
      color,
      formula_json,
      formula_mp,
      precio,
      categoria_elemento,
      imageUrl,
      nombre_plural,
      data_file,
      AddParametro,
      tipo_parametro,
      produccion_interna,
      nombre_parametro,
      parametro_catalogo,
      id_param,
      producto_id,
      parametros_data,
      popoversel,
      visible_formato,
      visible,
      visible_produccion,
      visible_instaladores,
      visible_cotizacion_t2,
      menor,
      AddProductoCatalogo,
      nombre_prod,
      formula_excel,
      precio_prod,
      precio_compra,
      valor_formula,
      descripcion_prod,
      provedor,
      id_prod,
      productos_data,
      managerFormula,
      agregarNuevo,
      limpiar,
      limpiarProdOpcion,
      editProducto,
      edit,
      guardar,
      crear,
      editar,
      guardarProducto,
      editarProducto,
      crearProducto,
      eliminarProducto,
      getDataCategorias,
      getDataProvedores,
      getData,
    };
  },
};
</script>
