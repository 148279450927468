<template>
<section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-start  ">

   

    <Tabla v-bind:key="reftable" v-if="data.length>0" :add="false" :columns="headings" :rows="data" :filtros="['cliente','vendedor','idventa','id','status_verificar']" :buscarp="buscar" :filtroApi="false" :paginate="false" />

    <el-drawer @closed="getData()" v-model="viewCotizacion" title="" :with-header="false" :size="$isMobile()?'100%':'100%'">
        <!--content-->
        <div class="h-full    flex flex-wrap content-start justify-center w-full  outline-none focus:outline-none overflow-y-scroll">
            <!--header-->

                 
                
                <alert_component tipo="success" title="Pedido por revisar y asignar " text="en esta sección se revisara e imprimira el formato de producion, ademas de asignar el empleado asignado a la produccion del producto, al precionar el boton de imprimir se genera un pdf y se marca como revisado los productos de la categoria."></alert_component>
              
               <div class="w-full flex flex-wrap" id="titulo_print">
                <div class="h-10 text-black text-base uppercase bg-gray-300 align-middle w-full flex flex-wrap " >
                    <div class="w-full h-10 bg-yellow-400 flex flex-wrap justify-start content-center font-extrabold px-5"><span class="align-middle">fecha impresión</span>:({{ formadivateTimeLT(fecha_impresion) }})</div>
                      </div>
                <div class="h-10 text-black text-base uppercase bg-gray-300 align-middle w-full flex flex-wrap" >
                    <div class="w-3/12 h-10 bg-yellow-300 flex flex-wrap justify-center content-center font-extrabold"><span class="align-middle">#Venta</span></div>
                    <div class="w-3/12 h-10 bg-yellow-200 flex flex-wrap justify-center content-center font-extrabold"><span class="align-middle">{{el_cotizacion.idventa}}</span></div>
                    <div class="w-3/12 h-10 bg-yellow-300 flex flex-wrap justify-center content-center font-extrabold"><span class="align-middle">Cliente</span></div>
                    <div class="w-3/12 h-12 bg-yellow-200 align-middle flex flex-wrap justify-center content-center"><span>{{ el_cotizacion.clientes.name}} {{ el_cotizacion.clientes.apellido_p}} {{ el_cotizacion.clientes.apellido_m}}</span></div>
                 </div>
                </div>
            <!--body-->
            <div class="w-full   flex  flex-wrap justify-start  content-start z-20 h-full">

                <div class="h-full bg-white px-2 flex flex-wrap w-full overflow-y-hidden">

                    <VistaProduccion v-bind:key="viewCotizacion" :cotizacion="el_cotizacion"  :edit="true"></VistaProduccion>

                </div>

            </div>

        </div>
        <template #footer>
            <div class="dialog-footer">
                <btn_component :text="'Cerrar'" :tipo="'info'"   @click="viewCotizacion=false"></btn_component>
                <!--<button class=" bg-blue-500 px-2 py-1 mx-2 rounded border-2  border-black" @click="viewCotizacion=false">Cerrar</button>-->

            </div>
        </template>
    </el-drawer>

</section>
</template>

    
    
<script>
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import Tabla from "@/components/Tabla.vue";
import VistaProduccion from "@/components/VistaProduccion.vue";
import generales from "@/functions/generales";
import metodos_pagos from "@/Services/metodos_pagos";
import abonos from "@/Services/abonos";
import cotizaciones from "@/Services/cotizaciones";

import {
    filter
} from "lodash";
export default {
    components: {
        Tabla,
        VistaProduccion
    },
    mixins: [generales, cotizaciones, metodos_pagos, abonos],
    data() {
        const user = useUserStore()

        return {
            headings: [

                {
                    key: 'id',
                    name: '# Cot',
                    show: true,
                    class:'min-w-[100px]'
                },
                {
                    key: 'idventa',
                    name: '# venta',
                    show: true,
                    class:'min-w-[100px]'
                },
                {
                    key: 'fecha_venta',
                    name: 'Fecha Pedido',
                    show: true,
                    class:'min-w-[200px]'
                },

                {
                    key: 'cliente',
                    name: 'Cliente',
                    show: true,
                    class:'min-w-[300px] text-base font-extrabold'
                },

                {
                    key: 'vendedor',
                    name: 'Usuario',
                    show: true,
                    class:'min-w-[300px] text-base font-extrabold'
                },
                {

                    key: 'status_revisar',
                    name: 'Estatus producción',
                    show: true,
                    class:'w-[200px]'
                },

                /* {
                     key: 'edit',
                     name: 'Editar',
                     show: true
                 },*/

            ],
            dates: [],
            upbuton: 0,
            el_cotizacion: {
                id: 0
            },
            open: false,
            user: user.getAll(),
            metodos_data: [],
            data: {},
            actionModal: null,
            viewCotizacion: false,
            showM: false,
            info: '',
            id: 0,
            obj_abono: {
                cotizacion: 0,
                tipo_pago: '',
                impuesto: 0,
                comision: 0,
                total: 0,
            },
            addAbono: false,
            tipo_metodo: null,
            buscar: 'all',
            pagina: 1,
            limit: 0,
            reftable: 0,
            tipo: 'produccion',
            fecha_impresion:new Date(),
            fecha: [this.getFirstDayOfMonth(), this.getLastDayOfMonth()],

        }

    },
    mounted() {
        emitter.on('closeProduccion', () => {

            this.closeProduccion();

        });
    },
    async created() {
        if (this.$route.query.hasOwnProperty('cid')) {
            this.buscar = this.$route.query.cid;
        }

        await this.getData()
        await this.getDataMetodos();
    },
    methods: {
        IsMov() {

            //return true;
            return this.$isMobile()
        },
        accionProduccion(row) {

            console.log(row,'---------------data---')

            this.getDataCotizacionId(row.id)
            //this.el_cotizacion = row;
           

        },

        async getDataCotizacionId(id) {
            emitter.emit("loading", true)

            let dat = {
                id: id
            }

            let r = await cotizaciones.SHOW(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.el_cotizacion = response;
                    this.viewCotizacion = true;
                   
                    emitter.emit("loading", false)
                    //this.$router.push('/app/'); 
                } else {
                    console.log(response)
                    emitter.emit("alerta_show", response.error);
                    emitter.emit("loading", false)
                }

                return;
            });
            return r;
        },
        async pageChangue(val) {

            this.page = val;
            await this.getData();

        },

        infoModal(row) {
            this.info = row;
            console.log(row, '---info---')
        },
        paginaSearch(pagina) {
            console.log('---- dat---' + pagina)
            this.pagina = pagina;

            this.getData();
        },

        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },
        limpiar() {
            this.viewCotizacion = false;
        },
        closeProduccion() {
            this.viewCotizacion = false;
        },
        buscador(row) {
            if (String(row.telefono).toUpperCase().search(String(this.buscar).toUpperCase()) != -1 || (String(row.nombre)).toUpperCase().search(String(this.buscar).toUpperCase()) != -1) {
                return true;
            } else {
                return false;
            }
        },
        getColumns() {
            let col = 0;
            this.headings.forEach(element => {
                if (element.show) {
                    col = col + 1
                }
            });
            return col;
        },

        buscarGet(text) {
            this.buscar = text;
            if (text == '') {
                this.buscar = 'all';
            }
            this.getData();
        },

        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)
            let dat = {
                search: this.buscar,
                page: this.pagina,
                tipo: this.tipo,
                inicio: this.formatDateEN(this.fecha[0]),
                fin: this.formatDateEN(this.fecha[1]),
                data: {}
            }

            await cotizaciones.GET_PRODUCCION_REVISAR_03(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    

                    this.data = response;

                   
                    this.reftable = this.reftable + 1;

                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                emitter.emit("loading", false)
            });
        },

        async Verificar(status) {

            let dat = {
                id: this.el_cotizacion.id,
                status_verificar: status,
                observaciones_verificar: this.el_cotizacion.observaciones_verificar

            }
            if (status == 'APROBADA') {
                dat.status_produccion = 'EN PRODUCCION'
            }

            if (this.el_cotizacion.id == 0) {
                //this.crear(dat)
            } else {
                this.editar(dat)
            }
        },

        async editar(dat) {
            emitter.emit("loading", true)

            await cotizaciones.EDITAR_PARAMS(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.el_cotizacion = response;
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
