<template>
<section class=" h-screen  w-full flex flex-wrap bg-gray-50 justify-center content-center overflow-y-auto">
    <div x-show="selectedRows.length" class="bg-teal-200 fixed top-0 left-0 right-0 z-40 w-full shadow " />
<div class="w-full h-6/12 bg-red-100 overflow-hidden flex flex-wrap  justify-center">
         
           
  <div class="h-full  w-full flex flex-wrap justify-center content-center overflow-hidden bg-black">

 <img class="h-full w-full   object-cover  transition-all duration-400 hover:scale-100 opacity-50 " :src="data.url" alt="blog">  
        <h2 class="w-full tracking-widest text-xs title-font font-medium text-2xl text-white mb-1 -mt-48">{{data.nombre}}</h2>
                        <h1 class="w-full title-font text-base font-extrabold text-white mb-3 -mt-32">{{data.tipo_membresia}}</h1>
                        
                        <p class="w-full leading-relaxed mb-3 text-ellipsis overflow-hidden text-white h-16 -mt-24" v-html="data.descripcion"></p>
        </div>     
   
</div>
    <div class="container px-5 py-10 mx-auto w-full h-full ">
        <div class="flex flex-wrap -m-4">
            <div class="p-4 md:w-1/3 " v-for="(row,index) in data.programas_archivos" v-bind:key="index">
                <div class="h-full rounded-xl shadow-cla-blue bg-gradient-to-r from-indigo-50 to-blue-50 overflow-hidden">
                      
                   
                         <video class="w-full h-32 object-cover "  v-if="row.url!=''" preload="metadata"  >
                          <source :src="row.url+'#t=0.8'" type="video/mp4">

                         </video>
   
                    <div class="p-6">
                        <h2 class="tracking-widest text-xs title-font font-medium text-blue-600 mb-1">{{row.nombre}}</h2>
                          
                        <p class="leading-relaxed mb-3 text-ellipsis overflow-hidden h-16" v-html="row.descripcion"></p>
                        <div class="flex items-center flex-wrap ">
                            <button @click="edit(row)" class="bg-blue-600 hover:scale-105 drop-shadow-md  shadow-cla-blue px-4 py-1 rounded-lg text-xs text-white font-extrabold">Editar</button>

                            <button @click="eliminar(row.id)" class="bg-red-600 hover:scale-105 drop-shadow-md  shadow-cla-blue px-4 py-1 rounded-lg text-xs text-white font-extrabold ml-2">Eliminar</button>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <button class="btn font-bold bg-blue-800 text-white text-3xl w-12 h-12 justify-center content-center flex flex-wrap fixed z-20  float-right m-r-xxs rounded-full fixed  bottom-5 right-5 " @click="addRow()">
        <vue-feather :type="'plus-circle'" size="24" class="self-center" :stroke="'white'" />
    </button>

    <button class="btn font-bold bg-yellow-300 text-white text-3xl w-12 h-12 justify-center content-center flex flex-wrap fixed bottom-5 z-20 right-20 float-right m-r-xxs rounded-full" @click="getData()">
        <vue-feather :type="'refresh-cw'" size="24" class="self-center" :stroke="'white'" />
    </button>

    <div v-if="showM" style="z-index:9000" class="overflow-x-hidden overflow-y-auto fixed inset-0  outline-none focus:outline-none justify-center bg-black bg-opacity-50 items-center flex">
        <div class="relative  my-6 mx-auto md:w-8/12 xl:w-6/12 lg:w-6/12">
            <!--content-->
            <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <!--header-->
                <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 class="text-3xl font-semibold">
                        {{ id==0?'Crear Archivo Programa':'Editar Archivo Programa' }}
                    </h3>
                    <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="showM=false">
                        <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                        </span>
                    </button>
                </div>
                <!--body-->
                <div class="relative p-6 flex-auto h-96 overflow-y-scroll flex flex-wrap">
                    
                    <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2 z-40">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Url Video
                        </span>
                               <input  v-model="url" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="text" placeholder="Url video">
                 
                        <video class="w-full object-cover h-48" v-if="url!=''" ref="youtube" :src="url" :loop="1" v-on:playing="true" autoplay controls="true"  :height="320"/>
                    </div>
                    <div class="w-6/12 flex flex-wrap">
                    <div class="mb-2 mt-2   justify-start flex flex-wrap w-full px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Nombre
                        </span>
                        <input id="cantidad" v-model="nombre" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="text" placeholder="Nombre">
                    </div>
                    <div class="mb-2 mt-2  justify-start flex flex-wrap w-full px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Descripcion
                        </span>
                        <textarea id="cantidad" v-model="descripcion" class="text-sm appearance-none h-24 rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline " placeholder="Descripcion" />
                    </div>
                    </div>
                  
                   
                </div>
                <!--footer-->
                <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button class="text-red-500 bg-red-500 bg-opacity-20 border border-solid border-red-500 hover:bg-red-500 hover:bg-opacity-30 hover:text-red-900 active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="showM=false">
                        Cancelar
                    </button>
                    <button class="text-blue-800 bg-blue-500 bg-opacity-40 border hover:text-white  hover:bg-blue-700 border-solid border-blue-800 font-bold uppercase px-6 py-3 text-sm outline-none  rounded focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="guardar()">
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="actionModal!=null" style="z-index:9999900" class="bg-slate-800 bg-opacity-50  h-screen w-full flex content-center justify-center items-center fixed  top-0 right-0 bottom-0 left-0">
        <div class=" bg-white w-72 h-72 self-center  flex-wrap px-5 py-14 rounded-md text-start flex content-center justify-center items-center">
            <div class="text-base mb-4 font-bold text-slate-500">
                ¿Qué desea hacer?
            </div>
            <div class="flex-wrap flex justify-between  w-full">
                <div class="w-3/6 px-2">
                    <button class="w-full bg-blue-500 bg-opacity-20 px-4 py-2 rounded-md text-md text-blue-500 border-2 border-blue-500" @click="edit(actionModal);">
                        Editar
                    </button>
                </div>
                <div class="w-3/6 px-2">
                    <button class="bg-red-500 px-4 py-2 bg-opacity-20 rounded-md text-md text-red-500 w-full  border-2 border-red-500" @click="eliminar(actionModal.id)">
                        Eliminar
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import programas from "@/Services/programas";
import archivos_programas from "@/Services/archivos_programas";
import instructores from "@/Services/instructores";

export default {
    components: {

    },
    mixins: [programas, instructores,archivos_programas],
    data() {
        const user = useUserStore()

        return {
           
            buscar: '',
           
            open: false,
            user: user.getAll(),
            data: [],
            data_file: null,
            instructores_data: [],
            actionModal: null,
            showM: false,
            id: 0,
            base64: '',
            tipo_membresia: 0,
            url: '',
            imageUrl: '',
            image: null,
            nombre: '',
            descripcion: '',
            programas_id: 0,

        }

    },
    mounted() {

    },
    created() {
        this.getData();
        this.getDataInstructores();
    },
    
    methods: {
      
      
        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },

        limpiar() {

            this.id = 0;
            this.nombre = '';
            this.descripcion = '';
            this.url = '';
            this.tipo_membresia = 'presencial';
            this.programas_id = 0;
            this.imageUrl = '';
        },
        edit(row) {
            this.id = row.id;
            this.nombre = row.nombre;
            this.descripcion = row.descripcion;

            this.url = row.url;
            this.programas_id = row.programas_id;
       

            this.showM = true;
            this.actionModal = null;
        },

        async getDataInstructores() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await instructores.GET(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.instructores_data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)
            this.programas_id=this.$route.query.p

            await programas.SHOW(this.$route.query.p).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async guardar() {

            if (this.url == '') {
                emitter.emit("alerta_show", 'Es necesario ingressar una url');
                return false;
            }
            var formData = new FormData();
       
                formData.append("url", this.url );
            
            
            formData.append("id", this.id, );
            formData.append("nombre", this.nombre, );
            formData.append("descripcion", this.descripcion, );
            formData.append("programas_id", this.$route.query.p, );

            let dat = {
                id: this.id,
                data: formData
            };
            if (this.id == 0) {
                this.crear(dat)
            } else {
                this.editar(dat)
            }
        },
        async editar(dat) {
            emitter.emit("loading", true)

            await archivos_programas.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async crear(dat) {
            emitter.emit("loading", true)

            await archivos_programas.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async eliminar(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await archivos_programas.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
