<template>
<button v-if="tipo=='clasic'&&info.tipo_boton==1" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border-0 focus:outline-none focus:ring transition text-gray-600 bg-gray-50 hover:text-gray-800 hover:bg-gray-100 active:bg-gray-200 focus:ring-gray-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class='text-lg font-medium text-white'>Get started</span>
        <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M0 8.71423C0 8.47852 0.094421 8.25246 0.262491 8.08578C0.430562 7.91911 0.658514 7.82547 0.896201 7.82547H13.9388L8.29808 2.23337C8.12979 2.06648 8.03525 1.84013 8.03525 1.60412C8.03525 1.36811 8.12979 1.14176 8.29808 0.974875C8.46636 0.807989 8.6946 0.714233 8.93259 0.714233C9.17057 0.714233 9.39882 0.807989 9.5671 0.974875L16.7367 8.08499C16.8202 8.16755 16.8864 8.26562 16.9316 8.3736C16.9767 8.48158 17 8.59733 17 8.71423C17 8.83114 16.9767 8.94689 16.9316 9.05487C16.8864 9.16284 16.8202 9.26092 16.7367 9.34348L9.5671 16.4536C9.39882 16.6205 9.17057 16.7142 8.93259 16.7142C8.6946 16.7142 8.46636 16.6205 8.29808 16.4536C8.12979 16.2867 8.03525 16.0604 8.03525 15.8243C8.03525 15.5883 8.12979 15.362 8.29808 15.1951L13.9388 9.603H0.896201C0.658514 9.603 0.430562 9.50936 0.262491 9.34268C0.094421 9.17601 0 8.94995 0 8.71423Z' fill='white' />
        </svg>
    </div>
</button>
<button v-if="tipo=='purple'&&info.tipo_boton==1" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border-0 focus:outline-none focus:ring transition text-purple-600 bg-purple-50 hover:text-purple-800 hover:bg-purple-100 active:bg-purple-200 focus:ring-purple-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='info'&&info.tipo_boton==1" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border-0 focus:outline-none focus:ring transition text-blue-600 bg-blue-50 hover:text-blue-800 hover:bg-blue-100 active:bg-blue-200 focus:ring-blue-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='success'&&info.tipo_boton==1" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border-0 focus:outline-none focus:ring transition text-green-600 bg-green-50 hover:text-green-800 hover:bg-green-100 active:bg-green-200 focus:ring-green-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='warning'&&info.tipo_boton==1" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border-0 focus:outline-none focus:ring transition text-yellow-600 bg-yellow-50 hover:text-yellow-800 hover:bg-yellow-100 active:bg-yellow-200 focus:ring-yellow-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='danger'&&info.tipo_boton==1" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border-0 focus:outline-none focus:ring transition text-red-600 bg-red-50 hover:text-red-800 hover:bg-red-100 active:bg-red-200 focus:ring-red-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>

<button v-if="tipo=='clasic'&&info.tipo_boton==2" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border-0 focus:outline-none focus:ring transition text-white bg-gray-500 hover:bg-gray-600 active:bg-gray-700 focus:ring-gray-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='purple'&&info.tipo_boton==2" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border-0 focus:outline-none focus:ring transition text-white bg-purple-500 hover:bg-purple-600 active:bg-purple-700 focus:ring-purple-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='info'&&info.tipo_boton==2" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border-0 focus:outline-none focus:ring transition text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700 focus:ring-blue-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='success'&&info.tipo_boton==2" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border-0 focus:outline-none focus:ring transition text-white bg-green-500 hover:bg-green-600 active:bg-green-700 focus:ring-green-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='warning'&&info.tipo_boton==2" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border-0 focus:outline-none focus:ring transition text-white bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 focus:ring-yellow-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='danger'&&info.tipo_boton==2" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border-0 focus:outline-none focus:ring transition text-white bg-red-500 hover:bg-red-600 active:bg-red-700 focus:ring-red-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>

<button v-if="tipo=='clasic'&&info.tipo_boton==3" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border focus:outline-none focus:ring transition text-gray-600 border-gray-600 hover:text-white hover:bg-gray-600 active:bg-gray-700 focus:ring-gray-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='purple'&&info.tipo_boton==3" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border focus:outline-none focus:ring transition text-purple-600 border-purple-600 hover:text-white hover:bg-purple-600 active:bg-purple-700 focus:ring-purple-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='info'&&info.tipo_boton==3" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border focus:outline-none focus:ring transition text-blue-600 border-blue-600 hover:text-white hover:bg-blue-600 active:bg-blue-700 focus:ring-blue-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='success'&&info.tipo_boton==3" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border focus:outline-none focus:ring transition text-green-600 border-green-600 hover:text-white hover:bg-green-600 active:bg-green-700 focus:ring-green-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='warning'&&info.tipo_boton==3" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border focus:outline-none focus:ring transition text-yellow-600 border-yellow-600 hover:text-white hover:bg-yellow-600 active:bg-yellow-700 focus:ring-yellow-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='danger'&&info.tipo_boton==3" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border focus:outline-none focus:ring transition text-red-600 border-red-600 hover:text-white hover:bg-red-600 active:bg-red-700 focus:ring-red-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>

<button v-if="tipo=='clasic'&&info.tipo_boton==4" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border shadow focus:outline-none focus:ring transition text-gray-600 bg-gray-600 bg-opacity-40 border-gray-200 hover:bg-gray-100 active:bg-gray-200 focus:ring-gray-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='purple'&&info.tipo_boton==4" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border shadow focus:outline-none focus:ring transition text-purple-600 bg-opacity-40 bg-purple-600 border-purple-800 hover:bg-purple-100 active:bg-purple-200 focus:ring-purple-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='info'&&info.tipo_boton==4" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold border shadow focus:outline-none focus:ring transition text-blue-700  bg-blue-600 bg-opacity-40 border-blue-800 hover:bg-blue-100 active:bg-blue-200 focus:ring-blue-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='success'&&info.tipo_boton==4" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border shadow focus:outline-none focus:ring transition text-green-600 bg-green-600 bg-opacity-40 border-green-800 hover:bg-green-100 active:bg-green-200 focus:ring-green-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='warning'&&info.tipo_boton==4" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border shadow focus:outline-none focus:ring transition text-yellow-600 bg-yellow-600 bg-opacity-40  border-yellow-800 hover:bg-yellow-100 active:bg-yellow-200 focus:ring-yellow-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='danger'&&info.tipo_boton==4" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border shadow focus:outline-none focus:ring transition text-red-600 bg-red-600 bg-opacity-40  border-red-800 hover:bg-red-100 active:bg-red-200 focus:ring-red-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>

<button v-if="tipo=='clasic'&&info.tipo_boton==5" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase focus:outline-none focus:ring transition text-gray-600 hover:bg-gray-50 active:bg-gray-100 focus:ring-gray-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='purple'&&info.tipo_boton==5" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase focus:outline-none focus:ring transition text-purple-600 hover:bg-purple-50 active:bg-purple-100 focus:ring-purple-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='info'&&info.tipo_boton==5" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase focus:outline-none focus:ring transition text-blue-600 hover:bg-blue-50 active:bg-blue-100 focus:ring-blue-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='success'&&info.tipo_boton==5" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase focus:outline-none focus:ring transition text-green-600 hover:bg-green-50 active:bg-green-100 focus:ring-green-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='warning'&&info.tipo_boton==5" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase focus:outline-none focus:ring transition text-yellow-600 hover:bg-yellow-50 active:bg-yellow-100 focus:ring-yellow-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='danger'&&info.tipo_boton==5" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase focus:outline-none focus:ring transition text-red-600 hover:bg-red-50 active:bg-red-100 focus:ring-red-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>

<button v-if="tipo=='clasic'&&info.tipo_boton==6" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border-b-2 focus:outline-none focus:ring transition text-white bg-gray-500 border-gray-800 hover:bg-gray-600 active:bg-gray-700 focus:ring-gray-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='purple'&&info.tipo_boton==6" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border-b-2 focus:outline-none focus:ring transition text-white bg-purple-500 border-purple-800 hover:bg-purple-600 active:bg-purple-700 focus:ring-purple-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='info'&&info.tipo_boton==6" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border-b-2 focus:outline-none focus:ring transition text-white bg-blue-500 border-blue-800 hover:bg-blue-600 active:bg-blue-700 focus:ring-blue-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='success'&&info.tipo_boton==6" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border-b-2 focus:outline-none focus:ring transition text-white bg-green-500 border-green-800 hover:bg-green-600 active:bg-green-700 focus:ring-green-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='warning'&&info.tipo_boton==6" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border-b-2 focus:outline-none focus:ring transition text-white bg-yellow-500 border-yellow-800 hover:bg-yellow-600 active:bg-yellow-700 focus:ring-yellow-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='danger'&&info.tipo_boton==6" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border-b-2 focus:outline-none focus:ring transition text-white bg-red-500 border-red-800 hover:bg-red-600 active:bg-red-700 focus:ring-red-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>



<button v-if="tipo=='clasic'&&info.tipo_boton==7" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border shadow focus:outline-none focus:ring transition text-gray-600 bg-gray-50 border-gray-200 hover:bg-gray-100 active:bg-gray-200 focus:ring-gray-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='purple'&&info.tipo_boton==7" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border shadow focus:outline-none focus:ring transition text-purple-600 bg-purple-50 border-purple-200 hover:bg-purple-100 active:bg-purple-200 focus:ring-purple-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='info'&&info.tipo_boton==7" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border shadow focus:outline-none focus:ring transition text-blue-600 bg-blue-50 border-blue-200 hover:bg-blue-100 active:bg-blue-200 focus:ring-blue-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='success'&&info.tipo_boton==7" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border shadow focus:outline-none focus:ring transition text-green-600 bg-green-50 border-green-200 hover:bg-green-100 active:bg-green-200 focus:ring-green-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='warning'&&info.tipo_boton==7" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border shadow focus:outline-none focus:ring transition text-yellow-600 bg-yellow-50 border-yellow-200 hover:bg-yellow-100 active:bg-yellow-200 focus:ring-yellow-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
<button v-if="tipo=='danger'&&info.tipo_boton==7" class="ml-1 mb-1  px-2 py-2 rounded-md text-xs font-extrabold uppercase border shadow focus:outline-none focus:ring transition text-red-600 bg-red-50 border-red-200 hover:bg-red-100 active:bg-red-200 focus:ring-red-300" type="button" @click="handleClick">
    <div class='flex items-center justify-between flex-1'>
        <span class="bg-black text-white rounded-full  text-xs text-center px-2 py-1" v-if="count>0">{{count}}</span>
        <span class='text-xs font-extrabold uppercase  mx-1'>{{text}}</span>
        <i class="las la-arrow-right text-xl"></i>
    </div>
</button>
</template>

<script>
import {
    useInfoStore
} from '@/stores/info'
export default {
    mixins: [],
    props: {
        count:{
type:Number,
default:0
        },
        text: {
            type: String,
            default: ''

        },
        tipo: {
            type: String,
            default: 'success'

        },
        style: {
            type: String,
            default: 'default'

        },

    },
    methods:{
        handleClick() {
      this.$emit("click",null);
    }  
    },
    setup() {

        const info = useInfoStore()

        return {

            info: info.getAll(),

        }

    },
}
</script>
