<template>
<div class="w-full flex flex-wrap h-full">

    <div class="w-2/12 flex flex-wrap justify-start content-start h-full bg-gray-300 px-1  py-1 ">
       
            <div v-for="(row,index) in data" v-bind:key="index" @click="tab_activa=row.url" class="mb-1 flex items-center justify-between text-white bg-gray-700 max-w-sm font-mono text-sm py-3 px-4 w-full rounded-md">
                <div class="flex gap-1"><span></span><span>{{row.nombre}}</span></div><span class="flex text-white cursor-pointer w-5 h-5 hover:text-gray-400 duration-200">
                    <i class="las la-long-arrow-alt-right text-base"></i>
                </span>
           

            

        </div>
    </div>
    <div class="h-full w-10/12 flex flex-wrap justify-center content-start bg-purple-400">
        <iframe v-if="tab_activa!=''" class="w-full h-full" :src="tab_activa" frameborder="0" style="border:0" allowfullscreen></iframe>

    </div>

</div>
</template>

<script>
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import reportes_bi from "@/Services/reportes_bi";

export default {
    components: {

    },
    mixins: [],
    data() {
        const user = useUserStore()

        return {

            tab_activa: '',
            user: user.getAll(),
            data: [],

        }

    },
    mounted() {

    },
    created() {
        this.getData()
    },
    methods: {

        async getData() {

            emitter.emit("loading", true)

            await reportes_bi.GET(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
