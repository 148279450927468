<template>
<div class="w-full h-full flex flex-wrap justify-center content-start overflow-y-scroll">

    <!--<div class="w-full flex flex-wrap font-bold text-blue-500 fntrel  border-black">
                        <div class="w-2/12 border border-black">Producto</div>
                        <div class="w-1/12 border border-black">Tipo de Producto</div>
                        <div :class="cotizacion.avance<100?'w-5/12':' w-full '" class=" border border-black ">Detalle</div>
                        <div class="w-1/12 border  border-black"> Producción total</div>
                        <div class="w-1/12 border  border-black"> Producción generada</div>
                        <div v-if="cotizacion.avance<100" class="w-2/12 border border-r border-black"> Actualización </div>
                    </div>-->
                    <alert_component class="sticky top-0" tipo="danger" title="Listado de productos por instalar " text="En esta seccion aparecen los productos los cuales ya fueron producidos y estan listos para instalar."></alert_component>
      <div class="flex flex-wrap justify-between content-center w-full sticky top-10">
        <div  class="flex flex-wrap justify-start w-full ">
            <input v-model="buscar" type="search" class="rounded-md border-0 w-full pl-10 pr-4 py-2  shadow focus:outline-none focus:shadow-outline text-gray-600 font-medium" placeholder="Buscador...">

        </div>
       
      </div>
    <div class="zoom2 w-full overflow-x-scroll   border-black mb-10 content-start justify-start flex-wrap">

        <div class="w-full h-full flex flex-wrap justify-center content-start   overflow-x-scroll">

            <!--<div class="w-full flex flex-wrap font-bold text-blue-500 fntrel  border-black">
                                        <div class="w-2/12 border border-black">Producto</div>
                                        <div class="w-1/12 border border-black">Tipo de Producto</div>
                                        <div :class="cotizacion.avance<100?'w-5/12':' w-full '" class=" border border-black ">Detalle</div>
                                        <div class="w-1/12 border  border-black"> Producción total</div>
                                        <div class="w-1/12 border  border-black"> Producción generada</div>
                                        <div v-if="cotizacion.avance<100" class="w-2/12 border border-r border-black"> Actualización </div>
                                    </div>-->

            <div   class="w-full    border-black  content-start justify-start flex flex-wrap ">

                <template  v-for="(r,indice) in cotizaciones" v-bind:key="indice">
                    <div class="w-auto  flex flex-wrap " v-if="String(r.cotizacion.clientes.name+' '+r.cotizacion.clientes.apellido_p+' '+r.cotizacion.clientes.apellido_m).toUpperCase().includes(String(buscar).toUpperCase())">

                        <div v-if="Number(r.produccion).toFixed(0)==Number(r.cantidad).toFixed(0)" class="w-auto flex flex-nowrap bg-blue-100 border-black border-t-2">
                            <div class="w-48  align-middle border-black border-l">
                                #cot
                            </div>
                            <div class="w-48  align-middle border-black border-l">
                                Fecha pedido
                            </div>
                            <div class="w-48  align-middle border-black border-l">
                                Produccion interna
                            </div>
                            <div class="w-48  align-middle border-black border-l">
                                # folio
                            </div>
                            <div class="w-48  align-middle border-black border-l">
                                # Venta
                            </div>
                            <div class="w-48  align-middle border-black border-l">
                                aprobado el
                            </div>
                            <div class="w-80  align-middle border-black border-l">
                                Cliente
                            </div>
                           
                            
                            <div class="w-48  align-middle border-black border-l">
                                Cantidad pedido
                            </div>
                            <div class="w-48  align-middle border-black border-l">
                                Cantidad producida
                            </div>
                            <div class="w-48  align-middle border-black border-l">
                                Cantidad instalada
                            </div>
                            <div class="w-48  align-middle border-black border-l">
                                Marcar instalación
                            </div>
                            <div class="w-48  align-middle border-black border-l">
                                Marcar fecha tentativa instalación
                            </div>
                            <div class="w-48  align-middle border-black border-l">
                                Agendar Instalacion
                            </div>
    
                            <div class="w-48  align-middle border-black border-l">
                                Producto
                            </div>
                            <template v-for="(p,i) in r.parametros" v-bind:key="i">
                                <div class="w-48  align-middle border-black border-l" v-if="p.visible_produccion==1">
                                    <strong v-if="p.tipo=='numerico'">{{p.nombre}} </strong>
                                    <strong v-if="p.tipo=='texto'">{{p.nombre}} </strong>
                                    <strong v-if="p.tipo=='calc_formula'"> {{p.nombre}}  </strong>
                                    <strong v-if="p.tipo=='texto_extendido'"> {{p.nombre}}  </strong>
                                     
                                   
                                    <strong v-if="p.tipo=='catalogo'">{{p.selproductos.nombre}} </strong>
    
                                </div>
                            </template>
                        </div>
                        <div v-if="Number(r.produccion).toFixed(0)==Number(r.cantidad).toFixed(0)" class="w-auto flex flex-nowrap">
                           
                            <div class="border border-black text-center align-middle w-48 font-extrabold text-blue-500 bg-blue-100 underline ">
                          
                                {{ r.cotizacion.id }}
                            </div>
                            <div class="border border-black text-center align-middle w-48 font-extrabold text-blue-500 bg-blue-100 underline ">
                          
                                {{ r.cotizacion.venta }}
                            </div>
                            <div :class="r.produccion_interna?'text-yellow-500 bg-yellow-100':'text-red-500 bg-red-100'" class="border border-black text-center align-middle w-48 font-extrabold  underline ">
                                {{r.produccion_interna?'Produccion interna':'Proveedor'}}
                            </div>
                            <div class="border border-black text-center align-middle w-48 font-extrabold text-blue-500 bg-blue-100 underline">
                                {{r.productos.categorias.nombre_corto}}
                            </div>
                            <div class="border border-black text-center align-middle w-48 font-extrabold text-blue-500 bg-blue-100 underline ">
                                {{r.cotizacion.idventa}}
                            </div>
                            <div @click="r.formato_aprobado?null:AprobarFormatoProducto(r.id)" :class="r.formato_aprobado?'text-green-500':'text-red-500'" class="border border-black text-center align-middle w-48 font-extrabold   underline ">
                                <p>
    
                                    {{r.formato_aprobado?formatDateTimeLT(r.fecha_aprobado):''}}
                                </p>
                            </div>
                            <div class="border border-black text-center align-middle w-80 font-extrabold text-green-500 bg-green-100 underline ">
                                {{r.cotizacion.clientes.name}} - {{r.cotizacion.clientes.apellido_p}} - {{r.cotizacion.clientes.apellido_m}}
                            </div>
                           
    
                            
                            <div class="border border-black text-center align-middle w-48 font-extrabold text-green-500 bg-green-100 underline  text-3xl">
                                {{Number(r.cantidad).toFixed(0)}}
                            </div>
                            <div class="border border-black text-center align-middle w-48 font-extrabold text-green-500 bg-green-100 underline  text-3xl">
                                {{Number(r.produccion).toFixed(0)}}
                            </div>
                            <div class="border border-black text-center align-middle w-48 font-extrabold text-green-500 bg-green-100 underline  text-3xl">
                                {{Number(r.instalados).toFixed(0)}}
                            </div>
                            <div class="border border-black text-center  w-48 font-extrabold text-blue-500 cursor-pointer  underline text-2xl align-middle">
                                <span v-if="Number(r.instalados).toFixed(0)<Number(r.produccion).toFixed(0)" @click="MarcarInstalacion(r.id,r.cotizacion.id)">
                                    Marcar instalación
                                </span>
                            </div>
                            <div class="border border-black text-center  w-48 font-extrabold text-blue-500 cursor-pointer  underline text-2xl align-middle">
                                <span v-if="Number(r.instalados).toFixed(0)<Number(r.produccion).toFixed(0)&&r.fecha_tentativa_instalacion==null" @click="MarcarFechaTentativaInstalacion(r.id,r.cotizacion.id)">
                                    Marcar Fecha Tentativa
                                </span>
                                {{ r.fecha_tentativa_instalacion }}
                                <span v-if="r.fecha_tentativa_instalacion!=null">
                                    
                                    {{r.fecha_tentativa_instalacion?formatDateTimeLT(r.fecha_tentativa_instalacion):''}}
                                </span>
                            </div>
                            <div class="border border-black text-center  w-48 font-extrabold text-blue-500 cursor-pointer  underline text-2xl align-middle">
                              
                              <div v-if="r.produtos_instalados.length>0" >
                                <template v-for="(prod,index_prod) in r.produtos_instalados" v-bind:key="index_prod">
                                    <a  class="uppercase text-red-500" :href="'/app/detalle/instaladores?cid='+prod.citas_id+'&m='+getMes(prod.citas.fecha_inicio)+'&a='+getAno(prod.citas.fecha_inicio)"><i class="text-2xl las la-calendar"></i></a>
                             
                                </template>
                               
                              </div>
                               
                                <a v-if="calcCantidadAgendada(r.produtos_instalados)==0&&Number(r.instalados).toFixed(0)<Number(r.produccion).toFixed(0)" target="_blank" :href="'/app/cita?idv='+r.cotizacion.idventa+'&tv=add'" class=" cursor-pointer  text-2xl  font-extrabold px-3 rounded-lg py-1  text-center  items-center text-green-600">
                                    Agendar instalación
                                </a>
    
                            </div>
    
                            <div class="w-48 border border-black align-middle text-center">
                                {{r.productos.nombre}}
                            </div>
                            <template v-for="(p,i) in r.parametros" v-bind:key="i">
                                <div v-if="p.visible_produccion==1" class="w-48 border border-black align-middle text-center">
                                    <template v-if="p.tipo=='numerico'"> {{p.valor_prod}} </template>
                                    <template v-if="p.tipo=='texto'">{{p.valor_prod}} </template>
                                    <template v-if="p.tipo=='texto_extendido'">{{p.valor_prod}} </template>
                                    <template v-if="p.tipo=='calc_formula'">    {{' '}} {{calcularParametroProd(r,p.formula_mp)}} {{' '}}</template>
                                    <template v-if="p.tipo=='value_formula'">    {{' '}} {{calcularParametroProd(r,p.formula_mp)}} {{' '}}</template>
                                     
                                    <template v-if="p.tipo=='catalogo'"> ( {{p.valor_prod==0?'N/A':p.producto_lista_prod!=null?p.producto_lista_prod.nombre:'--'}} ) </template>
    
                                </div>
                            </template>
    
                        </div>
    
                    </div>
                </template>
               

            </div>
        </div>

    </div>

</div>
</template>

<script>
//@ts-ignore
import {
    emitter
} from "@/mitt.js";
import generales from '@/functions/generales'

import cotizacionesf from '@/functions/cotizaciones'
import productos_cotizaciones from "@/Services/productos_cotizaciones";
import cotizaciones from "@/Services/cotizaciones";
import pdf from "@/Services/pdf";
import {
    throwStatement
} from '@babel/types';
//import palabras from "@/mixins/palabras";
import {
    useInfoStore
} from '../stores/info'
export default {
    name: "Home",
    components: {

    },
    mixins: [generales,cotizacionesf],
    props: {

        cotizaciones: {
            type: Array,
        },

    },
    data() {
        const info = useInfoStore()
        return {
            buscar:'',
            infositio: info.getAll(),
            categorias: {}

        }
    },

    mounted() {

        console.log(this.cotizaciones)

    },

    methods: {
        async MarcarFechaTentativaInstalacion(idproducto, idcot) {
            emitter.emit("loading", true)
            let dat = {
                idcot: idcot,
                idprod: idproducto

            }
            await productos_cotizaciones.ACTUALIZAR_FECHA_TENTATIVA(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.cotizaciones.forEach(cotizacion => {
                        
                        if (idproducto == cotizacion.id) {
                           
                            cotizacion.fecha_tentativa_instalacion = response.fecha_tentativa_instalacion;

                                

                            
                        }

                    });

                    // this.sel_producto.fecha_asignacion=response.fecha_asignacion;

                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                emitter.emit("loading", false)
            });
        },
        async MarcarInstalacion(idproducto, idcot) {
            emitter.emit("loading", true)
            let dat = {
                idcot: idcot,
                idprod: idproducto

            }
            await productos_cotizaciones.ACTUALIZAR_INSTALADOS(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.cotizaciones.forEach(cotizacion => {

                        if (idcot == cotizacion.cotizacion.id) {
                            cotizacion.productos_cotizaciones_instaladores.forEach(element => {

                                if (idproducto == element.id) {
                                    element.produccion = element.produccion + 1;

                                }

                            });
                        }

                    });

                    // this.sel_producto.fecha_asignacion=response.fecha_asignacion;

                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                emitter.emit("loading", false)
            });
        },
        calcCantidadAgendada(agendados) {

            let cantidad = 0;
            agendados.forEach(element => {
                cantidad = cantidad + element.cantidad
            });
            return cantidad
        }

    }
}
</script>

<style>
td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.vgt-wrap {
    height: 100%;
}

.vgt-inner-wrap {
    height: 100%;
}
</style>
