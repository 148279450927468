<template>
<section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-start  ">

    <div class="w-full flex flex-wrap justify-center content-start h-full  overflow-y-hidden">
        <div class="w-4/12 h-full flex flex-wrap content-start justify-center  bg-gray-200 overflow-y-scroll">

            <form class="px-5 w-full flex flex-wrap content-start justify-center">

                <div class="mb-6 flex flex-wrap justify-between mt-5">

                    <div class="flex flex-wrap justify-start w-full px-2">
                        <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Proveedor</label>

                        <select v-model="proveedores_id" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            <option :selected="proveedores_id==0" :value="0">Seleccione un proveedor</option>

                            <option v-for="(row,index) in data_proveedores" v-bind:key="index" :value="row.id">{{row.nombre}}</option>

                        </select>
                    </div>
                    <div class="w-full px-2   flex flex-wrap justify-start">
                        <div class="mb-5 w-full">
                            <label for="lName" class="mb-3 block text-base font-medium   text-left text-[#07074D]">
                                # Pedido
                            </label>
                            <input v-model="cotizaciones_id" type="text" id="visitors" class="h-12 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="# venta" required>

                           <!--- <SearchSelectCotizacion class="w-full" v-bind:key="cotizacion" @carga="buscarGetCotizaciones" :rows="cotizaciones_data" :label="'cotizacion'" :valor="cotizacion" @seleccionado="action_cotizacion" />-->

                        </div>
                    </div>
                    <div class="w-full py-2 text-start">
                        <label for="visitors" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Observaciones</label>
                        <textarea v-model="observaciones" type="number" id="visitors" class="h-24 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Observaciones" required>
                            </textarea>

                    </div>
                    <div class="w-full py-2 text-start">
                        <label for="visitors" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"># Factura</label>
                        <input v-model="numero_factura" type="text" id="visitors" class="h-12 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="# Factura" required>

                    </div>
                    <div class=" flex flex-wrap justify-start w-full px-2">
                        <label class="block mb-2 text-xl font-medium text-blue-500 dark:text-gray-300">Total compra:{{ this.getTotal()}}</label>

                    </div>

                </div>

            </form>
        </div>
        <div class="w-8/12 flex flex-wrap h-full overflow-y-scroll">

            <div class="bg-white w-full   h-full ">
                <div class="text-center flex flex-wrap bg-green-200 justify-between px-5 py-5 sticky  top-0">
                    <h1 class="text-3xl font-bold">Productos </h1>
                    <button type="nutton" @click="addProductoOrden()" class="ml-2 text-md border-2 border-green-500 p-2 text-green-500 hover:text-white hover:bg-green-500 rounded-lg flex">
                        <svg class="h-6 w-6" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <circle cx="12" cy="12" r="9" />
                            <line x1="9" y1="12" x2="15" y2="12" />
                            <line x1="12" y1="9" x2="12" y2="15" /></svg>
                        <span>Agregar</span>
                    </button>

                </div>
                <div class="mt-8 text-xs px-2">
                    <ul>
                        <li class="p-2 rounded-lg text-md mb-5 bg-gray-200" v-for="(row,index) in detalle_ordenes" v-bind:key="index">
                            <div class="flex align-middle flex-wrap w-full ">
                                <div class="w-full flex align-middle flex-row justify-between">
                                    <div class="p-2 text-start w-6/12">
                                        Producto
                                        <div class=" w-full flex flex-wrap">
                                            <v-select class="w-full" :reduce="(option) => option.id" v-model="row.productos_id" :options="productos_data" @change="selectProducto($event,index)" label="nombre" placeholder="Seleccione un producto">
                                                <template #selected-option="option">
                                                    {{ option.nombre_full }} - {{ option.precio_compra }}

                                                </template>
                                                <template v-slot:option="option">

                                                    {{ option.nombre_full }} - {{ option.precio_compra }}
                                                </template>
                                            </v-select>
                                            <!--<select   class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                                                <option :selected="row.nombre==0" :value="0">Seleccione un producto</option>

                                                <option v-for="(row_producto,index_prod) in productos_data" v-bind:key="index_prod" :value="row_producto.id" :selected="row.productos_id==row.id">{{row_producto.nombre}} / {{row_producto.categoria}}</option>

                                            </select>-->
                                        </div>

                                    </div>
                                    <div class="p-2 text-start w-3/12">

                                        Unidad medida
                                        <div class=" w-full flex flex-wrap">

                                            <select v-model="row.unidades_id" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                                                <option :selected="row.unidades_id==0" :value="0">Seleccione</option>

                                                <option v-for="(row_unidades,index_unidades) in unidades_medida" v-bind:key="index_unidades" :value="row_unidades.id" :selected="row.unidades_id==row_unidades.id">
                                                    {{row_unidades.nombre}}
                                                </option>

                                            </select>
                                        </div>

                                    </div>
                                    <div class="p-2 text-start w-3/12">
                                        Cantidad
                                        <p class="  text-blue-400">
                                            <input type="number" class=" text-center text-black font-extrabold w-24 h-10  bg-gray-200 rounded" v-model="row.cantidad" @keyup="row.total=Number(row.cantidad)*Number(row.precio);" />
                                        </p>
                                    </div>
                                    <div class="p-2 text-start pt-5">

                                        <button class=" h-10 text-red-500 border-2 border-red-500 p-2 rounded-lg flex flex-wrap justify-center content-center">
                                            <svg class="h-6 w-6 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                <circle cx="12" cy="12" r="10" />
                                                <line x1="15" y1="9" x2="9" y2="15" />
                                                <line x1="9" y1="9" x2="15" y2="15" /></svg>

                                        </button>
                                    </div>

                                </div>
                                <div class="w-full flex align-middle flex-row justify-start">
                                    <div class="p-2 text-start">
                                        Precio unitario compra
                                        <p class="  text-green-400 ">
                                            <input v-model="row.precio" placeholder="precio unitario" type="number" class=" text-center text-black font-extrabold w-42 h-10  bg-red-200 rounded" @keyup="row.total=Number(row.cantidad)*Number(row.precio)" />

                                        </p>
                                    </div>

                                </div>
                                <div class="w-full flex align-middle flex-row justify-between">
                                    <div class="p-2 text-start font-extrabold align-middle">

                                        <p class="text-xl  text-red-400">
                                            Total compra :{{row.total}}
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>

            </div>
            <div class="w-full flex flex-wrap justify-between p-5 bg-blue-100 sticky  bottom-0">
                <button @click="GuardarOrden()" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Guardar orden</button>

            </div>

        </div>

    </div>

</section>
</template>

<script>
import {
    emitter
} from "@/mitt.js";
import {
    useUserStore
} from '@/stores/user'
import productos from "@/Services/productos";
import categorias from "@/Services/categorias";
import unidades from "@/Services/unidades";
import proveedores from "@/Services/provedores";
import clientes from "@/Services/clientes";
import ordenes from "@/Services/ordenes";

import cotizaciones from "@/Services/cotizaciones";
import SearchSelectCotizacion from "@/components/SearchSelectCotizacion"
import generales from "@/functions/generales";

import {
    filter
} from "lodash";
export default {
    components: {
        //SearchSelectCotizacion
    },
    mixins: [productos, generales],
    data() {
        const user = useUserStore()

        return {

            upbuton: 0,
            open: false,
            user: user.getAll(),
            data: {},
            actionModal: null,
            showM: false,
            info: '',

            tipo_cliente: '',

            id: 0,
            cotizaciones_id:0,
            cotizaciones_data: [],
            cotizacion: null,
            cotizacion_sel: null,
            observaciones: '',
            data_categorias: [],
            detalle_ordenes: [],
            productos_data: [],
            data_proveedores: [],
            unidades_medida: [],
            numero_factura: '',
            total: '',
            proveedores_id: 0,
            clientes_id: 0,
            cliente: null,
            clientes_data: [],
            index_prod: [],

            origenes: [],
            buscar_c: '',
           
            buscar: '',
            pagina: 1,
            
            limit: 10,

        }

    },
    mounted() {
        this.getDataCategorias();
        this.getDataUnidades();
        this.getDataProveedores();
        if (this.$route.query.hasOwnProperty('id')) {

            this.getDataOrden(this.$route.query.id)
        }
    },
    created() {
        if (this.$route.query.hasOwnProperty('r')) {
            this.buscar = this.$route.query.r;
        }
        this.getData();

    },
    methods: {

        getTotal() {
            let total = 0;
            this.detalle_ordenes.forEach(element => {
                total += this.validarNumero(element.total)
            });
            this.total = total;
            return total;
        },

        selectProducto(event, index) {

            const producto = this.productos_data.filter((item) => event.value === item.id);

            this.detalle_ordenes[index].nombre = producto.nombre;

        },

        async getDataProveedores() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await proveedores.GET(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data_proveedores = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async getDataUnidades() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await unidades.GET(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.unidades_medida = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        addProductoOrden() {
            this.detalle_ordenes.push({
                nombre: '',

                cantidad: 1,
                precio: 0,
                total: 0,
                productos_id: 0,
                unidades_id: 0,

            })
        },
        async pageChangue(val) {

            this.page = val;
            await this.getData();

        },

        paginaSearch(pagina) {
            console.log('---- dat---' + pagina)
            this.pagina = pagina;

            this.getData();
        },

        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },

        limpiar() {
            this.id = 0;

            this.observaciones = '';
            this.proveedores_id = '';
            this.total = '';
            this.cotizaciones_id=0;
            this.detalle_ordenes = [];

        },

        async getDataOrden(id) {

            emitter.emit("loading", true)

            await ordenes.SHOW({
                id: id
            }).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.id = response.id;

                    this.observaciones = response.observaciones;
                    this.proveedores_id = response.provedores_id;
                    this.total = response.total;
                    this.numero_factura = response.numero_factura;
                    this.cotizaciones_id=response.cotizaciones_id;
                    this.detalle_ordenes = response.elementos_ordenes;

                    this.reftable = this.reftable + 1;

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },

        async getDataCategorias() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await categorias.GET(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data_categorias = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await productos.OPCION().then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.productos_data = response

                    this.reftable = this.reftable + 1;

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async GuardarOrden() {
            let dat = {
                id: this.id,

                observaciones: this.observaciones,
                proveedores_id: this.proveedores_id,
                total: this.getTotal(),
                numero_factura: this.numero_factura,
                cotizaciones_id:this.cotizaciones_id,

                productos: this.detalle_ordenes

            }
            if (this.id == 0) {
                this.crear(dat)
            } else {
                this.editar(dat)
            }
        },
        async editar(dat) {
            emitter.emit("loading", true)

            await ordenes.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    this.$router.push('/app/ordenes/compras')
                    this.limpiar();
                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                this.showM = false;

                emitter.emit("loading", false)
            });
        },

        async crear(dat) {
            emitter.emit("loading", true)

            await ordenes.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    // this.getData();
                    this.showM = false;
                    this.limpiar();
                    emitter.emit("loading", false)
                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);
                    emitter.emit("loading", false)
                }

            });
        },

        async eliminar(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await productos.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);
                    // emitter.emit("alerta_show",response.msg);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },
        action_cotizacion(ev) {
            this.cotizacion = ev.value;
        },
        buscarGetCotizaciones(busqueda) {
            console.log('...----', busqueda)
            this.buscar_c = busqueda;
            this.getDataCotizaciones();

        },
        async getDataCotizaciones() {

            let dat = {
                search: this.buscar_c,
                page: this.pagina,
                limit: this.limit,
                data: {}
            }

            await cotizaciones.GET(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    if (this.$route.query.hasOwnProperty('idc')) {

                       

                        this.cotizaciones_data = response.data;
                        // this.cotizacion=
                    } else {
                        this.cotizaciones_data = response.data;
                    }

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }

            });
        },

    }
}
</script>
