<template>
    <div class="w-full bg-gray-50 h-full overflow-y-auto p-8 ">
        <div class="flex items-center justify-between">
            <p tabindex="0" class="focus:outline-none text-2xl font-semibold leading-6 text-gray-800">Información de uso</p>

        </div>
        
  
        <div class="w-full p-3 mt-2 bg-black rounded flex  items-center">
          
            <div class="pl-3 w-full flex items-center justify-between">
                <p tabindex="0" class="focus:outline-none text-sm leading-none text-white">Borde negro instalación rechazada </p>
            </div>
        </div>
        <div class="w-full p-3 mt-2 bg-green-500 rounded flex  items-center">
          
            <div class="pl-3 w-full flex items-center justify-between">
                <p tabindex="0" class="focus:outline-none text-sm leading-none text-white">Borde verde instalación realizada </p>
            </div>
        </div>
        <div class="w-full p-3 mt-2 bg-blue-500 rounded flex  items-center">
          
            <div class="pl-3 w-full flex items-center justify-between">
                <p tabindex="0" class="focus:outline-none text-sm leading-none text-white">Borde azul cita agendada </p>
            </div>
        </div>
        <div class="w-full p-3 mt-2 bg-lime-500 rounded flex  items-center">
          
            <div class="pl-3 w-full flex items-center justify-between">
                <p tabindex="0" class="focus:outline-none text-sm leading-none text-white">Borde verde claro cita/instalación confirmada </p>
            </div>
        </div>
        <div class="w-full p-3 mt-2 bg-red-500 rounded flex  items-center">
          
            <div class="pl-3 w-full flex items-center justify-between">
                <p tabindex="0" class="focus:outline-none text-sm leading-none text-white">Borde rojo cita/instalción cancelada</p>
            </div>
        </div>
        <div class="w-full p-3 mt-2 bg-pink-500 rounded flex  items-center">
          
            <div class="pl-3 w-full flex items-center justify-between">
                <p tabindex="0" class="focus:outline-none text-sm leading-none text-white">Borde rosa cita/instalción reagendada</p>
            </div>
        </div>
        <div class="w-full p-3 mt-2 bg-yellow-500 rounded flex  items-center">
          
            <div class="pl-3 w-full flex items-center justify-between">
                <p tabindex="0" class="focus:outline-none text-sm leading-none text-white">Borde amarillo cita/instalción no atendida</p>
            </div>
        </div>
        <div class="w-full p-3 mt-4 bg-yellow-100 rounded flex items-center">
            <div tabindex="0" aria-label="storage icon" role="img" class="focus:outline-none w-24 h-8 border text-xs font-extrabold  rounded-full bg-yellow-500 flex items-center text-black flex-shrink-0 justify-center">
                #124797
            </div>
            <div class="pl-3 w-full flex items-center justify-between">
                <p tabindex="0" class="focus:outline-none text-sm leading-none text-yellow-700">La cita tiene relacion con una cotizacion</p>
            </div>
        </div>
        <div class="w-full p-3 mt-4 bg-green-100 rounded flex items-center">
            <div tabindex="0" aria-label="storage icon" role="img" class="focus:outline-none w-8 h-8 border text-2xl rounded-full text-green-500 flex items-center bg-white  flex-shrink-0 justify-center">
                <i class="las la-thumbs-up"></i>
            </div>
            <div class="pl-3 w-full flex items-center justify-between">
                <p tabindex="0" class="focus:outline-none text-sm leading-none text-green-700">La instalación fue realizada sin problemas</p>
            </div>
        </div>
        <div class="w-full p-3 mt-4 bg-red-100 rounded flex items-center">
            <div tabindex="0" aria-label="storage icon" role="img" class="focus:outline-none w-8 h-8 border text-2xl rounded-full text-red-500 flex items-center bg-white flex-shrink-0 justify-center">
                <i class="las la-thumbs-down"></i>
            </div>
            <div class="pl-3 w-full flex items-center justify-between">
                <p tabindex="0" class="focus:outline-none text-sm leading-none text-red-700">La instalacion tuvo problemas</p>
            </div>
        </div>

        <div class="w-full p-3 mt-4 bg-green-100 rounded flex items-center">
            <div tabindex="0" aria-label="storage icon" role="img" class="focus:outline-none w-8 h-8 border text-2xl rounded-full bg-green-500 flex items-center text-white flex-shrink-0 justify-center">
                <i class="las la-user-astronaut"></i>
            </div>
            <div class="pl-3 w-full flex items-center justify-between">
                <p tabindex="0" class="focus:outline-none text-sm leading-none text-green-700">La cita es general</p>
            </div>
        </div>

        <div class="w-full p-3 mt-4 bg-red-100 rounded flex items-center">
            <div tabindex="0" aria-label="storage icon" role="img" class="focus:outline-none w-8 h-8 border text-2xl rounded-full bg-red-500 flex items-center text-white flex-shrink-0 justify-center">
                <i class="las la-business-time"></i>
            </div>
            <div class="pl-3 w-full flex items-center justify-between">
                <p tabindex="0" class="focus:outline-none text-sm leading-none text-red-700">La cita es tipo pendiente</p>
            </div>
        </div>
       
        <div class="w-full p-3 mt-4 bg-purple-100 rounded flex items-center">
            <div tabindex="0" aria-label="storage icon" role="img" class="focus:outline-none w-8 h-8 border text-2xl rounded-full bg-purple-500 flex items-center text-white flex-shrink-0 justify-center">
                <i class="las la-tools"></i>
            </div>
            <div class="pl-3 w-full flex items-center justify-between">
                <p tabindex="0" class="focus:outline-none text-sm leading-none text-purple-700">La cita es de instalacion</p>
            </div>
        </div>
        <div class="w-full p-3 mt-4 bg-purple-100 rounded flex items-center">
            <div tabindex="0" aria-label="storage icon" role="img" class="focus:outline-none w-8 h-8 border text-2xl rounded-full bg-purple-500 flex items-center text-white flex-shrink-0 justify-center">
                <i class="las la-ruler-combined"></i>
            </div>
            <div class="pl-3 w-full flex items-center justify-between">
                <p tabindex="0" class="focus:outline-none text-sm leading-none text-purple-700">La cita es para revisar medidas</p>
            </div>
        </div>
        <div class="w-full p-3 mt-4 bg-blue-100 rounded flex items-center">
            <div tabindex="0" aria-label="storage icon" role="img" class="focus:outline-none w-8 h-8 border text-2xl rounded-full bg-blue-500 flex items-center text-white flex-shrink-0 justify-center">
                <i class="las la-info"></i>
            </div>
            <div class="pl-3 w-full flex items-center justify-between">
                <p tabindex="0" class="focus:outline-none text-sm leading-none text-blue-700">La cita tiene observaciones</p>
            </div>
        </div>

    </div>
</template>