<template>
<section class="w-full mx-auto h-full px-4 sm:px-6 lg:px-4 py-4 bg-gray-100 content-start flex flex-wrap">

    <div class="w-full flex flex-wrap justify-start conten-start h-full">
        <div class="  w-full flex flex-wrap justify-start content-start h-2/6 py-2">
            <div class="bg-white rounded-lg p-12 flex flex-col justify-center content-start w-3/6">
                <h1 class="font-bold text-xl  font-heading text-gray-900">
                    Metricas de vendedores

                </h1>
                <h1 class="font-bold mt-2  font-heading text-gray-900 w-full flex flex-wrap justify-center content-center">

                    <select class="w-full bg-gray-100 my-1 rounded-md h-12 text-center " v-model="metricos">
                        <option v-for="(row,i) in metricos_data" :value="i" v-bind:key="i">{{row.nombre}}</option>
                    </select>
                    <select class="w-full bg-gray-100 my-1 rounded-md h-12 text-center " v-model="vendedor" @change="getDataReporte()">
                        <option v-for="(row,i) in data_vendedores" :value="row.id" v-bind:key="i">{{row.name}}</option>
                    </select>
                    <div class="w-full flex flex-wrap justify-center content-center my-1">
                        <div class="w-6/12  flex flex-wrap justify-start">
                            <el-date-picker class="w-full" v-model="fechaInicio" type="date" placeholder="Fecha de inicio" :default-value="getDefaultStartDate" @change="getDataReporte()"></el-date-picker>

                        </div>
                        <div class="w-6/12 flex flex-wrap justify-start">
                            <el-date-picker v-model="fechaFin" type="date" placeholder="Fecha de fin" :default-value="getDefaultEndDate" @change="getDataReporte()"></el-date-picker>

                        </div>
                    </div>

                    <!-- <el-button class="w-full" type="primary" @click="applyDateRange">Aplicar</el-button>-->
                </h1>
            </div>

            <div class="w-3/6 p-2 h-full overflow-hidden">

            </div>

        </div>
        <div class=" w-full   flex flex-wrap  justify-start content-start h-4/6 py-2 space-x-1 bg-gray-100">
          
            <div v-if="metricos_data.length>0" class="flex flex-wrap w-full  bg-white text-black">
                <table class="w-full border h-full">
                    <thead>
                        <tr class="bg-yellow-100 py-4 px-2">
                            <th class="w-[300px] px-2 py-4">Nombre</th>
                            <th class="py-4">Ponderacion</th>
                            <th class="py-4">Formula</th>
                           <!-- <th>resultado</th>-->
                            <th  class="py-4">% Ponderacion Obtenido</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(r,i) in metricos_data[metricos].parametros" v-bind:key="i">
                            <td class="w-[300px] px-2 py-4">{{r.nombre}}</td>
                            <td  class="py-4">{{r.ponderacion}}</td>
                            <td  class="py-4">{{calculo(r.formula)}}</td>
                           <!-- <td>{{evalcalc(calculo(r.formula))}}</td>-->
                            <td :class="evalcalc(calculo(r.formula))?'bg-green-100':'bg-red-100'" class="py-4">{{evalcalc(calculo(r.formula))?r.ponderacion:0}} %</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>

    </div>

    <!-- Selector de fecha inicio y fecha fin -->

    <!-- Resto del contenido del componente -->
    <!-- <div v-if="data_reporte != null" :class="{'flex-row': !IsMov(), 'flex-row-reverse': IsMov()}" class="w-full h-12 bg-gray-100 flex flex-wrap content-center justify-between px-2">
            <div class="w-10/12" :class="{'justify-end': IsMov(), 'justify-center': !IsMov()}">
              <el-pagination v-model:currentPage="pagina" small background layout="prev, pager, next" :page-size="data_reporte['per_page']" :total="Number(data_reporte['total'])" @current-change="pageChangue" />
            </div>
          </div>-->

</section>
</template>

<script>
import {
    ref,
    onMounted,
    onBeforeUnmount
} from 'vue';
import {
    emitter
} from '@/mitt.js';
import {
    useUserStore
} from '@/stores/user';
import generales from '@/functions/generales';
import reportes from '@/Services/reportes';
import vendedores from '@/Services/user';
import metricos_vendedores from '@/Services/metricos_vendedores';
import DynamicChart from '@/components/charts/DynamicChart.vue';
import {
    filter
} from 'lodash';
import Tabla from "@/components/Tabla.vue";

export default {
    components: {

    },
    mixins: [generales],
    setup() {
        const user = useUserStore();
        const key = ref(0);
        const openTab = ref(1)
        const buscar = ref('all');
        const pagina = ref(1);
        const fechaInicio = ref(getDefaultStartDate());
        const fechaFin = ref(getDefaultEndDate());
        const data_reporte = ref(null);
        const data_ventas = ref(null);
        const data_citas = ref(null);
        const data_garantias = ref(null);
        const metricos_data = ref([]);
        const metricos = ref(0);
        const vendedor = ref(null);

        const data_vendedores = ref(null)

        const pageChangue = (val) => {
            pagina.value = val;
            getData();
        }
        const getData = async () => {
            getDataReporte()
        }
        const applyDateRange = () => {
            getData();
        }

        const getDataReporte = async () => {
            emitter.emit('loading', true);
            let dat = {
                //  page: pagina.value,
                fechaInicio: formatFecha(fechaInicio.value), // Pasa la fecha de inicio
                fechaFin: formatFecha(fechaFin.value),
                id: vendedor.value // Pasa la fecha de fin
            }
            await reportes.GET_CALCULOS_VENDEDOR(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    data_ventas.value = response;
                } else {
                    // emitter.emit("alerta_show",response.msg);
                }
                emitter.emit('loading', false);
            });
        }
        const getVendedores = async () => {
            emitter.emit('loading', true);
            let dat = {
                page: 1,
                limit: 200,
                search: 'all'
            }
            await vendedores.GET_USUARIOS(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    data_vendedores.value = response;

                    if (response.length > 0) {
                        vendedor.value = response[0].id;
                        getData();
                    }
                } else {
                    // emitter.emit("alerta_show",response.msg);
                }
                emitter.emit('loading', false);
            });
        }
        const getMetricosData = async () => {
            emitter.emit('loading', true);

            await metricos_vendedores.GET().then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    metricos_data.value = response;
                } else {
                    // emitter.emit("alerta_show",response.msg);
                }
                emitter.emit('loading', false);
            });
        }
        const getDataGarantias = async () => {
            emitter.emit('loading', true);
            let dat = {
                //  page: pagina.value,
                fechaInicio: formatFecha(fechaInicio.value), // Pasa la fecha de inicio
                fechaFin: formatFecha(fechaFin.value),
                id: vendedor.value // Pasa la fecha de fin
            }
            await reportes.GET_VENDEDOR_GARANTIAS(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    data_garantias.value = response;
                } else {
                    // emitter.emit("alerta_show",response.msg);
                }
                emitter.emit('loading', false);
            });
        }
        const formatFecha = (fecha) => {
            const dateObj = new Date(fecha);
            return dateObj.toISOString();
            const formattedDate = `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
            return formattedDate;
        }
        // Función para obtener el primer día del mes actual
        function getDefaultStartDate() {
            const today = new Date();
            return new Date(today.getFullYear(), today.getMonth(), 1);
        }

        // Función para obtener el último día del mes actual
        function getDefaultEndDate() {
            const today = new Date();
            return new Date(today.getFullYear(), today.getMonth() + 1, 0);
        }

        function evalcalc(expresion) {
            // Lista de operadores permitidos
      const operadoresPermitidos = ['===', '==', '>=', '<=', '<', '>'];

// Encuentra el operador en la expresión
const operadorEncontrado = operadoresPermitidos.find(op => expresion.includes(op));

// Validar el operador
if (!operadorEncontrado) {
  console.error('Operador no permitido en la expresión:', expresion);
  return 'Error';
}

// Dividir la expresión en partes
const partes = expresion.split(operadorEncontrado);

// Validar que haya dos partes
if (partes.length !== 2) {
  console.error('Formato de expresión no válido:', expresion);
  return 'Error';
}

const [izquierda, derecha] = partes.map(parte => parte.trim());

// Evaluar la expresión según el operador
switch (operadorEncontrado) {
  case '===':
    return izquierda === derecha;
  case '==':
    return izquierda == derecha; // No estrictamente igual
  case '>=':
    return Number(izquierda) >= Number(derecha);
  case '<=':
    return Number(izquierda) <= Number(derecha);
  case '<':
    return Number(izquierda) < Number(derecha);
  case '>':
    return Number(izquierda) > Number(derecha);
  default:
    console.error('Operador no reconocido:', operadorEncontrado);
    return 'Error';
}
        }

        function calculo(formula) {

            let rec = data_ventas.value != null ? data_ventas.value[0] : []
            console.log(rec, '---')

            let textoReemplazado = formula;

            for (const [key, value] of Object.entries(rec)) {

                console.log('---', key)
                const expresionRegular = new RegExp(`${key}`, 'g');
                textoReemplazado = textoReemplazado.replace(expresionRegular, value);
            }

            return textoReemplazado;

        }

        onMounted(async () => {
            getVendedores();
            getMetricosData();
            /* await getDataVentas();
               await getDataCitas();
               await getDataGarantias();
    */
        })

        onBeforeUnmount(() => {
            // Add any cleanup logic here.
        })

        return {
            evalcalc,
            calculo,
            getVendedores,
            data_vendedores,
            getDataReporte,
            getData,
            pageChangue,
            buscar,
            openTab,
            key,
            data_reporte,
            fechaInicio,
            fechaFin,
            applyDateRange,
            vendedor,
            data_ventas,
            data_citas,
            data_garantias,
            metricos_data,
            metricos

        };
    },
};
</script>

<style scoped>
/* Your component-specific styles here */
</style>
