<template>
<div class="h-full flex flex-wrap w-full content-start">
  
   
    <vue-cal ref="vuecal" locale="es" :selected-date="dia" :active-view="activeView"  hide-view-selector :time-from="9 * 60" :time-to="21 * 60" :time-cell-height="parseInt(height/23.5)" 
   :time="time_option"  sticky-split-labels :disable-views="['years', 'year']"  
      class=" demo mt-12 h-screen w-98 border-t border-gray-200 " :events="dataempleados" :on-event-click="onEventClick" events-count-on-year-view :time-step="30" @event-drop="eventoDrag">

        <template v-slot:event="{ event,view  }">
            <!--<v-icon>{{ event.icon }}</v-icon>-->
            
                <!-- Using Vuetify (but we prefer Wave UI 🤘) -->
              
   
    
            <div class="vuecal__event-title font-extrabold"></div>

            <!-- Or if your events are editable: -->

            <!-- <small class="vuecal__event-time"  >
             
              
                <strong>De</strong> <span>{{ ((event.start)) }} </span>
                <strong>a</strong> <span>{{ ((event.end))  }}</span>
            </small>-->
            <template v-if="time_option">
                <div class="vuecal__event-content h-20 overflow-hidden" v-if="view=='month'" v-html="event.content">

                </div>
                <div class="vuecal__event-content " v-else v-html="event.content">
    
                </div>
            </template>
            <template v-else>
              
                <div class="vuecal__event-content h-44 overflow-hidden" v-html="event.content">

                </div>
            </template>
            
        </template>

    </vue-cal>

    <el-dialog v-model="ViewInstalacionCotizacion" title="" :width="true?'100%':'50%'">
       
       <div class="overflow-x-scroll flex flex-col min-h-56">
        <VistaInstaladores :cotizacion="cita_sel"></VistaInstaladores>
       </div>
    </el-dialog>

    <el-dialog v-model="EdicionCreacionView" title="" :width="$isMobile()?'90%':'40%'">
        <div class="w-full  h-full overflow-x-hidden  transition ease-in-out duration-700" id="notification">
            <div class="w-full bg-gray-50 h-full overflow-y-auto p-8 ">
                <div class="flex items-center justify-between">
                    <p tabindex="0" class="focus:outline-none text-2xl font-semibold leading-6 text-gray-800">{{selectedEvent!=null?'Edición':'Creación'}} de cita</p>

                </div>

                <div class="w-full flex flex-wrap mt-10">

                    <div class=" flex flex-wrap w-full">
                        <div class="w-full px-2 flex flex-wrap  sm:w-1/2  justify-start">
                            <div class="mb-5 w-full">
                                <label for="fName" class="mb-3 block text-base font-medium   text-left text-[#07074D]">
                                    Tipo
                                </label>
                                <el-select v-model="tipo" class="w-full" placeholder="Tipo de cita">
                                    <el-option v-for="item in tipos" :key="item.value" :label="item.label" :value="item.value" />
                                </el-select>
                            </div>
                        </div>
                        <div class="w-full px-2  sm:w-1/2 flex flex-wrap">
                            <div class="mb-5 w-full justify-start ">
                                <label for="lName" class="mb-3 block text-left text-base w-full font-medium text-[#07074D]">
                                    Fecha
                                </label>
                                <el-date-picker v-model="fecha" class="w-full " type="date" placeholder="Pick a date" :default-value="new Date()" />
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-wrap w-full">
                        <div class="w-full px-2 flex flex-wrap   sm:w-1/2 justify-start">
                            <div class="mb-5 w-full flex flex-wrap">
                                <label for="fName" class="mb-3 block text-base font-medium  text-left text-[#07074D]">
                                    Hora inicio
                                </label>
                                <el-time-select v-model="startTime" :max-time="endTime" class=" w-full" placeholder="Hora inicio" start="08:30" step="00:15" end="18:30" />
                            </div>
                        </div>
                        <div class="w-full px-2 flex flex-wrap sm:w-1/2">
                            <div class="mb-5 w-full flex flex-wrap">
                                <label for="lName" class="mb-3 block text-left text-base font-medium text-[#07074D]">
                                    Hora fin
                                </label>
                                <el-time-select v-model="endTime" :min-time="startTime" placeholder="Hora fin" start="08:30" class=" w-full" step="00:15" end="18:30" />
                            </div>
                        </div>
                    </div>

                    <div class=" flex flex-wrap w-full">
                        <div class="w-full px-2 flex flex-wrap  sm:w-1/2  justify-start">
                            <div class="mb-5 w-full">
                                <label for="fName" class="mb-3 block text-base font-medium   text-left text-[#07074D]">
                                    Cliente
                                </label>
                                <SearchSelectClientecitas v-bind:key="cliente" @carga="buscarGet" :rows="clientes" :label="'cliente'" :valor="cliente" @seleccionado="action_cliente" />

                            </div>
                        </div>
                        <div class="w-full px-2  sm:w-1/2 flex flex-wrap justify-start">
                            <div class="mb-5 w-full">
                                <label for="lName" class="mb-3 block text-base font-medium   text-left text-[#07074D]">
                                    Cotizacion
                                </label>
                                <SearchSelectCotizacion class="w-full" v-bind:key="cotizacion" @carga="buscarGetCotizaciones" :rows="cotizaciones" :label="'cotizacion'" :valor="cotizacion" @seleccionado="action_cotizacion" />

                            </div>
                        </div>
                    </div>
                    <div class=" flex flex-wrap w-full">
                        <div class="w-full px-2 flex flex-wrap  w-full  justify-start">
                            <div class="mb-5 w-full">
                                <label for="fName" class="mb-3 block text-base font-medium   text-left text-[#07074D]">
                                    Observaciones
                                </label>
                                <textarea v-model="observaciones" class="h-24 w-full border-2"></textarea>
                            </div>
                        </div>

                    </div>

                    <button @click="guardarCotizacion()" type="button" class="px-4 mx-2 rounded py-2 bg-green-500 text-white font-extrabold">Guardar</button>

                </div>

            </div>
        </div>

    </el-dialog>

    <div v-if="showDialog" @click.self="showDialog=false" class="w-full h-full bg-black bg-opacity-20 z-40 fixed top-0 left-0 justify-center  content-center flex flex-wrap">

        <div class="w-full h-full xs:w-full xs:h-full sm:w-full sm:h-full md:w-80 md:h-auto lg:h-5/6 xl:h-5/6 lg:w-80 xl:w-80  py-10 px-5  bg-white self-center justify-center flex-flex-wrap overflow-y-scroll">
            <div v-if="signature" class="w-full h-96 flex flex-wrap justify-start bg-white">
                <VueSignaturePad class="w-full h-80 bg-gray-100" ref="signaturePad" />
                <div class="w-full flex flex-wrap justify-center py-2 content-center">
                    <el-button type="warning" @click="guardarFirma()">Registrar firma</el-button>
                </div>

            </div>

            <div v-if="!signature" class="w-full flex flex-wrap h-full justify-start content-start">
                <div class="w-full cursor-pointer bg-gray-100 bg-opacity-10 flex flex-wrap justify-end hover:bg-opacity-20 h-12  text-end" @click="showDialog=false;">
                    <i class="text-3xl las la-window-close"></i>
                </div>

                <div  class="flex flex-wrap w-full">
                 
                    <template v-if="selectedEvent.data.cotizaciones_id!=null ">
                        <a v-if="selectedEvent.data.cotizaciones.idventa==null"  :href="'/app/cotizaciones?cid='+selectedEvent.data.cotizaciones.id" class="select-all mb-10 w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  px-2 py-2 text-center   text-yellow-600 font-extrabold text-3xl">
                            <span style="font-weight:bold;">#Cotizacion</span>:
                            {{ selectedEvent.data.cotizaciones.id}}
            
                        </a>
                        <a v-else  :href="user.role=='admin'?'/app/detalle/ventas?cid='+selectedEvent.data.cotizaciones.id:'#'" class="select-all mb-10 w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  px-2 py-2 text-center   text-yellow-600 font-extrabold text-3xl">
                            <span style="font-weight:bold;">#Pedido</span>:
                            {{ selectedEvent.data.cotizaciones.idventa}}
            
                        </a>
                    </template>

                </div>
                <div class="flex flex-wrap w-full" v-if="selectedEvent.data.cotizaciones_id!=null ">
                    <a @click="getInfo(selectedEvent.data.id);" class=" w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  px-2 py-2 text-center justify-center  text-blue-600 font-extrabold flex flex-wrap">
                        <span class="text-base" style="font-weight:bold; ">Ver detalle productos</span>

                        <i  class="mx-1 cursor-pointer text-xl las la-eye"></i>

                    </a>

                </div>

                <div v-if="selectedEvent.data.status_instalacion!='rechazada'" class="mt-5 w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-red-600 font-bold">
                    <span> ESTATUS CITA: </span>

                    <span class="text-gray-600 uppercase select-all">{{selectedEvent.data.status}} </span>

                </div>
                <div v-if="selectedEvent.data.status_instalacion=='rechazada'" class="flex flex-wrao justify-start content-start">

                    <div class="mt-5 w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-red-600 font-bold">
                        <span> ESTATUS INSTALACION: </span>

                        <span class="text-gray-600 uppercase select-all">{{selectedEvent.data.status_instalacion}} </span>

                    </div>
                </div>
                <div  class=" w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-blue-600 font-bold">
                    <span> VENDEDOR: </span>

                    <a v-if="selectedEvent.data.usuario!=null" class="select-all text-gray-600 underline" :href="'tel:'+selectedEvent.data.usuario.phone">{{selectedEvent.data.usuario.name}} </a>

                </div>
                <div class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-blue-600 font-bold">
                    <span> CLIENTE: </span>

                    <a v-if="selectedEvent.data.clientes!=null" class="select-all text-gray-600 underline" :href="'tel:'+selectedEvent.data.clientes.phone">{{selectedEvent.data.clientes.name}}  {{selectedEvent.data.clientes.apellido_p}} {{selectedEvent.data.clientes.apellido_m}}/{{selectedEvent.data.clientes.phone}} </a>

                </div>
                <div v-if="selectedEvent.data.status_instalacion=='rechazada'" class="flex bg-red-100 py-2 px-1 flex-wrap justify-start content-start w-full">

                    <div class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-red-600 font-bold">
                        <span> FOTOS GARANTIA: </span>

                        <dd @click="proxyClick('garantia')" class='flex justify-start -space-x-1.5'>
                            <a v-for="(row,index) in selectedEvent.data.fotos" v-bind:key="index" href='#' class='inline-block -m-1'>
                                <img class='w-7 h-7 rounded-full ring-2 ring-white dark:ring-slate-800' :src='row.url' alt='avatar' />
                            </a>

                            <span class='inline-block -m-1 rounded-full ring-2 bg-green-500 h-7 flex flex-wrap justify-center content-center w-7 text-white p-0 ring-white dark:ring-slate-800'>
                                <i class="las la-plus-circle text-2xl"></i>
                            </span>
                        </dd>
                    </div>
                    <div class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-red-600 font-bold">
                        <span> OBSERVACIONES GARANTIA: </span>

                        <textarea :disabled="selectedEvent.data.status_garantia!=''&&selectedEvent.data.status_garantia!=null" v-model="selectedEvent.data.observaciones_garantia" class="h-24 w-full border-2"></textarea>
                    </div>
                    <div class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-red-600 font-bold">
                        <span> MOTIVO DE GARANTIA</span>

                        <select :disabled="selectedEvent.data.status_garantia!=''&&selectedEvent.data.status_garantia!=null" v-model="selectedEvent.data.motivo" class="h-12 w-full border-2 py-1 px-2 ">
                            <option v-for="(row,index) in data_motivos" v-bind:key="index" :value="row">{{row.nombre}}</option>
                        </select>
                    </div>
                    <btn_component v-if="selectedEvent.data.status_garantia==''||selectedEvent.data.status_garantia==null" :text="'Guardar datos de garantia'" :tipo="'warning'"   @click="EditarGarantia()"></btn_component>
                   
                    <!--<el-button v-if="selectedEvent.data.status_garantia==''||selectedEvent.data.status_garantia==null" type="warning" @click="EditarGarantia()">Guardar datos de garantia</el-button>-->

                </div>
                <div v-else class="w-full flex flex-wrap justify-start content-center">
                    <div v-if="selectedEvent.data.instalador_id!=null" class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-blue-600 font-bold">
                        <span> INSTALADOR: </span>

                        <span v-if="selectedEvent.data.instalador!=null" class="text-gray-600 select-all">{{selectedEvent.data.instalador.name}} </span>

                    </div>

                    <div class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-blue-600 font-bold">
                        <span> FECHA: </span>

                        <span class="text-gray-600 select-all">{{formatDateEN(selectedEvent.data.fecha_inicio)}} {{formatTimeName(selectedEvent.data.fecha_inicio)}} </span>

                    </div>
                    <div class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-blue-600 font-bold">
                        <span> OBSERVACIONES: </span>

                        <span class="text-gray-600 select-all">{{selectedEvent.data.observaciones}} </span>

                    </div>

                    <div class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-red-600 font-bold">
                        <span> DIRECCION: </span>

                        <a :href="'https://www.google.com/maps/search/?api=1&query='+(''+selectedEvent.data.calle+' '+selectedEvent.data.numero+' '+this.verfiNull(selectedEvent.data.codigo_postal)+' , '+selectedEvent.data.colonia+' , '+selectedEvent.data.municipio)" target="_blank" class="text-green-500 select-all">
                            {{this.verfiNull(selectedEvent.data.calle)+' '+this.verfiNull(selectedEvent.data.numero)+' '+this.verfiNull(selectedEvent.data.codigo_postal)+' , '+this.verfiNull(selectedEvent.data.colonia)+' , '+this.verfiNull(selectedEvent.data.municipio)}}</a>

                    </div>

                    <div class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-red-600 font-bold">
                        <span> FOTOS INSTALACIÓN: </span>

                        <dd @click="selectedEvent.data.status!='confirmada'?null:proxyClick('general')" class='flex justify-start -space-x-1.5'>
                            <a v-for="(row,index) in selectedEvent.data.fotos" v-bind:key="index" href='#' class='inline-block -m-1'>
                                <img class='w-7 h-7 rounded-full ring-2 ring-white dark:ring-slate-800' :src='row.url' alt='avatar' />
                            </a>

                            <span v-if="selectedEvent.data.status=='confirmada'" class='inline-block -m-1 rounded-full ring-2 bg-green-500 h-7 flex flex-wrap justify-center content-center w-7 text-white p-0 ring-white dark:ring-slate-800'>
                                <i class="las la-plus-circle text-2xl"></i>
                            </span>
                        </dd>
                    </div>
                    <div v-if="selectedEvent.data.firma_cliente!=''&&selectedEvent.data.firma_cliente!=null" class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-red-600 font-bold">
                        <span> Firma: </span>

                        <dd class='flex justify-start -space-x-1.5'>
                            <a href='#' class='inline-block -m-1'>
                                <img class='w-16 h-16 rounded-full ring-2 ring-white dark:ring-slate-800' :src='selectedEvent.data.firma_cliente' alt='avatar' />
                            </a>

                        </dd>
                    </div>

                    <!--<div v-if="(selectedEvent.data.firma_cliente==''||selectedEvent.data.firma_cliente==null)&&selectedEvent.data.status=='agendada'" class="w-full cursor-pointer border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  px-2 py-2 text-center">
                        <btn_component :text="'Con'" :tipo="'clasic'"   @click="signature=true;"></btn_component>
                    </div>-->
                    <div v-if="(selectedEvent.data.firma_cliente==''||selectedEvent.data.firma_cliente==null)&&selectedEvent.data.status=='confirmada'" class="w-full cursor-pointer border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  px-2 py-2 text-center">
                        <btn_component :text="'Registrar firma de cliente'" :tipo="'clasic'"   @click="signature=true;"></btn_component>
                    </div>
                        <!--<el-button type="primary" @click="signature=true;">Registrar firma de cliente</el-button>-->
                    <div v-if="selectedEvent.data.status=='confirmada'" class="w-full cursor-pointer border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  px-2 py-2 text-center">

                        <btn_component v-if="selectedEvent.data.firma_cliente!=null&&selectedEvent.data.firma_cliente!=''" :text="'Realizada'" :tipo="'success'"   @click="cambiarStatus(selectedEvent.data.id,'realizada','aceptada')"></btn_component>
                        <btn_component v-if="selectedEvent.data.firma_cliente==null||selectedEvent.data.firma_cliente==''" :text="'No firmo'" :tipo="'warning'"   @click="cambiarStatus(selectedEvent.data.id,'realizada','rechazada')"></btn_component>
                        <btn_component :text="'No atendida'" :tipo="'info'"   @click="cambiarStatus(selectedEvent.data.id,'no atendida')"></btn_component>
             

                        <!--<el-button v-if="selectedEvent.data.firma_cliente!=null&&selectedEvent.data.firma_cliente!=''" type="success" @click="cambiarStatus(selectedEvent.data.id,'realizada','aceptada')">Realizada</el-button>
                        <el-button v-if="selectedEvent.data.firma_cliente==null||selectedEvent.data.firma_cliente==''" type="warning" @click="cambiarStatus(selectedEvent.data.id,'realizada','rechazada')">No firmo</el-button>
                        <el-button type="info" @click="cambiarStatus(selectedEvent.data.id,'no atendida')">No atendida</el-button>-->

                    </div>

                </div>

            </div>
        </div>
    </div>

    <input type="file" style="display: none" ref="inputt" accept="image/*" @change="showPreviewAndPost" />

</div>
</template>

<script >
//@ts-nocheck

var body = document.body,
    html = document.documentElement;

var height = Math.max(body.scrollHeight, body.offsetHeight,
    html.clientHeight, html.scrollHeight, html.offsetHeight);
console.log(height, '----he------')

//@ts-ignore
import generales from '@/functions/generales'

import citas from "@/Services/citas";
import SearchSelectCotizacion from "@/components/SearchSelectCotizacion"
import SearchSelectClientecitas from "@/components/SearchSelectClientecitas"

import cotizaciones from "@/Services/cotizaciones";

import motivos_garantia from "@/Services/motivos_garantia";
import fotos_citas from "@/Services/fotos_citas";
import VistaInstaladores from "@/components/VistaInstaladores.vue";
import usuarios from "@/Services/user";
//import palabras from "@/mixins/palabras";

import {
    useUserStore
} from '@/stores/user'
//@ts-ignore
import {
    emitter
} from "@/mitt.js";
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
export default {
    name: "calendarVc",
    components: {
        VueCal,
        SearchSelectCotizacion,
        SearchSelectClientecitas,
        VistaInstaladores
    },

    mixins: [generales],
    props: {
        msg: {
            type: String,
            default: ''
        },
        dataempleados: {
            type: Array

        },
        hora: {
            type: Date,
            default: new Date
        },
        showid: {
            type: Number,
            default: 0
        },
        dia: {
            type: Date,
            default: new Date
        },
        activeView: {
            type: String
        },
        time_option:{
            type: Boolean,
            default: true
        },

    },
    data() {
        const user = useUserStore()
        return {

            //fotos
            user:user.getAll(),
            isCameraOpen: false,
            isPhotoTaken: false,
            isShotPhoto: false,
            isLoading: false,
            link: '#',
            foto: '',
            imageURL: null,
            uploadState: '',
            previewFoto: false,
           
            //fotos--
            ViewInstalacionCotizacion: false,
            EdicionCreacionView: false,
         
            selectedEvent: null,
            showDialog: false,
            dataeliminar: null,
            fecha: new Date(),
            endTime: '13:30',
            startTime: '09:30',
            height: height - 50,
            signature: false,
            tipo: 'cita',
            buscar_c: '',
            observaciones: '',
            buscar: '',
            pagina: 1,
            id: 0,
            limit: 10,
            clientes: [],
            cita_sel:null,
            cotizaciones: [],
            cotizacion: null,
            cotizacion_sel: null,
            cliente: null,
            data_motivos:[],
            tipos: [{
                    value: 'cita',
                    label: 'Cita general'
                },
                {
                    value: 'supervicion_instalacion',
                    label: 'Supervicion instalación'
                },
                {
                    value: 'reparacion',
                    label: 'Reparación'
                },
                {
                    value: 'medidas',
                    label: 'Revision de Medidas'
                },
                {
                    value: 'instalacion',
                    label: 'Instalación'
                },
                {
                    value: 'mantenimiento',
                    label: 'Manteniemiento'
                }
            ],

            selectedDate: this.dia,
            tipo_foto: 'general',
        }
    },

    computed: {
        // Get the Monday of the real time current week.
        previousFirstDayOfWeek() {
            return new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 6) % 7))
        }
    },
    methods: {
        proxyClick(tipo) {
            this.tipo_foto = tipo;
            this.$refs.inputt.click();
        },
        ///inicio modulo fotos

        async guardarFirma() {
            const {
                isEmpty,
                data
            } = this.$refs.signaturePad.saveSignature();
            console.log(isEmpty, data)
            if (isEmpty) {
                emitter.emit("alerta_show", 'No a ingresado la firma');
                return;
            } else {
                const base64 = await fetch(data);
                const blob = await base64.blob();
                this.subirFirma(blob)

            }
        },
        async subirFirma(blob) {
            emitter.emit("loading", true)
            var formData = new FormData();

            formData.append("doc", blob, );
            let dat = {
                id: this.selectedEvent.data.id,
                data: formData
            }
            await citas.REGISTRAR_FIRMA(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    this.signature = false;

                    this.selectedEvent.data.firma_cliente = response;

                    //this.productos_data = response
                    //this.$router.push('/app/'); 
                } else {
                    //this.imageURL = null;
                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        showPreviewAndPost(e) {

            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.imageURL = files[0];

            // preview image

            this.$root.$emit("loading", true);

            emitter.emit("loading", true)
            var formData = new FormData();

            formData.append("doc", this.imageURL, );
            let dat = {
                id: this.selectedEvent.data.id,
                tipo: this.tipo_foto,
                data: formData
            }
            this.subirArchivo(dat)

        },
        async subirArchivo(dat) {
            await fotos_citas.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    this.imageURL = null;

                    this.selectedEvent.data.fotos.push(response)

                    //this.productos_data = response
                    //this.$router.push('/app/'); 
                } else {
                    //this.imageURL = null;
                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },

      


    

       

     
        //finalizacion de modulo fotos

     
        buscarGetCotizaciones(busqueda) {
            console.log('...----', busqueda)
            this.buscar_c = busqueda;
            this.getDataCotizaciones();

        },
        async getDataCotizaciones() {

            let dat = {
                search: this.buscar_c,
                page: this.pagina,
                limit: this.limit,
                data: {}
            }

            await cotizaciones.GET(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    if (this.$route.query.hasOwnProperty('idc')) {

                        response.forEach(element => {
                            if (element.id == this.$route.query.idc) {
                                this.cotizacion = element;
                                this.cliente = element.clientes
                            }
                        });

                        this.cotizaciones = response;
                        // this.cotizacion=
                    } else {
                        this.cotizaciones = response;
                    }

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }

            });
        },
        async getInfo(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id
            }

            await citas.INFO(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.cita_sel = response;
                    //this.cita_sel.productos_cotizaciones=response.produtos_instalados_citas
                    this.ViewInstalacionCotizacion=true;
                    // this.cotizacion=

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)

            });
        },
        async getCotizacion() {
            emitter.emit("loading", true)
            let dat = {
                id: this.selectedEvent.id
            }

            await cotizaciones.SHOW(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.cotizacion_sel = response;
                    this.ViewInstalacionCotizacion=true;
                    // this.cotizacion=

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)

            });
        },
        buscarGet(busqueda) {
            this.buscar = busqueda;
            this.getDataClientes();

        },
        async getDataMotivosGarantia() {
           
            emitter.emit("loading", true)

            await motivos_garantia.GET().then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data_motivos = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async getDataClientes() {
            emitter.emit("loading", true)
            let dat = {
                search: this.buscar,
                page: this.pagina,
                limit: this.limit,
                data: {}
            }

            await usuarios.GET_CLIENTES(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.clientes = response;

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },

        action_cliente(ev) {
            this.cliente = ev.value;

        },
        action_cotizacion(ev) {

            this.cliente = ev.value.clientes
            this.cotizacion = ev.value;

        },

        async EditarGarantia() {
            emitter.emit("loading", true)
            let dat = {
                id: this.selectedEvent.data.id,
                observaciones_garantia: this.selectedEvent.data.observaciones_garantia,
                motivo: this.selectedEvent.data.motivo
            }
            await citas.EDITAR_GARANTIA(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.showDialog = false;
                    emitter.emit("getdataCitas", true)
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;

                emitter.emit("loading", false)
            });
        },
        async cambiarStatus(id, status, status_instalacion) {
            emitter.emit("loading", true)
            let dat = {
                id: id,
                status: status,
                status_instalacion: status_instalacion
            }
            await citas.EDITAR_STATUS(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    // this.getData();
                    //this.limpiar();
                    this.selectedEvent.data.status = status;
                    this.selectedEvent.data.status_instalacion = status_instalacion;
                    this.showDialog = false;
                    emitter.emit("getdataCitas", true)
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;

                emitter.emit("loading", false)
            });
        },
        async editar(dat) {
            emitter.emit("loading", true)

            await citas.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    // this.getData();
                    //this.limpiar();
                    this.EdicionCreacionView = false;
                    emitter.emit("getdataCitas", true)
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;

                emitter.emit("loading", false)
            });
        },

     

      
        
        onEventClick(event, e) {
            console.log(event)
            this.selectedEvent = event
            this.showDialog = true

            // Prevent navigating to narrower view (default vue-cal behavior).
            e.stopPropagation()
        },
        logEvents(type, event) {
            //console.log(event,'-----evento---',type)

        },
        onEventDragStart(e, draggable) {
            // Passing the event's data to Vue Cal through the DataTransfer object.
            e.stopPropagation()
            e.dataTransfer.setData('event', JSON.stringify(draggable))
            e.dataTransfer.setData('cursor-grab-at', e.offsetY)

        },
        eventoDrag(event) {
            console.log(event, '-----drag---')
            if (event.event.data.hasOwnProperty('asignacion')) {
                // event.stopPropagation();

            }

            this.$parent.returnVal(event)
            /*if (window.confirm("¿Esta segura de realizar este cambio?")) {
             
            }else{
              //cancelo
              console.log('----cancelo----')
            this.$parent.returnVal(event.originalEvent)
             
            }*/

        }
    },
    mounted() {
        // this.$refs.vuecal.switchView('day')
        console.log(this.dataempleados, '-------')
        if (this.$route.query.hasOwnProperty('cid')) {

            this.dataempleados.forEach(element => {
                if (element.data.id == this.$route.query.cid) {
                    this.selectedEvent = element;
                    this.showDialog = true;
                }
            });

        }
    },

    created() {

        console.log(this.selectedDate)

        // Place all the events in the real time current week.
        /* for (let i = 0; i < 5; i++) {
           const day = this.previousFirstDayOfWeek.addDays(i).format()

           this.demoExample.events.push(
             {
               start: `${day} 12:00`,
               end: `${day} 13:00`,
               title: 'LUNCH',
               class: 'lunch',
               background: true,
               deletable: false,
               resizable: false,
               split: 1
             },
             {
               start: `${day} 12:00`,
               end: `${day} 13:00`,
               title: 'LUNCH',
               class: 'lunch',
               background: true,
               deletable: false,
               resizable: false,
               split: 2
             }
           )
         }*/
        if (this.$route.query.hasOwnProperty('idc')) {
            this.buscar_c = this.$route.query.idc;
            this.EdicionCreacionView = true;
            this.getDataCotizaciones();
        }
        this.getDataMotivosGarantia();

    }
}
</script>

<style lang="css">
/*
.cardF64272  {background-color:  rgba(246, 66, 114,.30) !important; color:  rgba(246, 66, 114,3) !important;font-weight: bold; }
.cardF6648B  {background-color:  rgba(246, 100, 139,.30) !important;color:  rgba(246, 100, 139,3) !important;font-weight: bold; }
.cardF493A7  {background-color:  rgba(244, 147, 167,.30) !important;color:  rgba(244, 147, 167,3) !important; font-weight: bold;}
.cardF891A6  {background-color:  rgba(248, 145, 166,.30) !important;color:  rgba(248, 145, 166,3) !important; font-weight: bold;}
.cardFFCCD5  {background-color:  rgba(255, 204, 213,.30) !important; color:  rgba(255, 204, 213,3) !important;font-weight: bold; }
.card8b5aff  {background-color:  rgba(139, 90, 255,.30) !important; color:  rgba(139, 90, 255,3) !important;font-weight: bold; }
.carda27bff  {background-color:  rgba(162, 123, 255,.30) !important;color:  rgba(162, 123, 255,3) !important; font-weight: bold;}
.cardb99cff  {background-color:  rgba(185, 156, 255,.30) !important;color:  rgba(185, 156, 255,3) !important; font-weight: bold;}
.cardd0bdff  {background-color:  rgba(208, 189, 255,.30) !important;color:  rgba(208, 189, 255,3) !important; font-weight: bold;}
.carde8deff  {background-color:  rgba(232, 222, 255,.30) !important;color:  rgba(232, 222, 255,3) !important;font-weight: bold; }
.card51e5db  {background-color:  rgba(81, 229, 219,.30) !important; color:  rgba(81, 229, 219,3) !important; font-weight: bold;}
.card74ebe3  {background-color:  rgba(116, 235, 227,.30) !important;color:  rgba(116, 235, 227,3) !important; font-weight: bold;}
.card96f0ea  {background-color:  rgba(150, 240, 234,.30) !important;color:  rgba(150, 240, 234,3) !important;font-weight: bold; }
.cardb9f5f1  {background-color:  rgba(245, 188, 0, 0.30) !important;color:  rgba(245, 188, 0,3) !important; font-weight: bold;}
.carddcfaf8  {background-color:  rgba(220, 250, 248,.30) !important; color:  rgba(220, 250, 248,3) !important; font-weight: bold;}
.cardffa51a  {background-color:  rgba(255, 165, 26,.30)  !important; color:  rgba(255, 165, 26,3)  !important;font-weight: bold; }
.cardffb748  {background-color:  rgba(255, 183, 72,.30) !important; color:  rgba(255, 183, 72,3) !important; font-weight: bold;}

.cardffc976  {background-color:  rgba(255, 201, 118,.30) !important;color:  rgba(255, 201, 118,3) !important;font-weight: bold; }
.cardffdba3  {background-color:  rgba(255, 219, 163,.30) !important;color:  rgba(255, 219, 163,3) !important;font-weight: bold; }
.cardffedd1  {background-color:  rgba(255, 237, 209,.30) !important;color:  rgba(255, 237, 209,3) !important; font-weight: bold;}
.cardD7F9F1  {background-color:  rgba(215, 249, 241,.30) !important;color:  rgba(215, 249, 241,3) !important; font-weight: bold;}

.cardAFBC88  {background-color:  rgba(175, 188, 136,.30) !important;color:  rgba(175, 188, 136,3) !important;font-weight: bold; }
.cardF3722C  {background-color:  rgba(243, 114, 44,.30) !important;color:  rgba(243, 114, 44,3) !important;font-weight: bold; }
.cardF9C74F  {background-color:  rgba(249, 199, 79,.30) !important; color:  rgba(249, 199, 79,3) !important; font-weight: bold;}
.cardF8961E  {background-color:  rgba(248, 150, 30,.30) !important;color:  rgba(248, 150, 30,3) !important; font-weight: bold;}
.cardF94144  {background-color:  rgba(249, 65, 68,.30) !important;color:  rgba(249, 65, 68,3) !important; font-weight: bold;}
.card90BE6D  {background-color:  rgba(144, 190, 109,.30) !important;color:  rgba(144, 190, 109,3) !important;font-weight: bold; }
.card7678ed  {background-color:  rgba(118, 120, 237,.30) !important;color:  rgba(118, 120, 237,3) !important; font-weight: bold;}
.cardff595e  {background-color:  rgba(255, 89, 94,.30) !important;color:  rgba(255, 89, 94,3) !important; font-weight: bold;}
.cardefc3e6  {background-color:  rgba(239, 195, 230,.30) !important;color:  rgba(239, 195, 230,3) !important;font-weight: bold; }

.card9df7e5  {background-color:  rgba(157, 247, 229,.30) !important;color:  rgba(157, 247, 229,1) !important; font-weight: bold;}
.carda7c957  {background-color:  rgba(167, 201, 87,.30) !important;color:  rgba(167, 201, 87,1) !important;font-weight: bold; }
.cardcae9ff  {background-color:  rgba(202, 233, 255,.30) !important;color:  rgba(202, 233, 255,1) !important; font-weight: bold;}
.card73d2de  {background-color:  rgba(115, 210, 222,.30) !important;color:  rgba(115, 210, 222,1) !important; font-weight: bold;}
.card7ae582  {background-color:  rgba(122, 229, 130,.30) !important;color:  rgba(122, 229, 130,1) !important;font-weight: bold;}
.card64b5f6  {background-color:  rgba(100, 181, 246,.30) !important;color:  rgba(100, 181, 246,1) !important;font-weight: bold; }
.cardff7f51  {background-color:  rgba(255, 127, 81,.30) !important; color:  rgba(255, 127, 81,1) !important;font-weight: bold; }
.cardf3dfa2  {background-color:  rgba(243, 223, 162,.30) !important;color:  rgba(243, 223, 162,1) !important; font-weight: bold;}
.cardcbe896  {background-color:  rgba(203, 232, 150,.30) !important;color:  rgba(203, 232, 150,1) !important; font-weight: bold;}
.cardb298dc  {background-color:  rgba(178, 152, 220,.30) !important; color:  rgba(178, 152, 220,1) !important;font-weight: bold; }
.cardf6aa1c  {background-color:  rgba(246, 170, 28,.30) !important;color:  rgba(246, 170, 28,1) !important;font-weight: bold; }
.card00f5d4  {background-color:  rgba(0, 245, 212,.30) !important;color:  rgba(0, 245, 212,3) !important; font-weight: bold;}
.cardff86c8  {background-color:  rgba(255, 134, 200,.30) !important;color:  rgba(255, 134, 200,1) !important; font-weight: bold;} 
.cardffa3a5  {background-color:  rgba(255, 163, 165,.30) !important;color:  rgba(255, 163, 165,1) !important; font-weight: bold;}
.card9bb1ff  {background-color:  rgba(155, 177, 255,.30) !important;color:  rgba(155, 177, 255,1) !important; font-weight: bold;}
*/

.cardF64272 {
    background-color: rgba(246, 66, 114, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardF6648B {
    background-color: rgba(246, 100, 139, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardF493A7 {
    background-color: rgba(244, 147, 167, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardF891A6 {
    background-color: rgba(248, 145, 166, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardFFCCD5 {
    background-color: rgba(255, 204, 213, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card8b5aff {
    background-color: rgba(139, 90, 255, .30) !important;
    color: black !important;
    font-weight: bold;
}

.carda27bff {
    background-color: rgba(162, 123, 255, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardb99cff {
    background-color: rgba(185, 156, 255, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardd0bdff {
    background-color: rgba(208, 189, 255, .30) !important;
    color: black !important;
    font-weight: bold;
}

.carde8deff {
    background-color: rgba(232, 222, 255, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card51e5db {
    background-color: rgba(81, 229, 219, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card74ebe3 {
    background-color: rgba(116, 235, 227, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card96f0ea {
    background-color: rgba(150, 240, 234, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardb9f5f1 {
    background-color: rgba(245, 188, 0, 0.30) !important;
    color: black !important;
    font-weight: bold;
}

.carddcfaf8 {
    background-color: rgba(220, 250, 248, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardffa51a {
    background-color: rgba(255, 165, 26, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardffb748 {
    background-color: rgba(255, 183, 72, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardffc976 {
    background-color: rgba(255, 201, 118, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardffdba3 {
    background-color: rgba(255, 219, 163, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardffedd1 {
    background-color: rgba(255, 237, 209, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardD7F9F1 {
    background-color: rgba(215, 249, 241, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardAFBC88 {
    background-color: rgba(175, 188, 136, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardF3722C {
    background-color: rgba(243, 114, 44, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardF9C74F {
    background-color: rgba(249, 199, 79, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardF8961E {
    background-color: rgba(248, 150, 30, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardF94144 {
    background-color: rgba(249, 65, 68, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card90BE6D {
    background-color: rgba(144, 190, 109, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card7678ed {
    background-color: rgba(118, 120, 237, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardff595e {
    background-color: rgba(255, 89, 94, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardefc3e6 {
    background-color: rgba(239, 195, 230, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card9df7e5 {
    background-color: rgba(157, 247, 229, .30) !important;
    color: black !important;
    font-weight: bold;
}

.carda7c957 {
    background-color: rgba(167, 201, 87, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardcae9ff {
    background-color: rgba(202, 233, 255, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card73d2de {
    background-color: rgba(115, 210, 222, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card7ae582 {
    background-color: rgba(122, 229, 130, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card64b5f6 {
    background-color: rgba(100, 181, 246, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardff7f51 {
    background-color: rgba(255, 127, 81, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardf3dfa2 {
    background-color: rgba(243, 223, 162, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardcbe896 {
    background-color: rgba(203, 232, 150, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardb298dc {
    background-color: rgba(178, 152, 220, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardf6aa1c {
    background-color: rgba(246, 170, 28, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card00f5d4 {
    background-color: rgba(0, 245, 212, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardff86c8 {
    background-color: rgba(255, 134, 200, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardffa3a5 {
    background-color: rgba(255, 163, 165, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card9bb1ff {
    background-color: rgba(155, 177, 255, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardcambiosucursal {
    background: repeating-linear-gradient(45deg, transparent, transparent 10px, #e7900e 10px, #f2f2f2 20px);
    /* IE 10+ */
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardfinalizada {
    background-color: #15a34a !important;
    color: white !important
}

.vuecal__event {
    font-size: 12px;
    border-radius: 6px;
    border: 2px solid white
}

.vuecal .day-split-header {
    font-size: 11px;
}

.vuecal__body .split1,
.vuecal__body .split2,
.vuecal__body .split3,
.vuecal__body .split4,
.vuecal__body .split5,
.vuecal__body .split6,
.vuecal__body .split7,
.vuecal__body .split8,
.vuecal__body .split9,
.vuecal__body .split10,
.vuecal__body .split12,
.vuecal__body .split13,
.vuecal__body .split14 {
    background: #ffffffc9;
    /*background: #ffcb0061; 
border:1px solid #fff;*/
    border: 1px solid #e5e7eb;
}

.split-label {
    padding-top: 10px;
    color: rgb(22 101 52);
    font-weight: bold;
}

.split-label2 {

    color: rgb(124 107 37);
}

.vuecal__time-column {
    background: white;
    color: rgba(0, 0, 0, .7) !important
}

/*.split2 .vuecal__event {background-color: rgb(5, 5, 5);

}*/

.vuecal__no-event {
    display: none;
}

.hours::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-top: 1px solid rgb(5, 5, 5);
}

.minutesline::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    /* border-top: 1px solid rgba(0, 68, 255, 0.836)*/
}

/* Dash indicator */
.vuecal__cell-events-count {}

/* Dot indicator */
.vuecal__cell-events-count {

    color: white;
    font-weight: 800;

    background: green;
}

/* Cell background indicator */
.vuecal__cell--has-events {
    background-color: #fffacd;
}

.vuecal__cell-events-count {}
</style>
