

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
 // GET_CLIENTES CREAR_CLIENTES EDITAR_CLIENTES ELIMINAR_CLIENTES
 SHOW: async function( id){
  let url='/contenido/'+id;
  return initmixin.formatrespuesta('get', {},url,true)
},
 GET: async function( data){
      let url='/contenido/'
      return initmixin.formatrespuesta('get', data,url,true)
    },
    CREAR: async function( data) {
      let url='/contenido/'
      return initmixin.formatrespuesta('post', data.data,url,true,true)
    },
    EDITAR: async function( data) {
      let url='/contenido/'+data.id
      return initmixin.formatrespuesta('post', data.data,url,true,true)
    },
    ELIMINAR: async function( data) {
      let url='/contenido/'+data.id
      return initmixin.formatrespuesta('delete', data,url,true)
    },
   


}