<template>
  <section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-center content-center ">
    <div
      x-show="selectedRows.length"
      class="bg-teal-200 fixed top-0 left-0 right-0 z-40 w-full shadow"
    />

    <Tabla
      :columns="headings"
      :rows="data"
      :filtros="['nombre','descripcion','descuento']"
    />

    <div
      v-if="showM"
      style="z-index:9000"
      class="overflow-x-hidden overflow-y-auto fixed inset-0  outline-none focus:outline-none justify-center bg-black bg-opacity-50 items-center flex"
    >
      <div class="relative  my-6 mx-auto md:w-8/12 xl:w-6/12 lg:w-6/12">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-3xl font-semibold">
              {{ id==0?'Crear Promociones':'Editar Promociones' }}
            </h3>
            <button
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              @click="showM=false"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto h-96 overflow-y-scroll flex flex-wrap">
            <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
              <span
                class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold "
                htmlFor="nombre"
              >
                Nombre
              </span>
              <input
                id="cantidad"
                v-model="nombre"
                class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
                type="text"
                placeholder="Nombre"
              >
            </div>
            <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2">
              <span
                class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold "
                htmlFor="nombre"
              >
                Descuento
              </span>
              <select
                id="cantidad"
                v-model="descuento"
                class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
              >
                <option
                  :value="10"
                  :selected="descuento==10"
                >
                  10 %
                </option>
                <option
                  :value="15"
                  :selected="descuento==15"
                >
                  15 %
                </option>
                <option
                  :value="20"
                  :selected="descuento==20"
                >
                  20 %
                </option>
                <option
                  :value="25"
                  :selected="descuento==25"
                >
                  25 %
                </option>
                <option
                  :value="30"
                  :selected="descuento==30"
                >
                  30 %
                </option>
                <option
                  :value="35"
                  :selected="descuento==35"
                >
                  35 %
                </option>
                <option
                  :value="40"
                  :selected="descuento==40"
                >
                  40 %
                </option>
                <option
                  :value="45"
                  :selected="descuento==45"
                >
                  45 %
                </option>
                <option
                  :value="50"
                  :selected="descuento==50"
                >
                  50 %
                </option>
                <option
                  :value="55"
                  :selected="descuento==55"
                >
                  55 %
                </option>
                <option
                  :value="60"
                  :selected="descuento==60"
                >
                  60 %
                </option>
                <option
                  :value="65"
                  :selected="descuento==65"
                >
                  65 %
                </option>
                <option
                  :value="70"
                  :selected="descuento==70"
                >
                  70 %
                </option>
                <option
                  :value="75"
                  :selected="descuento==75"
                >
                  75 %
                </option>
                <option
                  :value="80"
                  :selected="descuento==80"
                >
                  80 %
                </option>
                <option
                  :value="85"
                  :selected="descuento==85"
                >
                  85 %
                </option>
                <option
                  :value="90"
                  :selected="descuento==90"
                >
                  90 %
                </option>
                <option
                  :value="95"
                  :selected="descuento==95"
                >
                  95 %
                </option>
                <option
                  :value="100"
                  :selected="descuento==100"
                >
                  100 %
                </option>
              </select>
            </div>
            <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
              <span
                class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold "
                htmlFor="nombre"
              >
                Descuento
              </span>
              <textarea
                id="cantidad"
                v-model="descripcion"
                class="text-sm appearance-none rounded h-16 w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
                type="text"
                placeholder="Descripcion"
              />
            </div>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
            <button
              class="text-red-500 bg-red-500 bg-opacity-20 border border-solid border-red-500 hover:bg-red-500 hover:bg-opacity-30 hover:text-red-900 active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              @click="showM=false"
            >
              Cancelar
            </button>
            <button
              class="text-blue-800 bg-blue-500 bg-opacity-40 border hover:text-white  hover:bg-blue-700 border-solid border-blue-800 font-bold uppercase px-6 py-3 text-sm outline-none  rounded focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              @click="guardar()"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="actionModal!=null"
      style="z-index:9999900"
      class="bg-slate-800 bg-opacity-50  h-screen w-full flex content-center justify-center items-center fixed  top-0 right-0 bottom-0 left-0"
    >
      <div class=" bg-white w-72 h-72 self-center  flex-wrap px-5 py-14 rounded-md text-start flex content-center justify-center items-center">
        <div class="text-base mb-4 font-bold text-slate-500">
          ¿Qué desea hacer?
        </div>
        <div class="flex-wrap flex justify-between  w-full">
          <div class="w-3/6 px-2">
            <button
              class="w-full bg-blue-500 bg-opacity-20 px-4 py-2 rounded-md text-md text-blue-500 border-2 border-blue-500"
              @click="edit(actionModal);"
            >
              Editar
            </button>
          </div>
          <div class="w-3/6 px-2">
            <button
              class="bg-red-500 px-4 py-2 bg-opacity-20 rounded-md text-md text-red-500 w-full  border-2 border-red-500"
              @click="eliminar(actionModal.id)"
            >
              Eliminar
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import promociones from "@/Services/promociones";
import Tabla from "@/components/Tabla.vue";
export default {
    components: {
        Tabla
    },
    mixins: [promociones],
    data() {
        const user = useUserStore()

        return {
            headings: [

                {
                    key: 'id',
                    name: '#',
                    show: true
                }, {
                    key: 'nombre',
                    name: 'Nombre',
                    show: true
                },
                {
                    key: 'descripcion',
                    name: 'Descripcion',
                    show: true
                },
                {
                    key: 'descuento',
                    name: 'Descuento',
                    show: true
                },

                {
                    key: 'edit',
                    name: 'Editar',
                    show: true
                },
                {
                    key: 'eliminar',
                    name: 'Eliminar',
                    show: true
                }
            ],
            buscar: '',
            open: false,
            user: user.getAll(),
            data: [],
            actionModal: null,
            showM: false,
            id: 0,
            nombre: '',
            descripcion: '',
            descuento: 10,

        }

    },
    mounted() {

    },
    created() {
        this.getData()
    },
    methods: {
        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },
        buscador(row) {
            if (String(row.telefono).toUpperCase().search(String(this.buscar).toUpperCase()) != -1 || (String(row.nombre)).toUpperCase().search(String(this.buscar).toUpperCase()) != -1) {
                return true;
            } else {
                return false;
            }
        },
        getColumns() {
            let col = 0;
            this.headings.forEach(element => {
                if (element.show) {
                    col = col + 1
                }
            });
            return col;
        },
        limpiar() {
            this.id = 0;
            this.nombre = '';
            this.descripcion = '';
            this.descuento = 10;

        },
        edit(row) {
            this.id = row.id;
            this.nombre = row.nombre;
            this.descripcion = row.descripcion;
            this.descuento = row.descuento;

            this.showM = true;
            this.actionModal = null;
        },
        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await promociones.GET(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async guardar() {
            let dat = {
                id: this.id,
                nombre: this.nombre,
                descripcion: this.descripcion,
                descuento: this.descuento,

            }
            if (this.id == 0) {
                this.crear(dat)
            } else {
                this.editar(dat)
            }
        },
        async editar(dat) {
            emitter.emit("loading", true)

            await promociones.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async crear(dat) {
            emitter.emit("loading", true)

            await promociones.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async eliminar(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await promociones.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
