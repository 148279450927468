//@ts-ignore
import { createRouter, createWebHistory } from "vue-router";

import Login from "../views/Login.vue";
import Registro from "../views/Registro.vue";
import Root from "../views/Root.vue";
import RootCliente from "../views/RootCliente.vue";

import Welcome from "../views/app/welcome.vue";
import clientes from "../views/app/admin/clientes.vue";
import productosManager from "../views/app/admin/productosManager.vue";
import productosCatalogosManager from "../views/app/admin/productosCatalogosManager.vue";
import CatalogosManager from "../views/app/admin/productosCatalogosManager1.vue";

import reportes_bigquery from "../views/app/admin/reportes_bigquery.vue";

import cotizacionManager from "../views/app/admin/cotizacionManager.vue";


import notificaciones from "../views/app/admin/notificaciones.vue";
import roles from "../views/app/admin/roles.vue";

//reportes


import reporte_produccion from "../views/app/admin/reportes/reporte_produccion.vue";
import reporte_compras_almacen from "../views/app/admin/reportes/reporte_compras_almacen.vue";
import reporte_vendedores from "../views/app/admin/reportes/reporte_vendedores.vue";
import reporte_seguimiento from "../views/app/admin/reportes/reporte_citas_seguimiento.vue";


import reporte_instalaciones from "../views/app/admin/reportes/reporte_instalaciones.vue";
import reporte_ventas from "../views/app/admin/reportes/reporte_ventas.vue";

import reporte_pagos from "../views/app/admin/reportes/reporte_pagos.vue";


import reporte_cotizaciones from "../views/app/admin/reportes/reporte_cotizaciones.vue";
import reporte_ordenes from "../views/app/admin/reportes/reporte_ordenes.vue";
import reporte_vendedores_metricas from "../views/app/admin/reportes/reporte_vendedores_metricas.vue";
import reporte_vendedores_ventas from "../views/app/admin/reportes/reporte_vendedores_ventas.vue";
import metricos_vendedores from "../views/app/admin/reportes/reporte_metricos_vendedores.vue";

import reporte_nomina from "../views/app/admin/reportes/reporte_nomina.vue";

import reporte_prospectos from "../views/app/admin/reportes/reporte_prospectos.vue";

import reporte_citas from "../views/app/admin/reportes/reporte_citas.vue";

//---end reportes


import lista_instaladores from "../views/app/admin/calendario_instaladores.vue";
import lista_produccion_revisar from "../views/app/admin/lista_produccion_revisar.vue";


import lista_produccion_instalar from "../views/app/admin/lista_produccion_instalar.vue";

import lista_produccion_avance from "../views/app/admin/lista_produccion_avance.vue";
import lista_produccion_instalados from "../views/app/admin/lista_produccion_instalados.vue";
import lista_produccion_formatos_terminados from "../views/app/admin/lista_produccion_formatos_terminados.vue";



import lista_ventas from "../views/app/admin/lista_ventas.vue";
import lista_aprobar from "../views/app/admin/lista_aprobar.vue";


import configuraciones from "../views/app/admin/configuraciones.vue";
import unidades from "../views/app/admin/unidades.vue";
import motivos_garantia from "../views/app/admin/motivos_garantia.vue";

import origenes_clientes from "../views/app/admin/origenes_clientes.vue";
import compras from "../views/app/admin/compras.vue";
import compras_a_proveedor from "../views/app/admin/compras_a_proveedor.vue";
import ComprasProveedorManager from "../views/app/admin/ComprasProveedorManager.vue";


/*configuracion estilo */

import configuracionBotones from "../views/app/admin/configuradores/configuracionBotones.vue";


import MetodosPagos from "../views/app/admin/metodos_pagos.vue";
import categorias from "../views/app/admin/categorias.vue";
import entradas from "../views/app/admin/entradas.vue";
import citas from "../views/app/admin/calendario_instalaciones.vue";
import calendario_citas_ventas from "../views/app/admin/calendario_citas_ventas.vue";


import estadisticasmes from "../views/app/admin/estadisticasmes.vue";
import estadisticasdia from "../views/app/admin/estadisticasdia.vue";
import inventario from "../views/app/admin/inventario.vue";
import ventas from "../views/app/admin/ventas.vue";
import cotizaciones from "../views/app/admin/cotizaciones.vue";
import cotizaciones_revendedor from "../views/app/admin/cotizaciones_revendedor.vue";
import vista_cotizaciones_revendedor from "../views/app/revendedor/cotizaciones_revendedor.vue";

import ventas_revendedor from "../views/app/admin/lista_ventas_revendedor.vue";
import promociones from "../views/app/admin/promociones.vue";
import sucursales from "../views/app/admin/sucursales.vue";
import Clases from "../views/app/admin/clases.vue";
import sectores from "../views/app/admin/sectores.vue";
import tipo_membresias from "../views/app/admin/tipo_membresias.vue";
import programas from "../views/app/admin/programas.vue";
import detalleprogramas from "../views/app/admin/detalleprogramas.vue";

import provedores from "../views/app/admin/provedores.vue";
import modificadores from "../views/app/admin/modificadores.vue";
import usuarios from "../views/app/admin/usuarios.vue";
import conversorformula from "../views/app/admin/conversorformula.vue";



import view_cot from "../views/view_cot.vue";

import IndexCliente from "../views/app/general/index.vue";

import pending from "../views/app/general/pending.vue";

import error from "../views/app/general/error.vue";
import Poliza from "../views/Polizagarantia.vue";


import Dash from "../views/Dash.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: Login
  },
  {
    path: "/poliza/",
    name: "poliza",
    component: Poliza
  },
 
  {
    path: "/r",
    name: "registro",
    component: Registro
  },
  {
    path: "/ctview",
    name: "cotizacion_print",
    component: view_cot
  },
  {
    path: "/cl",
    name: "cl",
    component: RootCliente,
    children: [
      {
        path: '',
        name: 'index',
        component: IndexCliente
      },
      {
        path: 'pending',
        name: 'pending',
        component: pending
      },
      {
        path: 'error',
        name: 'error_p',
        component: error
      },
     
      
    ]
  },
  {
    path: "/app",
    name: "app",
    component: Root,
    children: [

      
      {
        path: 'reporte/metricos_vendedores',
        name: 'metricos_vendedores',
        component: metricos_vendedores
      },
      {
        path: 'reporte/cotizaciones',
        name: 'rpt_cotizaciones',
        component: reporte_cotizaciones
      },
      {
        path: 'reporte/vendedores/metricas',
        name: 'rpt_vendedores_metricas',
        component: reporte_vendedores_metricas
      },
      {
        path: 'reporte/vendedores/ventas',
        name: 'rpt_vendedores_ventas',
        component: reporte_vendedores_ventas
      },
      
      {
        path: 'reporte/instalaciones',
        name: 'rpt_instalaciones',
        component: reporte_instalaciones
      },
  
      {
        path: 'reporte/ordenes',
        name: 'rpt_ordenes',
        component: reporte_ordenes
      },
  
      {
        path: 'reporte/produccion',
        name: 'rpt_produccion',
        component: reporte_produccion
      },
      {
        path: 'reporte/compras/almacen',
        name: 'rpt_compras_almacen',
        component: reporte_compras_almacen
      },
      {
        path: 'reporte/nomina',
        name: 'reporte_nomina',
        component: reporte_nomina
      },
      {
        path: 'reporte/citas',
        name: 'reporte_citas',
        component: reporte_citas
      },
      {
        path: 'reporte/prospectos',
        name: 'reporte_prospectos',
        component: reporte_prospectos
      },

      {
        path: 'reporte/seguimiento',
        name: 'reporte_seguimiento',
        component: reporte_seguimiento
      },

   
      
      {
        path: 'reporte/vendedores',
        name: 'rpt_vendedores',
        component: reporte_vendedores
      },
      {
        path: 'reporte/pagos',
        name: 'rpt_pagos',
        component: reporte_pagos
      },
      {
        path: 'reporte/ventas',
        name: 'rpt_ventas',
        component: reporte_ventas
      },
      
      {
        path: 'reporte/bigquery',
        name: 'reportes_bigquery',
        component: reportes_bigquery
      },

      
     
      {
        path: 'configuraciones/estilo/botones',
        name: 'configbotones',
        component: configuracionBotones
      },
      {
        path: 'roles',
        name: 'roles_config',
        component: roles
      },
      
      {
        path: 'compras',
        name: 'compras',
        component: compras
      },
      {
        path: 'ordenes/compras',
        name: 'compras_a_proveedor',
        component: compras_a_proveedor
      },
      {
        path: 'ordenes/nueva',
        name: 'comprasProveedorManager',
        component:  ComprasProveedorManager
      },
      {
        path: 'unidades',
        name: 'unidades',
        component: unidades
      },
      {
        path: 'motivos_garantia',
        name: 'motivos_garantia',
        component: motivos_garantia
      },
      {
        path: 'origenes',
        name: 'origenes',
        component: origenes_clientes
      },
       
      

      {
        path: 'clases',
        name: 'clases',
        component: Clases
      },
    
      {
        path: 'sucursales',
        name: 'sucursales',
        component: sucursales
      },
      
      
      {
        path: 'programas/contenido',
        name: 'detalleprogramas',
        component: detalleprogramas
      },
      {
        path: 'programas',
        name: 'programas',
        component: programas
      },
      {
        path: 'conversorformula',
        name: 'conversorformula',
        component: conversorformula
      },
      {
        path: 'welcome',
        name: 'Welcome',
        component: Welcome
      },
      {
        path: 'clientes',
        name: 'clientes',
        component: clientes
      },
      {
        path: 'entradas',
        name: 'entradas',
        component: entradas
      },
      {
        path: 'productos',
        name: 'productos',
        component: productosManager
      },
      {
        path: 'productos/v2/catalogo/',
        name: 'Productos tipo catalogo ',
        component: productosCatalogosManager
      },
      {
        path: 'productos/v1/catalogo',
        name: 'Productos tipo catalogo v1',
        component: CatalogosManager
      },
      
      {
        path: 'revendedor/cot',
        name: 'revendedores cotizaciones',
        component: vista_cotizaciones_revendedor
      },
      {
        path: 'rev/cot',
        name: 'revendedores cotizaciones',
        component: cotizaciones_revendedor
      },
         
      {
        path: 'rev/det/ventas',
        name: 'ventas revendedor',
        component: ventas_revendedor
      },
      {
        path: 'cotizaciones',
        name: 'cotizaciones',
        component: cotizaciones
      },
      {
        path: 'nueva/cotizacion',
        name: 'nueva_cotizacion',
        component: cotizacionManager
      },
      {
        path: 'notificaciones',
        name: 'notificaciones',
        component: notificaciones
      },
      {
        path: 'configuraciones',
        name: 'configuraciones',
        component: configuraciones
      },
      
      {
        path: 'metodos_pago',
        name: 'metodos_pago',
        component: MetodosPagos
      },
      {
        path: 'detalle/instaladores',
        name: 'detalle_instaladores',
        component: lista_instaladores
      },
      {
        path: 'cita',
        name: 'citas',
        component: citas
      },
      {
        path: 'calendario/citas/ventas',
        name: 'calendario_citas_ventas',
        component: calendario_citas_ventas
      },

      
      {
        path: 'detalle/revisar/produccion',
        name: 'detalle_produccion',
        component: lista_produccion_revisar
      },
      {
        path: 'detalle/avance/produccion',
        name: 'lista_produccion_avance',
        component: lista_produccion_avance
      },
  
      {
        path: 'detalle/avance/instalados',
        name: 'lista_produccion_instalados',
        component: lista_produccion_instalados
      },

      {
        path: 'detalle/avance/formatos',
        name: 'lista_produccion_formatos_terminados',
        component: lista_produccion_formatos_terminados
      },
      
      {
        path: 'detalle/avance/instalacion',
        name: 'lista_produccion_instalar',
        component: lista_produccion_instalar
      },

      

      

      {
        path: 'detalle/ventas',
        name: 'detalle_ventas',
        component: lista_ventas
      },
      {
        path: 'detalle/aprobar',
        name: 'detalle_verificar',
        component: lista_aprobar
      },
      {
        path: 'estadisticas/mes',
        name: 'estadisticas/mes',
        component: estadisticasmes
      },
      {
        path: 'estadisticas/dia',
        name: 'estadisticas/dia',
        component: estadisticasdia
      },
      {
        path: 'ventas',
        name: 'ventas',
        component: ventas
      },
      {
        path: 'inventario',
        name: 'inventario',
        component: inventario
      },
      {
        path: 'entradas',
        name: 'entradas',
        component: entradas
      },
      {
        path: 'promociones',
        name: 'promociones',
        component: promociones
      },
      {
        path: 'provedores',
        name: 'provedores',
        component: provedores
      },
      {
        path: 'usuarios',
        name: 'usuarios',
        component: usuarios
      },
      {
        path: 'categorias',
        name: 'categorias',
        component: categorias
      },
      {
        path: 'modificadores',
        name: 'modificadores',
        component: modificadores
      },
      

    ]
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
