<template>
<div class="bg-white text-gray-600 work-sans leading-normal text-base tracking-normal h-full w-full">

    <TemplateCotizacion v-if="el_cotizacion!=null" :cotizacion="el_cotizacion" :infoneg="true" :dn="true" :dc="true" :dtotal="true" :dqr="true" :ddt="true"></TemplateCotizacion>

</div>
</template>

<script>
//import PopularMovies from "@/components/PopularMovies.vue";

import TemplateCotizacion from "@/components/TemplateCotizacion.vue";
import skcard from "@/components/skeletons/sk-card.vue";
import {
    ref,
    reactive
} from "vue";
import cotizaciones from "@/Services/cotizaciones";
import infosistem from "@/Services/infosistem";
import landing from "@/Services/landing";

import {
    emitter
} from "../mitt.js";
import {
    useUserStore
} from '../stores/user'
import {
    useInfoStore
} from '../stores/info'
import {
    useConfigStore
} from '../stores/config'
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    method
} from "lodash";
export default {
    mixins: [],
    components: {
        TemplateCotizacion
    },
    props: {
        price: {
            type: Number,
            default: 0
        },

    },

    data() {
        return {
            el_cotizacion: null,
            tipol: 'login'
            //usuario: '',
            //contrasena: '',
        }
    },
    mounted() {
this.getCotizacion();
    },
    methods: {
        async getCotizacion() {
            let id = this.$route.query.hasOwnProperty('ci') ? this.$route.query.ci : '';
            if(id==''){
                return
            }
            let dat={
                id:id
            }
            await cotizaciones.SHOW(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.el_cotizacion = response;

                  
                    //console.log(this.$route.query.r,fresult,'recovery')

                   

                }else{
                    emitter.emit("alerta_show", response.error);
                }
                emitter.emit("loading", false)
            });
        }
    }
};
</script>

<style scoped>
.work-sans {
    font-family: 'Work Sans', sans-serif;
}

#menu-toggle:checked+#menu {
    display: block;
}

.hover\:grow {
    transition: all 0.3s;
    transform: scale(1);
}

.hover\:grow:hover {
    transform: scale(1.02);
}

.carousel-open:checked+.carousel-item {
    position: static;
    opacity: 100;
}

.carousel-item {
    -webkit-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;
}

#carousel-1:checked~.control-1,
#carousel-2:checked~.control-2,
#carousel-3:checked~.control-3 {
    display: block;
}

.carousel-indicators {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 2%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
}

#carousel-1:checked~.control-1~.carousel-indicators li:nth-child(1) .carousel-bullet,
#carousel-2:checked~.control-2~.carousel-indicators li:nth-child(2) .carousel-bullet,
#carousel-3:checked~.control-3~.carousel-indicators li:nth-child(3) .carousel-bullet {
    color: #000;
    /*Set to match the Tailwind colour you want the active one to be */
}
</style>
