import axios from "axios";
export default {
  GET: async function(data) {
    let host = window.location.host.replace(":8080", "");
    host = host.replace(":8081", "");
    let domain = host.split(".")[0];
    let url = `https://v2.api2pdf.com/chrome/pdf/url?url=https://${domain}.web.app/ctview?ci=${data.id}&apikey=d1ee403a-92aa-4b4d-a95f-bd66ca84c1c4`;

    let requestObj = {
      url: url,
      method: "get",
      // data: this.formData
    };
    //show progress bar at beginning of post
    // this.showProgress = true;
    axios(requestObj)
      .then((response) => {
        const csvFile = new Blob([response.data], {
          type: "application/pdf;charset=utf-8;",
        });
        // saveAs(csvFile, "data.csv");

        var temp_link = document.createElement("a");

        // Download csv file
        temp_link.download = id + ".pdf";
        var url = window.URL.createObjectURL(csvFile);
        temp_link.href = url;

        // This link should not be displayed
        temp_link.style.display = "none";
        document.body.appendChild(temp_link);

        // Automatically click the link to trigger download
        temp_link.click();
        document.body.removeChild(temp_link);
        return response;
      })
      .catch((error) => {
        return { error: error };
      });
  },
  SEND_HTML: async function(data) {
  console.log(data,'-------------data---html----')
    let url = `https://v2.api2pdf.com/chrome/pdf/html?apikey=d1ee403a-92aa-4b4d-a95f-bd66ca84c1c4`;
console.log(data,'-ssss')
    let requestObj = {
      url: url,
      method: "post",
      data: {
        "html": data.html,
        "watermark_url":data.hasOwnProperty('watermark')?data.watermark:'',
        "inline": false,
        "fileName": (data.hasOwnProperty('name')?data.name+'.pdf':'archivo.pdf'),
        "options": {
          "delay": 0,
          "puppeteerWaitForMethod": "WaitForNavigation",
          "puppeteerWaitForValue": "Load",
          "usePrintCss": true,
          "landscape": data.landscape,
          "printBackground": true,
          "displayHeaderFooter": true,
          "headerTemplate": "<span></span>",
          "footerTemplate": "<span>caca</span>",
          "width": "8.27in",
          "height": "11.69in",
          "marginTop": ".4in",
          "marginBottom": ".4in",
          "marginLeft": ".4in",
          "marginRight": ".4in",
          "pageRanges": "1-10000",
          "watermark":data.hasOwnProperty('watermark')?data.watermark:'',
          "scale": data.scale,
          "omitBackground": false
        },
        "useCustomStorage": false,
        
      }
    };
    //show progress bar at beginning of post
    // this.showProgress = true;
  let r=  await axios(requestObj)
      .then((response) => {
        console.log(response,'---')

     
        return response;
      })
      .catch((error) => {
        console.log(error)
        return {error:error}
        //return { error: error };
      });
      return r
  },

  ENVIAR_CORREO: async function( data) {
    let url = `https://v2.api2pdf.com/chrome/pdf/html?apikey=d1ee403a-92aa-4b4d-a95f-bd66ca84c1c4`;

    let requestObj = {
      url: url,
      method: "post",
      data: {
        "html": data.html,
        "inline": false,
        "fileName": "test.pdf",
        "options": {
          "delay": 0,
          "puppeteerWaitForMethod": "WaitForNavigation",
          "puppeteerWaitForValue": "Load",
          "usePrintCss": true,
          "landscape": data.landscape,
          "printBackground": true,
          "displayHeaderFooter": false,
          "headerTemplate": "<span></span>",
          "footerTemplate": "<span></span>",
          "width": "8.27in",
          "height": "11.69in",
          "marginTop": ".4in",
          "marginBottom": ".4in",
          "marginLeft": ".4in",
          "marginRight": ".4in",
          "pageRanges": "1-10000",
          "scale": data.scale,
          "omitBackground": false
        },
        "useCustomStorage": false,
        
      }
    };
    //show progress bar at beginning of post
    // this.showProgress = true;
  let r=  await axios(requestObj)
      .then((response) => {
        console.log(response,'---')

     
        return response;
      })
      .catch((error) => {
        console.log(error)
        return {error:error}
        //return { error: error };
      });
      return r
  },

};
