<template>
    <section class=" h-full  w-full flex  bg-gray-50 justify-start flex-wrap overflow-x-scroll ">
caca
        <div v-if="!IsMov()" class="w-full h-12 bg-gray-200 flex flex-wrap content-center justify-start pl-12">
            <div class="w-42 px-4 ">
                <el-date-picker class="w-full" v-model="fecha" type="daterange" unlink-panels range-separator="-" start-placeholder="De" end-placeholder="Hasta" @change="getData()" />
    
            </div>
            <div class="">
                <el-pagination v-model:currentPage="pagina" small background layout="prev, pager, next" :page-size="data['per_page']" :total="Number(data['total'])" @current-change="pageChangue" class="" />
    
            </div>
    
        </div>
        <div v-if="IsMov()" class="w-full h-24 bg-gray-200 flex flex-wrap content-center justify-end px-2 ">
    
            <div class="w-10/12 h-12 justify-end content-center flex flex-wrap ">
                <el-pagination v-model:currentPage="pagina" small background layout="prev, pager, next" :page-size="data['per_page']" :total="Number(data['total'])" @current-change="pageChangue" class="" />
    
            </div>
            <div class="w-full h-12 justify-center content-center flex flex-wrap ">
                <el-date-picker class="w-full" v-model="fecha" type="daterange" unlink-panels range-separator="-" start-placeholder="De" end-placeholder="Hasta" @change="getData()" />
    
            </div>
    
        </div>
    
        <Tabla  v-bind:key="reftable" v-if="data.hasOwnProperty('data')"
         :add="false" :columns="headings" :rows="data.data" :filtros="['email','phone','id']" :buscarp="buscar" :filtroApi="true" :paginate="true" :page="{
                page:data.current_page,
                total:data.last_page
               
              }" />
    
       
    
    </section>
</template>

<script>
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import Tabla from "@/components/Tabla.vue";
import TemplateCotizacion from "@/components/TemplateCotizacion.vue";
import generales from "@/functions/generales";
import metodos_pagos from "@/Services/metodos_pagos";
import abonos from "@/Services/abonos";
import cotizaciones from "@/Services/cotizaciones";
import unidades from "@/Services/unidades";
import productos_ordenes from "@/Services/productos_ordenes";
import productos from "@/Services/productos";
import TemplateCitas from "@/components/TemplateCitas.vue";
import TemplateOrdenes from "@/components/TemplateOrdenes.vue";

import {
    filter
} from "lodash";
export default {
    components: {
        Tabla
    },
    mixins: [generales],
    data() {
        const user = useUserStore()

        return {
            headings: [

                {
                    key: 'id',
                    name: '# siiii ',
                    show: true
                },
                
                {
                    key: 'status_compra',
                    name: 'Estatus',
                    show: true
                },

                {
                    key: 'cantidad',
                    name: 'Cantidad',
                    show: true
                },
                {
                    key: 'precio',
                    name: 'Precio',
                    show: true,
                    class: 'text-green-500 font-extrabold'
                },
                {
                    key: 'total',
                    name: 'Total',
                    show: true
                },
                {
                    key: 'unidades',
                    name: 'Unidad',
                    show: true
                },
                {
                    key: 'colores_productos',
                    name: 'Color',
                    show: true
                },
                
                {
                    key: 'productos',
                    name: 'Productos',
                    show: true
                },
              

            ],
            dates: [],
            upbuton: 0,
            el_cotizacion: {
                id: 0
            },
            open: false,
            user: user.getAll(),
            metodos_data: [],
            data: {},
            actionModal: null,
            viewCotizacion: false,
            unidades_data: [],
            provedores_data: [],
            showM: false,
            info: '',
            id: 0,
          
          
            buscar: 'all',
            pagina: 1,
            limit: 0,
            reftable: 0,
            tipo: 'ventas',
            fecha: [this.getFirstDayOfMonth(), this.getLastDayOfMonth()],

        }

    },
    mounted() {

    },
    async created() {
        if (this.$route.query.hasOwnProperty('cid')) {
            this.buscar = this.$route.query.cid;
        }

        await this.getData()
        // await this.getDataMetodos();
    },
    methods: {
        IsMov() {

            //return true;
            return this.$isMobile()
        },
        selOrdenes(el) {
            this.viewListOrdenes = true;
            this.el_cotizacion = el;

        },
        selStatusInstalacion(el) {
            this.viewListCitas = true;
            this.el_cotizacion = el;

        },
        verCotizacion(row) {

            this.el_cotizacion = row;
            this.viewCotizacion = true;

        },
        async pageChangue(val) {

            this.page = val;
            await this.getData();

        },

      
        paginaSearch(pagina) {
            console.log('---- dat---' + pagina)
            this.pagina = pagina;

            this.getData();
        },

        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },
        limpiar() {
            this.viewCotizacion = false;
        },
       

        buscarGet(text) {
            this.buscar = text;
            if (text == '') {
                this.buscar = 'all';
            }
            this.getData();
        },

        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)
            let dat = {
                search: this.buscar,
                page: this.pagina,
                tipo: this.tipo,
                inicio: this.formatDateEN(this.fecha[0]),
                fin: this.formatDateEN(this.fecha[1]),
                data: {}
            }

            await productos_ordenes.GET(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response;

                    
                    this.reftable = this.reftable + 1;

                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                emitter.emit("loading", false)
            });
        },
    
        async editar(dat) {
            emitter.emit("loading", true)

            await cotizaciones.EDITAR_PARAMS(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.el_cotizacion = response;
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
