<template>
<section class=" h-full  w-full flex flex-wrap bg-blue-50 overflow-y-hidden justify-center content-center ">
    <div class="w-6/12 h-full overflow-y-scroll py-2 px-2">
        <template v-for="(row,index) in data" v-bind:key="index">
            <div class="my-2 bg-gray-200 rounded justify-between flex flex-wrap px-2 py-2 " v-if="row.tipo=='catalogo'" @click="products_catalogo=row.productos_edit"><span>{{row.descripcion}}</span><span class="bg-blue-200 py-1 px-2 rounded">{{row.nombre}}</span></div>
        </template>

    </div>
    <div class="w-6/12 h-full flex flex-wrap justify-start content-start bg-gray-100 overflow-y-scroll" >
       
        <table class="table-auto w-full">
            <thead>
                <tr>
            
                    <td class="bg-gray-200 top-0 sticky">imagen</td>
                   
                    <td class="bg-gray-200 top-0 sticky">nombre</td>
                    <td class="bg-gray-200 top-0 sticky">tag</td>
                    <td class="bg-gray-200 top-0 sticky">precio</td>
                    <td class="bg-gray-200 top-0 sticky">precio_compra</td>
                </tr>
            </thead>
<tbody>
    <template v-for="(row,index) in products_catalogo" v-bind:key="index">
        <tr>
            
            <td class="py-1 " @click="()=>{if(row.url!=''&&row.url!=null){img_sel=row.url;imgview=true;}}">
                <div class=" flex w-full flex-wrap justify-center content-center">   <img class="border border-black w-10 h-10 self-center rounded-full" :src="row.url"></div>
             </td>
           
            <td>{{row.nombre}}</td>
            <td>{{row.tag}}</td>
            <td>{{row.precio}}</td>
            <td>{{row.precio_compra}}</td>
        </tr>
    </template>
</tbody>
        </table>
       
    </div>
    <el-dialog v-model="imgview" :show-close="false">
        
        <div class="flex flex-wrap">
            
           <img class="w-98 h-98 rounded" :src="img_sel">
            

        </div>

        <template #footer>
            <span class="dialog-footer">
                <button class="text-black bg-red-500 font-extrabold text-xs text-center px-2 py-1 rounded-md" @click="imgview = false">Cerrar</button>
            </span>

        </template>

    </el-dialog>
</section>
</template>

<script>
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import categorias from "@/Services/categorias";
import productos from "@/Services/productos";
import parametros from "@/Services/parametros";
import provedores from "@/Services/provedores";
import generales from "@/functions/generales";

export default {
    components: {

    },
    mixins: [categorias, generales, productos, parametros],
    data() {
        const user = useUserStore()

        return {

            user: user.getAll(),
            data: [],
            products_catalogo: [],
            imgview:false,
            img_sel:''

        }

    },
    mounted() {
        emitter.on('actualizacionformula', (data) => {

            //this.closeProduccion();
            this.managerFormula = false;
            this.formula_json = data;
        });
    },
    async created() {
        await this.getData();
        //await this.getDataCategorias();
        //await this.getDataProvedores();
    },
    methods: {

        async onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.data_file = files[0];
            console.log(e)

        },
        onPickFile() {
            this.$refs.fileInput.click()
        },

        editParametro(row) {
            console.log(row, '----param')
            this.id_param = row.id;
            this.nombre_parametro = row.nombre;
            this.parametro_catalogo = row.sel_productos_id;
            this.visible_formato = row.visible_formato;
            this.visible = row.visible;
            this.visible_produccion = row.visible_produccion;
            this.visible_instaladores = row.visible_instaladores;
            this.visible_cotizacion_t2 = row.visible_cotizacion_t2;
            this.formula_mp = row.formula_mp == null ? [] : JSON.parse(row.formula_mp)
            this.tipo_parametro = row.tipo;

            this.AddParametro = true;

        },
        async limpiar() {},
        async getData() {
            this.limpiar();
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await productos.GET_NOT_OPCION(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },

        async guardarProducto() {

            var formData = new FormData();

            formData.append("id", this.id_prod, );
            formData.append("nombre", this.nombre_prod, );
            formData.append("tag", this.tag, );

            formData.append("tipo_producto", 'opcion_catalogo', );
            formData.append("precio", this.precio_prod, );
            formData.append("precio_compra", this.precio_compra, );

            if (this.provedor > 0) {
                formData.append("provedores_id", this.provedor, );
            }
            if (this.descripcion_prod != '' && this.descripcion_prod != null) {
                formData.append("descripcion", this.descripcion_prod, );
            }

            formData.append("productos_id", this.id, );

            let dat = {
                id: this.id_prod,
                data: formData
            };

            if (this.id_prod == 0) {
                this.crearProducto(dat)
            } else {
                this.editarProducto(dat)
            }
        },

        async editarProducto(dat) {
            emitter.emit("loading", true)

            await productos.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    this.productos_data = this.productos_data.filter(row => row.id != dat.id)
                    this.productos_data.push(response)
                    //this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                this.AddProductoCatalogo = false;
                this.limpiarProdOpcion();
                emitter.emit("loading", false)
            });
        },

        async EliminarParam(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await parametros.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    this.parametros_data = this.parametros_data.filter(row => row.id != id)
                    //this.getData();
                    //this.$router.push('/app/');
                    this.actionModal = null
                } else {

                    emitter.emit("alerta_show", response.error);

                }

                emitter.emit("loading", false)
            });
        },

        //parametros

        async guardarParametro() {

            if (this.tipo_parametro != 'catalogo') {
                this.parametro_catalogo = null;
            } else {
                if (this.parametro_catalogo == 0) {
                    emitter.emit("alerta_show", 'Es necesario seleccionar un catalogo');
                    // return
                }
            }

            let dat = {
                id: this.id_param,
                nombre: this.nombre_parametro,
                tipo_parametro: this.tipo_parametro,
                visible: this.visible,
                formula_mp: this.formula_mp,
                visible_formato: this.visible_formato,
                visible_produccion: this.visible_produccion,
                visible_instaladores: this.visible_instaladores,
                visible_cotizacion_t2: this.visible_cotizacion_t2,
                producto_sel: this.parametro_catalogo,
                producto: this.id

            }
            if (this.id_param == 0) {
                this.crearParametro(dat)
            } else {
                this.editarParametro(dat)
            }
        },
        async editarParametro(dat) {
            emitter.emit("loading", true)

            await parametros.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    this.parametros_data = this.parametros_data.filter(row => row.id != dat.id)
                    this.parametros_data.push(response)
                    //this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                // this.showM = false;
                // this.limpiar();
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
