<template>

<div >
    
<!-- drawer init and toggle -->

 <router-view />

 
</div>
</template>

<script>

//import PopularMovies from "@/components/PopularMovies.vue";

//import TopRated from "@/components/TopRated.vue";
import userService from "@/Services/user";

import {
    useUserStore
} from '../stores/user'
import {
    useConfigStore
} from '../stores/config'
import {
    useInfoStore
} from '../stores/info'

import infosistem from "@/Services/infosistem";
export default {
    name: "Root",
    components: {

    },
    mixins: [infosistem],
    setup() {
        const user = useUserStore()
        const info = useInfoStore()

        const config = useConfigStore();

        let menu_admin = [{
                id: 'atajos',
                titulo: 'Atajos',
                rutas: [{
                        tag: 'Clientes',
                        ruta: '/app/clientes',
                        active: false,
                        child: false,
                        icon: 'user-plus'
                    },
                    {
                        tag: 'Caja',
                        ruta: '/app/caja',
                        active: false,
                        child: false,
                        icon: 'shopping-cart'
                    }, {
                        tag: 'Clases',
                        ruta: '/app/clases',
                        active: false,
                        child: false,
                        icon: 'shopping-cart'
                    }
                ]
            },
            {
                id: 'membresias',
                titulo: 'Membresias y paquetes',
                rutas: [{
                        tag: 'Membresias',
                        ruta: '/app/tipo_membresias',
                        active: false,
                        child: false,
                        icon: 'shopping-bag'
                    },
                    {
                        tag: 'Programas',
                        ruta: '/app/programas',
                        active: false,
                        child: false,
                        icon: 'cloud'
                    },
                ]
            },
            {
                id: 'ventas',
                titulo: 'Inventario',
                rutas: [{
                        tag: 'Ventas',
                        ruta: '/app/ventas',
                        active: false,
                        child: false,
                        icon: 'dollar-sign'
                    },
                    {
                        tag: 'Inventario',
                        ruta: '/app/inventario',
                        active: false,
                        child: false,
                        icon: 'database'
                    },
                    {
                        tag: 'Entradas',
                        ruta: '/app/entradas',
                        active: false,
                        child: false,
                        icon: 'file-text'
                    },
                    {
                        tag: 'Estadísticas',
                        ruta: '/app/estadisticas/mes',
                        active: false,
                        child: false,
                        icon: 'bar-chart-2'
                    },
                ]
            },
            {
                id: 'catalogos_menu',
                titulo: 'Catálogos',
                rutas: [{
                        tag: 'Instructores',
                        ruta: '/app/instructores',
                        active: false,
                        child: false,
                        icon: 'thumbs-up'
                    },
                    {
                        tag: 'Sucursales',
                        ruta: '/app/sucursales',
                        active: false,
                        child: false,
                        icon: 'home'
                    }, {
                        tag: 'Usuarios',
                        ruta: '/app/usuarios',
                        active: false,
                        child: false,
                        icon: 'users'
                    },
                    {
                        tag: 'Sectores',
                        ruta: '/app/sectores',
                        active: false,
                        child: false,
                        icon: 'grid'
                    },
                    // { tag: 'tablero_master', ruta: '/ms/tablero', active: false, child: false, icon: 'cellular-outline' },
                    {
                        tag: 'Provedores',
                        ruta: '/app/provedores',
                        active: false,
                        child: false,
                        icon: 'truck'
                    },
                    {
                        tag: 'Productos',
                        ruta: '/app/productos',
                        active: false,
                        child: false,
                        icon: 'package'
                    },
                    {
                        tag: 'Promociones',
                        ruta: '/app/promociones',
                        active: false,
                        child: false,
                        icon: 'tag'
                    },
                    {
                        tag: 'Modificadores',
                        ruta: '/app/modificadores',
                        active: false,
                        child: false,
                        icon: 'layers'
                    },
                    {
                        tag: 'Clientes',
                        ruta: '/app/clientes',
                        active: false,
                        child: false,
                        icon: 'user-plus'
                    },

                ]
            },
        ];
        let menu_cliente = [{
            id: 'general',
            titulo: 'General',
            rutas: [{
                    tag: 'index',
                    ruta: '/cl',
                    active: false,
                    child: false,
                    icon: 'home'
                },
                {
                    tag: 'Perfil',
                    ruta: '/cl/perfil',
                    active: false,
                    child: false,
                    icon: 'user'
                }, {
                    tag: 'Clases',
                    ruta: '/cl/clases',
                    active: false,
                    child: false,
                    icon: 'calendar'
                },
                {
                    tag: 'Cursos',
                    ruta: '/cl/cursos',
                    active: false,
                    child: false,
                    icon: 'cloud'
                },
                {
                    tag: 'Metodos',
                    ruta: '/cl/metodos',
                    active: false,
                    child: false,
                    icon: 'credit-card'
                },

            ]
        }, ];
        let menu_set = [];
        if (user.getAll().role == 'admin') {
            menu_set = menu_admin;
        } else {
            menu_set = menu_cliente;
        }
        return {
            active: 0,
            config,
            info: info.getAll(),
            infoset: info,
            user: user.getAll(),
            menu_set: menu_set,
            menu_admin: [],
            menu: []

        }

    },
    mounted() {
        this.get_info();

    },
    methods: {

        async get_info() {
            console.log('data------')
            let data = await infosistem.GET().then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    let infov = {};
                    response.forEach(element => {
                        infov[element.id] = element.valor;
                    });
                    this.infoset.set(
                        infov
                    );
                    //infoData.value=(infov);
                    //if(response.user.role=='admin'){

                    //this.$router.push('/app/'); 
                    //}

                }
            });
        },
        showMenu1(id) {
            let actual = false;
            if ($('#' + id).hasClass('hidden')) {
                actual = true;
            }

            $('.genmen').removeClass('hidden');

            $('.genmen').addClass('hidden');
            // let menu1 = document.getElementById(id);
            let icon1 = document.getElementById("icon" + id);
            let menu1 = document.getElementById(id);
            if (actual) {

                $('#' + id).removeClass('hidden');
                //$('#icon'+id).addClass('hidden');
                //$('#'+id).addClass('hidden');
            } else {

                //$('#'+id).removeClass('hidden');
                //$('#icon'+id).addClass('hidden');
                $('#' + id).addClass('hidden');
            }
            icon1.classList.toggle("rotate-180");
        },

        showMenu2(flag) {
            let menu2 = document.getElementById("menu2");

            if (flag) {
                icon2.classList.toggle("rotate-180");
                menu2.classList.toggle("hidden");
            }
        },

        showMenu3(flag) {
            let menu3 = document.getElementById("menu3");
            if (flag) {
                icon3.classList.toggle("rotate-180");
                menu3.classList.toggle("hidden");
            }
        },
        years() {
            const year = new Date().getFullYear()
            return Array.from({
                length: year - 1900
            }, (value, index) => 1901 + index)
        },
        validateEmail(value) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                this.msg['email'] = '';
            } else {
                this.msg['email'] = 'Invalid Email Address';
            }
        },

    }
};
</script>

<style scoped>

</style>
