<template>

    <div class="border border-black font-extrabold text-green-500  h-10 w-48">
        <div class="w-full h-10 border-l border-b border-r border-t border-black text-xs  text-blue-600 font-extrabold justify-center content-center flex flex-wrap">
            <div class="w-full flex flex-wrap items-center  h-full">
              
                <div @click="cantidad>0?()=>{emitp('min')}:null;" class=" w-4/12 flex cursor-pointer hover:bg-red-500 flex-shrink-0 justify-center items-center  h-full text-red-500 bg-red-200  dark:text-blue-300 dark:bg-red-900">
                    <span class="text-black text-xs font-extrabold">-</span>
                </div>
                <div class="overflow-hidden w-4/12 flex flex-shrink-0 justify-center items-center  h-full text-blue-500   dark:text-blue-300 dark:bg-blue-900">
                    <!--<span class="text-black text-xs font-extrabold">{{Number(cantidad).toFixed(0)}}</span>-->
                    <input style="border:0px" :max="maximo" :value="cantidad" v-on:keyup="formatNumber($event)" class="  bg-white w-full text-center text-xs  border-0  placeholder-gray-500" type="text" name="card_number" placeholder="" />

                </div>
                <div @click="maximo>cantidad?()=>{emitp('mas')}:null;" class=" w-4/12 flex cursor-pointer hover:bg-green-500 flex-shrink-0 justify-center items-center  h-full text-blue-500 bg-green-200  dark:text-blue-300 dark:bg-blue-900">
                    <span class="text-black text-xs font-extrabold">+</span>
                </div>
            </div>

        </div>
   

</div>
</template>

<script>
export default {
    mixins: [],
    props: {
        cantidad: {
            type: Number,
            default: 0

        },
        maximo: {
            type: Number,
            default: 0

        },

       
    },
    setup(props,{ emit }) {
      //let emit  = defineEmits(['seleccionado'])
       
      
        const emitp = (tipo) => {
          if(tipo=='mas'){
            emit("mas", true)
          }else{
            emit("min", true)
          }
            
        }
        return {
           emit,
           emitp
           
        }
    },
    methods: {
            update_c(cant,tipo) {
                
             
                emit('update:number', cant,tipo)
            },
           

            formatNumber(event) {

                var inputChar = String.fromCharCode(event.keyCode);
                var code = event.keyCode;
                var allowedKeys = [8];

                console.log()
                if (allowedKeys.indexOf(code) !== -1) {
                    return;
                }
                event.target.value = event.target.value
                    .replace(
                        /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
                    );
                emit('update:val', event.target.value)
            },
        }
}
</script>
