<template>
<section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-center content-center overflow-y-auto">
    <div x-show="selectedRows.length" class="bg-teal-200 fixed top-0 left-0 right-0 z-40 w-full shadow " />

    <div class="container px-5 py-10 mx-auto w-full h-full ">
        <div class="flex flex-wrap -m-4">
            <div class="p-4 md:w-1/3 " v-for="(row,index) in data" v-bind:key="index">
                <div class="h-full rounded-xl shadow-cla-blue bg-gradient-to-r from-indigo-50 to-blue-50 overflow-hidden">
                    <p class="leading-relaxed mb-3 text-black relative mt-1 z-30 font-extrabold text-xs  h-12 w-12 text-center bg-white  mt-1 mx-2 rounded-full flex  flex-wrap content-center justify-center text-xl"> <span class="   rounded-full">
                            {{row.programas_archivos.length}}</span>
                    </p>
                    <img class="lg:h-48 md:h-36  w-full object-cover -mt-12  object-center scale-110 transition-all x-20 duration-400 hover:scale-100" :src="row.url" alt="blog">

                    <div class="p-6">
                        <h2 class="tracking-widest text-xs title-font font-medium text-blue-600 mb-1">{{row.nombre}}</h2>
                        <h1 class="title-font text-lg font-extrabold text-green-600 mb-3">{{row.tipo_membresia}}</h1>

                        <p class="leading-relaxed mb-3 text-ellipsis overflow-hidden h-16" v-html="row.descripcion"></p>
                        <div class="flex items-center flex-wrap ">
                            <button @click="edit(row)" class="bg-blue-600 hover:scale-105 drop-shadow-md  shadow-cla-blue px-4 py-1 rounded-lg text-xs text-white font-extrabold">Editar</button>

                            <button @click=" this.$router.push('/app/programas/contenido?p='+row.id); " class="ml-2 bg-gradient-to-r from-green-800 to-blue-400 hover:scale-105 drop-shadow-md text-xs  shadow-cla-blue px-4 py-1 rounded-lg text-white font-extrabold">Ver contenido</button>
                            <button @click="eliminar(row.id)" class="bg-red-600 hover:scale-105 drop-shadow-md  shadow-cla-blue px-4 py-1 rounded-lg text-xs text-white font-extrabold ml-2">Eliminar</button>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <button class="btn font-bold bg-blue-800 text-white text-3xl w-12 h-12 justify-center content-center flex flex-wrap fixed z-20  float-right m-r-xxs rounded-full fixed  bottom-5 right-5 " @click="addRow()">
        <vue-feather :type="'plus-circle'" size="24" class="self-center" :stroke="'white'" />
    </button>

    <button class="btn font-bold bg-yellow-300 text-white text-3xl w-12 h-12 justify-center content-center flex flex-wrap fixed bottom-5 z-20 right-20 float-right m-r-xxs rounded-full" @click="getData()">
        <vue-feather :type="'refresh-cw'" size="24" class="self-center" :stroke="'white'" />
    </button>

    <el-drawer v-model="showM" title="" :with-header="false" size="50%">
        <div class="relative   w-full">
            <!--content-->

            <div class=" relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <!--header-->
                <div class="flex items-start justify-between p-2 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 class="text-xl font-semibold">
                        {{ id==0?'Crear Programa':'Editar Programa' }}
                    </h3>

                </div>
                <!--body-->
                <div class="relative p-6 pt-2 flex-auto  overflow-y-scroll flex flex-wrap">
                    <div class="mb-2 mt-2  justify-start flex flex-wrap w-full px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Nombre
                        </span>
                        <input id="cantidad" v-model="nombre" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="text" placeholder="Nombre">
                    </div>
                    <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Descripcion
                        </span>
                        <textarea id="cantidad" v-model="descripcion" class="text-sm appearance-none h-48 rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline " placeholder="Descripcion" />
                        </div>
  <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2 z-40">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                           Portada
                        </span>
                   <div class="w-full px-2 py-2  border-2 border-blue-400 flex flex-col justify-center content-center" v-if="imageUrl!=''">
                   <span class=" mb-2 bg-blue-500 text-white font-extrabold text-xs  self-center  z-40 px-4 rounded cursor-pointer" @click="imageUrl=''" >Cambiar imagen</span>
                   <img class="h-40 w-72 items-center self-center" :src="imageUrl"/>
                   </div> 

                      <div class=" h-28 w-full  items-center justify-center bg-gray-100 font-sans  " v-if="imageUrl==''" @click="onPickFile">
     
      <label
        for="dropzone-file"
        class="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center rounded-xl border-2 border-dashed border-blue-400 bg-white p-6 text-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-10 w-10 text-blue-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
          />
        </svg>

        <h2 class="mt-4 text-xl font-medium text-gray-700 tracking-wide"> Seleccione una imagen </h2>

        <p class="mt-2 text-gray-500 tracking-wide">Seleccionar imagen de tipo PNG o JPG. </p>

        <input
  type="file"
  style="display: none"
  ref="fileInput"
  accept="image/*"
  @change="onFileChange"/>
      </label>
    </div>
     </div>

                    
                    <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2 z-40">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Tipo de membresia
                        </span>
                         <select id="cantidad" v-model="tipo_membresia" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            <option :value="0" :selected="tipo_membresia==0">
                                    Seleccione una tipo de membresia
                                </option>
                            <template v-for="(row,index) in tipos_membresias" :key="index">
                                <option :value="row.id" :selected="tipo_membresia==row.id">
                                    {{ row.nombre }}
                                </option>
                            </template>
                        </select>
                    </div>
                     <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2 z-40">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                           Instructor
                        </span>
                         <select id="cantidad" v-model="instructores_id" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            <option :value="0" :selected="instructores_id==0">
                                    Seleccione un instructor
                                </option>
                            <template v-for="(row,index) in instructores_data" :key="index">
                                <option :value="row.id" :selected="instructores_id==row.id">
                                    {{ row.nombre }}
                                </option>
                            </template>
                        </select>
                    </div>
                      <div class="mb-2 mt-2 justify-start flex flex-wrap w-full px-2 z-40">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                           Tipos compra <el-tag class="ml-2" type="warning" @click="openDialogPlan=true">Agregar </el-tag>
                        </span>
           
      <el-dialog
        v-model="openDialogPlan"
        width="30%"
        title="Agregar plan de suscripcion a programa"
        append-to-body
      >
      <template #default>
      
<div class="relative p-6 flex-auto h-96 overflow-y-scroll flex flex-wrap">
<div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2 z-40">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Recurrente
                        </span>
                         <select id="cantidad" v-model="planObj.recurrente" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            <option :value="0" >
                                    Seleccione una opcion
                                </option>
                           
                                <option :value="0" :selected="planObj.recurrente==0">
                                    No
                                </option>
                                 <option :value="1" :selected="planObj.recurrente==1">
                                    Si
                                </option>
                           
                        </select>
                    </div>
                    <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2 z-40">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Semanas
                        </span>
                         <select id="cantidad" v-model="planObj.semanas" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            <option :value="0" >
                                    Seleccione una opcion
                                </option>
                           
                                <template v-for="(row,index) in semanas" :key="index">
                                <option :value="row.id" :selected="planObj.semanas==row.id">
                                    {{row.id}} - {{ row.nombre }}
                                </option>
                            </template>
                           
                        </select>
                    </div>
                      <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                    Precio
                </span>
                <input type="number" id="cantidad" v-model="planObj.precio" class="text-sm appearance-none  rounded w-full py-2 px-3 text-gray-700 bg-gray-200 h-10 leading-tight focus:outline-none focus:shadow-outline " placeholder="Precio" />
                </div>
                </div>

    </template>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="openDialogPlan = false">Cancel</el-button>
        <el-button @click="agregarNuevo()" type="primary">Guardar</el-button>
        
      </div>
    </template>
      </el-dialog>

                         <el-table :data="tableData" style="width: 100%">
    <el-table-column prop="id" label="Identificador"  />
    <el-table-column prop="semanas" label="Semanas" />
    <el-table-column prop="precio" label="Precio"   />
      <el-table-column prop="recurrente" label="Recurrente"  />
      <el-table-column fixed="right" label="Eliminar" width="120">
      <template #default="scope">
      
        <el-button link type="danger" size="small" @click="EliminarPlan(scope)"
          >Eliminar</el-button
        >
      
      </template>
    </el-table-column>
  </el-table>
                    </div>

                   
                    
                   
                  
                   
                </div>
                <!--footer-->
               
            </div>
        </div>

         <template #footer>
      <div style="flex: auto">
      <button class="text-red-500 bg-red-500 bg-opacity-20 border border-solid border-red-500 hover:bg-red-500 hover:bg-opacity-30 hover:text-red-900 active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="showM=false">
                        Cancelar
                    </button>
                    <button class="text-blue-800 bg-blue-500 bg-opacity-40 border hover:text-white  hover:bg-blue-700 border-solid border-blue-800 font-bold uppercase px-6 py-3 text-sm outline-none  rounded focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="guardar()">
                        Guardar
                    </button>
      </div>
    </template>
  </el-drawer>

    <div v-if="showM2" style="z-index:9000" class="overflow-x-hidden overflow-y-auto fixed inset-0  outline-none focus:outline-none justify-center bg-black bg-opacity-50 items-center flex">
        <div class="relative  my-6 mx-auto md:w-8/12 xl:w-6/12 lg:w-6/12">
            <!--content-->
            <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <!--header-->
                <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 class="text-3xl font-semibold">
                        {{ id==0?'Crear Programa':'Editar Programa' }}
                    </h3>
                    <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="showM=false">
                        <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                        </span>
                    </button>
                </div>
                <!--body-->
                <div class="relative p-6 flex-auto h-96 overflow-y-scroll flex flex-wrap">
                    <div class="mb-2 mt-2  justify-start flex flex-wrap w-full px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Nombre
                        </span>
                        <input id="cantidad" v-model="nombre" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="text" placeholder="Nombre">
                    </div>
                    <div class="mb-2 mt-2  justify-start flex flex-wrap w-full px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Descripcion
                        </span>
                        <textarea id="cantidad" v-model="descripcion" class="text-sm appearance-none h-24 rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline " placeholder="Descripcion" />
                        </div>
                    <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2 z-40">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Tipo de membresia
                        </span>
                         <select id="cantidad" v-model="tipo_membresia" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            <option :value="0" :selected="tipo_membresia==0">
                                    Seleccione una tipo de membresia
                                </option>
                            <template v-for="(row,index) in tipos_membresias" :key="index">
                                <option :value="row.id" :selected="tipo_membresia==row.id">
                                    {{ row.nombre }}
                                </option>
                            </template>
                        </select>
                    </div>
                    <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2 z-40">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                           Instructor
                        </span>
                         <select id="cantidad" v-model="instructores_id" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            <option :value="0" :selected="instructores_id==0">
                                    Seleccione un instructor
                                </option>
                            <template v-for="(row,index) in instructores_data" :key="index">
                                <option :value="row.id" :selected="instructores_id==row.id">
                                    {{ row.nombre }}
                                </option>
                            </template>
                        </select>
                    </div>
                    <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2 z-40">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                           Portada
                        </span>
                   <div class="w-full px-2 py-2  border-2 border-blue-400 flex flex-col justify-center content-center" v-if="imageUrl!=''">
                   <span class=" mb-2 bg-blue-500 text-white font-extrabold text-xs  self-center  z-40 px-4 rounded cursor-pointer" @click="imageUrl=''" >Cambiar imagen</span>
                   <img class="h-40 w-40 items-center self-center" :src="imageUrl"/>
                   </div> 

                      <div class=" h-28 w-full  items-center justify-center bg-gray-100 font-sans  " v-if="imageUrl==''" @click="onPickFile">
     
      <label
        for="dropzone-file"
        class="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center rounded-xl border-2 border-dashed border-blue-400 bg-white p-6 text-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-10 w-10 text-blue-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
          />
        </svg>

        <h2 class="mt-4 text-xl font-medium text-gray-700 tracking-wide"> Seleccione una imagen </h2>

        <p class="mt-2 text-gray-500 tracking-wide">Seleccionar imagen de tipo PNG o JPG. </p>

        <input
  type="file"
  style="display: none"
  ref="fileInput"
  accept="image/*"
  @change="onFileChange"/>
      </label>
    </div>
     </div>
                   
                </div>
                <!--footer-->
                <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button class="text-red-500 bg-red-500 bg-opacity-20 border border-solid border-red-500 hover:bg-red-500 hover:bg-opacity-30 hover:text-red-900 active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="showM=false">
                        Cancelar
                    </button>
                    <button class="text-blue-800 bg-blue-500 bg-opacity-40 border hover:text-white  hover:bg-blue-700 border-solid border-blue-800 font-bold uppercase px-6 py-3 text-sm outline-none  rounded focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="guardar()">
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="actionModal!=null" style="z-index:9999900" class="bg-slate-800 bg-opacity-50  h-screen w-full flex content-center justify-center items-center fixed  top-0 right-0 bottom-0 left-0">
        <div class=" bg-white w-72 h-72 self-center  flex-wrap px-5 py-14 rounded-md text-start flex content-center justify-center items-center">
            <div class="text-base mb-4 font-bold text-slate-500">
                ¿Qué desea hacer?
            </div>
            <div class="flex-wrap flex justify-between  w-full">
                <div class="w-3/6 px-2">
                    <button class="w-full bg-blue-500 bg-opacity-20 px-4 py-2 rounded-md text-md text-blue-500 border-2 border-blue-500" @click="edit(actionModal);">
                        Editar
                    </button>
                </div>
                <div class="w-3/6 px-2">
                    <button class="bg-red-500 px-4 py-2 bg-opacity-20 rounded-md text-md text-red-500 w-full  border-2 border-red-500" @click="eliminar(actionModal.id)">
                        Eliminar
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import programas from "@/Services/programas";
import instructores from "@/Services/instructores";

export default {
    components: {

    },
    mixins: [programas, instructores],
    data() {
        const user = useUserStore()

        return {
            headings: [

                {
                    key: 'id',
                    name: '#',
                    show: true
                },
                {
                    key: 'nombre',
                    name: 'Nombre',
                    show: true
                },
                {
                    key: 'descripcion',
                    name: 'Descripcion',
                    show: true
                },
                {
                    key: 'color',
                    name: 'Color',
                    show: true,

                },
                {
                    key: 'edit',
                    name: 'Editar',
                    show: true
                },
                {
                    key: 'eliminar',
                    name: 'Eliminar',
                    show: true
                }
            ],
            buscar: '',
            planObj: {
                id: 0,
                recurrente: 1,
                semana: 0,
                precio: 0,
                stripe_price_id: 0,
                activo: 1
            },
            semanas: [{
                    id: 1,
                    nombre: 'Semana'
                },
                {
                    id: 2,
                    nombre: 'Semanas'
                },
                {
                    id: 3,
                    nombre: 'Semanas'
                },
                {
                    id: 4,
                    nombre: 'Semanas'
                },
                {
                    id: 5,
                    nombre: 'Semanas'
                },
                {
                    id: 6,
                    nombre: 'Semanas'
                },
                {
                    id: 7,
                    nombre: 'Semanas'
                },
                {
                    id: 8,
                    nombre: 'Semanas'
                },
                {
                    id: 9,
                    nombre: 'Semanas'
                },

            ],
            tipos_membresias: [{
                    id: 'membresia',
                    nombre: 'Membresia'
                },
                {
                    id: 'online',
                    nombre: 'Online'
                }
            ],
            planes_recurrentes: [{
                    nombre: '1 MES',
                    precio: 0,
                    meses: 1,

                },
                {
                    nombre: '3 MESES',
                    precio: 0,
                    meses: 3,

                },
                {
                    nombre: '6 MESES',
                    precio: 0,
                    meses: 6,

                }
            ],
            tableData: [],
            tipocompra: 'recurrente',
            recurrente: 1,
            open: false,
            user: user.getAll(),
            data: [],
            data_file: null,
            instructores_data: [],
            actionModal: null,
            showM: false,
            id: 0,
            base64: '',
            tipo_membresia: 0,
            url: '',
            imageUrl: '',
            image: null,
            nombre: '',
            descripcion: '',
            instructores_id: 0,
            openDialogPlan: false,

        }

    },
    mounted() {

    },
    created() {
        this.getData();
        this.getDataInstructores();
    },
    watch: {
        url: function (newVal, oldVal) {
            if (newVal) {
                this.createBase64Image(newVal);
            } else {
                this.base64 = null;
            }
        }
    },
    methods: {
        EliminarPlan($el) {
            console.log($el)
            console.log($el.row)
            this.tableData.splice($el.$index, 1) 
        },
        agregarNuevo() {
            this.tableData.push(this.planObj)

        },
        async onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.data_file = files[0];
            console.log(e)

        },
        onPickFile() {
            this.$refs.fileInput.click()
        },
        onFilePicked(event) {
            const files = event.target.files
            let filename = files[0].name
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.image = files[0]
        },
        async createBase64Image(FileObject) {
            const reader = new FileReader();
            reader.onload = (event) => {
                this.base64 = event.target.result;
            }
            reader.readAsDataURL(FileObject);
        },
        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },

        limpiar() {

            this.id = 0;
            this.nombre = '';
            this.descripcion = '';
            this.url = '';
            this.tipo_membresia = 'presencial';
            this.instructores_id = 0;
            this.imageUrl = '';
        },
        edit(row) {
            this.id = row.id;
            this.nombre = row.nombre;
            this.descripcion = row.descripcion;

            this.imageUrl = row.url;
            this.tipo_membresia = row.tipo_membresia;
            this.instructores_id = row.instructores_id;

            this.showM = true;
            this.actionModal = null;
            this.tableData=[];
            this.tableData=row.programas_planes;
        },

        async getDataInstructores() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await instructores.GET(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.instructores_data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await programas.GET(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async guardar() {

            if (this.instructores_id == 0) {
                emitter.emit("alerta_show", 'Es necesario seleccionar un instructor');
                return false;
            }
            var formData = new FormData();

            formData.append("doc", this.data_file, );
            formData.append("id", this.id, );
            formData.append("nombre", this.nombre, );
            formData.append("descripcion", this.descripcion, );
            formData.append("tipo_membresia", this.tipo_membresia, );
            formData.append("instructores_id", this.instructores_id, );
             //formData.append("programas_planes",this.tableData , );
            formData.append("programas_planes",JSON.stringify(this.tableData) , );
/*
this.tableData.forEach(element => {
     formData.append("programas_planes[]",element , );
});

   */        
            

            let dat = {
                id: this.id,
                data: formData
            };
            if (this.id == 0) {
                this.crear(dat)
            } else {
                this.editar(dat)
            }
        },
        async editar(dat) {
            emitter.emit("loading", true)

            await programas.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async crear(dat) {
            emitter.emit("loading", true)

            await programas.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async eliminar(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await programas.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
