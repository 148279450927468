

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
  

    LOGIN_USER: async function( data){
      let url='/login/'
      return initmixin.formatrespuesta('post', data,url)
    },
    REGISTRO_USER: async function( data) {
      let url='/user/'
      return initmixin.formatrespuesta('post', data,url)
    },
    
    REGISTRO_CLIENTE: async function(data) {
      let url='/registro/'
      return initmixin.formatrespuesta('post', data,url)
    },
     
    LINK_CLIENTE: async function(data) {
      let url='/link/'
      return initmixin.formatrespuesta('post', data,url)
    },
    GET_PERFIL: async function( ) {
      let url='/perfil/'
      return initmixin.formatrespuesta('get',[],url,true)
    },
    
    GET_LINK: async function( data){
      let url='/usuarios/link'
      return initmixin.formatrespuesta('get', data,url,true)
    },
    GET: async function( data){
      let url='/usuarios/'
      return initmixin.formatrespuesta('get', data,url,true)
    },

    GET_INSTALADORES: async function( data){
      let url='/usuarios/instaladores/page/'+data.page+'/limit/'+data.limit+'/search/'+data.search
      return initmixin.formatrespuesta('get', data.data,url,true)
    },
    GET_USUARIOS: async function( data){
      let url='/usuarios/vendedores/page/'+data.page+'/limit/'+data.limit+'/search/'+data.search
      return initmixin.formatrespuesta('get', data.data,url,true)
    },
    
    GET_USUARIOS_PRODUCCION: async function( data){
      let url='/usuarios/produccion/page/'+data.page+'/limit/'+data.limit+'/search/'+data.search
      return initmixin.formatrespuesta('get', data.data,url,true)
    },
    GET_USUARIOS: async function( data){
      let url='/usuarios/vendedores/page/'+data.page+'/limit/'+data.limit+'/search/'+data.search
      return initmixin.formatrespuesta('get', data.data,url,true)
    },
    GET_CLIENTES: async function( data){
      let url='/usuarios/clientes/page/'+data.page+'/limit/'+data.limit+'/search/'+data.search
      return initmixin.formatrespuesta('get', data.data,url,true)
    },
    CREAR: async function( data) {
      let url='/usuarios/'
      return initmixin.formatrespuesta('post', data,url,true)
    },
    EDITAR: async function( data) {
      let url='/usuarios/'+data.id
      return initmixin.formatrespuesta('put', data,url,true)
    },
    ELIMINAR: async function( data) {
      let url='/usuarios/'+data.id
      return initmixin.formatrespuesta('delete', data,url,true)
    },




}