<template>
<div class="w-full h-5/6 flex flex-wrap justify-center content-start overflow-y-scroll ">

    <!--<div class="w-full flex flex-wrap font-bold text-blue-500 fntrel  border-black">
                        <div class="w-2/12 border border-black">Producto</div>
                        <div class="w-1/12 border border-black">Tipo de Producto</div>
                        <div :class="cotizacion.avance<100?'w-5/12':' w-full '" class=" border border-black ">Detalle</div>
                        <div class="w-1/12 border  border-black"> Producción total</div>
                        <div class="w-1/12 border  border-black"> Producción generada</div>
                        <div v-if="cotizacion.avance<100" class="w-2/12 border border-r border-black"> Actualización </div>
                    </div>-->

                    <el-dialog v-model="vista_asignar_fecha" title="" :width="$isMobile()?'90%':'60%'" :fullscreen="false">
                        <div class="w-full  h-full overflow-x-hidden  transition ease-in-out duration-700" id="notification">
                            <div class="w-full bg-gray-50  overflow-y-scroll p-4 h-5/6 overflow-x-hidden">
                                <div class="flex items-center justify-between ">
                                    <p tabindex="0" class="focus:outline-none text-2xl font-semibold  text-gray-800">  Asignar fecha prometida</p>
                
                                </div>
                
                                <div class="border-b border-b-gray-200 flex flex-wrap mt-10 mb-10">
                                    <div class="w-full px-2  sm:w-1/2 flex flex-wrap">
                                        <div class="mb-5 w-full justify-start flex flex-wrap">
                                            <label for="lName" class="mb-3 block text-left text-base w-full font-medium text-[#07074D]">
                                                Fecha
                                            </label>
                                            <input v-model="fecha_prometida" class="w-full " placeholder="Fecha" type="date" />
                                        </div>
                                    </div>
                                    <div class="w-full px-2 flex flex-wrap   sm:w-1/2 justify-start">
                                        <div class="mb-5 w-full flex flex-wrap">
                                            <label for="fName" class="mb-3 block text-base font-medium  text-left text-[#07074D]">
                                                Hora
                                            </label>
                                            <el-time-select v-model="tiempo_fecha_prometida"  class=" w-full" placeholder="Fecha prometida" start="08:30" step="00:15" end="21:30" />
                                        </div>
                                    </div>
                                </div>
                               
                                <btn_component :text="'Asignar fecha'" :tipo="'success'"   @click="AsignarFechaPrometida()"></btn_component>
                                <btn_component :text="'Cerrar detalle'" :tipo="'info'"   @click="vista_asignar_fecha=false"></btn_component>
                
                                <!--<button @click="guardarCotizacion()" type="button" class="px-4 mx-2 rounded py-2 bg-green-500 text-white font-extrabold">Guardar</button>-->
                
                            </div>
                        </div>
                
                    </el-dialog>
                    

            
    <div class="zoom2 w-full overflow-x-scroll   border-black my-10 content-start justify-start flex-wrap" :id="'cat'+index">

        <div class="w-full  flex flex-wrap" v-for="(r,indice) in cotizacion.productos_cotizaciones" v-bind:key="indice">

            <div class="w-full flex flex-nowrap bg-blue-100">
                <div v-if="abrobar" class="w-1/12  align-middle">
                    Estatus formato
                </div>
                <div v-if="abrobar" class="w-1/12  align-middle">
                    Fecha prometida
                </div>
                <div v-if="!abrobar" class="w-1/12  align-middle">
                    Estatus
                </div>
                <div class="w-1/12  align-middle">
                    Cantidad pedido
                </div>
                <div class="w-1/12 align-middle">
                    ##
                </div>
                <div class="w-2/12  align-middle">
                    Producto
                </div>
                <div class="w-6/12 border border-black align-middle text-center">
                    </div>
                <!--<template v-for="(p,i) in r.parametros" v-bind:key="i">
                    <div class="w-48  align-middle" v-if="p.visible_produccion==1">
                        <strong v-if="p.tipo=='numerico'">{{p.nombre}} </strong>
                        <strong v-if="p.tipo=='calc_formula'"> {{p.nombre}} </strong>
                        <strong v-if="p.tipo=='texto_extendido'"> {{p.nombre}} </strong>

                        <strong v-if="p.tipo=='texto'">{{p.nombre}} </strong>
                        <strong v-if="p.tipo=='catalogo'">{{p.selproductos.nombre}} </strong>

                    </div>
                </template>-->
            </div>
            <div class="w-full flex flex-nowrap">
                <div v-if="abrobar" @click="r.formato_aprobado?null:AprobarFormatoProducto(r.id,cotizacion.id)" :class="r.formato_aprobado?'text-green-500':'text-red-500'" class="border border-black text-center align-middle w-1/12 font-extrabold   underline ">
                    <p> {{r.formato_aprobado!=null&&r.fecha_aprobado!=null?'Aprobado el':'Aprobar'}}
                        <br>

                        {{r.formato_aprobado?formadivateTimeLT(r.fecha_aprobado):''}}
                    </p>
                </div>
                <div v-if="abrobar" @click="r.fecha_prometida?null:OpenFechaPrometida(r.id,cotizacion.id)" :class="r.fecha_prometida==null?'text-purple-500':'text-red-500'" class="border border-black text-center align-middle w-1/12 font-extrabold   underline">
                    <p> {{r.fecha_prometida!=null?'':'Establecer fecha'}}
                       

                        {{r.fecha_prometida!=null?formatDateTimeLT(r.fecha_prometida):''}}
                    </p>
                </div>
                <div v-if="!abrobar" :class="r.formato_aprobado?'text-green-500':'text-red-500'" class="border border-black text-center align-middle w-48 font-extrabold   underline ">
                    <p> {{r.formato_aprobado?'Aprobado':'Sin Aprobar'}}

                    </p>
                </div>
                <div class="border border-black text-center align-middle w-1/12 font-extrabold text-green-500 bg-green-100 underline ">
                    {{Number(r.cantidad).toFixed(0)}}
                </div>
                <div class="w-1/12 border border-black align-middle text-center">
                    # {{ r.id }} 
                  </div>
                <div v-if="r.hasOwnProperty('productos')&&r.productos.hasOwnProperty('nombre')"
             class="w-2/12 border border-black align-middle text-center">
                 {{r.productos.nombre}}
                </div>
                <div class="w-6/12 border border-black align-middle text-center">
                                
                                
                    <template v-for="(p,i) in r.parametros" v-bind:key="i">
                        <div class="w-full flex flex-wrap border border-black align-middle text-center text-xl" v-if="p.visible_produccion==1">
                            <div class="w-6/12 border border-black align-middle text-center text-xl">
                              
                                    <div class="w-full align-middle text-md" v-if="p.visible_produccion==1">
                                        <strong v-if="p.tipo=='calc_formula'"> {{p.nombre}}  </strong>
                                        <strong v-if="p.tipo=='value_formula'"> {{p.nombre}}  </strong>
                                        <strong v-if="p.tipo=='texto_extendido'"> {{p.nombre}}  </strong>
                                         
                                       
                                        <strong v-if="p.tipo=='numerico'">{{p.nombre}} </strong>
                                        <strong v-if="p.tipo=='texto'">{{p.nombre}} </strong>
                                        <strong v-if="p.tipo=='catalogo'">{{p.selproductos.nombre}} </strong>
    
                                    </div>
                               
                            </div>
                            <div class="w-6/12 border border-black align-middle text-center text-xl">
                                <template v-if="p.tipo=='numerico'"> {{p.valor_prod}} </template>
                                <template v-if="p.tipo=='calc_formula'">    {{' '}} {{calcularParametroProd(r,p.formula_mp)}} {{' '}}</template>
                             
                                <template v-if="p.tipo=='value_formula'">    {{' '}} {{calcularParametroProd(r,p.formula_mp)}} {{' '}}</template>
                                <template v-if="p.tipo=='texto'">{{p.valor_prod}} </template>
                                <template v-if="p.tipo=='texto_extendido'">{{p.valor_prod}} </template>
                                <template v-if="p.tipo=='catalogo'"> ( {{p.valor_prod==0||p.valor_prod==NULL?'N/A':p.producto_lista_prod.nombre}} ) </template>
                               
                            </div>


                           <!-- <template v-if="p.tipo=='catalogo'"> ( {{p.val_prod_productos_id==0?'N/A':p.producto_lista.nombre}} ) </template>-->
                          
                       
                       

                           
                       
                        </div>
                    </template>

                  </div>
                <!--<template v-for="(p,i) in r.parametros" v-bind:key="i">
                    <div v-if="p.visible_produccion==1" class="w-48 border border-black align-middle text-center">
                        <template v-if="p.tipo=='numerico'"> {{p.valor_prod}} </template>
                        <template v-if="p.tipo=='texto'">{{p.valor_prod}} </template>
                        <template v-if="p.tipo=='calc_formula'"> {{calcularParametro(r,p.formula_mp)}} {{' '}}</template>

                        <template v-if="p.tipo=='texto_extendido'">{{p.valor_prod}} </template>
                        <template v-if="p.tipo=='catalogo'"> ( {{p.valor_prod==0?'N/A':p.producto_lista_prod.nombre}} ) </template>

                    </div>
                </template>-->

            </div>

        </div>

    </div>

</div>
</template>

    
        
        
    
<script>
//@ts-ignore
import {
    emitter
} from "@/mitt.js";
import generales from '@/functions/generales'
import cotizacionesf from '@/functions/cotizaciones'
import productos_cotizaciones from "@/Services/productos_cotizaciones";
import cotizaciones from "@/Services/cotizaciones";
import pdf from "@/Services/pdf";
import {
    throwStatement
} from '@babel/types';
//import palabras from "@/mixins/palabras";
import {
    useInfoStore
} from '../stores/info'
export default {
    name: "Home",
    components: {

    },
    mixins: [generales, cotizacionesf],
    props: {
        abrobar: {
            type: Boolean,
            default: true
        },
        cotizacion: {
            type: Array,
        },
        edit: {
            type: Boolean,
            default: true
        },

    },
    data() {
        const info = useInfoStore()
        return {
            infositio: info.getAll(),
            categorias: {} ,
            tiempo_fecha_prometida:'09:00',
            fecha_prometida:new Date(),
            productoid:0,
            vista_asignar_fecha:false

        }
    },

    mounted() {

        console.log(this.cotizacion)

    },

    methods: {
        OpenFechaPrometida(id, cot){

this.vista_asignar_fecha=true;
this.productoid=id;
        },
async AsignarFechaPrometida() {


            emitter.emit("loading", true)

            let dat = {
                id: this.productoid,
              fecha_prometida: this.fecha_prometida+' '+this.tiempo_fecha_prometida

            }
            await productos_cotizaciones.ASIGNAR_FECHA_PROMETIDA(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    this.vista_asignar_fecha=false;
                    this.cotizacion.productos_cotizaciones.forEach(element => {

                        if (this.productoid == element.id) {
                            element.fecha_prometida = this.fecha_prometida+' '+this.tiempo_fecha_prometida;
                          
                        }

                    });


                    // this.sel_producto.fecha_asignacion=response.fecha_asignacion;

                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                emitter.emit("loading", false)
            });
        },
        async AprobarFormatoProducto(id, cot) {
            emitter.emit("loading", true)

            let dat = {
                id: id,
                idcot: cot

            }
            await productos_cotizaciones.ACEPTAR_FORMATO_PRODUCTO(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.cotizacion.productos_cotizaciones.forEach(element => {

                        if (id == element.id) {
                            element.formato_aprobado = 1;
                            element.fecha_aprobado = new Date();
                        }

                    });

                    // this.sel_producto.fecha_asignacion=response.fecha_asignacion;

                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
        
        
    
<style>
td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.vgt-wrap {
    height: 100%;
}

.vgt-inner-wrap {
    height: 100%;
}
</style>
