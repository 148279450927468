<template>
    <div class="w-full">
        <div v-if="tipo=='success'" class="flex bg-green-100 rounded-lg p-4  text-sm text-green-700 w-full" role="alert">
            <svg class="w-5 h-5 inline mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <div>
                <span class="font-extrabold">{{title}} </span>{{text}}
            </div>
        </div>
        <div v-if="tipo=='info'" class="flex bg-blue-100 rounded-lg p-4  text-sm text-blue-700" role="alert">
            <svg class="w-5 h-5 inline mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <div>
                <span class="font-extrabold">{{title}} </span>{{text}}
            </div>
        </div>
        <div v-else-if="tipo=='danger'" class="flex bg-red-100 rounded-lg p-4  text-sm text-red-700" role="alert">
            <svg class="w-5 h-5 inline mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <div>
                <span class="font-extrabold">{{title}} </span>{{text}}
            </div>
        </div>
    </div>
   
</template>
<script>
export default {
    mixins:[],
    props: {
        title: {
            type: String,
            default:''

        },
        text: {
            type: String,
            default:''

        },
        tipo: {
            type: String,
            default:'success'

        },
        style: {
            type: String,
            default:'default'

        },
       
    },
}
</script>