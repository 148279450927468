<template lang="es">
<div class="content-center h-full w-full flex flex-wrap justify-center">


<div class="relative flex min-h-screen flex-col items-center justify-center overflow-hidden bg-white p-8 sm:p-12">
    <div class="w-full max-w-xl rounded-2xl bg-violet-50 px-20 py-14">
      <div class="mx-auto flex max-w-sm flex-col items-center">
        <h3 class="max-w-2xl text-center text-2xl font-bold leading-tight sm:text-3xl md:text-2xl md:leading-tight">Su pago esta siendo procesado </h3>
        <p class="mt-3 text-center text-black/80">Favor revise el estatus de su cotizacion en unos minutos para verificar que su pago se haya registrado con exito.</p>
        <form action="" class="mx-auto mt-6 flex w-full flex-col gap-3 px-5 sm:flex-row">
          <!--<input type="button" name="email" id="email" class="grow rounded-lg border border-transparent bg-violet-200/50 py-3 px-5 placeholder:text-black/30 focus:border-violet-500 focus:outline-none" placeholder="" />-->
          <a type="button" class="rounded-lg bg-black px-5 py-3 font-bold text-white" href="/cl">Regresar a cotizaciones</a>
        </form>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import 'vue-cal/dist/vuecal.css'
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import clases from "@/Services/clases";
import sucursales from "@/Services/sucursales";
import programas from "@/Services/programas";
import instructores from "@/Services/instructores";
import generales from "@/functions/generales";

import {
    dateEquals
} from 'element-plus';

export default {
    components: {

     
    },
    mixins: [clases, generales, sucursales, instructores, programas],
    data() {
        const user = useUserStore()

        return {

            user: user.getAll(),
            programasdata: [],
            metodos_list: user.getMetodos(),
            metodo: null,
            fecha: new Date(),
            modalPlanes: null,
            VideosList:null,
            
            MetodosPago: false,
            urlvideo:null

        }

    },
    mounted() {
       
    },
    created() {

    },
    methods: {
       

    }
}
</script>
