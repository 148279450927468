<template>
<section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-start  ">
   
    <Tabla v-bind:key="reftable" v-if="data.hasOwnProperty('data')" :columns="headings" :rows="data.data" :filtros="['name','email','phone']" :buscarp="buscar" :filtroApi="true" :paginate="true" :page="{
        page:data.current_page,
        total:data.last_page
      }" />

    <el-drawer v-model="showM" title="" @closed="getData()" :with-header="false" :size="$isMobile()?'100%':'60%'" class="flex flex-wrap justify-center">
        <div class="w-full flex flex-wrap h-full">

            <div class="flex    h-full w-full">
                <!--content-->

                <div class=" relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <!--header-->
                    <div class="flex items-start justify-between p-2 border-b border-solid border-blueGray-200 rounded-t">
                        <h3 class="text-xl font-semibold">
                            {{ id==0?'Crear Cliente':'Editar Cliente' }}
                        </h3>

                    </div>
                    <!--body-->
                    <div class="relative p-6 pt-2   overflow-y-scroll flex flex-wrap">
                        <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                            <div class="mb-5 w-full flex flex-wrap">
                                <label for="fName" class="mb-3 block text-base font-medium  text-left text-[#07074D]">
                                    Nombre <span class="text-red-500">*</span>
                                </label>

                                <input v-model="name" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            </div>
                        </div>
                        <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                            <div class="mb-5 w-full flex flex-wrap">
                                <label for="fName" class="mb-3 block text-base font-medium  text-left text-[#07074D]">
                                    Apellido paterno
                                </label>

                                <input v-model="apellido_p" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            </div>
                        </div>
                        <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                            <div class="mb-5 w-full flex flex-wrap">
                                <label for="fName" class="mb-3 block text-base font-medium  text-left text-[#07074D]">
                                    Apellido materno
                                </label>

                                <input v-model="apellido_m" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            </div>
                        </div>
                        <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                            <div class="mb-5 w-full flex flex-wrap">
                                <label for="fName" class="mb-3 block text-base font-medium  text-left text-[#07074D]">
                                    Correo<span class="text-red-500">*</span>
                                </label>

                                <input v-model="email" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            </div>
                        </div>
                        <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                            <div class="mb-5 w-full flex flex-wrap">
                                <label for="fName" class="mb-3 block text-base font-medium  text-left text-[#07074D]">
                                    Telélefono<span class="text-red-500">*</span>
                                </label>

                                <input v-model="phone" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            </div>
                        </div>
                        <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                            <div class="mb-5 w-full flex flex-wrap">
                                <label for="fName" class="mb-3 block text-base font-medium  text-left text-[#07074D]">
                                    Contraseña<span class="text-red-500">*</span>
                                </label>

                                <input v-model="password" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            </div>
                        </div>

                        <div class="w-full px-2 flex flex-wrap   sm:w-1/2 justify-start">
                            <div class="mb-5 w-full flex flex-wrap">
                                <label for="fName" class="mb-3 block text-base font-medium  text-left text-[#07074D]">
                                    Municipio
                                </label>

                                <input v-model="municipio" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            </div>
                        </div>
                        <div class="w-full px-2 flex flex-wrap sm:w-1/2">
                            <div class="mb-5 w-full flex flex-wrap">
                                <label for="lName" class="mb-3 block text-left text-base font-medium text-[#07074D]">
                                    Codigo postal
                                </label>

                                <input v-model="codigo_postal" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            </div>
                        </div>
                        <div class="w-full px-2 flex flex-wrap   sm:w-1/2 justify-start">
                            <div class="mb-5 w-full flex flex-wrap">
                                <label for="fName" class="mb-3 block text-base font-medium  text-left text-[#07074D]">
                                    Colonia
                                </label>
                                <input v-model="colonia" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            </div>
                        </div>

                        <div class="w-full px-2 flex flex-wrap sm:w-1/2">
                            <div class="mb-5 w-full flex flex-wrap">
                                <label for="lName" class="mb-3 block text-left text-base font-medium text-[#07074D]">
                                    Calle
                                </label>

                                <input v-model="calle" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            </div>
                        </div>
                        <div class="w-full px-2 flex flex-wrap   sm:w-1/2 justify-start">
                            <div class="mb-5 w-full flex flex-wrap">
                                <label for="fName" class="mb-3 block text-base font-medium  text-left text-[#07074D]">
                                    Numero
                                </label>
                                <input v-model="numero" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            </div>
                        </div>
                        <div class="w-full px-2 flex flex-wrap   sm:w-1/2 justify-start">
                            <div class="mb-5 w-full flex flex-wrap">
                                <label for="fName" class="mb-3 block text-base font-medium  text-left text-[#07074D]">
                                    Origen
                                </label>
                                <select v-model="origen" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                                    <option v-for="(row,index) in origenes" v-bind:key="index" :value="row.nombre">{{row.nombre}}</option>

                                </select>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <div style="flex: auto">

                <btn_component :text="'Guardar'" :tipo="'success'" @click="guardar()"></btn_component>
                <btn_component :text="'Cerrar'" :tipo="'info'" @click="showM=false"></btn_component>

            </div>
        </template>
    </el-drawer>

    <el-drawer @closed="getData()" v-model="viewCotizacion" title="" :with-header="false" :size="$isMobile()?'100%':'100%'">
        <!--content-->
        <div class="h-full    flex flex-wrap content-start justify-center w-full bg-red-100 outline-none focus:outline-none overflow-y-scroll">
            <!--header-->

            <div class="sticky top-0 flex flex-wrap justify-between p-5 border-b border-solid border-blueGray-200  z-30 bg-white rounded-t w-full">
                <h3 class="text-3xl font-semibold">
                    Cotizaciones Cliente
                </h3>
                <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="showM=false">
                    <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                    </span>
                </button>
            </div>
            <!--body-->
            <div class="w-full   overflow-y-scroll flex  flex-wrap justify-start  content-start z-20 h-full">

                <div class="h-full bg-gray-100 py-2 flex flex-wrap w-full content-start px-2 ">

                    <div class="w-full h-full">

                        <div class="max-w-full overflow-x-scroll overflow-y-auto content-start h-full">
                            <table class="w-full   table-auto">
                                <thead>
                                    <tr>
                                        <th class="border-2 top-0 sticky border-black bg-gray-200 min-w-[120px]">#cotizacion</th>
                                        <th class="border-2 top-0 sticky border-black bg-gray-200 min-w-[120px]">#venta</th>
                                        <th class="border-2 top-0 sticky border-black bg-gray-200  min-w-[180px]">Fecha Crecion</th>
                                        <th class="border-2 top-0 sticky border-black bg-gray-200  min-w-[260px]">Usuario</th>
                                        <th class="border-2 top-0 sticky border-black bg-gray-200  min-w-[160px]">Subtotal</th>
                                        <th class="border-2 top-0 sticky border-black bg-gray-200  min-w-[160px]">Total</th>
                                        <th class="border-2 top-0 sticky border-black bg-gray-200  min-w-[160px]">Abonado</th>
                                        <th class="border-2 top-0 sticky border-black bg-gray-200  min-w-[200px]">Detalle Cotización</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(row,index_abono) in el_cliente.cotizaciones" v-bind:key="index_abono">
                                        <tr :class="row.venta==null||row.venta==''?'':'bg-green-200'">
                                            <td class="border border-black text-blue-500 font-extrabold min-w-[120px]">{{row.id}}</td>
                                            <td class="border border-black text-blue-500 font-extrabold min-w-[120px]">{{row.idventa}}</td>
                                            <td class="border border-black text-blue-500 font-extrabold  min-w-[180px]">{{formatFullDate(row.created_at)}}</td>
                                            <td class="border border-black text-blue-500 font-extrabold  min-w-[260px]">{{row.usuario.name}}</td>

                                            <td class="border border-black text-green-500 font-extrabold  min-w-[160px]">${{formatPrice((Number(row.subtotal)).toFixed(0))}}</td>

                                            <td class="border border-black text-green-500  font-extrabold  min-w-[160px]">${{formatPrice(Number(row.total).toFixed(0))}}</td>
                                            <td class="border border-black text-red-500  font-extrabold  min-w-[160px]">${{formatPrice(CalcularAbonos(row))}}</td>
                                            <td class="border border-black text-green-500 font-extrabold  min-w-[200px]">
                                                <a v-if="row.venta==null||row.venta==''" class="text-yellow-600" :href="'/app/cotizaciones?cid='+row.id">Ver Cotización</a>
                                                <div v-else>

                                                    <a class="text-yellow-600" :href="'/app/detalle/ventas?cid='+row.id">Ver avance</a>

                                                </div>
                                            </td>
                                        </tr>
                                    </template>

                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

            </div>

        </div>
        <template #footer>
            <div class="dialog-footer">

                <btn_component :text="'Cerrar'" :tipo="'info'" @click="viewCotizacion=false"></btn_component>

            </div>
        </template>

    </el-drawer>

</section>
</template>

<script>  
/* eslint-disable */
import {
    emitter
} from "../../../mitt.js";
import { 
    useUserStore
} from '../../../stores/user'
import usaurios from "@/Services/user";
import origenes_clientes from "@/Services/origenes_clientes";
import Tabla from "@/components/Tabla.vue";
import generales from "@/functions/generales";

import {
    filter
} from "lodash";
export default {
    components: {
        Tabla
    },
    mixins: [usaurios, generales],
    data() {
        const user = useUserStore()
 
        return {
            headings: [

                {
                    key: 'id',
                    name: '#',
                    class: 'min-w-[120px]',
                    show: true
                },

                {
                    key: 'name',
                    name: 'Nombre',
                    class: 'min-w-[160px]',
                    show: true
                },
                {
                    key: 'apellido_p',
                    name: 'Apellido paterno',
                    class: 'min-w-[160px]',
                    show: true
                },
                {
                    key: 'apellido_m',
                    name: 'Apellido materno',
                    class: 'min-w-[160px]',
                    show: true
                },
                {
                    key: 'origen',
                    name: 'Origen',
                    class: 'min-w-[160px]',
                    show: true
                },
                {
                    key: 'direccion',
                    name: 'Dirección',
                    class: 'min-w-[660px]',
                    show: true
                },
                {
                    key: 'email',
                    name: 'Correo',
                    class: 'min-w-[250px]',
                    show: true
                },

                {
                    key: 'phone',
                    name: 'Teléfono',
                    class: 'min-w-[200px]',
                    show: true
                },

                {
                    key: 'password_plano',
                    name: 'Contraseña',
                    class: 'min-w-[160px]',
                    show: true
                },
                {

                    key: 'ver_cotizacion',
                    name: 'Ver cotizaciones',
                    class: 'min-w-[160px]',
                    show: true
                },

                {
                    key: 'edit',
                    name: 'Editar',
                    show: true
                },
                {
                    key: 'eliminar',
                    name: 'Eliminar',
                    show: true
                }
            ],
            upbuton: 0,
            open: false,
            user: user.getAll(),
            data: {},
            actionModal: null,
            showM: false,
            info: '',
            id: 0,
            link: '',
            email: '',
            phone: '',
            role: 'cliente',
            password: '',
            numero: '',
            calle: '',
            colonia: '',
            codigo_postal: '',
            municipio: '',
            origen: '',
            apellido_p: '',
            apellido_m: '',
            buscar: 'all',
            pagina: 1,
            limit: 0,
            reftable: 0,
            origenes: [],
            membresiaModal: null,
            viewCotizacion: false,
            el_cliente: {
                id: 0
            }

        }

    },
    mounted() {

    },
    created() {
        if (this.$route.query.hasOwnProperty('r')) {
            this.buscar = this.$route.query.r;
        }
        this.getData();
        this.getDataOrigenes();
    },
    methods: {
        CalcularAbonos(row) {

            let total = 0;
            row.abonos.forEach(element => {
                total = Number(total) + Number(element.capital)
            });
            return Number(total)

        },
        infoModal(row) {
            this.info = row;
            console.log(row, '---info---')
        },
        paginaSearch(pagina) {
            console.log('---- dat---' + pagina)
            this.pagina = pagina;

            this.getData();
        },

        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },
        buscador(row) {
            if (String(row.telefono).toUpperCase().search(String(this.buscar).toUpperCase()) != -1 || (String(row.nombre)).toUpperCase().search(String(this.buscar).toUpperCase()) != -1) {
                return true;
            } else {
                return false;
            }
        },
        verCotizacion(row) {
            console.log(row)
            this.el_cliente = row;
            this.viewCotizacion = true;

        },
        getColumns() {
            let col = 0;
            this.headings.forEach(element => {
                if (element.show) {
                    col = col + 1
                }
            });
            return col;
        },
        limpiar() {
            this.id = 0;
            this.email = '';
            this.password = '';
            this.phone = '';
            this.name = '';
            this.role = 'cliente';
            this.apellido_m = '';
            this.apellido_p = '';
            this.origen = '';
            this.numero = '',
                this.calle = '';
            this.colonia = '';
            this.codigo_postal = '';
            this.municipio = '';

        },
        edit(row) {
            this.id = row.id;
            this.email = row.email;
            this.name = row.name;
            this.apellido_m = row.apellido_m;
            this.apellido_p = row.apellido_p;
            this.origen = row.origen;
            this.numero = row.numero,
                this.calle = row.calle;
            this.colonia = row.colonia;
            this.codigo_postal = row.codigo_postal;
            this.municipio = row.municipio;
            this.password = row.password;
            this.phone = row.phone;
            this.role = row.role;

            this.showM = true;
            this.actionModal = null;
        },
        buscarGet(text) {
            this.buscar = text;
            if (text == '') {
                this.buscar = 'all';
            }
            this.getData();
        },
        async getDataOrigenes() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await origenes_clientes.GET(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.origenes = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)
            let dat = {
                search: this.buscar,
                page: this.pagina,
                limit: this.limit,
                data: {}
            }

            await usaurios.GET_CLIENTES(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response

                    if (this.$route.query.hasOwnProperty('r')) {
                        let fresult = response.data.filter(r => r.id == this.$route.query.r)
                        if (fresult.length > 0) {
                            this.viewCotizacion = true
                            this.el_cliente = fresult[0];
                            console.log(this.el_cliente)
                            this.$router.replace({
                                name: 'clientes',
                                force: true
                            });
                            // this.infoModal(fresult[0]);
                        }
                        //console.log(this.$route.query.r,fresult,'recovery')
                    }
                    this.reftable = this.reftable + 1;

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async guardar() {
            let dat = {
                id: this.id,
                role: this.role,
                phone: this.phone,
              
                password: this.password,
                name: String(this.name).toUpperCase(),
                email:   String(this.email).toUpperCase(),
                colonia:  String(this.colonia).toUpperCase(),
                calle:  String(this.calle).toUpperCase(),
                municipio:  String(this.municipio).toUpperCase(),
                apellido_m:  String(this.apellido_m).toUpperCase(),
                apellido_p: String(this.apellido_p).toUpperCase(),
               
                numero: this.numero,
                codigo_postal: this.codigo_postal,
              
                origen: this.origen,

            }
            if (this.id == 0) {
                this.crear(dat)
            } else {
                this.editar(dat)
            }
        },
        async editar(dat) {
            emitter.emit("loading", true)

            await usaurios.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    this.limpiar();
                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                this.showM = false;

                emitter.emit("loading", false)
            });
        },

        async crear(dat) {
            emitter.emit("loading", true)

            await usaurios.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    this.showM = false;
                    this.limpiar();
                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);
                    emitter.emit("loading", false)
                }

            });
        },

        async eliminar(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await usaurios.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);
                    // emitter.emit("alerta_show",response.msg);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
