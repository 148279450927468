<template>
  <div class=" h-screen bg-white w-full  overflow-y-auto  overflow-x-hidden">
    <loading
      :active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
    <div
      v-if="showmodal"
      style="z-index:9999999999"
      class="bg-black bg-opacity-50  h-screen w-full flex content-center justify-center items-center fixed  top-0 right-0 bottom-0 left-0"
    >
      <div
        :class="{'bg-white':!statusal,'bg-green-100':statusal}"
        class=" w-80 min-h-72 self-center  flex-wrap px-16 py-14 rounded-md text-start flex content-center justify-center items-center"
      >
        <div
          class="text-base mb-4 font-bold text-slate-500 w-full"
          v-html="mensaje"
        />
        <button
          class="bg-black px-4 py-2 rounded-md text-md text-white"
          @click="showmodal=false"
        >
          Ok
        </button>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>



//import { MagicStick } from '@element-plus/icons'
import {
    emitter
} from "./mitt.js";
import {
    useUserStore
} from './stores/user'
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    components: {
        Loading
    },
      data() {
        const user = useUserStore()
       // const config = useConfigStore()

        return {
            user,
            //config,
             isLoading: false,
            fullPage: true,
               showmodal: false,
            mensaje: '',

            statusal: false,
        }

    },
    created() {

      sessionStorage.clear();
      //localStorage.clear();
      console.log(document)
      //document.zoom = .8;
        var th = this;
        emitter.on('loading', (number) => {
                console.log(number)
                this.doAjax(number);
            }

        );
        emitter.on('alerta_show', (msg) => {
            console.log(msg)
            this.show_alert(msg);

        });
        emitter.on('success', (msg) => {

            this.success(msg);

        });

        this.user.$subscribe((mutation, state) => {
            // import { MutationType } from 'pinia'
            //mutation.type // 'direct' | 'patch object' | 'patch function'
            // same as cartStore.$id
            //mutation.storeId // 'cart'
            // only available with mutation.type === 'patch object'
            //mutation.payload // patch object passed to cartStore.$patch()

            // persist the whole state to the local storage whenever it changes
            localStorage.setItem('user', JSON.stringify(state))
        })

    }, methods: {
        mounted() {

        
          document.body.style.zoom = (window.innerWidth / window.outerWidth)




            $(document).bind('focus', function (e) {
                e.preventDefault();
            });
            let element = document.getElementById('app');
            let scale = 1.0;
            element.addEventListener('wheel', (ev) => {
                // This is crucial. Without it, the browser will do a full page zoom
                ev.preventDefault();

                // This is an empirically determined heuristic.
                // Unfortunately I don't know of any way to do this better.
                // Typical deltaY values from a trackpad pinch are under 1.0
                // Typical deltaY values from a mouse wheel are more than 100.
                let isPinch = Math.abs(ev.deltaY) < 50;

                if (isPinch) {
                    // This is a pinch on a trackpad
                    let factor = 1 - 0.01 * ev.deltaY;
                    scale *= factor;
                    element.innerText = `Pinch: scale is ${scale}`;
                } else {
                    // This is a mouse wheel
                    let strength = 1.4;
                    let factor = ev.deltaY < 0 ? strength : 1.0 / strength;
                    scale *= factor;
                    element.innerText = `Mouse: scale is ${scale}`;
                }
            });

        },
        doAjax(status) {
            this.isLoading = status;

        },
        show_alert(msg) {
            this.mensaje = msg;
            this.statusal = false;
            this.showmodal = true;
        },
        success(msg) {
            this.mensaje = msg;
            this.statusal = true;
            this.showmodal = true;
        },
    }
}
</script>


<style>

/*e------------------estilo para selector search-----------------*/
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}
/*e------------------estilo para selector search-----------------*/
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0;
  margin: 0;
}

.scrollhidden::-webkit-scrollbar {
  display: none;
}
  #journal-scroll::-webkit-scrollbar {
            width: 4px;
            cursor: pointer;
            /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/

        }
        #journal-scroll::-webkit-scrollbar-track {
            background-color: rgba(229, 231, 235, var(--bg-opacity));
            cursor: pointer;
            /*background: red;*/
        }
        #journal-scroll::-webkit-scrollbar-thumb {
            cursor: pointer;
            background-color: #a0aec0;
            /*outline: 1px solid slategrey;*/
        }
       /* .el-popper{
          z-index: 200000009!important;
          left:calc(50% - 100px)!important;
          height: 250px!important;
          width: 300px!important;
           top:calc(50% - 100px)!important;
        }*/



        .avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
.vuecal__view-btn--active{
  display:none
}


.vuecal__event {
  font-size:12px;
  border-radius: 6px;
  border:2px solid white
}
.vuecal .day-split-header {font-size: 11px;}
.vuecal__body .split1,
.vuecal__body .split2,
.vuecal__body .split3,
.vuecal__body .split4,
.vuecal__body .split5,
.vuecal__body .split6,
.vuecal__body .split7,
.vuecal__body .split8,
.vuecal__body .split9,
.vuecal__body .split10,
.vuecal__body .split12,
.vuecal__body .split13,
.vuecal__body .split14 {
background: #ffffffc9;
/*background: #ffcb0061; 
border:1px solid #fff;*/
border: 1px solid #e5e7eb;
}
.split-label{
  padding-top:10px;
  color: rgb(22 101 52);
 
  font-weight: bold;
}
.split-label2{
 
  color: rgb(124 107 37);
}
.vuecal__title-bar {
  display:none!important
  }
.vuecal__time-column{
   background: white;
   color:rgba(0,0,0,.7)!important
}
/*.split2 .vuecal__event {background-color: rgb(5, 5, 5);

}*/
.vuecal__time-column {
    width: 5em!important;
    height: 100%;
    flex-shrink: 0;
}

.vuecal__no-event {display: none;}
.hours::before {
    content:"";  
    position: absolute;
    left: 0;
    right: 0;
    border-top: 1px solid rgb(5, 5, 5);
}
.vld-overlay.is-full-page {
    z-index: 99999991!important;
  
}
.minutesline::before {
    content:"";  
    position: absolute;
    left: 0;
    right: 0;
   /* border-top: 1px solid rgba(0, 68, 255, 0.836)*/
}

.fntrel{
  font-family: 'Quicksand', sans-serif!important
}
.hover-trigger .hover-target {
  transition: ease all 1s;

  opacity: 0;
  height: 0px;
  bottom: -88px;
}

.hover-trigger:hover .hover-target {
  bottom: 48px;
  height: 100px;
  opacity: 1;
}
/*loading efects*/
html, body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
}

html {
  border: 2px solid rgba(0,0,0,0);
  overflow-y: hidden;
  width: 100vw;
  height: 100vh;
}
.input-cell {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
</style>
