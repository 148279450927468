<template>
  <div class=" w-full ">
    <div class=" relative  w-full">
      <input
      v-if="seleccionado==null"
        id="search"
        v-model="search"
        type="text"
        :placeholder="'Buscar '+label+'...'"
        class="p-2  w-full border border-gray-300 rounded"
        @input="$parent.buscarGet(search)"
      >

      <ul
        v-if="searchData.length"
        class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-30"
      >
        <li class="px-1 pt-1 pb-2 font-bold border-b border-gray-200">
          Mostrando {{ searchData.length }} de {{ rows.length }} elementos
        </li>
        <li
          v-for="sel in searchData"
          :key="sel.nombre"
          class="cursor-pointer hover:bg-gray-100 p-1 z-30"
          @click="selectRow(sel)"
        >
          <div class=" w-full flex  flex-wrap justify-start overflow-x-hidden">
            <span class="text-xs font-extrabold text-blue-900 w-full"> {{ sel.nombre }}</span>
          
          </div>
        </li>
      </ul>

      <div
        v-if="seleccionado"
        @click="selectRow(null)"
        class="font-extrabold pt-1 text-start w-full justify-start flex text-xs px-2"
      >

      <!--{{ CapitalizeTo(label) }} seleccionado--> <span class="font-semibold text-blue-500 text-start text-base"> : {{ seleccionado.hasOwnProperty('nombre')?seleccionado.nombre:seleccionado.name+' '+seleccionado.apellido_p+' '+seleccionado.apellido_m+' '+seleccionado.email+' '+seleccionado.phone }} 
        <i class="text-3xl las la-window-close"></i>
      </span>
      </div>
    </div>
  </div>
</template>

<script>
import generales from '@/functions/generales'
import {
    ref,
    computed,
    defineEmits
} from 'vue'
import {
    emitter
} from "@/mitt.js";
 //import { defineProps, useContext } from 'vue'
 import {
    useRouter,
    useRoute
} from 'vue-router'
export default {
    mixins:[generales],
    props: {
       
        rows: {
            type: Array,

        },
        label: {
            type: String,
            default:''

        },
        valor:{
            type:Object
        }
    },
    //emits:'seleccionado',
    setup(props,{ emit }) {
      //let emit  = defineEmits(['seleccionado'])
      const router = useRouter()
        const route = useRoute()
        let search = ref('')
        const searchData = computed(() => {
            if (search.value === '') {
                return []
            }
           
           if(props.rows){
            return props.rows.filter(country => {
                //if (String(country.nombre).toLowerCase().includes(search.value.toLowerCase()) && matches < 10) {
                    
                    return country
                //}
            })
           }else{
            return [];
           }
           
        });
      
        const selectRow = (country) => {
          if(route.query.hasOwnProperty('clid')){
                emitter.emit("alerta_show", 'No es posible cambiar de cliente seleccionado');
                return
            }else{
              seleccionado.value = country
            search.value = ''
             emit("seleccionado", seleccionado)
            }
           
        }
        let seleccionado = ref(props.valor)
        return {
           emit,
            search,
            searchData,
            selectRow,
            seleccionado
        }
    },
    mounted(){
console.log(this.rows)
    }
}
</script>
