<template>
    <section class="w-full mx-auto h-full px-4 sm:px-6 lg:px-4 py-4 bg-gray-100 content-start flex flex-wrap">
    
        <div class="w-full flex flex-wrap justify-start conten-start h-full">
            <div class="  w-full flex flex-wrap justify-start content-start h-2/6 py-2" >
                <div class="bg-white rounded-lg p-12 flex flex-col justify-center content-start w-2/6">
                    <h1 class="font-bold text-2xl md:text-2xl lg:text-3xl font-heading text-gray-900">
                        Reporte citas
    
                    </h1>
                    <h1 class="font-bold mt-2  font-heading text-gray-900 w-full flex flex-wrap justify-center content-center">
                        <el-date-picker v-model="fechaInicio" type="date" placeholder="Fecha de inicio" :default-value="getDefaultStartDate"></el-date-picker>
                        <el-date-picker v-model="fechaFin" type="date" placeholder="Fecha de fin" :default-value="getDefaultEndDate"></el-date-picker>
                        <el-button type="primary" @click="applyDateRange">Aplicar</el-button>
                    </h1>
                </div>
                <div class="w-4/6 px-5 py-1 h-full">
                  <div class="rounded-md bg-gray-100">
                      </div>
    
              </div>
    
            </div>
            <div class=" w-full bg-white rounded-lg px-2 py-2 flex  justify-start content-start h-4/6">
                <Tabla v-if="data_reporte != null" :columns="headings_vendedores" :rows="data_reporte" :filtros="['vendedor']"  :filtroApi="false" :buscador="false" />
                  
            </div>
        </div>
    
       
        <!-- Selector de fecha inicio y fecha fin -->
    
        <!-- Resto del contenido del componente -->
        <!-- <div v-if="data_reporte != null" :class="{'flex-row': !IsMov(), 'flex-row-reverse': IsMov()}" class="w-full h-12 bg-gray-100 flex flex-wrap content-center justify-between px-2">
            <div class="w-10/12" :class="{'justify-end': IsMov(), 'justify-center': !IsMov()}">
              <el-pagination v-model:currentPage="pagina" small background layout="prev, pager, next" :page-size="data_reporte['per_page']" :total="Number(data_reporte['total'])" @current-change="pageChangue" />
            </div>
          </div>-->
    
    </section>
    </template>
    
      
      
    <script>
    import {
        ref,
        onMounted,
        onBeforeUnmount
    } from 'vue';
    import {
        emitter
    } from '@/mitt.js';
    import {
        useUserStore
    } from '@/stores/user';
    import generales from '@/functions/generales';
    import reportes from '@/Services/reportes';
    import DynamicChart from '@/components/charts/DynamicChart.vue';
    import {
        filter
    } from 'lodash';
    import Tabla from "@/components/Tabla.vue";
    
    export default {
        components: {
            Tabla
        },
        mixins: [generales],
        setup() {
            const user = useUserStore();
            const key = ref(0);
            const buscar = ref('all');
            const pagina = ref(1);
            const fechaInicio = ref(getDefaultStartDate());
            const fechaFin = ref(getDefaultEndDate());
            const data_reporte = ref(null);
    const headings_vendedores=ref([
        {
                    key: 'name',
    
                    name: 'Vendedor ',
                    show: true,
                    class: 'text-green-500 font-extrabold w-[200px]'
                },
                {
                    key: 'citas',
                    tipo:'format_price',
                    name: 'total citas ',
                    show: true,
                    class: '!bg-blue-100 text-blue-500 font-extrabold w-[120px]'
                },
    
    {
                    key: 'monto_inicial',
                    tipo:'format_price',
                    name: 'total cotizacion ',
                    show: true,
                    class: '!bg-blue-100 text-blue-500 font-extrabold w-[120px]'
                },
      {
                    key: 'monto_final',
                    tipo:'format_price',
                    name: 'Total ventas ',
                    show: true,
                    class: '!bg-blue-100 text-blue-500 font-extrabold w-[120px]'
                },
                {
                    key: 'descuento',
                    tipo:'format_price',
                    name: 'Descuento ',
                    show: true,
                    class: '!bg-green-100 text-green-500 font-extrabold w-[120px]'
                },
                {
                    key: 'descuento_personalizado',
                    tipo:'format_price',
                    name: 'Descuento personalizado ',
                    show: true,
                    class: '!bg-red-100 text-red-500 font-extrabold w-[120px]'
                },
                
               
    ])
            const headings = ref([{
                    key: 'idventa',
                    name: '# pedido ',
                    show: true,
                    class: 'text-green-500 font-extrabold min-w-[100px]'
                },
                {
                    key: 'fecha_pedido',
                    name: 'Fecha pedido ',
                    show: true,
                    class: 'text-green-500 font-extrabold w-[200px]'
                },
    
                {
                    key: 'total',
                    tipo:'format_price',
                    name: 'Total',
                    show: true,
                    class: ' w-[200px] !bg-green-100 text-green-500 border-gray-500 border-1'
                },
                {
                    key: 'origen',
                    name: 'Origen',
                    show: true,
                    class: ' min-w-[250px]'
                },
                {
                    key: 'contacto',
                    name: 'Cliente',
                    show: true,
                    class: ' min-w-[250px]'
                },
                {
                    key: 'vendedor',
                    name: 'Vendedor',
                    show: true,
                    class: ' min-w-[200px]'
                },
                {
                    key: 'primer_abono',
                    tipo:'format_price',
                    name: 'Anticipo',
                    class: ' min-w-[200px] !bg-blue-100 text-blue-500 border-gray-500 border-1',
                    show: true
                },
                {
                    key: 'segundo_abono',
                    tipo:'format_price',
                    name: 'Segundo abono',
                    show: true,
                    class: ' min-w-[200px] !bg-blue-100 text-blue-500 border-gray-500 border-1'
                },
    
                {
                    key: 'ultimo_metodo_abono',
                    name: 'Ultimo metodo de pago',
                    show: true,
                    class: ' min-w-[200px]'
                },
    
                {
                    key: 'total_abonos',
                    tipo:'format_price',
                    name: 'Total abonos',
                    show: true,
                    class: ' min-w-[200px] !bg-blue-100 text-blue-500 border-gray-500 border-1'
                },
                {
                    key: 'restante_abono',
                    tipo:'format_price',
                    name: 'Resta por pagar',
                    show: true,
                    class: ' w-[200px] !bg-red-100 text-red-500 border-gray-500 border-1'
                },
    
            ])
    
            const pageChangue = (val) => {
                pagina.value = val;
                getData();
            }
    
            const applyDateRange = () => {
                getData();
            }
    
            const getData = async () => {
                emitter.emit('loading', true);
                let dat = {
                    //  page: pagina.value,
                    fechaInicio: formatFecha(fechaInicio.value), // Pasa la fecha de inicio
                    fechaFin: formatFecha(fechaFin.value), // Pasa la fecha de fin
                }
                await reportes.GET_CITAS_VENDEDORES(dat).then((response) => {
                    if (response != null && !response.hasOwnProperty('error')) {
                        data_reporte.value = response;
                        let tot=0;
                        let tot_desc=0;
                        let tot_abonos=0;
                        let tot_restante=0;
                        let ventas_totales=0;
                        
                        
                    } else {
                        // emitter.emit("alerta_show",response.msg);
                    }
                    emitter.emit('loading', false);
                });
            }
            const formatFecha = (fecha) => {
                const dateObj = new Date(fecha);
                return dateObj.toISOString();
                const formattedDate = `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
                return formattedDate;
            }
            // Función para obtener el primer día del mes actual
            function getDefaultStartDate() {
                const today = new Date();
                return new Date(today.getFullYear(), today.getMonth(), 1);
            }
    
            // Función para obtener el último día del mes actual
            function getDefaultEndDate() {
                const today = new Date();
                return new Date(today.getFullYear(), today.getMonth() + 1, 0);
            }
    
            onMounted(() => {
                getData();
            })
    
            onBeforeUnmount(() => {
                // Add any cleanup logic here.
            })
    
            return {
              headings_vendedores,
                getData,
                pageChangue,
                buscar,
                headings,
                key,
                data_reporte,
                fechaInicio,
                fechaFin,
                applyDateRange
            };
        },
    };
    </script>
      
      
    <style scoped>
    /* Your component-specific styles here */
    </style>
    