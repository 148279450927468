<template>
    <SectionConfigurator
    :type="'boton'"
      :initial-properties="sectionProperties"
      :initial-selected-properties="selectedProperties"
      @update-properties="updateProperties"
    />
  </template>
  
  <script>
  import SectionConfigurator from '@/components/SectionConfigurator.vue';
  
  export default {
    components: {
      SectionConfigurator,
    },
    data() {
      return {
        sectionProperties: {
          'Color de fondo': {
            'Azul': 'bg-blue-500',
            'Rojo': 'bg-red-500',
            // Agrega más opciones de color aquí
          },
          'Tamaño de borde': {
            'Sin borde': '',
            'Pequeño': 'border',
            'Mediano': 'border-2',
            'Grande': 'border-4',
          
            // Agrega más opciones de color aquí
          },
          'Espaciado de boton': {
            'Sin espaciado': 'padding-0',
            'Pequeño': 'p-2',
            'Mediano': 'p-4',
            'Grande': 'p-6',
          
            // Agrega más opciones de color aquí
          },
          'Color de borde': {
            'Borde Azul': 'border-blue-500',
            'Borde Rojo': 'border-red-500',
            'Borde verde': 'border-green-500',
            'Borde negro': 'border-black',
            'Borde blanco': 'border-white',
            // Agrega más opciones de color aquí
          },
          'Color de texto': {
            'Blanco': 'text-white',
            'Negro': 'text-black',
            // Agrega más opciones de color de texto aquí
          },
         
          'Radio de borde': {
            'Ninguno': 'rounded-none',
            'Medio': 'rounded-md',
            'Completo': 'rounded-full',
            // Agrega más opciones de radio de borde aquí
          },
          'Tamaño de texto': {
            'Pequeño': 'text-sm',
            'Base': 'text-base',
            'Grande': 'text-lg',
            // Agrega más opciones de tamaño de texto aquí
          },
          'Sombra': {
            'Ninguna': 'shadow-none',
            'Pequeña': 'shadow-sm',
            'Media': 'shadow-md',
            'Grande': 'shadow-lg',
            // Agrega más opciones de sombra aquí
          },
        },
        selectedProperties: {
          'Color de fondo': 'bg-blue-500',
          'Tamaño de borde': 'border',
          'Color de borde': 'border-blue-500',
          'Espaciado de boton': 'padding-0',
          'Color de hover': 'bg-blue-500',
          'Color de texto': 'text-white',
          
          'Radio de borde': 'rounded-md',
          'Tamaño de texto': 'text-base',
          'Sombra': 'shadow-none',
        },
      };
    },
    methods: {
    updateProperties(newProperties) {
      this.selectedProperties = newProperties;
    },
  },
  };
  </script>
  