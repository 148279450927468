

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
 // GET_CLIENTES CREAR_CLIENTES EDITAR_CLIENTES ELIMINAR_CLIENTES

 GET: async function( data){
      let url='/landing/'
      return initmixin.formatrespuesta('get', data,url,true)
    },
   
   


}