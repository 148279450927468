<template>
<div class="w-full h-full flex flex-wrap overflow-y-scroll pb-40" id="capture">

    <div class="bg-[white] rounded-b-md border-2 border-black w-full flex flex-wrap content-start ">
        <div class="w-full flex flex-wrap font-bold text-blue-500  fntrel  border-b-2 border-black">

            <div v-if="tipo_view=='cotizacion'" class="w-full  text-base  flex flex-wrap justify-between  content-center font-extrabold text-black bg-gray-200 text-left">
                <div class="w-6/12 bg-gray-300 px-2">
                    <strong class="text-black text-md">Cotización</strong>
                </div>
                <div class="font-extrabold text-md text-right w-3/12">
                    <strong class="text-black ">#</strong>{{cotizacion.id}}
                </div>
                <div class="font-extrabold text-md text-right w-2/12">
                    <strong class="text-black ">FECHA</strong>
                </div>
                <div class="font-extrabold text-md text-right w-2/12 px-2">
                    <strong class="text-black ">{{formatDateEN(cotizacion.created_at)}}</strong>
                </div>
                <div v-if="infositio.hasOwnProperty('active_tipo_moneda')" class="font-extrabold text-md text-right w-2/12 px-2">
                    <strong class="text-black ">TIPO MONEDA
                        <select v-model="valor_moneda">
                            <option v-for="(row,index) in monedas" v-bind:key="index" :value="row.id">{{row.id}}{{row.text}}</option>
                        </select></strong>
                </div>
            </div>
            <div v-if="tipo_view=='pedido'" class="w-full  text-base  flex flex-wrap justify-between  content-center font-extrabold text-black bg-gray-200 text-left">
                <div class="w-6/12 bg-gray-300 px-2">
                    <strong class="text-black text-md ">PEDIDO</strong>
                </div>
                <div class="font-extrabold text-md text-center w-2/12">
                    <strong class="text-black ">#</strong>{{cotizacion.idventa}}
                </div>
                <div class="font-extrabold text-md bg-gray-300 text-center w-2/12">
                    <strong class="text-black ">FECHA</strong>
                </div>
                <div class="font-extrabold text-md text-right w-2/12 px-2">
                    <strong class="text-black ">{{formatDateEN(cotizacion.venta)}}</strong>
                </div>
            </div>

        </div>
        <div v-if="infoneg" class="p-2 bg-gray-100 w-full">

            <div class="flex flex-wrap justify-center content-center">

                <div class=" w-3/12 sm:w-6/12 md:w-3/12 lg:w-3/12 xl:w-3/12">
                    <img class="object-cover bg-white  w-28   mb-0 sm:mb-4 xl:mb-4 xl:mb-4 lg:mb-4   " :src="infositio.logo_favicon" />

                </div>
                <div class=" w-9/12 text-base px-2 flex flex-wrap content-center">
                    <ul class="text-left w-full uppercase" v-if="dn">
                        <li class="select-all"><strong class="text-3xl">{{infositio.nombre}} </strong></li>
                        <li class="select-all"><strong>Dirección : </strong>{{infositio.direccion}}</li>
                        <li class="select-all" ><a class="text-black" :href="'tel:'+infositio.telefono"><strong>Teléfono : </strong><strong class=" text-blue-700">{{infositio.telefono}}</strong></a></li>
                        <li class="select-all"><a class="text-black" :href="'mailto:'+infositio.correo"><strong>Correo : </strong><strong class="underline text-blue-500">{{infositio.correo}}</strong></a></li>
                    </ul>
                </div>

            </div>
        </div>
        <div class="p-9 border-t-2 border-black bg-gray-200 w-full">
            <div class="flex w-full  flex-wrap">
                <div class="w-full flex flex-wrap text-base">

                    <ul class="text-left w-full uppercase" v-if="dc&&cotizacion.clientes!=null">
                        <li class="select-all"><strong>Nombre : </strong>{{cotizacion.clientes.name}} {{cotizacion.clientes.apellido_p}} {{cotizacion.clientes.apellido_m}}</li>
                        <li class="select-all"><strong>Dirección : </strong>{{cotizacion.clientes.calle}}, #{{cotizacion.clientes.numero}}, {{cotizacion.clientes.colonia}},{{cotizacion.clientes.municipio}},{{cotizacion.clientes.codigo_postal}}</li>
                        <li ><span :href="'#:'+cotizacion.clientes.phone"><strong>Teléfono : </strong><strong class="select-all text-blue-700">{{cotizacion.clientes.phone}}</strong></span></li>
                        <li ><span :href="'#:'+cotizacion.clientes.email"><strong>Correo : </strong><strong class="select-all underline text-blue-500">{{cotizacion.clientes.email}}</strong></span></li>
                    </ul>

                    <!--<div class="text-xs font-light text-slate-500 w-6/12 border">
                                        <p class="text-sm font-normal text-slate-700">
                                            Nombre cliente:
                                        </p>
                                        <p>{{cotizacion.clientes.name}}</p>

                                    </div>
                                    <div class="text-xs font-light text-slate-500  w-6/12 border">
                                        <p class="text-sm font-normal text-slate-700">Correo Cliente</p>
                                        <p>{{cotizacion.clientes.email}}</p>

                                    </div>
                                    <div class="text-xs font-light text-slate-500  w-6/12 border">
                                        <p class="text-sm font-normal text-slate-700">Telefono Cliente</p>
                                        <p>{{cotizacion.clientes.telefono}}</p>

                                    </div>
                                    <div class="text-xs font-light text-slate-500  w-6/12 border">
                                        <p class="text-sm font-normal text-slate-700">Direccion cliente</p>
                                        <p>{{cotizacion.clientes.telefono}}</p>

                                    </div>-->
                </div>
            </div>
        </div>

        <div class="w-full flex flex-wrap justify-start  border-t-2 border-black">
            <!--<div class="w-full flex flex-wrap font-bold text-blue-500 fntrel border-t-2 border-b-2 border-black">

                <div class="w-full px-2 text-xs  font-extrabold text-black bg-gray-100 text-left">Productos cotización</div>

            </div>-->
            <div class="w-full flex flex-wrap font-bold text-black fntrel  border-black">

                <div class="w-10/12  px-2  text-md">Productos</div>

                <div class="w-2/12 border-l px-2 text-center text-md"> <span v-if="ddt">Subtotal</span> </div>
            </div>

            <div class="w-full flex flex-wrap  border-t-2 border-black">
                <template v-for="(row,index) in cotizacion.productos_cotizaciones" v-bind:key="index">
                    <div class="flex flex-wrap justify-start content-start w-full" v-if="row.subproducto==0">
                        <div class="w-full flex flex-wrap border-black border-t-2 py-4  uppercase">
                            <div class="w-10/12 text-base px-2 ">

                                <p class="text-gray-600 w-full text-left select-all">
                                    <strong class="w-full text-left font-extrabold text-gray-700 uppercase select-all" v-if="row.hasOwnProperty('productos')&&row.productos!=null&&row.productos.hasOwnProperty('nombre')"> <span class="text-black">{{Number(row.cantidad).toFixed(0)}}</span> {{row.cantidad>1?row.productos.nombre_plural:row.productos.nombre}} {{' '}} </strong>
                                    <template v-for="(row_par,index_par) in row.parametros" v-bind:key="index_par">
                                        <span v-if="getTipoFormato(row_par)&&row_par.tipo=='numerico'&&row_par.valor!='NO'&&row_par.valor!='no'&&row_par.valor!=''&&row_par.valor!=0&&row_par.valor!=null">{{row_par.nombre}} {{' '}} {{(row_par.valor)}} {{' '}} </span>
                                        <span v-if="getTipoFormato(row_par)&&row_par.tipo=='calc_formula'">{{row_par.nombre}} {{' '}} {{Number(calcularParametro(row,row_par.formula_mp)).toFixed(3)}} {{' '}} </span>
                                        <span v-if="getTipoFormato(row_par)&&row_par.tipo=='value_formula'">{{row_par.nombre}} {{' '}} {{Number(calcularParametro(row,row_par.formula_mp)).toFixed(1)}} {{' '}} </span>
                                        <span v-if="getTipoFormato(row_par)&&row_par.tipo=='texto'&&row_par.valor!='NO'&&row_par.valor!='no'&&row_par.valor!=''&&row_par.valor!=0">{{row_par.nombre}} {{' '}} {{row_par.valor}} {{' '}} </span>
                                        <span v-if="getTipoFormato(row_par)&&row_par.tipo=='texto_extendido'&&row_par.valor!='NO'&&row_par.valor!='no'&&row_par.valor!=''&&row_par.valor!=0&&row_par.valor!=null">{{row_par.nombre}} {{' '}} {{row_par.valor}} {{' '}} </span>
                                        <span v-if="getTipoFormato(row_par)&&row_par.tipo=='catalogo'&&row_par.valor!='NO'&&row_par.valor!=NULL&&row_par.valor!=0&&row_par.producto_lista.nombre!='0'&&row_par.producto_lista.nombre!='NO'">{{row_par.nombre}} ( {{row_par.valor==0?'N/A':(row_par.producto_lista.tag!='null'&&row_par.producto_lista.tag!=null?row_par.producto_lista.tag:'')+' '+row_par.producto_lista.nombre}} )</span>

                                        <!--<span v-if="row_par.visible==1&&row_par.tipo=='catalogo'&&row_par.producto_lista!=NULL&&row_par.producto_lista.nombre!='NO'&&row_par.producto_lista.nombre!=0&&row_par.producto_lista.nombre!='0'">{{row_par.nombre}} ( {{row_par.valor==0?'N/A':(row_par.producto_lista.tag!='null'&&row_par.producto_lista.tag!=null?row_par.producto_lista.tag:'')+' '+row_par.producto_lista.nombre}} )</span>
                    -->
                                    </template>

                                </p>

                            </div>

                            <div class="w-2/12  border-l text-base  text-black font-extrabold justify-center content-center flex flex-wrap">
                               <!--- <span v-if="ddt">${{formatPrice(Number(Number(row.precio*row.cantidad)*valor_moneda).toFixed(2))}}</span>-->
                               <span v-if="ddt">${{formatPrice(Number(Number(row.total)*valor_moneda).toFixed(2))}}</span>
                            </div>
                        </div>
                    </div>

                </template>
                <div class="w-full flex flex-wrap border-t-2 border-black" v-if="dtotal">
                    <div class="w-6/12 text-xs px-2 ">

                    </div>
                    <div class="w-2/12 border-l border-r text-gray-600 font-extrabold text-md border-black  justify-center content-center flex flex-wrap">
                        Subtotal
                    </div>
                    <div class="w-2/12 border-l border-r text-gray-600 font-extrabold text-md border-black  justify-center content-center flex flex-wrap">

                    </div>
                    <div class="w-2/12  text-md  text-blue-500 font-extrabold justify-center content-center flex flex-wrap">
                        ${{formatPrice(Number(Number(cotizacion.total)*this.valor_moneda).toFixed(2))}}
                    </div>
                </div>
                <div class="w-full flex flex-wrap border-t-2 border-black" v-if="dtotal&&Number(Number(cotizacion.descuento)*this.valor_moneda).toFixed(2)>0">
                    <div class="w-6/12 text-xs px-2 ">

                    </div>
                    <div class="w-2/12 border-l border-r text-gray-600 font-extrabold text-md border-black  justify-center content-center flex flex-wrap">
                        Descuento 
                    </div>
                    <div class="w-2/12 border-l border-r text-gray-600 font-extrabold text-md border-black  justify-center content-center flex flex-wrap">

                    </div>
                    <div class="w-2/12  text-md  text-green-500 font-extrabold justify-center content-center flex flex-wrap">
                        ${{formatPrice(Number(Number(cotizacion.descuento)*this.valor_moneda).toFixed(2))}}
                    </div>
                </div>
                <div class="w-full flex flex-wrap border-t-2 border-black" v-if="dtotal&&Number(Number(cotizacion.descuento_personalizado)*this.valor_moneda).toFixed(2)>0">
                    <div class="w-6/12 text-xs px-2 ">

                    </div>
                    <div class="w-2/12 border-l border-r text-gray-600 font-extrabold text-md border-black  justify-center content-center flex flex-wrap">
                        Descuento {{ cotizacion.concepto_descuento_personalizado }}
                    </div>
                    <div class="w-2/12 border-l border-r text-gray-600 font-extrabold text-md border-black  justify-center content-center flex flex-wrap">

                    </div>
                    <div class="w-2/12  text-md  text-green-500 font-extrabold justify-center content-center flex flex-wrap">
                        ${{formatPrice(Number(Number(cotizacion.descuento_personalizado)*this.valor_moneda).toFixed(2))}}
                    </div>
                </div>

                <div v-if="(dtotal&&Number(Number(cotizacion.descuento_personalizado)*this.valor_moneda).toFixed(2)>0)||(dtotal&&Number(Number(cotizacion.descuento)*this.valor_moneda).toFixed(2)>0)"  class="w-full flex flex-wrap border-t-2 border-black" >
                    <div class="w-6/12 text-xs px-2 ">

                    </div>
                    <div  class="w-2/12 border-l border-r text-gray-600 font-extrabold text-md border-black  justify-center content-center flex flex-wrap">
                        Total con descuento
                    </div>
                    <div class="w-2/12 border-l border-r text-gray-600 font-extrabold text-md border-black  justify-center content-center flex flex-wrap">

                    </div>
                    <div class="w-2/12  text-md  text-blue-500 font-extrabold justify-center content-center flex flex-wrap">
                        ${{formatPrice(Number(Number(cotizacion.total)*this.valor_moneda).toFixed(2)-Number(Number(cotizacion.descuento_personalizado)*this.valor_moneda).toFixed(2)-Number(Number(cotizacion.descuento)*this.valor_moneda).toFixed(2))}}
                    </div>
                </div>
                <div class="w-full flex flex-wrap" v-if="dtotal">
                    <div v-for="(row_abono,index_abono) in cotizacion.abonos" v-bind:key="index_abono" class="w-full flex flex-wrap  border-t-2 border-black ">
                        <div class="w-6/12 text-xs px-2 ">

                        </div>
                        <div class="w-2/12 border-l border-r text-gray-600 font-extrabold text-md border-black  justify-center content-center flex flex-wrap">
                            Abonado
                        </div>

                        <div class="w-2/12  text-base border-l border-r text-green-500 font-extrabold  border-black justify-center content-center flex flex-wrap">
                            <span class="text-blue-500 text-base">{{formatDateLT(row_abono.created_at)}}</span>
                            {{row_abono.tipo_pago}}
                        </div>
                        <div class="w-2/12  text-md  text-green-500 font-extrabold justify-center content-center flex flex-wrap">

                            ${{formatPrice((Number(row_abono.capital)).toFixed(0))}}
                        </div>

                    </div>
                </div>
                <div class="w-full flex flex-wrap border-t-2 border-black" v-if="dtotal">
                    <div class="w-6/12 text-xs px-2 ">

                    </div>
                    <div class="w-2/12 border-l border-r text-gray-600 font-extrabold text-md border-black  justify-center content-center flex flex-wrap">
                        Resto
                    </div>
                    <div class="w-2/12 border-l border-r text-gray-600 font-extrabold text-md border-black  justify-center content-center flex flex-wrap">

                    </div>
                    <div class="w-2/12  text-md  text-red-500 font-extrabold justify-center content-center flex flex-wrap">
                        ${{formatPrice(Number(CalcularDeuda()).toFixed(2))}}
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div class="w-full flex flex-wrap justify-end">
        <div class="w-28 mt-3">
            <QRCodeVue3 v-if="dqr" :value="infositio.url+'?ci='+cotizacion.id" :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }" :dotsOptions="{ type: 'square', color: '#000000' }" :backgroundOptions="{  color: '#ffffff' }" :dotsOptionsHelper="{
                                colorType: { single: true, gradient: false },
                                gradient: {
                                  linear: true,
                                  radial: false,
                                  color1: '#6a1a4c',
                                  color2: '#6a1a4c',
                                  rotation: 0
                                }
                              }" :cornersSquareOptions=" { type: '', color: '#000000' }" :cornersSquareOptionsHelper=" {
                                colorType: { single: true, gradient: false },
                                gradient: {
                                  linear: true,
                                  radial: false,
                                  color1: '#000000',
                                  color2: '#000000',
                                  rotation: 0
                                }
                              }" :cornersDotOptions="{ type: '', color: '#000000' }" :cornersDotOptionsHelper=" {
                                colorType: { single: true, gradient: false },
                                gradient: {
                                  linear: true,
                                  radial: false,
                                  color1: '#000000',
                                  color2: '#000000',
                                  rotation: 0
                                }
                              }" :backgroundOptionsHelper="{
                                colorType: { single: true, gradient: false },
                                gradient: {
                                  linear: true,
                                  radial: false,
                                  color1: '#ffffff',
                                  color2: '#ffffff',
                                  rotation: 0
                                }
                              }" />
        </div>
    </div>

    <div class="mt-18 py-9">
        <div class="border-t  border-slate-200">
            <div class="text-base text-left font-light text-gray-500" v-html="getObservaciones()">

            </div>
        </div>
        <div class="border-t  border-slate-200">
            <div class="text-base text-left font-light text-gray-500" v-html="(cotizacion.datos_cotizacion==''||cotizacion.datos_cotizacion==null)?infositio.mensaje_cotizacion:cotizacion.datos_cotizacion">

            </div>
        </div>
        <div class="border-t  border-slate-200">
            <div class="text-base text-left font-extrabold text-gray-500" v-html="infositio.leyenda_cotizacion">

            </div>
        </div>
    </div>

</div>
</template>

<script>
//@ts-ignore
import generales from '@/functions/generales'
import cotizaciones_f from '@/functions/cotizaciones'
import QRCodeVue3 from "qrcode-vue3";
//import palabras from "@/mixins/palabras";
import {
    useInfoStore
} from '../stores/info'
export default {
    name: "Home",
    components: {
        QRCodeVue3
    },
    mixins: [generales, cotizaciones_f],
    props: {
        tipo_formato: {
            type: String,
            default: 'con_medidas'
        },
        tipo_view: {
            type: String,
            default: 'cotizacion'
        },
        infoneg: {
            type: Boolean,
            default: true
        },
        cotizacion: {
            type: Array,
        },
        dn: {
            type: Boolean,
            default: true
        },
        dc: {
            type: Boolean,
            default: true
        },
        dtotal: {
            type: Boolean,
            default: true
        },
        ddt: {
            type: Boolean,
            default: true
        },
        dqr: {
            type: Boolean,
            default: true
        },

    },
    data() {
        const info = useInfoStore()
        return {
            infositio: info.getAll(),
            tipo_moneda: 'USD',
            monedas: [],
            valor_moneda: 1,

        }
    },

    mounted() {
        this.monedas = [],
            this.monedas.push({
                id: 1,
                text: 'USD'
            })
        this.monedas.push({
            id: this.cotizacion.valor_peso,
            text: 'MXN'
        })

        console.log(this.infositio)
        //console.log(this.cotizacion, '----pc---')

    },

    methods: {
        getObservaciones() {
            return (this.cotizacion.observaciones == '' || this.cotizacion.observaciones == null) ? '<h1>INCLUYE INSTALACIÓN</h1>' : '<div>' + this.cotizacion.observaciones + '</div>';
        },
        getTipoFormato(row) {
            return ((this.tipo_formato == 'con_medidas' && row.visible == 1) || (this.tipo_formato == 'sin_medidas' && row.visible_cotizacion_t2 == 1))
        },
        CalcularDeuda() {
            if (this.cotizacion.id != 0) {
                let total = 0;
                this.cotizacion.abonos.forEach(element => {
                    total = Number(total) + Number(element.capital)
                });
                return Number(Number(this.cotizacion.total) - Number(this.cotizacion.descuento) - Number(this.cotizacion.descuento_personalizado) - Number(total)) * this.valor_moneda
            } else {
                return 0;
            }

        },

    }
}
</script>

<style>
td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.vgt-wrap {
    height: 100%;
}

.vgt-inner-wrap {
    height: 100%;
}
</style>
