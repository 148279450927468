<template>
<div class="w-full h-full flex flex-wrap overflow-y-scroll content-start">
    <el-dialog v-model="selectOption" title="" width="30%">
        <h1 class="text-xl mb-4 font-bold text-slate-500">¿Que desea hacer?</h1>
        <button @click=" this.selectOption=false;eliminar(selElement.id)" class="bg-red-500 px-4 py-2 rounded-md text-xs text-white">Eliminar</button>
        <button @click=" this.selectOption=false;estatus(selElement.id,'En transito')" v-if="selElement.status=='CREADO'" class="bg-blue-500 px-7 py-2 ml-2 rounded-md text-xs text-white font-semibold">En transito</button>
        <button @click=" this.selectOption=false;estatus(selElement.id,'En almacen')"  v-if="selElement.status=='En transito'" class="bg-green-500 px-7 py-2 ml-2 my-1 text-xs rounded-md  text-white font-semibold">En almacen</button>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="selectOption = false">Cancel</el-button>

            </span>
        </template>
    </el-dialog>

    <template v-for="(row,index) in cotizacion.productos_ordenes" v-bind:key="index">
        <div @click="selectOption=true;selElement=row" tabindex="0" class="focus:outline-none mx-2 w-full xl:mb-0 mb-8">

            <div class="bg-gray-100 my-1">
                <div class="flex items-center justify-between px-4 pt-4">
                    <div class="#text-xs text-left">
                        {{formatDateTime(row.created_at)}}
                    </div>
                    <div :class="'bg-'+getColor(row.status)+'-400 py-1.5 px-6 rounded-full'">
                        <p tabindex="0" :class="'focus:outline-none text-xs text-'+getColor(row.status)+'-700'"> {{row.status}}</p>
                    </div>

                </div>
                <div class="p-4">
                    <div class="flex items-center">
                        <h2 tabindex="0" class="focus:outline-none text-sm text-left font-semibold">

                            {{row.productos.nombre}}/{{row.productos.producto.nombre}} - x{{Number(row.cantidad).toFixed(0)}}</h2>

                    </div>
                    <p tabindex="0" class="focus:outline-none text-xs text-gray-600 mt-2"></p>
                    <div class="flex mt-4">
                        <div>
                            <p tabindex="0" class="focus:outline-none text-xs text-gray-600 px-2 bg-gray-200  uppercase py-1"> #Factura :{{row.factura}}</p>
                        </div>
                        <div  class="pl-2">
                            <p tabindex="0" class="focus:outline-none text-xs text-gray-600 px-2 bg-gray-200  uppercase py-1"> {{row.provedores.nombre}}</p>
                        </div>
                        <div class="pl-2" >
                            <p tabindex="0" class="focus:outline-none text-xs text-gray-600 px-2 bg-yellow-200 uppercase py-1" >Color : {{(row.color==''||row.color==null?'N/A':row.color)}}</p>
                        </div>
                        <div class="pl-2">
                            <p tabindex="0" class="focus:outline-none text-xs text-gray-600 px-2 bg-gray-200 uppercase py-1">{{row.unidades.nombre}}</p>
                        </div>

                    </div>
                    <div class="flex items-center justify-between py-4">
                        <h2 tabindex="0" class="focus:outline-none text-indigo-700 text-xs font-semibold">Usuario creador :{{row.usuario.name}}</h2>

                        <div class="bg-green-200 py-1.5 px-6 rounded-full">
                            <p tabindex="0" class="focus:outline-none text-xs text-green-700"> ${{formatPrice(row.total)}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>

</div>
</template>

    
    
<script >
//@ts-ignore
import generales from '@/functions/generales'
//@ts-ignore
import {
    emitter
} from "@/mitt.js";
//import palabras from "@/mixins/palabras";
import {
    useInfoStore
} from '../stores/info'
export default {
    name: "Home",
    components: {

    },
    mixins: [generales],
    props: {
        infoneg: {
            type: Boolean,
            default: true
        },
        cotizacion: {
            type: Array,
        },
        instalacion: {
            type: Boolean,
            default: false
        },

    },
    setup(props,{ emit }) {
      //let emit  = defineEmits(['seleccionado'])
       
        const eliminar = (id) => {
           console.log('id')
           //search.value = ''
          //  emit("eliminar", id)
            emitter.emit("eliminar", id);
            
       }
       const estatus = (id,status) => {
           
           //search.value = ''
           emitter.emit("estatus", {id,status});
          
       }
      
        return {
           emit,
            eliminar,
            estatus,
           
        }
    },
    data() {
        const info = useInfoStore()
        return {
            infositio: info.getAll(),
            showimg: false,
            imgsel: '',
            selectOption: false,
            selElement: null

        }
    },

    mounted() {

        console.log(this.infositio)
        //console.log(this.cotizacion, '----pc---')

    },

    methods: {
        getColor(status) {
            //cita.status
            switch (String(status).toUpperCase()) {
                case 'CREADO':
                    return 'gray'
                    break;
                case 'EN TRANSITO':
                    return 'blue'
                    break;
                case 'EN ALMACEN':
                    return 'green'
                    break;
              

                default:
                    break;
            }
        },
        parseTimeN(date) {
            const event = new Date(date);

            return event.toLocaleTimeString("es-MX", {
                hour12: true,
                hour: "2-digit",
                minute: "2-digit",
            });
        },

        getTime(date) {
            let tiempo = date.split(' ')
            return tiempo[1];
        },
        formatTime(time) {
            let tiempo = time.split(':')
            return tiempo[0] + ":" + tiempo[1];
        },

    }
}
</script>
    
    
<style>
td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.vgt-wrap {
    height: 100%;
}

.vgt-inner-wrap {
    height: 100%;
}
</style>
