// stores/counter.js
import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => {
    return { 
        usuario: {token:'token'},
        methods:[],
        token:'',
    
    }
  },
  persist: true,
  // could also be defined as
  // state: () => ({ count: 0 })
  getters: {
    getAll: (state) => {
      return () => state.usuario
    },
    getToken: (state) => {
      return () => state.token
    },
    getMetodos: (state) => {
      return () => state.methods
    },
  },
  actions: {
    set(data) {
      this.token=data.token;
      this.usuario=data.user;
      this.methods=data.methods;
    },
    limpiar(data) {
      this.token='';
      this.usuario={token:'token'};
      this.methods=[]
    },
    get() {
     return this.usuario
    },
  },
})