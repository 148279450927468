<template>
    <div class="flex bg-white shadow-md rounded-lg p-6">
      <div class="w-1/2 space-y-4">
        <div v-for="(options, property) in properties" :key="property" class="flex flex-col">
          <label :for="property" class="font-semibold text-gray-600">{{ property }}: </label>
          <select v-model="selectedProperties[property]" :id="property" class="p-2 rounded-md shadow-sm border border-gray-300">
            <option v-for="(value, name) in options" :value="value" :key="name">{{ name }}</option>
          </select>
        </div>
        <button @click="saveProperties" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Guardar
        </button>
      </div>
  
      <div class="w-1/2 ml-6">

        <template v-if="type">
            <Boton :styles="selectedProperties" />
        </template>
      
      </div>
    </div>
  </template>
  
  <script>
  import Boton from '@/components/generales/boton.vue';
  
  export default {
    name: 'SectionConfigurator',
    components: {
        Boton,
    },
    props: {
      initialProperties: {
        type: Object,
        required: true,
      },
      initialSelectedProperties: {
        type: Object,
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        selectedProperties: this.initialSelectedProperties,
        properties: this.initialProperties,
      };
    },
    methods: {
      saveProperties() {
        this.$emit('update-properties', this.selectedProperties);
      },
    },
  };
  </script>
  