import axios from "axios";

import { useConfigStore } from "../stores/config";
import { useUserStore } from "../stores/user";


let host = (window.location.host).replace(':8080', '');
host = (host).replace(':8081', '');
let domain=host.split('.')[0]
let url='';
switch (domain) {
  case 'cotizadorapp':
    url="https://api-cotizaciones-demo.herokuapp.com/api";
    break;
  case 'persianasenmonterrey2':
    url="https://api-cotizador-pem-60a43aa877d5.herokuapp.com/api";
    break;
    case 'marbel-pop':
    url="https://api-popcing-marbel.herokuapp.com/api";
    break;
    case 'persianasenmonterrey':
    url="https://api-persianas-cot-e8c9efc576ca.herokuapp.com/api";
    break;
    case 'papeltapiz':
      url="https://api-cotizador-papel-1598855ac93c.herokuapp.com/api";
      break;

  default:
  url='http://marbelapi.lndo.site/api';
  //url='http://192.168.1.6:800/api'
  //url="https://api-popcing-marbel.herokuapp.com/api";
    break;
}
const serviceInstance = axios.create({
  baseURL: url,
  //https://api-cotizaciones-demo.herokuapp.com/api
  //baseURL: "http://192.168.1.5:8000/api",
 //baseURL: "https://api-popcing-marbel.herokuapp.com/api",
  Headers: { Accept: "application/json" },
  timeout: 1000000,
});
var _axios_config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "",
  },
};
const validation = (data) => {
  let text = "";
  for (var [key, value] of Object.entries(data.errors)) {
    text +=
      '<div class="w-full flex flex-wrap text-center justify-center content-center"><span>' +
      value +
      "</span> </div><br>"; // "a 5", "b 7", "c 9"
  }
  return text;
  //emitter.emit("alerta_show",text);
};
export default {
  data() {
    const config = useConfigStore();

    return { config, user };
  },

  formatrespuesta: (method, data, url, auth, file) => {
    let result = null;
    let config = _axios_config;
    console.log(auth);
    if (auth) {
      const user = useUserStore();
      let tok = user.getToken();
      //let us=user.getAll();
      config.headers.Authorization = "Bearer " + tok;
    }
    switch (method) {
      case "post":
        if (file) {
          config.headers["Content-Type"] = "multipart/form-data";
        } else {
          config.headers["Content-Type"] = "application/json";
        }

        result = serviceInstance
          .post(url, data, config)
          .then((response) => {
            return response.data;
          })
          .catch(function(error) {
            if (error.response) {
              //console.log(error.response.status);

              switch (error.response.status) {
                case 422:
                  return { error: validation(error.response.data) };

                  break;

                case 500:
                  return { error: error.response.data.message };
                  break;
              }
            } else {
              return  { error: error };
            }
          });

        return result;

        break;
      case "get":
        result = serviceInstance
          .get(url, config)
          .then((response) => {
            return response.data;
          })
          .catch(function(error) {
            if (error.response) {
              //console.log(error.response.status);

              switch (error.response.status) {
                case 422:
                  return { error: validation(error.response.data) };

                  break;

                case 500:
                  if (error.response.hasOwnProperty("data")) {
                    if (error.response.hasOwnProperty("message")) {
                      return { error: error.response.data };
                    } else {
                      return { error: error.response.data.message };
                    }
                  } else {
                    return { error: error.response.data.message };
                  }
                  break;
              }
            } else {
              return  { error: error };
            }
          });

        return result;

        break;
      case "put":
        if (file) {
          config.headers["Content-Type"] = "multipart/form-data";
        } else {
          config.headers["Content-Type"] = "application/json";
        }
        result = serviceInstance
          .put(url, data, config)
          .then((response) => {
            return response.data;
          })
          .catch(function(error) {
            if (error.response) {
              //console.log(error.response.status);

              switch (error.response.status) {
                case 422:
                  return { error: validation(error.response.data) };

                  break;

                case 500:
                  if (error.response.hasOwnProperty("data")) {
                    if (error.response.hasOwnProperty("message")) {
                      return { error: error.response.data };
                    } else {
                      return { error: error.response.data.message };
                    }
                  } else {
                    return { error: error.response.data.message };
                  }

                  break;
              }
            } else {
              return  { error: error };
            }
          });

        return result;

        break;
      case "delete":
        result = serviceInstance
          .delete(url, config)
          .then((response) => {
            return response.data;
          })
          .catch(function(error) {
            if (error.response) {
              //console.log(error.response.status);

              switch (error.response.status) {
                case 422:
                  return { error: validation(error.response.data) };

                  break;

                case 500:
                  if (error.response.hasOwnProperty("data")) {
                    if (error.response.hasOwnProperty("message")) {
                      return { error: error.response.data };
                    } else {
                      return { error: error.response.data.message };
                    }
                  } else {
                    return { error: error.response.data.message };
                  }
                  break;
              }
            } else {
              return  { error: error };
            }
          });

        return result;

        break;

      default:
        break;
    }

    return {
      formatrespuesta,
      validation,
      config,
      user,
      _axios_config: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
      },
    };
  },
};
