import { ref } from 'vue';
import moment from 'moment-timezone';
import dayjs from 'dayjs';
import 'dayjs/plugin/relativeTime';
import { es } from 'dayjs/locale/es';

dayjs.extend(require('dayjs/plugin/relativeTime'));

export function useMixin() {
  function getMesAno(fecha) {
    const meses = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    const mes = meses[fecha.getMonth()];
    const año = fecha.getFullYear();

    return `${mes} de ${año}`;
  }

  function verfiNull(valor) {
    if (valor == null || valor == "" || valor == "null" || valor == 0) {
      return "";
    } else {
      return valor;
    }
  }

  function IsMov() {
    return true;
    // Puedes eliminar la segunda línea: return this.$isMobile();
  }

  function timeAgo(date) {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    options.timeZone = "UTC";
    options.timeZoneName = "short";
    let fecha = formatFullDateTime(date);
    let f = new Date(date);
    let ff = f.toLocaleString("es-MX", options);
    return ff;
  }

  function exportCSV(id) {
    let csv = [];
    var container = document.querySelector("#" + id);
    const rows = container.querySelectorAll("tr");
    for (const row of rows.values()) {
      const cells = row.querySelectorAll("td, th");
      const rowText = Array.from(cells).map((cell) => cell.innerText);
      csv.push(rowText.join(","));
    }
    const csvFile = new Blob([csv.join("\n")], {
      type: "text/csv;charset=utf-8;",
    });

    var temp_link = document.createElement("a");
    temp_link.download = id + ".csv";
    var url = window.URL.createObjectURL(csvFile);
    temp_link.href = url;
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);
    temp_link.click();
    document.body.removeChild(temp_link);
  }

  function formatNumberAnd0(event) {
    var inputChar = String.fromCharCode(event.keyCode);
    var code = event.keyCode;
    var allowedKeys = [8];

    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }
    event.target.value = event.target.value.replace(
      /[^\d\/]|^[\/]*$/g,
      "" // Para permitir solo dígitos y `/`
    );
  }

  function formatNumber(event) {
    var inputChar = String.fromCharCode(event.keyCode);
    var code = event.keyCode;
    var allowedKeys = [8];

    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }
    event.target.value = event.target.value.replace(
      /[^\d\/]|^[\/]*$/g,
      "" // Para permitir solo dígitos y `/`
    );
  }

  function CapitalizeTo(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function validarNumero(valor) {
    if (valor === null || valor === undefined || valor === '') {
      return 0;
    }
    if (isNaN(valor)) {
      return 0;
    }
    return Number(valor);
  }

  function validation(data) {
    let text = "";
    for (var [key, value] of Object.entries(data.errors)) {
      text +=
        '<div class="w-full flex flex-wrap text-center justify-center content-center"><span>' +
        value +
        "</span> </div><br>"; // "a 5", "b 7", "c 9"
    }
    return text;
    // emitter.emit("alerta_show", text);
  }
  function getFirstDayOfMonth() {
    var date = new Date();
    var d = new Date(date.getFullYear(), date.getMonth(), 1);
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function formatDateEN(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function getMes(date) {
    return moment(date).format("MM");
  }

  function formatTimeName(date) {
    return moment(date)
      .tz("HH:mm:ss", "America/Monterrey")
      .format("LT");
  }

  function formatFecha(fecha) {
    return format(new Date(fecha), 'dd/MM/yyyy');
  }
  formatTimeLT,
  formatFecha,
  formatTimeName,
  getMes,
  formatDateEN,
  getFirstDayOfMonth

  function formatTimeLT(date) {
    return moment(date)
      .tz("HH:mm:ss", "America/Monterrey")
      .format("L");
  }

  function formatDateLT(date) {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    options.timeZone = "UTC";
    options.timeZoneName = "short";
    let fecha = formatFullDateTime(date);
    let f = new Date(date);
    let ff = f.toLocaleString("es-MX", options);

    return String(ff).replace(", UTC", "");
  }

  function getAno(date) {
    return moment(date).format("YYYY");
  }

  function formatDateTime(date) {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }

  function formatDateTimeLT(date) {
    return moment(date).format("YYYY-MM-DD HH:mm");
  }

  function getLastDayOfMonth() {
    var date = new Date();
    var d = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function getfisrtDay() {
    var myCurrentDate = new Date();
    var myPastDate = new Date(myCurrentDate);
    myPastDate.setDate(myPastDate.getDate() - 7);
    return myPastDate;
  }

  function formatPrice(value) {
    value = value.toString().replace(".00", "");
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  
  function setMasOneDay(date_d) {
    var myPastDate = new Date(date_d);
    myPastDate.setDate(myPastDate.getDate() + 1);
    return myPastDate;
  }

  function formatFullDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function formatFullDateTime(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return (
      [year, month, day].join("-") +
      " " +
      d.getUTCHours() +
      ":" +
      d.getUTCMinutes() +
      ":" +
      d.getUTCSeconds()
    );
  }

  function colorProduccion(status) {
    switch (status) {
      case "EN PRODUCCION":
        return "bg-yellow-600 text-black font-extrabold";
      case "TERMINADO":
        return "bg-green-500 text-black font-extrabold";
      default:
        return "bg-red-500 text-black font-extrabold";
    }
  }

  function getStatusInstalacion(row) {
    let count = 0;
    row.citas.forEach((element) => {
      if (element.tipo == "instalacion") {
        count++;
      }
    });
    if (count > 0) {
      return "EN PROGRESO";
    } else {
      return "SIN PROGRAMAR";
    }
  }

  function colorInstalacion(row) {
    row = row._cell.row.data;
    let col = "";
    switch (row.status_instalacion) {
      case "INCOMPLETO":
        col = "bg-orange-500 text-black font-extrabold";
        break;
      case "INSTALADO":
        col = "bg-green-500 text-black font-extrabold";
        break;
      case "SIN PROGRAMAR":
        col = "bg-red-500 text-black font-extrabold";
        break;
      case "EN PROGRESO":
        col = "bg-yellow-600 text-black font-extrabold";
        break;
      default:
        col = "bg-red-500 text-black font-extrabold";
        break;
    }
    return `<span class="${col} text-black text-xs font-extrabold px-2 py-1 rounded-full"> ${
      row.status_instalacion == null || row.status_instalacion == ""
        ? getStatusInstalacion(row)
        : row.status_instalacion
    }  </span>`;
  }

  function colorVerificar(row) {
    row = row._cell.row.data;
    let col = "";
    switch (row.status_verificar) {
      case "PENDIENTE DE VERIFICAR":
        col = "bg-yellow-500 text-black font-extrabold";
        break;
      case "RECHAZADA":
        col = "bg-red-500 text-black font-extrabold";
        break;
      case "APROBADA":
        col = "bg-green-500 text-black font-extrabold";
        break;
      default:
        col = "bg-blue-500 text-black font-extrabold";
        break;
    }

    return `<span class="${col} text-black text-xs font-extrabold px-2 py-1 rounded-full"> ${
      row.status_verificar == null || row.status_verificar == ""
        ? "SIN VERIFICAR"
        : row.status_verificar
    }  </span>`;
  }

  function impar(numero) {
    if (numero % 2 == 0) {
      return "gray-400 bg-opacity-10";
    } else {
      return "white";
    }
  }

  function formatDat(row) {
    row = row._cell.row.data;
    var options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let dat = new Date(row.created_at);
    return dat.toLocaleDateString("es-ES", options);
  }

  function getCliente(row) {
    row = row._cell.row.data;
    return row.clientes.name;
  }

  function getUsuario(row) {
    row = row._cell.row.data;
    console.log(row, "--");
    return row.usuario.name;
  }

  function getCompras(row) {
    row = row._cell.row.data;
    return `<span class="bg-blue-300 text-black text-xs font-extrabold px-2 py-1 rounded-full"> ${row.citas.length} -  Citas </span>`;
  }

  function CalcExistencias(row) {
    row = row._cell.row.data;
    let total = 0;
    let total_vendidos = 0;
    row.existencias.forEach((element) => {
      total = Number(total) + element.cantidad;
    });
    row.detalle_ventas.forEach((element) => {
      total_vendidos = Number(total_vendidos) + element.cantidad;
    });
    return total - total_vendidos;
  }

  function verificarTipo(tipo) {
    switch (tipo) {
      case "distancia_permitir":
        return "Permite estas Cordenadas";
      case "distancia_bloquear":
        return "Bloquea estas Cordenadas";
      case "bloqueo_hora":
        return "Bloquea por horas";
    }
  }

  // Otras funciones y datos del mixin

  return {
    formatDateLT,
  getAno,
  formatDateTime,
  formatDateTimeLT,
  setMasOneDay,
  formatPrice,
  getfisrtDay,
  getLastDayOfMonth,
    formatFullDate,
    getfisrtDay,
    colorProduccion,
    colorInstalacion,
    colorVerificar,
    impar,
    formatDat,
    getCliente,
    getUsuario,
    getCompras,
    CalcExistencias,
    verificarTipo,
    getMesAno,
    verfiNull,
    IsMov,
    timeAgo,
    exportCSV,
    formatNumberAnd0,
    formatNumber,
    CapitalizeTo,
    validarNumero,
    validation,
    // Otras funciones y datos aquí
  };
}
