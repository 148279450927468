<template>
   <section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-center content-start ">
        <div
          x-show="selectedRows.length"
          class="bg-teal-200 fixed top-0 left-0 right-0 z-40 w-full shadow"
        />
    
        <Tabla
          :columns="headings"
          :rows="roles_data"
          :filtros="['nombre','telefono','correo','rfc']"
        />
    
          
      
    
      <div v-if="showPreview" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center " style="z-index:9999">
        <div class="bg-white p-6 rounded shadow-md w-5/6">
            <h2 class="text-xl font-semibold mb-4">Previsualización de Datos</h2>

            <div class="w-full flex overflow-y-scroll overflow-x-scroll max-h-80">

                <h2>{{ role.name }} - Editar Permisos</h2>

                <div v-for="route in routes" :key="route.id">
                    <h3>{{ route.name }}</h3>

                    <div v-for="action in actions" :key="action">
                        <label>
                            <input type="checkbox" :id="`${route.id}-${action}`" :value="action" v-model="selectedPermissions[route.id][action]" />
                            {{ action }}
                        </label>
                    </div>
                </div>

                <button @click="updatePermissions">Actualizar Permisos</button>
            </div>
        </div>
    </div>
    
      </section>
   

</template>

<script>

import { ref, onMounted, onCreated,reactive } from 'vue';
import { emitter } from '../../../mitt.js';
import { useUserStore } from '../../../stores/user';
import categorias from '@/Services/categorias';
import roles from '@/Services/roles';
import routes from '@/Services/routes';
import parametros from '@/Services/parametros';
import provedores from '@/Services/provedores';
import generales from '@/functions/generales';
import { saveAs } from 'file-saver'

import Tabla from "@/components/Tabla.vue";

   
export default {
    props: ['role', 'routes', 'permissions'],
    components: {Tabla},
    setup(props) {
        const user = useUserStore();
        const selectedPermissions = reactive({});
        const actions = ['read', 'create', 'update', 'delete'];
        const roles_data = ref([]);
        const routes_data = ref([]);
        const headings = ref( [

{
    key: 'id',
    name: '#',
    show: true
},
{
    key: 'name',
    name: 'Nombre',
    show: true
},
{
    key: 'descripcion',
    name: 'Descripcion',
    show: true
},

{
    key: 'edit',
    name: 'Editar',
    show: true
},

]);
const  edit=async(data)=> {
console.log(data,'---data--')
}
const  getRutas=async()=> {
           
           emitter.emit("loading", true)

           await routes.GET().then(response => {

               if (response != null && !response.hasOwnProperty('error')) {

                   routes_data.value = response
                   //this.$router.push('/app/'); 
               } else {

                   // emitter.emit("alerta_show",response.msg);

               }
               emitter.emit("loading", false)
           });
       }
const  getData=async()=> {
           
            emitter.emit("loading", true)

            await roles.GET().then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    roles_data.value = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        }

        const updatePermissions = () => {
            const updatedPermissions = [];

            for (const routeId in selectedPermissions) {
                for (const action in selectedPermissions[routeId]) {
                    if (selectedPermissions[routeId][action]) {
                        updatedPermissions.push({
                            route_id: parseInt(routeId),
                            action,
                        });
                    }
                }
            }

            // Lógica para enviar la solicitud a tu API Laravel
            // Utiliza props.role.id y updatedPermissions

            // Ejemplo de cómo podrías enviar la solicitud con Axios
            // axios.post(`/api/roles/${props.role.id}/permissions`, { permissions })
            //   .then(response => {
            //     console.log(response.data);
            //   })
            //   .catch(error => {
            //     console.error(error.response.data);
            //   });
        };
        const updateRole=()=>{
            props.routes.forEach(route => {
                selectedPermissions[route.id] = {};

                actions.forEach(action => {
                    const hasPermission = props.permissions.some(
                        permission => permission.route_id === route.id && permission.action === action
                    );

                    selectedPermissions[route.id][action] = hasPermission;
                });
            });
        }
        onMounted(() => {
            getData()
            getRutas();
        });

        return {
            edit,
            selectedPermissions,
            actions,
            roles_data,
            getData,
            headings,
            routes_data,
            getRutas,
            updatePermissions,
        };
    },
};
</script>
