<template>
<div class="w-full h-full">

    <el-dialog v-model="showimg" :width="$isMobile()?'90%':'70%'" :before-close="handleClose">
        <div class="w-full flex flex-wrap justify-center bg-gray-100">
            <img :src="imgsel" class="h-96 w-96 object-contain">
        </div>

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="showimg = false">Cerrar</el-button>

            </span>
        </template>
    </el-dialog>
    <div class="flex flex-col">
        <div class="overflow-x-auto flex flex-col ">
  
              <table class="">
                <thead>
              
                <tr class="bg-gra-200 ">
                    <th  class="sticky top-0 w-28 border-2 border-black align-middle "># CITA</th>
                    <th class="sticky top-0 w-64 border-2 border-black align-middle ">ESTATUS</th>
                    <th  class="sticky top-0 w-28 border-2 border-black align-middle ">INSTALACION</th>
                    <th  class="sticky top-0 w-28 border-2 border-black align-middle ">OBSERVACION <br> GARANTIA</th>
                    <th  class="sticky top-0 w-28 border-2 border-black align-middle ">MOTIVO <br> GARANTIA</th>
                    <th  class="sticky top-0 w-64 border-2 border-black align-middle ">FECHA</th>
                    <th  class="sticky top-0 w-28 border-2 border-black align-middle ">VENDEDOR</th>
                    <th class="sticky top-0 w-64 border-2 border-black align-middle ">VER</th>
                    <th class="sticky top-0 w-28 border-2 border-black align-middle ">FIRMA </th>
                    <th class="sticky top-0 w-64 border-2 border-black align-middle"> FOTOS</th>
                </tr>
            </thead>

            <tbody>
                <template v-for="(row,index) in cotizacion.citas" v-bind:key="index">
                    <tr class="text-xs ">

                        <td class="w-28 border-2 border-black align-middle  bg-gray-200  content-center  px-1">
                         {{row.id}}-{{row.tipo}}
                        </td>
                        <td class="w-64 border-2 border-black align-middle  bg-gray-200  content-center  px-1" :class="'text-'+getColor(row.status)+'-500'">
                           {{row.status}}

                        </td>
                        <td class="border-2 border-black align-middle  bg-gray-200 content-center  px-1">
                      
                            {{row.status_instalacion}}

                        
                     </td>
                        <td class="border-2 border-black align-middle  bg-gray-200  content-center  px-1">
                            {{row.observaciones_garantia}}
 
                         </td>
                         <td class="border-2 border-black align-middle  bg-gray-200  content-center  px-1" >
                           {{row.motivo}}
 
                         </td>
                  
                        <td  class="w-64 border-2 border-black align-middle  bg-gray-200 content-center  px-1">

                           {{formatDateTime(row.fecha_inicio)}}
                        </td>
                        <td class="border-2 border-black align-middle  bg-gray-200  content-center  px-1">
                           {{row.usuario.name}}
                        </td>
                        <td class="border-2 border-black align-middle   bg-gray-200 content-center  px-1">
                           <a class="uppercase text-red-500" :href="'/app/cita?cid='+row.id+'&m='+getMes(row.fecha_inicio)+'&a='+getAno(row.fecha_inicio)"><i class="text-2xl las la-calendar"></i></a>
                          
                        </td>
                        <td class="border-2 border-black align-middle  bg-gray-200  content-center  px-1">
                            <a href='#' class="flex flex-wrap content-center px-2  w-full" v-if="row.firma_cliente!=null&&row.firma_cliente!=''" @click="imgsel=row.firma_cliente;showimg=true">
                                <img class='w-10 h-10 bg-white rounded-full ' :src='row.firma_cliente' alt='avatar' />
                            </a>
                        </td>
                        <td class=" border-2 border-black align-middle  bg-gray-300 content-center justify-center  px-1">

                            <div class='flex py-3 justify-start content-center px-2 h-3/6 overflow-x-scroll'>
                                <template v-for="(row,index) in row.fotos" v-bind:key="index">
                                    <img @click="imgsel=row.url;showimg=true" class='w-7 h-7 rounded-full ring-2 ring-white dark:ring-slate-800' :src='row.url' alt='avatar' />
                                </template>
                            </div>
                        </td>

                    </tr>
                </template>
            </tbody>
        </table>

    </div>
    </div>
</div>
</template>

<script >
//@ts-ignore
import generales from '@/functions/generales'

//import palabras from "@/mixins/palabras";
import {
    useInfoStore
} from '../stores/info'
export default {
    name: "Home",
    components: {

    },
    mixins: [generales],
    props: {
        infoneg: {
            type: Boolean,
            default: true
        },
        cotizacion: {
            type: Array,
        },
        instalacion: {
            type: Boolean,
            default: false
        },

    },
    data() {
        const info = useInfoStore()
        return {
            infositio: info.getAll(),
            showimg: false,
            imgsel: ''

        }
    },

    mounted() {

        console.log(this.infositio)
        //console.log(this.cotizacion, '----pc---')

    },

    methods: {
        getColor(status) {
            //cita.status
            switch (status) {
                case 'realizada':
                    return 'green'
                    break;
                case 'agendada':
                    return 'blue'
                    break;
                case 'cancelada':
                    return 'red'
                    break;
                case 'reagendada':
                    return 'pink'
                    break;
                case 'no atendida':
                    return 'yellow'
                    break;

                default:
                    break;
            }
        },
        parseTimeN(date) {
            const event = new Date(date);

            return event.toLocaleTimeString("es-MX", {
                hour12: true,
                hour: "2-digit",
                minute: "2-digit",
            });
        },

        getTime(date) {
            let tiempo = date.split(' ')
            return tiempo[1];
        },
        formatTime(time) {
            let tiempo = time.split(':')
            return tiempo[0] + ":" + tiempo[1];
        },

    }
}
</script>

<style>
td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.vgt-wrap {
    height: 100%;
}

.vgt-inner-wrap {
    height: 100%;
}
</style>
