

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
 // GET_CLIENTES CREAR_CLIENTES EDITAR_CLIENTES ELIMINAR_CLIENTES
 
 GET_CALCULOS_VENDEDOR: async function( data){
  let url='/reportes/metricos_vendedores?fechaInicio='+data.fechaInicio+'&fechaFin='+data.fechaFin+'&id='+data.id
  return initmixin.formatrespuesta('get', data,url,true)
},
 GET_PRODUCCION_CONTADOR: async function( data){
      let url='/reportes/produccion/contador?fechaInicio='+data.fechaInicio+'&fechaFin='+data.fechaFin
      return initmixin.formatrespuesta('get', data,url,true)
    },
    GET_ORDENES: async function( data){
      let url='/reportes/ordenes?fechaInicio='+data.fechaInicio+'&fechaFin='+data.fechaFin
      return initmixin.formatrespuesta('get', data,url,true)
    },
    GET_VENTAS_VENDEDORES: async function( data){
      let url='/reportes/ventas/vendedores?fechaInicio='+data.fechaInicio+'&fechaFin='+data.fechaFin
      return initmixin.formatrespuesta('get', data,url,true)
    },
    GET_CITAS_VENDEDORES: async function( data){
      let url='/reportes/citas/vendedores?fechaInicio='+data.fechaInicio+'&fechaFin='+data.fechaFin
      return initmixin.formatrespuesta('get', data,url,true)
    },
    
    GET_VENDEDOR_VENTAS: async function( data){
      let url='/reportes/vendedor/ventas?fechaInicio='+data.fechaInicio+'&fechaFin='+data.fechaFin+'&id='+data.id
      return initmixin.formatrespuesta('get', data,url,true)
    },
    GET_VENDEDOR_GARANTIAS_RESPONSABLE: async function( data){
      let url='/reportes/vendedor/garantias/responsable?fechaInicio='+data.fechaInicio+'&fechaFin='+data.fechaFin+'&id='+data.id
      return initmixin.formatrespuesta('get', data,url,true)
    },
    GET_VENDEDOR_GARANTIAS_VENTA: async function( data){
      let url='/reportes/vendedor/garantias/venta?fechaInicio='+data.fechaInicio+'&fechaFin='+data.fechaFin+'&id='+data.id
      return initmixin.formatrespuesta('get', data,url,true)
    },
    GET_VENDEDOR_CITAS: async function( data){
      let url='/reportes/vendedor/citas?fechaInicio='+data.fechaInicio+'&fechaFin='+data.fechaFin+'&id='+data.id
      return initmixin.formatrespuesta('get', data,url,true)
    },
    GET_REPORTE_SEGUIMIENTOS: async function( data){
      let url='/reportes/seguimientos?fechaInicio='+data.fechaInicio+'&fechaFin='+data.fechaFin+'&id='+data.id
      return initmixin.formatrespuesta('get', data,url,true)
    },

    
    
   


}