<template>
    <section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-start  ">
    
        <div v-if="!IsMov()" class="w-full h-12 bg-gray-100 flex flex-wrap content-center justify-start pl-12">
            <div class="w-42 px-4 ">
                <el-date-picker class="w-full" v-model="fecha" type="daterange" unlink-panels range-separator="-" start-placeholder="De" end-placeholder="Hasta" @change="getData()" />
    
            </div>
            <div class="">
                <el-pagination v-model:currentPage="pagina" small background layout="prev, pager, next" :page-size="data['per_page']" :total="Number(data['total'])" @current-change="pageChangue" class="" />
    
            </div>
    
        </div>
        <div v-if="IsMov()" class="w-full h-24 bg-gray-100 flex flex-wrap content-center justify-end px-2 ">
    
            <div class="w-10/12 h-12 justify-end content-center flex flex-wrap ">
                <el-pagination v-model:currentPage="pagina" small background layout="prev, pager, next" :page-size="data['per_page']" :total="Number(data['total'])" @current-change="pageChangue" class="" />
    
            </div>
            <div class="w-full h-12 justify-center content-center flex flex-wrap ">
                <el-date-picker class="w-full" v-model="fecha" type="daterange" unlink-panels range-separator="-" start-placeholder="De" end-placeholder="Hasta" @change="getData()" />
    
            </div>
    
        </div>
    
        <Tabla v-bind:key="reftable" v-if="data.hasOwnProperty('data')"  
        :add="false" :columns="headings" :rows="data.data" :filtros="['email','phone','id']" :buscarp="buscar" :filtroApi="true" :paginate="true" :page="{
            page:data.current_page,
            total:data.last_page
          }" />
    
        <el-drawer @closed="getData()" v-model="viewCotizacion" title="" :with-header="false" :size="$isMobile()?'100%':'100%'">
            <!--content-->
            <div class="h-full    flex flex-wrap content-start justify-center w-full  outline-none focus:outline-none overflow-y-scroll">
                <!--header-->
    
               
                <!--body-->
                <div class="w-full   overflow-y-scroll flex  flex-wrap justify-start  content-start z-20 h-full">
    
                    <div class="h-full bg-white px-2 flex flex-wrap w-full">
                        <div class="h-10 text-black text-base uppercase bg-gray-300 align-middle w-full flex flex-wrap" >
                            <div class="w-3/12 h-10 bg-yellow-300 flex flex-wrap justify-center content-center font-extrabold"><span class="align-middle">#Venta</span></div>
                            <div class="w-3/12 h-10 bg-yellow-200 flex flex-wrap justify-center content-center font-extrabold"><span class="align-middle">{{el_cotizacion.idventa}}</span></div>
                            <div class="w-3/12 h-10 bg-yellow-300 flex flex-wrap justify-center content-center font-extrabold"><span class="align-middle">Cliente</span></div>
                            <div class="w-3/12 h-12 bg-yellow-200 align-middle flex flex-wrap justify-center content-center"><span>{{ el_cotizacion.clientes.name}} {{ el_cotizacion.clientes.apellido_p}} {{ el_cotizacion.clientes.apellido_m}}</span></div>
                         </div>
                        <alert_component tipo="success" title="Panel para aprobación " text="en esta seccion se aprueba el formato de produccion independiente por producto."></alert_component>
              
                        <!--<div class="w-full flex flex-wrap">
                            <span class="text-blue-500">Observaciones de aprobación</span>
                        </div>
                        <textarea class="h-20 text-left w-full px-2 mb-4" v-model="el_cotizacion.observaciones_verificar"></textarea>-->
    
                        <VistaAprobacion v-bind:key="viewCotizacion" :cotizacion="el_cotizacion" :edit="false"></VistaAprobacion>
    
                    </div>
    
                </div>
    
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <!--<a v-if="el_cotizacion.status_verificar=='PENDIENTE DE REVISIÓN'||el_cotizacion.status_verificar=='RECHAZADA'" class=" bg-green-500 text-black  px-2 py-1 rounded border-2  border-black" @click="Verificar('APROBADA')">Aprobar</a>
                    <button v-if="el_cotizacion.status_verificar=='PENDIENTE DE REVISIÓN'||el_cotizacion.status_verificar=='RECHAZADA'" class=" bg-blue-500 px-2 py-1 mx-2 rounded border-2  border-black" @click="Verificar('RECHAZADA')">Rechazar</button>
                    -->
                  <!--  <button class=" bg-blue-500 px-2 py-1 mx-2 rounded border-2  border-black" @click="viewCotizacion=false">Cerrar</button>-->
                    <btn_component :text="'Cerrar'" :tipo="'info'"   @click="viewCotizacion=false"></btn_component>
                    
                </div>
            </template>
        </el-drawer>
    
    </section>
    </template>
    
    <script>
    import {
        emitter
    } from "../../../mitt.js";
    import {
        useUserStore
    } from '../../../stores/user'
    import Tabla from "@/components/Tabla.vue";
    import TemplateCotizacion from "@/components/TemplateCotizacion.vue";
    import generales from "@/functions/generales";
    import metodos_pagos from "@/Services/metodos_pagos";
    import abonos from "@/Services/abonos";
    import cotizaciones from "@/Services/cotizaciones";
    
    import VistaAprobacion from "@/components/VistaAprobacion.vue";
    import {
        filter
    } from "lodash";
    export default {
        components: {
            Tabla,
            VistaAprobacion
        },
        mixins: [generales, cotizaciones, metodos_pagos, abonos],
        data() {
            const user = useUserStore()
    
            return {
                headings: [
    
                    {
                        key: 'id',
                        name: '# Cot',
                        show: true
                    },
                    {
                        key: 'idventa',
                        name: '# venta',
                        show: true
                    },
                    {
                        key: 'venta',
                        name: 'Fecha venta',
                        show: true
                    },
    
                    {
                        key: 'clientes',
                        name: 'Cliente',
                        show: true
                    },
    
                    {
                        key: 'usuario',
                        name: 'Usuario',
                        show: true
                    },
                    {
    
                        key: 'verificar_aprobar',
                        name: 'Verificar',
                        show: true
                    },
    
                    /* {
                         key: 'edit',
                         name: 'Editar',
                         show: true
                     },*/
    
                ],
                dates: [],
                upbuton: 0,
                el_cotizacion: {
                    id: 0
                },
                open: false,
                user: user.getAll(),
                metodos_data: [],
                data: {},
                actionModal: null,
                viewCotizacion: false,
                showM: false,
                info: '',
                id: 0,
                obj_abono: {
                    cotizacion: 0,
                    tipo_pago: '',
                    impuesto: 0,
                    comision: 0,
                    total: 0,
                },
                addAbono: false,
                tipo_metodo: null,
                buscar: 'all',
                pagina: 1,
                limit: 0,
                reftable: 0,
                tipo: 'verificar',
                fecha: [this.getFirstDayOfMonth(), this.getLastDayOfMonth()],
    
            }
    
        },
        mounted() {
    
        },
        async created() {
            if (this.$route.query.hasOwnProperty('cid')) {
                this.buscar = this.$route.query.cid;
            }
    
            await this.getData()
            await this.getDataMetodos();
        },
        methods: {
            IsMov() {
    
                //return true;
                return this.$isMobile()
            },
            verCotizacion(row) {
    console.log(row,'---data---')
                this.el_cotizacion = row;
                this.viewCotizacion = true;
    
            },
            async pageChangue(val) {
    
                this.page = val;
                await this.getData();
    
            },
    
            infoModal(row) {
                this.info = row;
                console.log(row, '---info---')
            },
            paginaSearch(pagina) {
                console.log('---- dat---' + pagina)
                this.pagina = pagina;
    
                this.getData();
            },
    
            addRow() {
                this.showM = !this.showM;
                this.limpiar()
            },
            limpiar() {
                this.viewCotizacion = false;
            },
            buscador(row) {
                if (String(row.telefono).toUpperCase().search(String(this.buscar).toUpperCase()) != -1 || (String(row.nombre)).toUpperCase().search(String(this.buscar).toUpperCase()) != -1) {
                    return true;
                } else {
                    return false;
                }
            },
            getColumns() {
                let col = 0;
                this.headings.forEach(element => {
                    if (element.show) {
                        col = col + 1
                    }
                });
                return col;
            },
    
            buscarGet(text) {
                this.buscar = text;
                if (text == '') {
                    this.buscar = 'all';
                }
                this.getData();
            },
    
            async getData() {
                this.showM = false;
                this.actionModal = null;
                emitter.emit("loading", true)
                let dat = {
                    search: this.buscar,
                    page: this.pagina,
                    tipo: this.tipo,
                    inicio: this.formatDateEN(this.fecha[0]),
                    fin: this.formatDateEN(this.fecha[1]),
                    data: {}
                }
    
                await cotizaciones.GET(dat).then(response => {
    
                    if (response != null && !response.hasOwnProperty('error')) {
    
                        this.data = response;
    
                        if (this.$route.query.hasOwnProperty('cid')) {
                            let fresult = response.data.filter(r => r.id == this.$route.query.cid)
    
                            if (fresult.length > 0) {
                                this.$router.replace({
                                    name: 'detalle_verificar',
                                    force: true
                                });
                                this.el_cotizacion = fresult[0];
                                this.viewCotizacion = true;
                            }
                            //console.log(this.$route.query.r,fresult,'recovery')
                        }
                        this.reftable = this.reftable + 1;
    
                        //this.$router.push('/app/'); 
                    } else {
    
                        emitter.emit("alerta_show", response.error);
    
                    }
                    emitter.emit("loading", false)
                });
            },
    
            async Verificar(status) {
    
                let dat = {
                    id: this.el_cotizacion.id,
                    status_verificar: status,
                    observaciones_verificar: this.el_cotizacion.observaciones_verificar
    
                }
                if (status == 'APROBADA') {
                    dat.status_produccion = 'EN PRODUCCION'
                }
    
                if (this.el_cotizacion.id == 0) {
                    //this.crear(dat)
                } else {
                    this.editar(dat)
                }
            },
    
            async editar(dat) {
                emitter.emit("loading", true)
    
                await cotizaciones.EDITAR_PARAMS(dat).then(response => {
    
                    if (response != null && !response.hasOwnProperty('error')) {
    
                        this.el_cotizacion = response;
                        //this.$router.push('/app/'); 
                    } else {
    
                        // emitter.emit("alerta_show",response.msg);
    
                    }
                    this.showM = false;
                    this.limpiar();
                    emitter.emit("loading", false)
                });
            },
    
        }
    }
    </script>
    