<template>
<section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-center content-center ">
    <div class="w-full  flex flex-wrap h-full  h-full bg-white">

        <div class="w-full  h-full  flex flex-wrap content-center  justify-between ">

            <div class="text-xs xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-base w-full xs:w-full sm:w-full md:w-6/12 lg:w-6/12 xl:w-6/12 h-3/6 xs:h-3/6 sm:h-3/6 md:h-full lg:h-full xl:h-full bg-white  overflow-hidden ">
                <!--listado de categorias-->

                <div v-if="filtro==0&&prod_sel==null&&tipo_view=='produccion'" class="h-full w-full  justify-center content-start flex flex-wrap bg-gray-100   ">

                    <div class="w-full h-full bg-gray-200 justify-center content-center flex flex-wrap">
                        <div class="w-full h-full justify-center content-center flex flex-wrap bg-gray-300 px-10 ">
                            <button type="button" class=" font-extrabold text-left w-full flex flex-wrap  px-2 py-2 justify-start content-center">

                                <span class="text-xs xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-base self-center text-blue-600 text-center w-full">Estas por enviar formato de produccion, al enviar ya no podras realizar cambios en la cotización, solo podras realizar cambios en la producción </span></button>

                            <span class="text-xs xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-base self-center text-blue-600 text-center w-full">
                                Si desea modificar el precio de la cotización es necesario entrar a edicion de cotizacion de click
                                <a :href="'/app/nueva/cotizacion?select='+id" class="text-green-500 font-extrabold">Aquí</a> para ello </span>
                        </div>

                    </div>

                </div>
                <div v-if="filtro==0&&prod_sel==null&&tipo_view=='produccion_edit'" class="h-full w-full  justify-center content-start flex flex-wrap bg-gray-100  ">

                    <div class="w-full h-full bg-gray-200 justify-center content-center flex flex-wrap">
                        <div class="w-full h-full justify-center content-center flex flex-wrap bg-gray-300 px-10 ">
                            <button type="button" class=" font-extrabold text-left w-full flex flex-wrap  px-2 py-2 justify-start content-center">

                                <span class="text-xs xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-base self-center text-blue-600 text-center w-full">
                                    Este es el modo de edicion de producción
                                </span></button>

                            <span class="text-xs xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-base self-center text-blue-600 text-center w-full">
                                Cualquier cambio aqui no afecta a el precio de la venta ni los parametros de la cotizacion</span>
                        </div>

                    </div>

                </div>

                <div v-if="filtro==0&&prod_sel==null&&(tipo_view!='produccion'&&tipo_view!='produccion_edit')" class="h-full w-full  justify-center content-start flex flex-wrap bg-gray-100   ">

                    <div class="w-full h-2/6 xs:h-2/6 sm:h-1/6 md:h-1/6 lg:h-1/6 xl:h-1/6 bg-gray-200 justify-center content-center flex flex-wrap">
                        <div class="w-full h-3/6 justify-center content-center flex flex-wrap bg-gray-300">
                            <button type="button" class=" font-extrabold text-left w-full flex flex-wrap  px-2 py-2 justify-start content-center">

                                <span class="stext-xs xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-base elf-center text-yellow-600 text-center w-full">Seleccione una categoría para visualizar los productos disponibles</span></button>
                        </div>
                        <div class="w-full h-3/6 justify-center content-center flex flex-wrap">
                            <button type="button" class="text-base font-extrabold text-left w-full flex flex-wrap px-2 py-2 justify-start content-center" @click="filtro=0">

                                <span class="text-xs xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-base self-center text-center w-full">Categorías</span> </button>
                        </div>
                    </div>
                    <div class="w-full h-4/6 xs:h-4/6 sm:h-5/6 md:h-5/6 lg:h-5/6 xl:h-5/6 py-2 px-2 justify-center bg-white overflow-y-scroll content-start flex flex-wrap">

                        <template v-for="(row,index) in categorias_data" v-bind:key="index">

                            <div @click="filtro=row.id" class="h-32 w-6/12 xs:w-6/12 sm:w-4/12 px-1 py-1 lg:w-32 xl:w-32 border-0 cursor-pointer border-yellow-500 hover:border-2 hover:rounded-full ">
                                <!-- Start Card List -->
                                <div class="relative w-full h-full bg-cover bg-center group  rounded-lg overflow-hidden  shadow-lg transition duration-300 ease-in-out " :style="'background-image: url('+row.url+');'">
                                    <div class="absolute inset-0 bg-black bg-opacity-50 transition duration-300 ease-in-out"></div>
                                    <div class="relative w-full h-full px-4 sm:px-6 lg:px-4 flex items-center">
                                        <div class="w-full text-center flex flex-wrap justify-center content-center">
                                            <div class="text-white text-lg flex text-center  w-full space-x-2 items-center  flex flex-wrap justify-center content-center font-extrabold">

                                                <p>{{row.nombre}}</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <!-- End Card List -->
                            </div>
                            <!-- <a @click="filtro=row.id" href="#" class="h-32 hidden w-6/12 xs:w-6/12 sm:w-4/12 lg:w-32 xl:w-32 px-1 py-1 my-2">

                                <div class="  flex flex-wrap  bg-white border-black rounded-xl  border-4  shadow-xl shadow-black hover:opacity-70 overflow-hidden  shadow-md hover:border-yellow-400 ">
                                    <span v-if="filtro==row.id" class="absolute l-0 -ml-4 -mt-2 inline-flex items-center p-1  text-sm font-semibold text-yellow-800 bg-yellow-400 rounded-full dark:bg-blue-200 dark:text-blue-800">
                                        <svg aria-hidden="true" class="w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                        </svg>
                                        <span class="sr-only">Icon description</span>
                                    </span>
                                    <img class="object-cover w-full h-32 rounded-t-lg  md:rounded-none md:rounded-l-lg" :src="row.url" alt="">
                                    <div class="flex flex-wrap w-full -mt-12 bg-white z-30 bg-opacity-70 justify-center content-center text-clip  leading-normal  w-8/12">
                                        <h5 class=" text-xs xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-base font-extrabold  overflow-hidden  text-ellipsis text-left capitalize font-bold tracking-tight text-gray-900 dark:text-white">{{row.nombre}}</h5>

                                    </div>
                                </div>
                            </a>-->

                        </template>
                    </div>
                </div>
                <!--listado de productos-->
                <div v-if="filtro!=0&&prod_sel==null&&(tipo_view!='produccion'&&tipo_view!='produccion_edit')" class="h-full w-full justify-center  flex flex-wrap bg-gray-100   ">

                    <div class="w-full h-2/6 xs:h-2/6 sm:h-1/6 md:h-1/6 lg:h-1/6 xl:h-1/6 bg-gray-200 justify-center content-center flex flex-wrap">
                        <div class="w-full h-3/6 justify-center content-center flex flex-wrap bg-gray-300">
                            <button type="button" class=" font-extrabold text-left w-full flex flex-wrap  px-2 py-2 justify-start content-center">

                                <span class="text-xs xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-base self-center text-yellow-600 text-center w-full">Seleccione un producto a cotizar</span></button>
                        </div>
                        <div v-if="filtro!=0" class="w-full h-3/6 justify-center content-center flex flex-wrap">
                            <button type="button" class="text-base font-extrabold text-left w-full flex flex-wrap px-2 py-2 justify-start content-center" @click="filtro=0">
                                <vue-feather :type="'arrow-left-circle'" size="10" class="flex-shrink-0 w-8 h-8 mr-4 text-white transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" :stroke="'gray'" />

                                <span class="self-center text-xs xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-base">Regresar a categorías </span><span class="self-center px-4">/</span> <span class="text-xs xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-base self-center text-yellow-600"> Productos</span></button>
                        </div>
                    </div>

                    <div class="w-full h-4/6 xs:h-4/6 sm:h-5/6 md:h-5/6 lg:h-5/6 xl:h-5/6 py-2  px-2  justify-center bg-white overflow-y-scroll content-start flex flex-wrap">

                        <!--productos categoria-->

                        <template v-for="(row,index) in data" v-bind:key="index">
                            <a href="#" v-if="filtro==row.tipo||filtro==row.categorias_id" @click="formatval(row)" class="ml-1 mt-1 col-span-2 flex flex-wrap  bg-white border-black rounded-lg  border-4 shadow-xl shadow-black hover:opacity-70 overflow-hidden  shadow-md h-32 w-32 hover:border-yellow-400 ">
                                <img v-if="row.tipo=='producto_venta'" class="object-cover w-32 h-32 rounded-t-lg  md:rounded-none md:rounded-l-lg" :src="row.url==null||row.url==''?'https://res.cloudinary.com/dgzea9m7q/image/upload/c_scale,q_auto:low,w_888/v1627521854/popcing/6-06_iworfi.png':row.url" alt="">
                                <div class="flex flex-wrap -mt-32   bg-white z-30 bg-opacity-30 justify-between text-clip p-4 leading-normal " :class="row.tipo=='producto_venta'?'w-full':'w-full'">
                                    <h5 class=" text-sm  overflow-hidden  text-ellipsis text-left font-bold tracking-tight text-gray-900 dark:text-white">{{row.nombre}}</h5>
                                    <p class="mb-1 text-xs max-h-10 truncate text-left font-normal text-gray-700 dark:text-gray-400">{{row.descripcion}}</p>
                                    <div class="flex justify-between items-center">
                                        <span class="text-xl font-bold text-gray-900 dark:text-white" v-if="row.tipo=='opcion_catalogo'">${{row.precio}}</span>

                                    </div>
                                </div>
                            </a>
                        </template>
                    </div>

                </div>
                <!--vista de producto seleccionado-->
                <div v-if="prod_sel!=null" class="h-full w-full justify-center  flex flex-wrap bg-gray-100   ">

                    <div class="w-full h-2/6 xs:h-2/6 sm:h-1/6 md:h-1/6 lg:h-1/6 xl:h-1/6 bg-gray-200 justify-center content-center flex flex-wrap">
                        <div class="w-full h-3/6 justify-center content-center flex flex-wrap bg-gray-300">
                            <button type="button" class=" font-extrabold text-left w-full flex flex-wrap  px-2 py-2 justify-start content-center">

                                <span class="text-xs xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-base self-center text-yellow-600 text-center w-full">
                                    {{tipo_view=='produccion'?'Revise la información de el producto seleccionado y posterior a ello enviar a revision':' Producto seleccionado llene la informacion necesaria y al finalizar precione agregar'}}

                                </span></button>
                        </div>
                        <div class="w-full h-3/6 justify-center content-center flex flex-wrap">
                            <button type="button" v-if="(tipo_view!='produccion'&&tipo_view!='produccion_edit')" @click="prod_sel=null;" class="text-base font-extrabold text-left w-full flex flex-wrap px-2 py-2 justify-start content-center">
                                <vue-feather :type="'arrow-left-circle'" size="10" class="flex-shrink-0 w-8 h-8 mr-4 text-white transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" :stroke="'gray'" />

                                <span class="text-xs xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-base self-center">Cancelar </span><span class="self-center px-4">/</span> <span class="text-xs xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-base self-center text-yellow-600"> {{prod_sel.nombre}}</span></button>
                        </div>
                    </div>

                    <div class="w-full h-4/6 xs:h-4/6 sm:h-5/6 md:h-5/6 lg:h-5/6 xl:h-5/6 pt-2 pb-20 px-2 justify-center bg-white overflow-y-scroll content-start flex flex-wrap">

                        <p class="w-full">{{prod_sel.descripcion}}</p>

                        <template v-for="(row,index) in prod_sel.parametros" v-bind:key="index">
                            <div class="mb-1 mt-1  justify-start flex flex-wrap w-6/12 px-2" v-if="isVisible(row)&&row.tipo=='catalogo'">

                                <span class=" py-2 block text-gray-700 uppercase text-start text-sm font-semibold " htmlFor="nombre">
                                    {{row.nombre}}
                                </span>

                                <input placeholder="Buscador" class="px-4 w-full h-8 rounded" v-model="row.search" >
                                <div v-if="row.hasOwnProperty('search')&&row.search!=''"  class="bg-white z-30 w-full rounded-b-md font-medium max-h-24 py-1 overflow-y-scroll">
                                    <div class=" space-y-1">
                                        <template v-for="(row_prod,index_pr) in row.selproductos.productos" :key="index_pr">
                                            <div v-if="(!row.hasOwnProperty('search')&&row.search=='')||String(row_prod.nombre).toLowerCase().includes(String(row.search).toLowerCase())">
                                               
                                                
                                                    <div v-if="row_prod.activo" :value="row_prod.id" :selected="row.valor==row_prod.id" @click="row.valor=row_prod.id"
                                                         class="bg-blue-200 border-2 border-blue-200 cursor-pointer rounded-md text-xs hover:border-light-blue-1"
                                                        >{{ row_prod.nombre }}/{{ row_prod.tag }}-{{row_prod.precio}}</div>
                                               
                                            </div>
                                        </template>
                                       
                                    </div>
                                </div>
                                    <select id="cantidad" :disabled="issetInFormula(row,prod_sel.formula)" v-model="row.valor" :class="issetInFormula(row,prod_sel.formula)?'border-red-500 bg-red-100':''" class="text-sm rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10  ">
                                        <option :value="0" :selected="row.valor==0">
                                            Seleccione una opción
                                        </option>
                                        <template v-for="(row_prod,index_pr) in row.selproductos.productos" :key="index_pr">
                                            <option v-if="row_prod.activo" :value="row_prod.id" :selected="row.valor==row_prod.id">
                                                {{ row_prod.nombre }}/{{ row_prod.tag }}-{{row_prod.precio}}
                                            </option>
                                        </template>
                                    </select>
                         
                               
                              
                                
                               
                            </div>
                            <div class="mb-1 mt-1  justify-start flex flex-wrap w-6/12 px-2" v-if="isVisible(row)&&row.tipo=='texto'">

                                <span class=" py-2 block text-gray-700 uppercase text-start text-sm font-semibold " htmlFor="nombre">
                                    {{row.nombre}}
                                </span>
                                <input id="cantidad" :disabled="issetInFormula(row,prod_sel.formula)" v-model="row.valor" :class="issetInFormula(row,prod_sel.formula)?'border-red-500 bg-red-100':''" class="text-sm  rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10  " type="text" :placeholder="row.nombre">
                            </div>

                            
                            <div class="mb-1 mt-1  justify-start flex flex-wrap w-6/12 px-2" v-if="row.tipo=='value_formula'">

                                <span class=" py-2 block text-gray-700 uppercase text-start text-sm font-semibold " htmlFor="nombre">
                                    {{row.nombre}}
                                </span>
                                <input id="cantidad" :disabled="true" :value="calcularParametro(prod_sel,row.formula_mp)" class="text-sm  rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10  " type="text" :placeholder="row.nombre">
                            </div>
                            <div class="mb-1 mt-1  justify-start flex flex-wrap w-6/12 px-2" v-if="row.tipo=='calc_formula'">

                                <span class=" py-2 block text-gray-700 uppercase text-start text-sm font-semibold " htmlFor="nombre">
                                    {{row.nombre}}
                                </span>
                                <input id="cantidad" :disabled="true" :value="calcularParametro(prod_sel,row.formula_mp)" class="text-sm  rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10  " type="text" :placeholder="row.nombre">
                            </div>

                            <div class="mb-1 mt-1  justify-start flex flex-wrap w-6/12 px-2" v-if="isVisible(row)&&row.tipo=='texto_extendido'">

                                <span class=" py-2 block text-gray-700 uppercase text-start text-sm font-semibold " htmlFor="nombre">
                                    {{row.nombre}}
                                </span>
                                <textarea id="cantidad" :disabled="issetInFormula(row,prod_sel.formula)" v-model="row.valor" :class="issetInFormula(row,prod_sel.formula)?'border-red-500 bg-red-100':''" class="text-sm  rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-24  " :placeholder="row.nombre"></textarea>
                            </div>

                            <div class="mb-1 mt-1  justify-start flex flex-wrap w-6/12 px-2" v-if="isVisible(row)&&row.tipo=='numerico'">

                                <span class=" py-2 block text-gray-700 uppercase text-start text-sm font-semibold " htmlFor="nombre">
                                    {{row.nombre}}
                                </span>
                                <input id="cantidad" :disabled="issetInFormula(row,prod_sel.formula)" v-model="row.valor" :class="issetInFormula(row,prod_sel.formula)?'border-red-500 bg-red-100':''" class="text-sm  rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10  " type="text" :placeholder="row.nombre">
                            </div>
                        </template>
                    </div>
                    <div class="w-full sticky bottom-0 py-2 justify-end px-4 bg-black overflow-y-scroll content-start flex flex-wrap">
                        <button v-if="!prod_sel.hasOwnProperty('indice')" class=" bg-yellow-400 px-2 text-base py-1 font-extrabold rounded  text-black" type="button" @click="AgregaraCotizacion(null)">Agregar</button>
                        <button v-if="prod_sel.hasOwnProperty('indice')" class=" bg-yellow-400 px-2 text-base py-1 font-extrabold rounded  text-black" type="button" @click="ActualizarProducto()">Actualizar Producto</button>
                    </div>
                </div>

            </div>
            <!--vista de productos agregados a cotizacion-->
            <div :class="tipo_view!='produccion_edit'&&tipo_view!='produccion'?'':''" class="w-full xs:w-full sm:w-full md:w-6/12 lg:w-6/12 xl:w-6/12  bg-gray-100  h-3/6 xs:h-3/6 sm:h-3/6 md:h-full lg:h-full xl:h-full mx-auto  flex flex-wrap  relative  overflow-hidden   px-2 justify-center content-start pb-12 pt-0">

                <div v-bind:key="tot" v-if="tipo_view!='produccion_edit'&&tipo_view!='produccion'" class="h-20 z-10   sticky to-0 justify-between flex flex-wrap content-center   text-black text-base px-2 w-full">
                    <div class="w-full flex flex-wrap justify-end text-yellow-600 font-extrabold mb-1">
                        <span v-if="infositio.hasOwnProperty('active_tipo_moneda')" class="text-xs mx-3 flex flex-wrap justify-center content-center">CONFIGURACION MONEDA 
                            <input class="mx-2" v-model="configuracion_ver" :checked="configuracion_ver" type="checkbox">
                        </span>
                        <span class="text-xs mx-3 flex flex-wrap justify-center content-center">ES GARANTIA 
                            <input class="mx-2" v-model="garantia" :checked="garantia" type="checkbox">
                        </span>
                    </div>
                    <SearchSelectCliente v-bind:key="cliente" :rows="clientes" class="border-0" :label="'cliente'" :valor="cliente" @seleccionado="action_cliente" />
                </div>

                <div v-bind:key="tot" v-if="cotizacion_edit!=null&&(tipo_view=='produccion_edit'||tipo_view=='produccion')" class="h-20 z-10   sticky to-0 justify-between flex flex-wrap content-center   text-black text-base  w-full">
                    <div class="h-10 text-black text-base uppercase bg-gray-300 align-middle w-full flex flex-wrap">
                        <div class="w-3/6 h-8  border border-black bg-yellow-300 flex flex-wrap justify-center content-center font-extrabold"><span class="align-middle">#Venta</span></div>
                        <div class="w-3/6 h-8 border border-black bg-yellow-200 flex flex-wrap justify-center content-center font-extrabold"><span class="align-middle">{{cotizacion_edit.idventa}}</span></div>
                        <div class="w-3/6 h-8 border border-black bg-yellow-300 flex flex-wrap justify-center content-center font-extrabold"><span class="align-middle">Cliente</span></div>
                        <div class="w-3/6 h-8 border border-black bg-yellow-200 align-middle flex flex-wrap justify-center content-center"><span>{{ cliente.name}} {{ cliente.apellido_p}} {{ cliente.apellido_m}}</span></div>
                    </div>
                </div>
                <div :style="'height:calc(100% - 80px )'" :class="tipo_view!='produccion_edit'&&tipo_view!='produccion'?'h-fit':'h-fit'" class="w-full py-2 flex flex-wrap justify-start content-start bg-gray-100 overflow-y-scroll pb-24">
                    <div v-if="configuracion_ver" class="w-full my-10 py-5 bg-yellow-200 px-5 flex flex-wrap justify-start">

                        <span class="w-full text-underline text-xs text-black font-extrabold text-start">Precio de el peso a tomar MXN</span>
                        <input v-model="valor_peso" class="w-full h-10 border border-gray-300 mb-2 p-1" placeholder="#precio de peso ">
                         </div>
                    <div v-if="garantia" class="w-full my-10 py-5 bg-yellow-200 px-5 flex flex-wrap justify-start">

                        <span class="w-full text-underline text-xs text-black font-extrabold text-start">Datos Garantia</span>
                        <input v-model="cot_garantia" class="w-full h-10 border border-gray-300 mb-2 p-1" placeholder="#cotizacion origen">
                        <SearchSelectUsuario :rows="usuarios_data" class="border-0" :label="'Usuarios'" :valor="responsable_garantia" @seleccionado="action_responsable" @search="getDataUsuarios" />

                        <textarea v-model="descripcion_garantia" class="w-full h-24 border border-gray-300 p-1" placeholder="Descripcion causa garantia"></textarea>
                    </div>

                    <template v-for="(row,index) in productos_cotizacion" v-bind:key="index">

                        <div id="toast-interactive" class="py-2 px-2 w-full  text-gray-500 bg-white   overflow-hidden rounded-sm flex flex-wrap justify-start content-center shadow-sm border-t  border-gray-600  min-h-42 " role="alert">
                           
                            <div class=" flex flex-wrap justify-start content-center w-4/6 h-auto">

                                <div class="w-1/12  flex flex-wrap content-center justify-center">

                                    <div @click="row.cantidad=Number(row.cantidad)+1" class="inline-flex cursor-pointer hover:bg-green-500 flex-shrink-0 justify-center items-center w-full h-8 text-blue-500 bg-green-200 rounded-lg dark:text-blue-300 dark:bg-blue-900">
                                        <span class="text-black text-xs font-extrabold">+</span>
                                    </div>
                                    <div class="inline-flex flex-shrink-0 justify-center items-center w-full h-8 text-blue-500 bg-blue-100 rounded-lg dark:text-blue-300 dark:bg-blue-900">
                                        <span class="text-black text-xs font-extrabold">{{Number(row.cantidad).toFixed(0)}}</span>
                                    </div>
                                    <div @click="row.cantidad>1?row.cantidad--:null" class="inline-flex cursor-pointer hover:bg-red-500 flex-shrink-0 justify-center items-center w-full h-8 text-blue-500 bg-red-200 rounded-lg dark:text-blue-300 dark:bg-blue-900">
                                        <span class="text-black text-xs font-extrabold">-</span>
                                    </div>
                                </div>
                                <div class="text-sm font-normal flex flex-wrap  w-11/12  px-2 content-center">
                                    <div class="mb-1 text-left w-full text-sm font-semibold text-gray-900 dark:text-white">{{row.idprodcot}}-{{row.nombre}}</div>
                                    <div class="mb-2 text-xs font-bold text-left">
                                        <!--<label class="w-full"><strong class="text-blue-500">Formula : </strong>{{CastFormula(row)}}</label>--><br>

                                        <template v-for="(row_par,index_par) in row.parametros" v-bind:key="index_par">

                                            <!-- <span v-if="row_par.visible==1&&row_par.tipo=='calc_formula'">{{row_par.nombre}}  {{' '}} {{calcularParametro(row,row_par.formula_mp)}} {{' '}} </span>-->
                                            <label v-if="row_par.tipo=='numerico'&&isVisible(row_par)">{{row_par.nombre}} x {{row_par.valor}} , </label>
                                            <label v-if="row_par.tipo=='texto'&&isVisible(row_par)">{{row_par.nombre}} = {{row_par.valor}} , </label>
                                            <label v-if="row_par.tipo=='texto_extendido'&&isVisible(row_par)">{{row_par.nombre}} = {{row_par.valor}} , </label>
                                            <label v-if="row_par.tipo=='catalogos'&&isVisible(row_par)">{{row_par}}</label>
                                            <label v-if="row_par.tipo=='catalogoOLD'&&isVisible(row_par)">{{ row_par.nombre }} ( {{row_par.valor==0?'N/A':row_par.selproductos==null?'':row_par.selproductos.productos.find(element => element.id ==row_par.valor).nombre}} ) ,</label>
                                            <label v-if="row_par.tipo=='catalogo'&&isVisible(row_par)">{{ row_par.nombre }} ( {{row_par.valor==0||row_par.valor==null||row_par.valor==''?'N/A':row_par.selproductos==null?'':row_par.selproductos.productos.find(element => element.id ==row_par.valor).nombre}} ) ,</label>
                                     
                                        </template>

                                    </div>

                                </div>

                                

                            </div>
                            <div class="     py-1 flex flex-wrap  w-2/6 justify-end">
                                <button @click="selEditProducto(row,index)" type="button" class=" bg-blue-500 flex flex-wrap justify-center content-center text-white   rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-blue-700 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-red-800 dark:hover:bg-red-700" data-dismiss-target="#toast-interactive" aria-label="Close">
                                    <i class="las la-pen"></i>
                                </button>
                                <button v-if="tipo_view=='produccion_edit'||tipo_view=='produccion'" @click="separarProducto(id,row.idprodcot)" type="button" class="ml-2 bg-red-500 flex flex-wrap justify-center content-center text-white   rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-red-700 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-red-800 dark:hover:bg-red-700" data-dismiss-target="#toast-interactive" aria-label="Close">
                                    <i class="las la-stream"></i>
                                </button>

                                <button v-if="tipo_view!='produccion_edit'&&tipo_view!='produccion'" @click="eliminarIndice(index)" type="button" class="mx-2 bg-gray-300 flex flex-wrap justify-center content-center text-black border border-black hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-red-800 dark:hover:bg-red-700" data-dismiss-target="#toast-interactive" aria-label="Close">
                                    <span class="sr-only">Close</span>
                                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                    </svg>
                                </button>
                                <div v-if="(tipo_view!='produccion'&&tipo_view!='produccion_edit')" class="w-full mt-2 content-center  jusrify-center flex flex-wrap px-2 ">

                                    <button v-if="infositio.hasOwnProperty('active_tipo_moneda')" type="button" class="flex flex-wrap bg-green-100 text-xs justify-center content-center text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-full dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-interactive">
                                        <strong class="text-green-500">$ {{formatPrice(Number(Number(Number(row.precio_prod)*Number(row.cantidad))*valor_peso).toFixed(2))}} MXN</strong><br>
                                        
                                    </button>
                                    <button v-if="infositio.hasOwnProperty('active_tipo_moneda')" type="button" class="mt-2 flex flex-wrap bg-pink-100 text-xs justify-center content-center text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-full dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-interactive">
                                          <strong class="text-pink-500">$ {{formatPrice(Number(Number(Number(row.precio_prod)*Number(row.cantidad))).toFixed(2))}} USD </strong>
                                  
                                    </button>
                                    <button v-if="!infositio.hasOwnProperty('active_tipo_moneda')" type="button" class="flex flex-wrap bg-green-100 text-xs justify-center content-center text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-full dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-interactive">
                                        <strong class="text-green-500">$ {{formatPrice(Number(Number(Number(row.precio_prod)*Number(row.cantidad))).toFixed(2))}} MXN</strong><br>
                                        
                                    </button>
                                  
                            
                                </div>
                            </div>
                           

                        </div>

                    </template>
                </div>

                <div v-bind:key="tot" class="h-12    -bottom-12 sticky    justify-between flex flex-wrap content-center bg-gray-800 text-white text-base  w-full z-0">
                    <!--<div v-if="id>0&&tipo_view=='produccion'" class="w-full h-3/6 bg-gray-200 justify-center content-center flex flex-wrap">
                        <div class="w-full h-full justify-center content-center flex flex-wrap  px-10 ">

                            <span class="text-xs xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-base self-center text-blue-600 text-center w-full">
                                Si desea modificar el precio de la cotización es necesario entrar a edicion de cotizacion de click
                                <a :href="'/app/nueva/cotizacion?select='+id" class="text-green-500 font-extrabold">Aquí</a> para ello </span>
                        </div>

                    </div>-->
                    <div class="w-full h-full  justify-between px-5 content-center flex flex-wrap">
                        <div v-if="tipo_view!='produccion_edit'&&tipo_view!='produccion'">Precio total de cotizacíon : <strong class="text-green-300  font-extrabold">${{ formatPrice(  Number(calcularTotalCotizacion()).toFixed(2) )}}</strong>

                        </div>
                        <button v-if="id==0" type="button" class="rounded px-4 py-1 text-black text-xs text-center bg-yellow-500 font-extrabold" @click="GuardarCotizacion()">Guardar cotización</button>
                        <button v-if="id>0&&tipo_view=='crear'" type="button" class="rounded px-4 py-1 text-black text-xs text-center bg-blue-500 font-extrabold" @click="EditarCotizacion('')">Actualizar cotización</button>
                        <button v-if="id>0&&tipo_view=='produccion'" type="button" class="rounded px-4 py-1 text-black text-xs text-center bg-blue-500 font-extrabold" @click="EditarCotizacion('PENDIENTE DE REVISIÓN')">Enviar a verificar</button>
                        <button v-if="id>0&&tipo_view=='produccion_edit'" type="button" class="rounded px-4 py-1 text-black text-xs text-center bg-blue-500 font-extrabold" @click="EditarCotizacion('EDITAR PRODUCCIÓN')">Actualizar formato de produccion</button>
                    </div>
                </div>

            </div>

        </div>

    </div>

</section>
</template>

<script>
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import categorias from "@/Services/categorias";
import cotizaciones from "@/Services/cotizaciones";
import productos from "@/Services/productos";
import productos_cotizaciones from "@/Services/productos_cotizaciones";
import parametros from "@/Services/parametros";
import generales from "@/functions/generales";
import SearchSelectCliente from "@/components/SearchSelectCliente";
import SearchSelectUsuario from "@/components/SearchSelectUsuario";
import {
    useInfoStore
} from '@/stores/info'
import usaurios from "@/Services/user";
import {
    conditionalExpression
} from "@babel/types";
import {
    forEach
} from "lodash";
usaurios
export default {
    components: {
        SearchSelectCliente,
        SearchSelectUsuario
    },
    mixins: [categorias, generales, productos, parametros, usaurios, cotizaciones],
    data() {
        const user = useUserStore()
        const info = useInfoStore()
        return {
            infositio: info.getAll(),
            open: false,
            filtro: 0,
            user: user.getAll(),
            data: [],
            categorias_data: [],
            tipos_productos: [{
                    id: 'catalogo',
                    nombre: 'Catálogo',
                    show: true
                },
                {
                    id: 'producto_venta',
                    nombre: 'Producto venta',
                    show: true
                }
            ],
            tipos_parametros: [{
                    id: 'numerico',
                    nombre: 'Numerico',
                    show: true
                },
                {
                    id: 'texto',
                    nombre: 'Texto',
                    show: true
                },
                {
                    id: 'catalogo',
                    nombre: 'Catálogo',
                    show: true
                }
            ],
            actionModal: null,
            showM: false,
            prod_sel: null,
            id: 0,
            usuarios_data: [],
            productos_data: [],
            productos_cotizacion: [],
            cliente_cotizacion: null,
            tot: 0,
            valor_peso:22,
            buscar: '',
            pagina: 1,
            limit: 10,
            cliente: null,
            clientes: [],
            cotizacion_edit: null,
            tipo_view: 'crear',
            garantia: 0,
            cot_garantia: '',
            configuracion_ver:0,
          
            descripcion_garantia: '',
            responsable_garantia: null

        }

    },
    async mounted() {
        emitter.emit("loading", true)
        this.getData();
        this.getDataCategorias();

        if (this.$route.query.hasOwnProperty('a')) {
            this.tipo_view = this.$route.query.a;
        }
        if (this.$route.query.hasOwnProperty('garantia_de')) {
            //alert('ss')
            this.garantia = 1
            this.cot_garantia = this.$route.query.garantia_de;
            this.getDataClientes();

        }

        if (this.$route.query.hasOwnProperty('select')) {
            this.id = this.$route.query.select;
            await this.getDataCotizacion();
            //  this.formatearCotizacionEditada();
        }
        this.getDataUsuarios();
        emitter.emit("loading", false)

    },
    created() {

    },
    methods: {
        isVisible(row) {
            if ((this.tipo_view == 'produccion' || this.tipo_view == 'produccion_edit')) {
                //cuando es produccion o formato produccion
                return row.visible_produccion;

            } else if ((this.tipo_view == 'crear')) {
                //cuando es cotizacion

                return row.visible_formato;
            }

            return true;

        },
        issetInFormula(element, formula) {
            return false;

            //esta funcion sirve para marcar los parametros que afectan el total de la cotizacion
            if ((this.tipo_view != 'produccion' && this.tipo_view != 'produccion_edit')) {
                return false
            }
            formula = JSON.parse(formula);
            let keys = [];
            formula.forEach(element => {
                if (String(element.tipo).toUpperCase() == 'PARAMETRO' || String(element.tipo).toUpperCase() == 'CATALOGO' ||
                    String(element.tipo).toUpperCase() == 'NUMERICO' ||
                    String(element.tipo).toUpperCase() == 'TEXTO') {
                    keys.push(element.valor)
                }
            });

            if (keys.includes(element.id)) {
                return true;
            } else {
                return false;
            }

        },
        issetInFormulaAgregar(element, formula) {

            //esta funcion sirve para marcar los parametros que afectan el total de la cotizacion

            formula = JSON.parse(formula);
            let keys = [];
            formula.forEach(element => {
                if (String(element.tipo).toUpperCase() == 'PARAMETRO' || String(element.tipo).toUpperCase() == 'CATALOGO' ||
                    String(element.tipo).toUpperCase() == 'NUMERICO' ||
                    (String(element.tipo).toUpperCase() == 'TEXTO' || String(element.tipo).toUpperCase() == 'TEXTO_EXTENDIDO')) {
                    keys.push(element.valor)
                }
            });

            if (keys.includes(element.id)) {
                return true;
            } else {
                return false;
            }

        },
        async getDataCotizacion() {
            this.showM = false;
            this.actionModal = null;

            let dat = {
                id: this.id
            }

            let r = await cotizaciones.SHOW(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.cotizacion_edit = response;

                    this.formatearCotizacionEditada();

                    //this.$router.push('/app/'); 
                } else {
                    console.log(response)
                    emitter.emit("alerta_show", response.error);

                }

                return;
            });
            return r;
        },
        async formatearCotizacionEditada() {
            this.productos_cotizacion = [];

            this.cliente = this.cotizacion_edit.clientes;
            this.descripcion_garantia = this.cotizacion_edit.detalle_garantia;
            this.responsable_garantia = this.cotizacion_edit.responsable;
            this.cot_garantia = this.cotizacion_edit.garantia;
            if (this.cot_garantia != '' && this.cot_garantia != null) {
                this.garantia = 1;
            }
            let prod_cotizacion = [];
            await this.cotizacion_edit.productos_cotizaciones.forEach(element => {

                let p = JSON.parse(JSON.stringify(element.productos));

                p.idprodcot = element.id;
                p.parametros = [];
                p.subproducto = element.subproducto;
                p.cantidad = element.cantidad;
                p.formula = element.formula;
                element.parametros.forEach(param => {
                    console.log(param, '-parap---- for---')
                    let parametro_add = {}
                    // parametro_add.parametros = JSON.parse(JSON.stringify(param));
                    //tipo_view=='produccion_edit'
                    if (this.tipo_view == 'produccion') {
                        parametro_add = param;
                        parametro_add.id = parametro_add.pm_id;
                        parametro_add.valor_prod = param.valor;
                        parametro_add.valor = param.valor;
                        parametro_add.valor_old = param.valor;
                        parametro_add.provedores_id = param.provedores_id;
                        parametro_add.selproductos = param.selproductos;
                    } else if (this.tipo_view == 'produccion_edit') {
                        parametro_add = param;
                        //cuando se edita se intercambian valores de produccion por cotizacion para no modificar 
                        //asi la funcion de formula 
                        parametro_add.id = parametro_add.pm_id;
                        parametro_add.valor_old = param.valor;
                        parametro_add.valor = param.valor_prod;
                        parametro_add.valor_prod = param.valor_prod;
                      
                        parametro_add.provedores_id = param.provedores_id;
                        parametro_add.selproductos = param.selproductos;

                    } else {
                        //cotizacion
                        /// parametro_add=param.parametros;

                        parametro_add = param;

                        parametro_add.id = parametro_add.pm_id;
                        parametro_add.valor_prod = param.valor_prod;
                        parametro_add.valor = param.valor;
                        parametro_add.provedores_id = param.provedores_id;
                        parametro_add.selproductos = param.selproductos;
                    }

                    p.parametros.push(parametro_add);
                });
                /*
                                let formula_formateada = this.CastFormula(p,element.formula);
                                let precio_producto = this.calcularPrecio(formula_formateada);

                */

                if (this.id != 0) {
                    p.formato_impreso = element.formato_impreso;
                    p.formato_aprobado = element.formato_aprobado;
                    p.produccion = element.produccion;
                    p.fecha_aprobado = element.fecha_aprobado;
                    p.instalados = element.instalados;
                    p.fecha_prometida = element.fecha_prometida;
                    p.fecha_asignacion = element.fecha_asignacion;

                    p.users_id = element.users_id;
                }

                // p.precio_prod = precio_producto;
                p.precio_prod = element.precio;
                p.precio = element.precio;
                prod_cotizacion.push(p)
                this.tot = this.tot + 1;

            });
            console.log(prod_cotizacion, '---------prod----')
            this.productos_cotizacion = prod_cotizacion;

        },
        async GuardarCotizacion() {

            emitter.emit("loading", true)
            if (this.cliente == null) {
                emitter.emit("alerta_show", 'Seleccione un cliente');
                emitter.emit("loading", false)
                return

            }

            let dat = {
                valor_peso:this.valor_peso,
                total: this.calcularTotalCotizacion(),
                subtotal: this.calcularTotalCotizacion(),
                tipo: this.tipo_view == 'produccion' ? 'enviar_formato' : this.tipo_view == 'produccion_edit' ? 'editar_prod' : 'cotizacion',
                //$el->catalogos_id = $request->input('catalogos_id');
                clientes_id: this.cliente.id,
                productos: this.productos_cotizacion,

            }

            if (this.garantia) {
                dat.garantia = this.cot_garantia;
                dat.detalle_garantia = this.descripcion_garantia;

                if (this.cot_garantia == null) {
                    emitter.emit("alerta_show", 'Es necesario ingresar numero de cotizacion');
                    emitter.emit("loading", false)
                    return
                }

                if (this.responsable_garantia == null) {
                    emitter.emit("alerta_show", 'Es necesario ingresar al responsable');
                    emitter.emit("loading", false)
                    return
                }
                dat.responsable_id = this.responsable_garantia.id;

            }

            let r = await cotizaciones.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    //this.getData();
                    //this.$router.push('/app/'); 
                    console.log(response)
                    emitter.emit("loading", false)
                   
                        this.$router.push('/app/cotizaciones?cid='+response.id); 
                    
                    
                    //this.limpiar();
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                //this.showM = false;
                //this.limpiar();
                emitter.emit("loading", false)
                return;

            });
            return r;
        },
        async EditarCotizacion(sverificar) {

            if (this.cliente == null) {
                emitter.emit("alerta_show", 'Seleccione un cliente');
                return

            }

            if (this.tipo_view == 'produccion_edit') {
                let alert_text = ''
                this.productos_cotizacion.forEach(producto => {
                    let parametros_faltan = this.ParametrosFaltantes(producto);
                    if (parametros_faltan > 0) {
                        alert_text += parametros_faltan+' parametros sin ingresar en '+producto.nombre +'<br>'
                    }

                });
                if (alert_text != '') {

                    emitter.emit("alerta_show", '<h1 class="font-extrabold text-red-500">Datos incompletos</h1><br>' + alert_text);
                    return
                }

            }
//alert('nuevo si viene old')
            console.log(this.productos_cotizacion,'.-------prodd-----')

            emitter.emit("loading", true)
            let dat = {
                id: this.id,
                valor_peso:this.valor_peso,
                total: this.calcularTotalCotizacion(),
                subtotal: this.calcularTotalCotizacion(),
                tipo: this.tipo_view == 'produccion' ? 'enviar_formato' : this.tipo_view == 'produccion_edit' ? 'editar_prod' : 'cotizacion',

                status_verificar: sverificar,
                //$el->catalogos_id = $request->input('catalogos_id');
                clientes_id: this.cliente.id,
                productos: this.productos_cotizacion,

            }
            if (this.garantia) {
                dat.garantia = this.cot_garantia;
                dat.detalle_garantia = this.descripcion_garantia;

                if (this.cot_garantia == null) {
                    emitter.emit("alerta_show", 'Es necesario ingresar numero de cotizacion');
                    emitter.emit("loading", false)
                    return
                }

                if (this.responsable_garantia == null) {
                    emitter.emit("alerta_show", 'Es necesario ingresar al responsable');
                    emitter.emit("loading", false)
                    return
                }
                dat.responsable_id = this.responsable_garantia.id;

            }

            let r = await cotizaciones.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
//return;
                    //this.getData();
                    switch (this.tipo_view) {
                        case 'produccion':
                       
                        //this.$router.push('/app/detalle/ventas?cid='+response.id);
                        this.$router.push('/app/detalle/aprobar?cid='+response.id); 
                       
                            break;
                            case 'produccion_edit':
                        
                        this.$router.push('/app/detalle/aprobar?cid='+response.id); 
                            break;
                           
                    
                        default:
                        this.$router.push('/app/clientes?r=' + this.cliente.id);
                            break;
                    }

                   // this.$router.push('/app/clientes?r=' + this.cliente.id);
                    // this.limpiar();
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                //this.showM = false;
                //this.limpiar();
                emitter.emit("loading", false)

            });

        },
        action_cliente(ev) {

      
                this.cliente = ev.value;
            
           

        },
        action_responsable(ev) {
            this.responsable_garantia = ev.value;
        },

        ActualizarProducto() {

            this.AgregaraCotizacion(this.prod_sel.indice)
        },
        async separarProducto(idcot, id) {

            let dat = {
                id: id,
                idcot: idcot,

            }
            emitter.emit("loading", true)
            await productos_cotizaciones.SEPARAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getDataCotizacion();

                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                emitter.emit("loading", false)
            });

        },
        selEditProducto(row, indice) {

            let valsel = JSON.parse(JSON.stringify(row))
            console.log(valsel)

            this.filtro = valsel.categorias_id;
            this.prod_sel = valsel;
            this.prod_sel.indice = indice

        },
        eliminarIndice(indice) {
            this.productos_cotizacion = this.productos_cotizacion.filter((value, index) => indice !== index);

        },
        calcularPrecio(formula) {

            console.log(String(formula).toString(), '----formullllllll-------------aa---')
            let res2 = Function('return ' + String(formula))
            //console.log(formula, '----fr-----')
            let pr = res2()
            //console.log(pr, '----pr-----')
            return pr;
        },
        calcularTotalCotizacion() {
            let total = 0;
            this.productos_cotizacion.forEach(element => {
                total = Number(total) + (element.precio_prod * element.cantidad);
            });
            return Number(total).toFixed(0);

        },
        buscarGet(busqueda) {
            this.buscar = busqueda;
            this.getDataClientes();

        },
        async getDataUsuarios() {

            emitter.emit("loading", true)

            await usaurios.GET(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.usuarios_data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async getDataClientes() {

            let dat = {
                search: this.$route.query.hasOwnProperty('clid') ? this.$route.query.clid : this.buscar,
                page: this.pagina,
                limit: this.limit,
                data: {}
            }

            await usaurios.GET_CLIENTES(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.clientes = response;
                    if (this.$route.query.hasOwnProperty('clid')) {

                        response.forEach(element => {
                            if (element.id == this.$route.query.clid) {
                                this.cliente = element;
                                console.log('siiii')
                                console.log(this.cliente)
                            }

                        });

                    }

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }

            });
        },
        getValueProperty(row,formula) {
        let val = '';
        //console.log(JSON.parse(row.formula), '----formula.-----')
        JSON.parse(formula).forEach(element => {

            if (element.tipo == 'key') {
                val = val + element.valor;
            } else if (element.tipo == 'catalogo') {
                let param = row.parametros.find(p => p.id == Number(element.valor));
                let valor_param_prod = param.selproductos.productos.find(function (el) {
                                    return el.id == (param.valor);
                                });
                                if (valor_param_prod != undefined && valor_param_prod.hasOwnProperty('valor_formula')) {
                                    val = val + valor_param_prod.valor_formula;
                                } else {
                                    val =val + 0;
                                }
            } 

        });
        // console.log(val,'----formula 2---')
        return val;
    },
        CastFormula(row, formula) {
            let val = '';
           // console.log(row.parametros, '---vall row---')
            console.log(formula, '---formula cast---')
            //console.log(JSON.parse(row.formula), '----formula.-----')
            JSON.parse(formula).forEach(element => {

                if (element.tipo == 'key') {
                    val = val + element.valor;
                } else if (element.tipo == 'catalogo' || element.tipo == 'parametro') {

                    //let param = row.parametros.find(p => {(p.id) == Number(element.valor)});
                    let param = row.parametros.find(function (el) {
                        return el.id == Number(element.valor);
                    });

                    if (param == null) {
                        val = val + 'Number(' + Number(0) + ')';
                        //no esta en la formula de ese producto
                        console.log(element, 'no esta en los parametros de este producto pero si aparece en la formula')

                    } else {

                        switch (param.tipo) {
                           
                              

                           
                            case 'catalogo':
                                let valor_param_prod = param.selproductos.productos.find(function (el) {
                                    return el.id == (param.valor);
                                });
                                if (valor_param_prod != undefined && valor_param_prod.hasOwnProperty('precio')) {
                                    val = val + valor_param_prod.precio;
                                } else {
                                    val =val + 0;
                                }

                                break;
                            case 'parametro':
                                val = val + 'Number(' + Number(param.valor) + ')';
                                break;

                            default:
                                val = val + 'Number(' + Number(param.valor) + ')';
                                break;
                        }

                        //si esta en la formula de ese producto

                    }
                    //let valor_param_prod = param.selproductos.productos.find(p => {(p.id) == Number(param.valor)})

                }else if(element.tipo == 'catalogo_valor_formula'){
                    let param = row.parametros.find(function (el) {
                        return el.id == Number(element.valor);
                    });
                    let valor_param_p = param.selproductos.productos.find(function (el) {
                                    return el.id == (param.valor);
                                });
                                if (valor_param_p != undefined && valor_param_p.hasOwnProperty('valor_formula')) {
                                    val = val + valor_param_p.valor_formula;
                                } else {
                                    val =val + 0;
                                }

                }
                else if(element.tipo == 'numerico'){
                    let param = row.parametros.find(function (el) {
                        return el.id == Number(element.valor);
                    });
                    val = val + 'Number(' + Number(param.valor) + ')';
                    //val = val + element.valor;

                }
                else if(element.tipo == 'value_formula'){
                    
                    val = val + element.valor;

                }
                else if(element.tipo == 'calc_formula'){
                    let param = row.parametros.find(function (el) {
                        return el.id == Number(element.valor);
                    });
                    val = val + this.CastFormula(row, param.formula_mp);

                }

            });
            // console.log(val,'----formula 2---')
            console.log(val, 'formu finla-----')
            return val;
        },
        CastFormulaold(row, formula) {
            let val = '';
            console.log(row.parametros, '---vall row---')
            console.log(formula, '---formula---')
            //console.log(JSON.parse(row.formula), '----formula.-----')
            JSON.parse(formula).forEach(element => {

                if (element.tipo == 'key') {
                    val = val + element.valor;
                } else if (element.tipo == 'catalogo') {

                    //let param = row.parametros.find(p => {(p.id) == Number(element.valor)});
                    let param = row.parametros.find(function (el) {
                        return el.id == Number(element.valor);
                    });
                    if (param == null) {
                        val = val + 'Number(' + 0 + ')';
                        //no esta en la formula de ese producto
                        console.log(element, 'no esta en los parametros de este producto pero si aparece en la formula')

                    } else {

                        //si esta en la formula de ese producto

                        let valor_param_prod = param.selproductos.productos.find(function (el) {
                            return el.id == (param.valor);
                        });
                        if (valor_param_prod != undefined && valor_param_prod.hasOwnProperty('precio')) {
                            val = val + valor_param_prod.precio;
                        } else {
                            val = val + 0;
                        }
                    }
                    //let valor_param_prod = param.selproductos.productos.find(p => {(p.id) == Number(param.valor)})

                } else if (element.tipo == 'parametro') {

                    //let param = row.parametros.find(p => Number(p.id) == Number(element.valor));
                    let param = row.parametros.find(function (el) {
                        return el.id == Number(element.valor);
                    });
                    if (param == null) {
                        console.log(element, '---parametro no encontrado---')
                        val = val + 'Number(' + 0 + ')';

                    } else {
                        val = val + 'Number(' + param.valor + ')';
                    }

                    //console.log(param, '---p---');
                    //if (String(param.nombre).toUpperCase() == 'METROS LARGO' || String(param.nombre).toUpperCase() == 'METROS ANCHO') {
                    //      val = val + (param.valor < 1 ? 'Number(' + 1 + ')' : 'Number(' + param.valor + ')');

                    //  } else {

                    //  }
                    //val = val + param.valor<1?1:param.valor;
                }

            });
            // console.log(val,'----formula 2---')
            console.log(val, 'formula-----')
            return val;
        },
        formatval(row) {
            console.log(row, '----seleccionado')
            row.cantidad = 1;
            row.parametros.forEach(element => {
                if (element.tipo == 'catalogo') {
                    element.valor = 0;
                } else {
                    element.valor = null;
                }

            });
            this.prod_sel = row;
            console.log(this.productos_cotizacion, '----productos new---')
        },

        async getDataCategorias() {
            this.showM = false;
            this.actionModal = null;

            await categorias.GET(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.categorias_data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }

            });
        },
        limpiar() {
            this.$router.replace({
                name: 'nueva_cotizacion',
                force: true
            });
            this.id = 0;
            this.productos_cotizacion = [];
            this.cliente = null;
            this.tot = 0;
            this.prod_sel = null;
            this.tipo_view = 'crear'
            this.filtro = 0;
        },
        async getData() {
            this.showM = false;
            this.actionModal = null;

            await productos.GET_NOT_OPCION(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }

            });
        },
        calcularParametroValor(row,formula){
console.log(formula,'----')
        let formula_formateada = this.CastFormula(row,formula)
        console.log('-------------------parametro value-----',formula_formateada)
            let total = this.calcularPrecio(formula_formateada);
            console.log(total,'-cac---');
            return total;
    },
        calcularParametro(row, formula) {
            console.log(formula, '----')
            let formula_formateada = this.CastFormula(row, formula)

            let total = this.calcularPrecio(formula_formateada);
            console.log(total, '-cac---');
            return total;
        },

        ParametrosFaltantes(producto) {
            let parametros_faltan = 0;
            producto.parametros.forEach(element => {

                if (this.tipo_view == 'produccion' || this.tipo_view == 'produccion_edit') {

                    if (element.visible_produccion && element.tipo != 'calc_formula' && element.tipo != 'value_formula') {

                        if (element.visible_produccion && element.tipo == 'catalogo') {

                        if (element.valor == '0' || element.valor == 0) {
                            parametros_faltan++;
                        }
                        }else{
                            if (element.valor == '' || element.valor == null) {
                            parametros_faltan++;
                        }
                        }
                        
                    }
                    

                } else {
                    if (!this.issetInFormulaAgregar(element, producto.formula)) {
                        //si el parametro no esta en la formula
                    } else {
                        //si el parametro si esta en la formula
                        if ( element.tipo != 'calc_formula' && element.tipo != 'value_formula') {
                            if (element.valor == '' || element.valor == null) {
                                parametros_faltan++;
                            }
                        }
                    }
                }

            });
            return parametros_faltan;
        },

        async AgregaraCotizacion(indice) {
            let vacios = 0;
            //console.log(this.prod_sel.parametros, '----parametros----')

            vacios = this.ParametrosFaltantes(this.prod_sel)
            /* console.log(vacios,'----vacios------')
             return;*/
            // console.log(this.prod_sel, '---detalle_prod')
            if (vacios > 0) {
                emitter.emit("alerta_show", 'Debe de llenar los campos obligatorios');
            } else {
                //let p=this.prod_sel;
                console.log(this.prod_sel, '----')
                let formula_formateada = this.CastFormula(this.prod_sel, this.prod_sel.formula)
                console.log(formula_formateada,'---formula formateads----')
                let precio_producto = this.calcularPrecio(formula_formateada);

                this.prod_sel.precio_prod = precio_producto;

                let p = JSON.parse(JSON.stringify(this.prod_sel));
                p.formato_impreso = 0;
                p.fecha_asignacion = null;
                p.formato_aprobado = 0;
                p.users_id = null;

                if (indice != null) {
                    this.productos_cotizacion[indice] = p;
                } else {
                    this.productos_cotizacion.push(p);
                }
                this.prod_sel = null;
                this.tot = this.tot + 1;

                console.log(this.productos_cotizacion, '-produ-coti')
            }
        }

    }
}
</script>
