<template>
<div class="flex h-full w-full bg-gray-100">
    <div class="w-full flex flex-wrap justify-center content-center h-full">
        <button type="button" :class="styles['Color de fondo']+' '+styles['Color de hover']+' '+styles['Color de texto']+' '
        +' '+styles['Radio de borde']+' '+styles['Tamaño de texto']+' '+styles['Sombra']+' '
        +styles['Color de borde']+' '+styles['Tamaño de borde']+' '">
           
            <span :class="styles['Espaciado de boton']">
                Texto de boton
            </span>
        </button>

    </div>

</div>
</template>

  
  
<script>
/*
  'Color de fondo': 'bg-blue-500',
          'Color de hover': 'bg-blue-500',
          'Color de texto': 'text-white',
          'Tamaño de borde': 'border-2',
          'Radio de borde': 'rounded-md',
          'Tamaño de texto': 'text-base',
          'Sombra': 'shadow-none',
*/

export default {
    name: 'ButtonConfigurator',
    components: {

    },
    props: {

        styles: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            selectedProperties: this.initialSelectedProperties,
            properties: this.initialProperties,
        };
    },
    methods: {
        saveProperties() {
            this.$emit('update-properties', this.selectedProperties);
        },
    },
};
</script>
