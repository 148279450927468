

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
 // GET_CLIENTES CREAR_CLIENTES EDITAR_CLIENTES ELIMINAR_CLIENTES

 GET_DATA: async function( data){
      let url='/graficas/'+data.url
      return initmixin.formatrespuesta('get', data,url,true)
    },


    
    
   


}