

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
 // GET_CLIENTES CREAR_CLIENTES EDITAR_CLIENTES ELIMINAR_CLIENTES
 INFO: async function( data){
  let url='/citas/info/'+data.id
      return initmixin.formatrespuesta('get', data,url,true)
    },
 GET: async function( data){
  let url='/citas/ano/'+data.ano+'/mes/'+data.mes+'?cliente='+data.cliente
      return initmixin.formatrespuesta('get', data,url,true)
    },
    GET_INSTALADORES: async function( data){
      let url='/citas/instalacion/ano/'+data.ano+'/mes/'+data.mes+'?cliente='+data.cliente
          return initmixin.formatrespuesta('get', data,url,true)
        },
    

        

        REGISTRAR_FIRMA: async function( data) {
          let url='/citas/firma/'+data.id
          return initmixin.formatrespuesta('post', data.data,url,true,true)
        },
       
    CREAR: async function( data) {
      let url='/citas/'
      return initmixin.formatrespuesta('post', data,url,true)
    },

    
    EDITAR_FECHA: async function( data) {
      let url='/citas/fecha/'+data.id
      return initmixin.formatrespuesta('put', data,url,true)
    },
    EDITAR: async function( data) {
      let url='/citas/'+data.id
      return initmixin.formatrespuesta('put', data,url,true)
    },
    EDITAR_STATUS: async function( data) {
      let url='/citas/status/'+data.id
      return initmixin.formatrespuesta('put', data,url,true)
    },
    EDITAR_GARANTIA: async function( data) {
      let url='/citas/garantia/'+data.id
      return initmixin.formatrespuesta('put', data,url,true)
    },
    
    ELIMINAR: async function( data) {
      let url='/citas/'+data.id
      return initmixin.formatrespuesta('delete', data,url,true)
    },
   


}