

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
 // GET_CLIENTES CREAR_CLIENTES EDITAR_CLIENTES ELIMINAR_CLIENTES

 GET: async function( data){
      let url='/productos/'
      return initmixin.formatrespuesta('get', data,url,true)
    },

    GET_NOT_OPCION: async function( data){
      let url='/productos/nopcion/'
      return initmixin.formatrespuesta('get', data,url,true)
    },
    CATALOGO: async function( data){
      let url='/productos/catalogo/'
      return initmixin.formatrespuesta('get', data,url,true)
    },
    VENTA: async function( data){
      let url='/productos/venta/'
      return initmixin.formatrespuesta('get', data,url,true)
    },
 OPCION: async function( data){
  let url='/productos/opcion/'
  return initmixin.formatrespuesta('get', data,url,true)
},


IMPORT_MASIVO: async function( data) {
  let url='/productos/catalogo/'+data.id+'/importmasivo/'
  return initmixin.formatrespuesta('post', data,url,true)
},
    CREAR: async function( data) {
      let url='/productos/'
      return initmixin.formatrespuesta('post', data.data,url,true,true)
    },
    EDITAR: async function( data) {
      let url='/productos/'+data.id
      return initmixin.formatrespuesta('post', data.data,url,true,true)
    },
    ELIMINAR: async function( data) {
      let url='/productos/'+data.id
      return initmixin.formatrespuesta('delete', data,url,true)
    },
   


}