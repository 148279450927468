

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
 // GET_CLIENTES CREAR_CLIENTES EDITAR_CLIENTES ELIMINAR_CLIENTES
 GET: async function( data){
  let url='/abonos/'
  return initmixin.formatrespuesta('get', data,url,true)
},
CREAR: async function( data) {
  let url='/abonos/'
  return initmixin.formatrespuesta('post', data,url,true)
},
BOTTON: async function( data) {
  let url='/abonos/mp/'
  return initmixin.formatrespuesta('post', data,url,true)
},
EDITAR: async function( data) {
  let url='/abonos/'+data.id
  return initmixin.formatrespuesta('put', data,url,true)
},
ELIMINAR: async function( data) {
  let url='/abonos/'+data.id
  return initmixin.formatrespuesta('delete', data,url,true)
},

}