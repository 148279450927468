<template>
    <div class="w-full h-5/6 flex flex-wrap justify-center content-start overflow-y-scroll">
    
        <!--<div class="w-full flex flex-wrap font-bold text-blue-500 fntrel  border-black">
                            <div class="w-2/12 border border-black">Producto</div>
                            <div class="w-1/12 border border-black">Tipo de Producto</div>
                            <div :class="cotizacion.avance<100?'w-5/12':' w-full '" class=" border border-black ">Detalle</div>
                            <div class="w-1/12 border  border-black"> Producción total</div>
                            <div class="w-1/12 border  border-black"> Producción generada</div>
                            <div v-if="cotizacion.avance<100" class="w-2/12 border border-r border-black"> Actualización </div>
                        </div>-->
                       
                        <div class="w-full flex flex-wrap h-14 content-center justify-start">
                            <span  @click="onCapture('',false,.3,0,{},false)" class="bg-gray-400 align-middle flex h-10 w-10 rounded-full  text-center  flex-wrap justify-center content-center ">
                                <i class="cursor-pointer text-3xl las la-print text-black h"></i>
                            </span>
                        </div>
        <div class="zoom2 w-full overflow-x-scroll   border-black my-10 content-start justify-start flex-wrap" >
    
            <div class="w-full h-full flex flex-wrap justify-center content-start   overflow-y-scroll" id="capture">
    
                <!--<div class="w-full flex flex-wrap font-bold text-blue-500 fntrel  border-black">
                                            <div class="w-2/12 border border-black">Producto</div>
                                            <div class="w-1/12 border border-black">Tipo de Producto</div>
                                            <div :class="cotizacion.avance<100?'w-5/12':' w-full '" class=" border border-black ">Detalle</div>
                                            <div class="w-1/12 border  border-black"> Producción total</div>
                                            <div class="w-1/12 border  border-black"> Producción generada</div>
                                            <div v-if="cotizacion.avance<100" class="w-2/12 border border-r border-black"> Actualización </div>
                                        </div>-->
    
                <div class="w-full    border-black  content-start justify-start flex flex-wrap ">
    
                    <div class="w-full  flex flex-wrap " v-for="(r,indice) in cotizacion.produtos_instalados_citas" v-bind:key="indice">
    
                        <div class="w-full  flex flex-wrap " >
                            <div class="w-full flex flex-nowrap bg-blue-100">
                         
                            <div class="w-1/12  align-middle border-black border-l">
                                # Producto
                            </div>
                            <div class="w-1/12  align-middle border-black border-l">
                                Cantidad
                            </div>
                            
    
                            <div class="w-3/12  align-middle border-black border-l">
                                Producto
                            </div>
                           
                            <div class="w-7/12 align-middle">
                               
                            </div>
                        </div>
                        <div class="w-full"></div>
                        <div class="w-full  flex flex-wrap text-xl">
                       
                            <div class="w-full flex flex-nowrap ">
                           
                           
                         
                            <div class="border border-black text-center align-middle w-1/12 font-extrabold text-blue-500 bg-blue-100 underline">
                                {{r.id}}
                            </div>
    
                         
                            <div class="border border-black text-center align-middle w-1/12 font-extrabold text-green-500 bg-green-100 underline  text-3xl">
                                {{Number(r.cantidad).toFixed(0)}}
                            </div>
                           
                           
                           
    
                            <div class="w-3/12 border border-black align-middle text-center">
                                {{r.productos.productos.nombre}}
                            </div>

                            <div class="w-7/12 border border-black align-middle text-center">
                                
                                
                                <template v-for="(p,i) in r.productos.parametros" v-bind:key="i">
                                    <div class="w-full flex flex-wrap border border-black align-middle text-center text-xl" v-if="p.visible_instaladores">
                                        <div class="w-6/12 border border-black align-middle text-center text-xl">
                                          
                                                <div class="w-full align-middle text-md" v-if="p.visible_instaladores">
                                                    <strong v-if="p.tipo=='calc_formula'"> {{p.nombre}}  </strong>
                                                    <strong v-if="p.tipo=='value_formula'"> {{p.nombre}}  </strong>
                                                    <strong v-if="p.tipo=='texto_extendido'"> {{p.nombre}}  </strong>
                                                     
                                                   
                                                    <strong v-if="p.tipo=='numerico'">{{p.nombre}} </strong>
                                                    <strong v-if="p.tipo=='texto'">{{p.nombre}} </strong>
                                                    <strong v-if="p.tipo=='catalogo'">{{p.selproductos.nombre}} </strong>
                
                                                </div>
                                           
                                        </div>
                                        <div class="w-6/12 border border-black align-middle text-center text-xl">
                                            <template v-if="p.tipo=='numerico'"> {{p.valor_prod}} </template>
                                            <template v-if="p.tipo=='calc_formula'">    {{' '}} {{calcularParametroProd(r,p.formula_mp)}} {{' '}}</template>
                                            <template v-if="p.tipo=='value_formula'">    {{' '}} {{calcularParametroProd(r,p.formula_mp)}} {{' '}}</template>
                                         
                                         
                                            <template v-if="p.tipo=='texto'">{{p.valor_prod}} </template>
                                            <template v-if="p.tipo=='texto_extendido'">{{p.valor_prod}} </template>
                                            <template v-if="p.tipo=='catalogo'"> ( {{p.valor_prod==0?'N/A':p.producto_lista_prod.nombre}} ) </template>
                                           
                                        </div>


                                       <!-- <template v-if="p.tipo=='catalogo'"> ( {{p.val_prod_productos_id==0?'N/A':p.producto_lista.nombre}} ) </template>-->
                                      
                                   
                                   
    
                                       
                                   
                                    </div>
                                </template>

                              </div>
                            </div>

                           <!--<template v-for="(p,i) in r.productos.parametros" v-bind:key="i">
                                <div v-if="p.visible_instaladores" class="w-48 border border-black align-middle text-center">
                                  

                                    <template v-if="p.tipo=='numerico'"> {{p.valor_prod}} </template>
                                    <template v-if="p.tipo=='calc_formula'">    {{' '}} {{calcularParametro(r,p.formula_mp)}} {{' '}}</template>
                                 
                                 
                                    <template v-if="p.tipo=='texto'">{{p.valor_prod}} </template>
                                    <template v-if="p.tipo=='texto_extendido'">{{p.valor_prod}} </template>
                                    <template v-if="p.tipo=='catalogo'"> ( {{p.valor_prod==0?'N/A':p.producto_lista_prod.nombre}} ) </template>
                                   
                               
                                </div>
                            </template>-->
    
                        </div>
    
                    </div>
                    </div>
    
                </div>
            </div>
    
        </div>
    
    </div>
    </template>
    
    <script>
    //@ts-ignore
    import {
        emitter
    } from "@/mitt.js";
    import generales from '@/functions/generales'
    import cotizaciones_f from '@/functions/cotizaciones'
    
    import productos_cotizaciones from "@/Services/productos_cotizaciones";
    import cotizaciones from "@/Services/cotizaciones";
    import pdf from "@/Services/pdf";
    import {
        throwStatement
    } from '@babel/types';
    //import palabras from "@/mixins/palabras";
    import {
        useInfoStore
    } from '../stores/info'
    export default {
        name: "Home",
        components: {
    
        },
        mixins: [generales,cotizaciones_f],
        props: {
    
            cotizacion: {
                type: Array,
            },
            edit: {
                type: Boolean,
            },
    
        },
        data() {
            const info = useInfoStore()
            return {
                infositio: info.getAll(),
                categorias: {}
    
            }
        },
    
        mounted() {
    
            console.log(this.cotizaciones)
    
        },
    
        methods: {
    
          
            calcCantidadAgendada(agendados) {
    
                let cantidad = 0;
                agendados.forEach(element => {
                    cantidad = cantidad + element.cantidad
                });
                return cantidad
            }
    
        }
    }
    </script>
    
    <style>
   
    </style>
    