<template>
  <div class=" w-full">
    <div class=" relative  w-full">
      <input
        id="search"
        v-model="search"
        type="text"
        :placeholder="'Buscar '+label+'...'"
        class="p-2  w-full border border-gray-300 rounded"
      >

      <ul
        v-if="searchData.length"
        class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-40"
      >
        <li class="px-1 pt-1 pb-2 font-bold border-b border-gray-200">
          Mostrando {{ searchData.length }} de {{ rows.length }} elementos
        </li>
        <li
          v-for="sel in searchData"
          :key="sel.nombre"
          class="cursor-pointer hover:bg-gray-100 p-1"
          @click="selectRow(sel)"
        >
          <div class=" w-full flex  flex-wrap justify-start overflow-x-hidden">
            <span class="text-xs font-extrabold text-blue-900 w-full"> {{ sel.nombre }}</span>
            <span
              v-for="(prod_mod,index_pm) in sel.productos_modificadores"
              :key="index_pm"
              class="bg-blue-500 px-2 mx-2 h-4 mb-1 rounded font-extrabold text-white flex w-auto text-xs flex-wrap"
            >
              <!--{{prod_mod.modificadores.modificador.nombre}} :--> {{ prod_mod.modificadores.nombre }}
            </span>
          </div>
        </li>
      </ul>

      <div
        v-if="seleccionado"
        class="font-extrabold pt-1 text-start w-full justify-start flex text-xs px-2"
      >
        {{ CapitalizeTo(label) }} seleccionado <span class="font-semibold text-blue-500 text-start text-xs"> : {{ seleccionado.nombre }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import generales from '@/functions/generales'
import {
    ref,
    computed,
    defineEmits
} from 'vue'
 //import { defineProps, useContext } from 'vue'
   
export default {
    mixins:[generales],
    props: {
       
        rows: {
            type: Array,

        },
        label: {
            type: String,
            default:''

        },
        valor:{
            type:Object
        }
    },
    //emits:'seleccionado',
    setup(props,{ emit }) {
      //let emit  = defineEmits(['seleccionado'])
        let search = ref('')
        const searchData = computed(() => {
            if (search.value === '') {
                return []
            }
            let matches = 0
            console.log(props.rows)
            return props.rows.filter(country => {
                if (String(country.nombre).toLowerCase().includes(search.value.toLowerCase()) && matches < 10) {
                    matches++
                    return country
                }
            })
        });
        const selectRow = (country) => {
            seleccionado.value = country
            search.value = ''
             emit("seleccionado", seleccionado)
        }
        let seleccionado = ref(props.valor)
        return {
           emit,
            search,
            searchData,
            selectRow,
            seleccionado
        }
    },
    mounted(){
console.log(this.rows)
    }
}
</script>
