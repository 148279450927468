<template lang="es">
<section class=" w-full mx-auto h-full px-4 sm:px-6 lg:px-4 py-12 bg-yellow-600 bg-opacity-10 overflow-y-scroll ">

    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-3 ">

        <div class="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">

            <h1 class="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900">
                Estadisticas del dia

            </h1>
            <h1 class="font-bold mt-2  font-heading text-gray-900 w-full flex flex-wrap justify-center content-center">
                <month-picker-input class="self-center flex flex-wrap  rounded " v-model="mes" lang="es" :default-month="mes.monthIndex" :default-year="mes.selectedYear" @change="recargar" />
            </h1>
        </div>
        <div class="w-full bg-white rounded-lg p-12 flex flex-col justify-start items-center">
            <div class="mb-8">
                <apexchart class="w-full" type="bar" :options="chartOptions" :series="series"></apexchart>
            </div>
            <div class="text-center">
                <p class="text-xl text-gray-700 font-bold mb-2">
                    Detalle venta
                </p>
                <p class="text-base text-gray-400 font-normal">
                    Desgloce por metodo de pago
                </p>
            </div>
        </div>
        <div class="w-full bg-white  rounded-lg p-12 flex flex-col justify-center items-center">
            <div class="mb-8 h-64 bg-green-100 w-full">
                <div class="overflow-x-auto bg-white shadow overflow-y-auto  h-full w-full">
                    <table class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                        <thead class="bg-gray-50 ">
                            <tr>

                                <th class="bg-gray-100 h-11 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs z-30">Nombre</th>
                                <th class="bg-gray-100 h-11 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs z-30">Cantidad</th>

                            </tr>
                        </thead>
                        <tbody>

                            <tr v-for="(row,index) in productos_ventas" v-bind:key="index" v-show="row.hasOwnProperty('sum_ventas')&& row.sum_ventas.length>0">
                                <td class="border-dashed border-t py-3 px-3 border-l border-gray-200 userId text-xs font-bold">

                                    {{row.nombre}}
                                </td>
                                <td v-if="row.sum_ventas.length>0" class="border-dashed border-t py-3 px-3 text-xs font-bold border-l border-gray-200 userId">
                                    <span class="px-2 w-8 h-8 py-2 bg-yellow-500 rounded-full  font-bold">{{row.sum_ventas[0].total}}</span>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            <div class="text-center">
                <p class="text-xl text-gray-700 font-bold mb-2">
                    Total Productos vendidos <strong class="text-blue-500">{{calcTotalProductos()}}</strong>
                </p>
                <p class="text-base text-gray-400 font-normal">
                    Detalle de productos vendidos
                </p>
            </div>
        </div>
        <div class="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
            <div class="mb-8 h-64 bg-green-100 w-full">
            <div class="overflow-x-auto bg-white shadow overflow-y-auto  h-full w-full">
                <table class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                    <thead class="bg-gray-50 ">
                        <tr>

                            <th class="bg-gray-100 h-11 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs z-30">Nombre</th>
                            <th class="bg-gray-100 h-11 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs z-30">Total</th>
                            <th class="bg-gray-100 h-11 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs z-30">Cantidad de usos</th>

                        </tr>
                    </thead>
                    <tbody>

                        <tr v-for="(row,index) in promociones_ventas" v-bind:key="index" v-show="row.hasOwnProperty('ventas')&& row.ventas.length>0">
                            <td class="border-dashed border-t py-3 px-3 border-l border-gray-200 userId text-xs font-bold">

                                {{row.nombre}}
                            </td>
                            <td v-if="row.ventas.length>0" class="border-dashed border-t py-3 px-3 text-xs font-bold border-l border-gray-200 userId">
                                <span class="px-2 w-8 h-8 py-2 bg-yellow-500 rounded-full  font-bold">{{row.ventas[0].total}}</span>
                            </td>
                            <td v-if="row.ventas.length>0" class="border-dashed border-t py-3 px-3 text-xs font-bold border-l border-gray-200 userId">
                                <span class="px-2 w-8 h-8 py-2 bg-yellow-500 rounded-full  font-bold">{{row.ventas[0].cantidad_usada}}</span>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
  
    <p class="text-xl text-gray-700 font-bold mb-2">
        Total Promociones aplicadas <strong class="text-blue-500">{{calcTotalPromociones()}}</strong>
    </p>
    
    </div>
    

    </div>

</section>
</template>

<script>
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import tablero from "@/Services/tablero";
import {
    MonthPickerInput
} from 'vue-month-picker'
export default {
    components: {
        MonthPickerInput
    },
    mixins: [tablero],
    data() {
        const user = useUserStore()

        return {
            productos_ventas: [],
            promociones_ventas: [],
            series: [{
                data: []
            }],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: ['Total vendido', 'Total en efectivo', 'Total tarjeta credito', 'Total tarjeta de debito', ],
                }
            },

            mes: {

                "month": this.getmes(),
                monthIndex: new Date().getMonth() + 1,
                year: 2022,
                selectedYear: 2022,
            },

        }

    },
    mounted() {

    },
    created() {
        this.getDataTablero()
    },
    methods: {
        calcTotalPromociones() {
            let total = 0;
            this.promociones_ventas.forEach(element => {
                if (element.ventas.length > 0) {
                    total = Number(total) + Number(element.ventas[0].cantidad_usada);
                }

            });
            return total;
        },
        calcTotalProductos() {
            let total = 0;
            this.productos_ventas.forEach(element => {
                if (element.sum_ventas.length > 0) {
                    total = Number(total) + Number(element.sum_ventas[0].total);
                }

            });
            return total;
        },
        recargar($ev) {
            console.log($ev)
            this.mes = $ev;
            this.getDataTablero();
        },
        getmes() {
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            const d = new Date();
            return monthNames[d.getMonth()];
        },
        async getDataTablero() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)
            let dat = {
                mes: this.mes.monthIndex,
                ano: this.mes.year,
            }

            await tablero.GET(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    //this.data = response
                    this.series[0].data = [];
                    this.series[0].data.push(response.ventas.total)
                    this.series[0].data.push(response.ventas.efectivo)
                    this.series[0].data.push(response.ventas.tc)
                    this.series[0].data.push(response.ventas.td)
                    this.productos_ventas = response.ventas_productos;
                    this.promociones_ventas = response.ventas_promociones;
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
