<template>
  <section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-center content-center ">
    <Tabla
      :columns="headings"
      :rows="data"
      :filtros="['nombre']"
      :coloresparam="colores"
      :add="false"
    />

      
  

    
    <div
      v-if="actionModal!=null"
      style="z-index:9999900"
      class="bg-slate-800 bg-opacity-50  h-screen w-full flex content-center justify-center items-center fixed  top-0 right-0 bottom-0 left-0"
    >
      <div class=" bg-white w-72 h-72 self-center  flex-wrap px-5 py-14 rounded-md text-start flex content-center justify-center items-center">
        <div class="text-base mb-4 font-bold text-slate-500">
          ¿Qué desea hacer?
        </div>
        <div class="flex-wrap flex justify-between  w-full">
          <div class="w-3/6 px-2">
            <button
              class="w-full bg-blue-500 bg-opacity-20 px-4 py-2 rounded-md text-md text-blue-500 border-2 border-blue-500"
              @click="edit(actionModal);"
            >
              Editar
            </button>
          </div>
          <div class="w-3/6 px-2">
            <button
              class="bg-red-500 px-4 py-2 bg-opacity-20 rounded-md text-md text-red-500 w-full  border-2 border-red-500"
              @click="eliminar(actionModal.id)"
            >
              Eliminar
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import provedores from "@/Services/provedores";
import productos from "@/Services/productos";
import Tabla from "@/components/Tabla.vue";
export default {
    components: {
Tabla,

    },
    mixins: [provedores,productos],
    data() {
        const user = useUserStore()

        return {
            colores:[
                
                {precio:800,color:'bg-green-500'},
                 {precio:600,color:'bg-yellow-300'},
                  {precio:500,color:'bg-yellow-500'},
                   {precio:300,color:'bg-yellow-600'},
                    {precio:100,color:'bg-red-500'},
                   
                   
                   
                    
                
            ],
            headings: [
               
                {
                    key: 'id',
                    name: '#',
                    show: true
                },{
                    key: 'nombre',
                    name: 'Nombre',
                    show: true
                },
                {
                    key: 'variantes',
                    name: 'Variantes',
                    show: true
                },
                {
                    key: 'inventario',
                    name: 'Inventario',
                    show: true
                },
                
            ],
            buscar:'',
            open: false,
            user: user.getAll(),
            data: [],
            actionModal: null,
            showM: false,
            id: 0,
            nombre: '',
            correo: '',
            telefono: '',
            rfc: '',
            provedores_data:[],
            productos_data:[],


        }

    },
    mounted() {

    },
    created() {
        this.getData();
    },
    methods: {
        addRow(){
            this.showM=!this.showM;
            this.limpiar()
        },
        
        limpiar() {
            this.id = 0;
            this.nombre = '';
            this.telefono = '';
            this.correo = '';

            this.rfc = ''
        },
       
         
                 async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await productos.GET_NOT_OPCION(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
       
        
       
        
        async eliminar(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await productos.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
